import {
  ISportTeamStaffModel,
  SportTeamStaffRoleService,
  SportTeamStaffService,
  TimeHelper,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { ICommonTeamDetailsTabProps } from "./ICommonTeamDetailsTabProps";
import {
  Button,
  Heading,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  Link,
  Popconfirm,
  Table,
  Tooltip,
  useAppFeedback,
} from "@bms/common-ui";
import { useTableDataProvider } from "../../../../../../helpers";
import { useTranslation } from "react-i18next";
import { FilterCleanIcon } from "../../../../../../resources/icons";
import { useState } from "react";
import { ROUTES as PEOPLE_ROUTES } from "../../../../../People/constants";
import { AddSportTeamStaffModal } from "../../../TeamStaff/AddSportTeamStaffModal";
import { SportTeamStaffForm } from "../../../TeamStaff/SportTeamStaffForm";

type Props = Pick<ICommonTeamDetailsTabProps, "team">;

const teamStaffService = new SportTeamStaffService().promisify();
const rolesService = new SportTeamStaffRoleService().promisify();

export const StaffTab = ({ team }: Props) => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const [modalOpen, setModalOpen] = useState(false);
  const [editableStaff, setEditableStaff] = useState<ISportTeamStaffModel>();

  const rolesLoader = useDataLoader({
    loader: () => rolesService.getRoles(),
    deps: [],
  });

  const {
    dataLoader: teamStaffLoader,
    filters,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      Roles: "strings",
    },
    loader: (filters, pagination) => {
      if (!team?.Id) {
        return;
      }

      return teamStaffService.search({
        ...filters,
        ...pagination,
        Teams: [team.Id],
      });
    },
    deps: [team?.Id],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
    }));

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value,
    }));

  const getColumnsProps = (): Array<
    ITableColumnProps<ISportTeamStaffModel>
  > => {
    return [
      {
        key: "FirstName",
        dataIndex: "FirstName",
        title: t("MODEL_FIRST_NAME"),
        render: (text: any, row: ISportTeamStaffModel) => {
          return (
            <a
              title={row.FirstName}
              onClick={() => {
                setModalOpen(true);
                setEditableStaff(row);
              }}
            >
              {row.FirstName}
            </a>
          );
        },
      },
      {
        key: "LastName",
        dataIndex: "LastName",
        title: t("MODEL_LAST_NAME"),
        render: (_, row: ISportTeamStaffModel) => (
          <Link to={`${PEOPLE_ROUTES.PEOPLE_DETAILS}/${row.PersonId}`}>
            {row.LastName}
          </Link>
        ),
      },
      {
        key: "Roles",
        dataIndex: "Roles",
        title: t("MODEL_ROLE"),
        filters: rolesLoader.data?.map((role) => ({
          text: role.DisplayName || role.Name,
          value: role.Code,
        })),
        filterSearch: true,
        filteredValue: filters.asTableArray.Roles,
        render: (_, row: ISportTeamStaffModel) =>
          row.RoleDisplayName || row.RoleCode,
      },
      {
        key: "DateFrom",
        dataIndex: "DateFrom",
        width: "200px",
        title: t("MODEL_DATE_FROM"),
        render: (_, row: ISportTeamStaffModel) =>
          row.DateFrom ? TimeHelper.format(row.DateFrom, "YYYY-MM-DD") : null,
      },
      {
        key: "DateTo",
        dataIndex: "DateTo",
        width: "200px",
        title: t("MODEL_DATE_TO"),
        render: (_, row: ISportTeamStaffModel) =>
          row.DateTo ? TimeHelper.format(row.DateTo, "YYYY-MM-DD") : null,
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_, teamStaff: ISportTeamStaffModel) => (
          <>
            <Popconfirm
              title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
              onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
                e?.preventDefault();
                const result = await teamStaffService.deleteTeamStaff(
                  teamStaff
                );
                if (result.ok) {
                  await teamStaffLoader.refresh();
                } else {
                  notification.error({
                    message: t("DELETE_ELEMENT_FAILTURE"),
                    description: result.error?.Message,
                  });
                }
              }}
              okText={t("BUTTON_YES")}
              cancelText={t("BUTTON_NO")}
            >
              <Button
                danger
                icon={<Icon type="delete" />}
                title={t("DELETE_ELEMENT")}
              />
            </Popconfirm>
          </>
        ),
      },
    ];
  };

  const [createTeamStaff, createTeamStaffState] = useServiceCaller(
    async (data: ISportTeamStaffModel) => {
      const result = await teamStaffService.createTeamStaff(data);
      if (result.ok) {
        setModalOpen(false);
        notification.success({
          message: t("SPORT_TEAM_STAFF_CREATE_SUCCESS"),
        });
        await teamStaffLoader.refresh();
      } else {
        notification.error({
          message: t("SPORT_TEAM_STAFF_CREATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const [updateTeamStaff, updateTeamStaffState] = useServiceCaller(
    async (data: ISportTeamStaffModel) => {
      const result = await teamStaffService.updateTeamStaff(data);
      if (result.ok) {
        setModalOpen(false);
        notification.success({
          message: t("SPORT_TEAM_STAFF_UPDATE_SUCCESS"),
        });
        await teamStaffLoader.refresh();
      } else {
        notification.error({
          message: t("SPORT_TEAM_STAFF_UPDATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  return (
    <>
      <AddSportTeamStaffModal
        visible={modalOpen}
        close={() => setModalOpen(false)}
        processing={
          createTeamStaffState.processing || updateTeamStaffState.processing
        }
      >
        <SportTeamStaffForm
          isEditMode={!!editableStaff}
          onSubmit={editableStaff ? updateTeamStaff : createTeamStaff}
          team={team}
          staff={editableStaff}
        />
      </AddSportTeamStaffModal>
      <Heading
        actions={
          <>
            <InputSearch
              key="search"
              placeholder={t("SEARCH_PLACEHOLDER")}
              value={fullTextSearch}
              onChange={({ target: { value } }) => setFullTextSearch(value)}
              onSearch={onSearch}
              style={{ width: 250 }}
              allowClear
            />
            <Tooltip overlay={t("MENU_OPTION_CLEAR_FILTERS")}>
              <Button
                key="clear-filters"
                shape="circle"
                icon={<FilterCleanIcon />}
                onClick={filters.clear}
                title={t("MENU_OPTION_CLEAR_FILTERS")}
              />
            </Tooltip>
            <Button
              key="reload"
              shape="circle"
              icon={<Icon type="reload" />}
              onClick={teamStaffLoader.refresh}
              title={t("BUTTON_REFRESH_TITLE")}
            />
            <Button
              key="add"
              shape="circle"
              type="primary"
              icon={<Icon type="plus" />}
              onClick={() => {
                setModalOpen(true);
                setEditableStaff(undefined);
              }}
              title={t("BUTTON_ADD")}
            />
          </>
        }
      />
      <Table<ISportTeamStaffModel>
        rowKey="Id"
        columns={getColumnsProps()}
        dataSource={teamStaffLoader.data?.Entities}
        loading={teamStaffLoader.loading}
        pagination={false}
        onChange={onTableChange}
      />
    </>
  );
};
