import { Button, Col, Form, Icon, Input, required, Row } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IPeopleModel } from "@bms/common-services";
import { useState } from "react";
import { ISportTeamStaffFormFieldCommonProps } from "./ISportTeamStaffFormFieldCommonProps";
import { SyncedPerson } from "../useSportTeamStaffController";
import { PeopleBrowserModal } from "../../../../../People/components";

interface IPersonFieldProps extends ISportTeamStaffFormFieldCommonProps {
  onSelect?: (row?: IPeopleModel) => void;
  onClear?: () => void;
  genericPerson: SyncedPerson;
}

export const PersonField = ({
  genericPerson,
  onSelect,
  onClear,
}: IPersonFieldProps) => {
  const { t } = useTranslation();

  const [personBrowserVisible, setPersonBrowserVisible] = useState(false);

  const onPersonSelect = (row: IPeopleModel) => {
    setPersonBrowserVisible(false);
    onSelect?.(row);
  };

  const defaultValue =
    genericPerson.FirstName || genericPerson.LastName
      ? `${genericPerson.FirstName || ""} ${genericPerson.LastName || ""}`
      : "";

  return (
    <Form.Item
      name="PersonId"
      label={t("MODEL_PERSON")}
      key="personId"
      initialValue={defaultValue}
      rules={[required()]}
    >
      <Row gutter={8}>
        <Col flex="auto">
          <Input
            placeholder={t("MODEL_PERSON_SELECT_PLACEHOLDER")}
            disabled
            value={defaultValue}
          />
          <PeopleBrowserModal
            visible={personBrowserVisible}
            onCancel={() => setPersonBrowserVisible(false)}
            onSelect={onPersonSelect}
          />
        </Col>
        <Col style={{ textAlign: "right" }}>
          {genericPerson.Id && (
            <Button
              icon={<Icon type="delete" />}
              onClick={onClear}
              style={{ marginRight: "8px" }}
            />
          )}
          <Button
            icon={<Icon type="edit" />}
            onClick={() => setPersonBrowserVisible(true)}
          />
        </Col>
      </Row>
    </Form.Item>
  );
};
