import {
  IPeopleModel,
  ISportTeamModel,
  ISportTeamStaffModel,
} from "@bms/common-services";
import { Form, IFormValues, useSendable, useSyncedState } from "@bms/common-ui";
import { pick } from "lodash";
import { useEffect, useMemo } from "react";
import { EVERY_FORM_FIELDS, formLayouts } from "./SportTeamStaffFormUtils";

export interface ISportTeamStaffFormProps {
  isEditMode: boolean;
  staff?: ISportTeamStaffModel;
  team?: ISportTeamModel;
  onSubmit?: (data: ISportTeamStaffModel) => void;
}

export type SyncedPerson = {
  Id?: string | number;
  FirstName?: string;
  LastName?: string;
};

export const useSportTeamStaffFormController = ({
  isEditMode,
  staff,
  team,
  onSubmit,
}: ISportTeamStaffFormProps) => {
  const [form] = Form.useForm();
  const { onFieldsChange, setDirty } = useSendable();

  const formLayout = useMemo(
    () => (isEditMode ? formLayouts.formItem : formLayouts.modalFormItem),
    [isEditMode]
  );

  const [person, setPerson] = useSyncedState<SyncedPerson>(
    () => ({
      Id: staff?.PersonId,
      FirstName: staff?.FirstName,
      LastName: staff?.LastName,
    }),
    [staff]
  );

  const onFinish = async (_values: IFormValues) => {
    const values = { ..._values };
    const picked: any = {};

    const parserMapper: IFormValues = {};

    EVERY_FORM_FIELDS.filter((it) => parserMapper[it] !== undefined).forEach(
      (it) => (picked[it] = parserMapper[it])
    );

    const staffDetails: Partial<ISportTeamStaffModel> = {
      Id: staff?.Id,
      DateFrom: values.DateFrom,
      DateTo: values.DateTo,
      TeamId: team?.Id,
      PersonId: Number(person.Id),
      ...(values.RoleId && { RoleId: Number(values.RoleId.value) }),
    };

    onSubmit?.(staffDetails as ISportTeamStaffModel);
  };

  useEffect(() => {
    form.resetFields();
  }, [JSON.stringify(pick(staff, EVERY_FORM_FIELDS))]);

  const onPersonSelect = (person?: IPeopleModel) => {
    setPerson({
      Id: person?.Id,
      FirstName: person?.FirstName,
      LastName: person?.LastName,
    });
    setDirty();
  };

  const onPersonClear = () => onPersonSelect();

  useEffect(() => {
    if (!person) {
      return;
    }
    form.setFieldValue("PersonId", `${person.FirstName} ${person.LastName}`);
  }, [person]);

  return {
    formLayout,
    formFieldNames: EVERY_FORM_FIELDS,
    form,
    onFieldsChange,
    onFinish,
    onPersonSelect,
    onPersonClear,
    person,
  };
};
