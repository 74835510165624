import { ReactElement, useState } from "react";
import { Button, Col, Form, Icon, Link, Row } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { UserBrowserModal } from "../../../../User/components/UserBrowserModal";
import { ROUTES as USER_ROUTES } from "../../../../User/constants";
import { IUserModel } from "@bms/common-services";
import { IPeopleFormFieldCommonProps } from "../..";

interface ICreatedByFieldProps extends IPeopleFormFieldCommonProps {
  onSelect?: (row?: IUserModel) => void;
  onClear?: () => void;
  userCreator: {
    Id?: string | number;
    FullName?: string | number;
  };
}

export const CreatedByField = ({
  isEditMode,
  isHidden,
  onSelect,
  onClear,
  userCreator,
}: ICreatedByFieldProps) => {
  const [userBrowserVisible, setUserBrowserVisible] = useState(false);
  const { t } = useTranslation();
  if (!isEditMode || isHidden) {
    return null;
  }

  const onUserSelect = (row: IUserModel) => {
    setUserBrowserVisible(false);
    onSelect?.(row);
  };

  const onUserClear = () => {
    onClear?.();
  };

  const creatorUserIdView: ReactElement | string = userCreator.Id ? (
    <Link to={`${USER_ROUTES.USER_DETAILS}/${userCreator.Id}`}>
      {userCreator.FullName}
    </Link>
  ) : (
    t("COMMON_UNDEFINED")
  );

  return (
    <Form.Item name="CreatorUserId" label={t("MODEL_CREATOR")}>
      <Row gutter={8}>
        <Col flex="auto">
          <Form.Item>{creatorUserIdView}</Form.Item>
          <UserBrowserModal
            profiles={["CREATOR"]}
            visible={userBrowserVisible}
            onCancel={() => setUserBrowserVisible(false)}
            onSelect={onUserSelect}
          />
        </Col>
        <Col style={{ textAlign: "right" }}>
          {userCreator.Id && (
            <Button
              icon={<Icon type="delete" />}
              onClick={onUserClear}
              style={{ marginRight: "8px" }}
            />
          )}
          <Button
            icon={<Icon type="edit" />}
            onClick={() => setUserBrowserVisible(true)}
          />
        </Col>
      </Row>
    </Form.Item>
  );
};
