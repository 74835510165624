import { Form, Input } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IMatchesFormFieldCommonProps } from "./IMatchesFormFieldCommonProps";

interface IStadiumFieldProps extends IMatchesFormFieldCommonProps {}

// For now - Disabled field
export const StadiumField = ({
  isHidden,
  isEditMode,
  match,
}: IStadiumFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="Stadium"
      label={t("MODEL_STADIUM")}
      key="stadium"
      initialValue={isEditMode ? match?.Stadium || "" : ""}
    >
      <Input placeholder={t("MODEL_STADIUM_PLACEHOLDER")} disabled />
    </Form.Item>
  );
};
