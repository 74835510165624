/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import {
  ApolloClient,
  ApolloQueryResult,
  DocumentNode,
  FetchResult,
  InMemoryCache,
  OperationVariables,
} from "@apollo/client";
import { IAxinomTokenModel } from "../models";
import { StorageManager } from "../../../../services";
import { TimeHelper } from "../../../../helpers";
import { ACCOUNT_TOKEN_MUTATION } from "../gql";

const apiUrl = process.env.REACT_APP_AXINOM_API_URL || "";
const authApiUrl = process.env.REACT_APP_AXINOM_AUTH_API_URL || "";
const authApiClientId = process.env.REACT_APP_AXINOM_AUTH_API_CLIENT_ID || "";
const authApiClientSecret =
  process.env.REACT_APP_AXINOM_AUTH_API_CLIENT_SECRET || "";

export class AxinomService {
  private _client: any;
  private _apiUrl = `${apiUrl}/graphql`;
  private _authApiUrl = `${authApiUrl}/graphql`;

  private initialize = async (): Promise<void> => {
    if (
      !StorageManager.getValue("axinomSession") ||
      TimeHelper.isAfterCurrent(StorageManager.getValue("axinomSession").TokenExpires)
    ) {
      await this.getAccessToken();
    }

    if (this._client) {
      return;
    }

    this._client = new ApolloClient({
      uri: this._apiUrl,
      cache: new InMemoryCache(),
    });
  };

  private getAccessToken = async (): Promise<void> => {
    const accountTokenVariables = {
      input: {
        clientId: authApiClientId,
        clientSecret: authApiClientSecret,
      },
    };
    const accountTokenClient = new ApolloClient({
      uri: this._authApiUrl,
      cache: new InMemoryCache(),
    });
    await accountTokenClient
      .mutate({
        mutation: ACCOUNT_TOKEN_MUTATION,
        variables: accountTokenVariables,
      })
      .then((result: FetchResult<IAxinomTokenModel>) => {
        const now = new Date();
        const tokenExpires = TimeHelper.getDateWithOffset(
          now,
          result.data?.authenticateServiceAccount.expiresInSeconds || 0,
          "second"
        );
        StorageManager.setValue("axinomSession", {
          Token: result.data?.authenticateServiceAccount.accessToken,
          TokenExpires: tokenExpires,
        });
      })
      .catch((error: any) => {
        throw { ...error, Message: error.message };
      });
  };

  public search = async <T>(
    query: DocumentNode,
    variables?: OperationVariables
  ): Promise<ApolloQueryResult<T>> => {
    await this.initialize();

    let headers = {};

    const authorizationToken = StorageManager.getValue("axinomSession").Token;

    headers = {
      Authorization: `Bearer ${authorizationToken}`,
    };

    return await this._client
      .query({
        query: query,
        variables: variables || {},
        context: {
          headers,
        },
      })
      .then((result: ApolloQueryResult<T>) => {
        return result;
      })
      .catch((error: any) => {
        throw { ...error, Message: error.message };
      });
  };
}
