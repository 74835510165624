import React from "react";
import { IBreadcrumbProps } from "@bms/common-ui";
import { ROUTES } from "../../constants";
import { generateBreadcrumb } from "../../../../helpers";
import { useTranslation } from "react-i18next";
import { UserSubscriptionsTable } from "../UserSubscriptionsTable";

export const UserSubscriptions = () => {
  const { t } = useTranslation();

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.SUBSCRIPTION_LIST}`,
        breadcrumbName: t("USER_SUBSCRIPTIONS_LIST_TITLE"),
      },
    ]);
  };

  return (
    <UserSubscriptionsTable
      tableTitle={t("USER_SUBSCRIPTIONS_LIST_TITLE")}
      getBreadcrumbProps={getBreadcrumbProps}
    />
  );
};
