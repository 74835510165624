import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { HTTP_METHOD } from "../../constants";
import { PromisifiableBase } from "../Base";
import { IVoucherPriceListFilterModel, IVoucherPriceListItemModel, IVoucherPriceListModel } from "../../models";

export class VoucherPriceListService extends PromisifiableBase {
  get url(): string {
    return "/VoucherPriceList";
  }

  public search = (data: IVoucherPriceListFilterModel): Observable<IVoucherPriceListModel> =>
    new Observable(
      (observer: Observer<IVoucherPriceListModel>) =>
         new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Search`,
        })
    );

  public update = (data: IVoucherPriceListItemModel): Observable<IVoucherPriceListItemModel> =>
    new Observable(
      (observer: Observer<IVoucherPriceListItemModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Update`,
        })
    );

  public insert = (data: IVoucherPriceListItemModel): Observable<IVoucherPriceListItemModel> =>
    new Observable(
      (observer: Observer<IVoucherPriceListItemModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Insert`,
        })
    );

  public delete = (data: IVoucherPriceListItemModel): Observable<undefined> =>
    new Observable(
      (observer: Observer<undefined>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.DELETE,
          url: `${this.url}/Delete?id=${data.Id}`,
        })
    );
}
