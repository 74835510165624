export const ROUTES = {
  AGE_CATEGORIES: "/sport/age-categories",
  PLAYER_POSITIONS: "/sport/player-positions",
  PLAYERS: "/sport/players",
  PLAYER_DETAILS: "/sport/player-details",
  TEAMS: "/sport/teams",
  TEAM_DETAILS: "/sport/team-details",
  STAFF_ROLES: "/sport/staff-roles",
  MATCHES: "/sport/matches",
  MATCH_DETAILS: "/sport/match-details",
  CLUBS: "/sport/clubs",
  CLUB_DETAILS: "/sport/club-details",
  LEAGUES: "/sport/leagues",
  LEAGUE_DETAILS: "/sport/league-details",
  STATISTICS_UNITS: "/sport/statistics-units",
};
