/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useState } from "react";
import { Alert, Button, Form, Modal, Result, Spin } from "@bms/common-ui";
import { AssetService, IErrorModel } from "@bms/common-services";
import { useTranslation } from "react-i18next";

const assetService = new AssetService();

export interface IAssetSyncProps {
  visible: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export const AssetSyncModal: React.FC<IAssetSyncProps> = ({
  visible,
  onCancel,
  onSuccess,
}: IAssetSyncProps) => {
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<IErrorModel>();
  const isFinished = !isProcessing && (success || error) ? true : false;

  const [form] = Form.useForm();

  const reset = () => {
    setSuccess(false);
    setError(undefined);
  };

  const onFinish = async () => {
    setIsProcessing(true);

    try {
      await assetService.importFastyData().toPromise();
      setSuccess(true);
      setIsProcessing(false);
    } catch (error) {
      setError(error as IErrorModel);
      setIsProcessing(false);
    }
  };

  const renderInfo = () => {
    if (isFinished) {
      return null;
    }

    if (isProcessing) {
      return (
        <Form.Item
          style={{
            textAlign: "center",
            paddingTop: "24px",
            paddingBottom: "24px",
          }}
        >
          <Spin tip={t("ASSET_SYNC_MODAL_PROGRESS_MESSAGE")} />
        </Form.Item>
      );
    }

    return (
      <Form.Item>
        <Alert
          description={t("ASSET_SYNC_MODAL_INFO_MESSAGE")}
          type="info"
          showIcon
        />
      </Form.Item>
    );
  };

  const renderResult = () => {
    if (error) {
      return (
        <Form.Item>
          <Result
            status="error"
            title={t("ASSET_SYNC_MODAL_ERROR_MESSAGE")}
            extra={[
              <Button key="close" onClick={onCancelModal}>
                {t("BUTTON_CLOSE")}
              </Button>,
            ]}
          />
        </Form.Item>
      );
    }

    if (success) {
      return (
        <Form.Item>
          <Result
            status="success"
            title={t("ASSET_SYNC_MODAL_SUCCESS_MESSAGE")}
            extra={[
              <Button
                key="close"
                onClick={() => {
                  onSuccess?.();
                  reset();
                }}
              >
                {t("BUTTON_CLOSE")}
              </Button>,
            ]}
          />
        </Form.Item>
      );
    }

    return null;
  };

  const onCancelModal = () => {
    onCancel?.();
    reset();
  };

  const renderFooter = () => {
    if (isFinished) {
      return null;
    }

    return (
      <>
        <Button
          key="cancel"
          onClick={onCancelModal}
          type="link"
          disabled={isProcessing}
        >
          {t("BUTTON_CANCEL")}
        </Button>
        <Button
          form="AssetSyncForm"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={isProcessing}
          disabled={isProcessing}
        >
          {t("BUTTON_SYNC")}
        </Button>
      </>
    );
  };

  return (
    <Modal
      title={t("ASSET_SYNC_MODAL_TITLE")}
      footer={renderFooter()}
      processing={isProcessing}
      width={600}
      open={visible}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={onCancelModal}
    >
      <Form
        form={form}
        name="AssetSyncForm"
        className="AssetSyncForm"
        onFinish={onFinish}
      >
        {renderInfo()}
        {renderResult()}
      </Form>
    </Modal>
  );
};
