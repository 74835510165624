import { Form } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import {
  IListModelBase,
  IModelBase,
  ISportTeamStaffRoleModel,
  SportTeamStaffRoleService,
} from "@bms/common-services";
import { LazyChoose } from "../../../../../../components";
import { ISportTeamStaffFormFieldCommonProps } from "./ISportTeamStaffFormFieldCommonProps";

interface IRoleIdFieldProps extends ISportTeamStaffFormFieldCommonProps {}

const rolesService = new SportTeamStaffRoleService();

let roles: ISportTeamStaffRoleModel[];

export const RoleIdField = ({ isEditMode, staff }: IRoleIdFieldProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name="RoleId"
      label={t("MODEL_ROLE")}
      initialValue={
        (isEditMode && {
          value: staff?.RoleId,
          label: staff?.RoleDisplayName || staff?.RoleCode,
        }) ||
        {}
      }
      rules={[{ required: true }]}
    >
      <LazyChoose<
        ISportTeamStaffRoleModel & IModelBase,
        ISportTeamStaffRoleModel & IModelBase
      >
        loader={async () => {
          if (!roles) {
            roles = await rolesService.getRoles().toPromise();
          }
          const data: IListModelBase<
            ISportTeamStaffRoleModel & IModelBase,
            {}
          > = {
            TotalCount: roles.length,
            Entities: roles,
          };
          return {
            data,
            ok: true,
          };
        }}
        candidateToOption={(item) => ({
          label: item.DisplayName || item.Code,
          value: `${item.Id}`,
        })}
        multiple={false}
        selectedToOption={(item) => ({
          label: item.DisplayName || item.Code,
          value: `${item.Id}`,
          item,
        })}
      />
    </Form.Item>
  );
};
