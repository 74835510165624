import React, { useEffect, useMemo, useState } from "react";
import {
  PageContent,
  Pagination,
  PageHeader,
  IBreadcrumbProps,
  useAppFeedback,
  InputSearch,
  Tooltip,
  Button,
  Table,
  ITableFilter,
  Link,
  Tag,
  Text,
  ITableColumnProps,
  DomainTag,
  setTableColumnSearchProps,
} from "@bms/common-ui";
import { generateBreadcrumb } from "../../../../helpers";
import { useTranslation } from "react-i18next";
import {
  CurrencyStore,
  IVoucherModel,
  IVoucherSearchFilterModel,
  TimeHelper,
  VoucherDiscountType,
  VoucherRedeemType,
  VouchersService,
} from "@bms/common-services";
import { useTableDataProvider } from "../../../../helpers";
import { FilterCleanIcon } from "./../../../../resources/icons";
import { ROUTES as COMMON_ROUTES } from "../../../../constants";
import { ROUTES } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { VoucherCreateModalForm } from "../VoucherCreateModalForm";
import { TFilterSchemaBasedOnFilterModelWithoutPagination } from "helpers/searchFilters";

import "./VoucherList.scss";

const vouchersService = new VouchersService().promisify();

type TVoucherListFilterSchema = TFilterSchemaBasedOnFilterModelWithoutPagination<
  IVoucherSearchFilterModel
>;

const filtersSchema: TVoucherListFilterSchema = {
  FullTextSearch: "string",
  RedeemType: "string",
  Code: "string",
  Currencies: "strings",
  DiscountType: "string",
};

export const VoucherList = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { notification } = useAppFeedback();
  const { data: currenciesData } = useSelector(
    CurrencyStore.Selectors.currenciesSelector
  );
  const dispatch = useDispatch();
  const [isCreateFormVisible, setIsCreateFormVisible] = useState<boolean>(
    false
  );

  const isCurrencyDataFetched = Boolean(currenciesData);
  useEffect(() => {
    if (!isCurrencyDataFetched) {
      dispatch(CurrencyStore.Actions.selectCurrencies());
    }
  }, [isCurrencyDataFetched, dispatch]);

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.VOUCHER_LIST}`,
        breadcrumbName: t("PAYMENT_VOUCHER_LIST_TITLE"),
      },
    ]);
  };

  const {
    dataLoader,
    filters,
    pagination,
    setFullTextSearch,
    fullTextSearch,
  } = useTableDataProvider({
    filtersSchema,
    debounce: 800,
    loader: (filters, pagination) =>
      vouchersService.search({
        ...filters,
        ...pagination,
      }),
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
    deps: [],
  });

  const extraItems = useMemo(() => {
    const onSearch = (text: string) => {
      filters.update((oldFilters) => ({
        ...oldFilters,
        FullTextSearch: text || undefined,
      }));
    };

    const onSearchInputChange = (el: React.ChangeEvent<HTMLInputElement>) => {
      const text = el.target.value;
      setFullTextSearch(text);
    };

    return [
      <InputSearch
        key="VoucherListHeaderSearchInput"
        placeholder={t("SEARCH_PLACEHOLDER", "Search...")}
        value={fullTextSearch}
        onSearch={onSearch}
        onChange={onSearchInputChange}
        style={{ width: 250 }}
        allowClear
      />,
      <Tooltip
        key="clear-filter-tooltip"
        overlay={t("MENU_OPTION_CLEAR_FILTERS", "")}
      >
        <Button
          key="VoucherListHeaderClearBtn"
          shape="circle"
          icon={<FilterCleanIcon />}
          onClick={filters.clear}
          title={t("BUTTON_RESET")}
        />
      </Tooltip>,
      <Button
        key="VoucherListHeaderRefreshBtn"
        shape="circle"
        icon={<ReloadOutlined />}
        onClick={dataLoader.refresh}
        title={t("BUTTON_REFRESH_TITLE")}
      />,
      <Button
        key="VoucherListHeaderAddBtn"
        shape="circle"
        type="primary"
        icon={<PlusOutlined />}
        disabled={!isCurrencyDataFetched || !Boolean(currenciesData?.length)}
        onClick={() => {
          setIsCreateFormVisible(true);
        }}
        title={t("BUTTON_ADD")}
      />,
    ];
  }, [
    dataLoader.refresh,
    fullTextSearch,
    setFullTextSearch,
    filters.clear,
    filters.update,
  ]);

  const currenciesCodes = currenciesData?.map((currency) => currency.Code);

  const columns = useMemo<Array<ITableColumnProps<IVoucherModel>>>(
    () => [
      {
        key: "Code",
        dataIndex: "Code",
        title: t("MODEL_CODE"),
        width: "140px",
        render: (_: unknown, row) => {
          return (
            <Link to={`${ROUTES.VOUCHER_DETAILS}/${row.Id}`}>
              <Text copyable>{row.Code}</Text>
            </Link>
          );
        },
        filteredValue: filters.asTableArray.Code,
        ...setTableColumnSearchProps("Code", t("MODEL_CODE", "Code")),
      },
      {
        key: "DiscountType",
        dataIndex: "DiscountType",
        title: t("PAYMENT_VOUCHER_DISCOUNT_TYPE_COLUMN"),
        render: (_: unknown, row) => (
          <Tag colorRotate={Number(row.DiscountType)}>
            {row.DiscountType === VoucherDiscountType.Fixed
              ? t("PAYMENT_VOUCHER_DISCOUNT_TYPE_COLUMN_FIXED_FILTER")
              : t("PAYMENT_VOUCHER_DISCOUNT_TYPE_COLUMN_PERCENTAGE_FILTER")}
          </Tag>
        ),
        filters: [
          {
            text: t("PAYMENT_VOUCHER_DISCOUNT_TYPE_COLUMN_FIXED_FILTER"),
            value: VoucherDiscountType.Fixed,
          },
          {
            text: t("PAYMENT_VOUCHER_DISCOUNT_TYPE_COLUMN_PERCENTAGE_FILTER"),
            value: VoucherDiscountType.Percentage,
          },
        ],
        filteredValue: filters.asTableArray.DiscountType || null,
        filterMultiple: false,
      },
      {
        key: "Value",
        dataIndex: "Value",
        title: t("MODEL_VALUE"),
        render: (_: unknown, row) => <>{row.Value}</>,
      },
      {
        key: "CurrencyCode",
        dataIndex: "CurrencyCode",
        title: t("MODEL_CURRENCY"),
        render: (_: unknown, row) => (
          <DomainTag domain="currency" noMargin value={row.CurrencyCode} />
        ),
        filters:
          currenciesCodes?.map((currencyCode) => ({
            text: currencyCode,
            value: currencyCode,
          })) ?? [],
        filteredValue: filters.asTableArray.Currencies || null,
        filterMultiple: true,
      },
      {
        key: "RedeemType",
        dataIndex: "RedeemType",
        title: t("PAYMENT_VOUCHER_REDEEM_TYPE_COLUMN"),
        render: (_: unknown, row) => (
          <Tag colorRotate={Number(row.RedeemType)}>
            {row.RedeemType === VoucherRedeemType.OneTime
              ? t("PAYMENT_VOUCHER_REDEEM_TYPE_COLUMN_ONE_TIME_FILTER")
              : t("PAYMENT_VOUCHER_REDEEM_TYPE_COLUMN_MULTIPLE_FILTER")}
          </Tag>
        ),
        filters: [
          {
            text: t("PAYMENT_VOUCHER_REDEEM_TYPE_COLUMN_ONE_TIME_FILTER"),
            value: VoucherRedeemType.OneTime,
          },
          {
            text: t("PAYMENT_VOUCHER_REDEEM_TYPE_COLUMN_MULTIPLE_FILTER"),
            value: VoucherRedeemType.Multiple,
          },
        ],
        filteredValue: filters.asTableArray.RedeemType || null,
        filterMultiple: false,
      },
      {
        key: "Created",
        dataIndex: "Created",
        title: t("MODEL_CREATED"),
        render: (_: unknown, row) => TimeDisplay(row.Created),
      },
      {
        key: "CreatedBy",
        dataIndex: "CreatedBy",
        title: t("MODEL_CREATED_BY"),
        render: (_: unknown, row) => (
          <Link to={`${COMMON_ROUTES.USER_DETAILS}/${row.CreatedBy}`}>
            <p>{row.CreatedByFullName}</p>
          </Link>
        ),
      },
      {
        key: "ValidTo",
        dataIndex: "ValidTo",
        title: t("PAYMENT_VOUCHER_VALID_TO_COLUMN"),
        render: (_: unknown, row) => TimeDisplay(row.ValidTo),
      },
      {
        key: "RedemptionLimit",
        dataIndex: "RedemptionLimit",
        title: t("PAYMENT_VOUCHER_REDEMPTION_LIMIT_COLUMN"),
        render: (_: unknown, row) => <>{row.RedemptionLimit}</>,
      },
      {
        key: "RedeemedCount",
        dataIndex: "RedeemedCount",
        title: t("PAYMENT_VOUCHER_REDEEM_COUNT_COLUMN"),
        render: (_: unknown, row) => <>{row.RedeemedCount}</>,
      },
      // {
      //   key: "UsageCount",
      //   dataIndex: "UsageCount",
      //   title: t("PAYMENT_VOUCHER_USAGE_COUNT_COLUMN"),
      //   render: (_: unknown, row) => <>{row.UsageCount}</>,
      // },
      {
        key: "UserId",
        dataIndex: "UserId",
        title: t("PAYMENT_VOUCHER_USER_FULL_NAME_COLUMN"),
        render: (_: unknown, row) => (
          <Link to={`${COMMON_ROUTES.USER_DETAILS}/${row.UserId}`}>
            <p>{row.UserFullName}</p>
          </Link>
        ),
      },
    ],
    [filters, language, currenciesCodes]
  );

  const handleTableChange = (_: unknown, tableFilters: ITableFilter) => {
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...{
        DiscountType: tableFilters.DiscountType?.[0],
        Code: tableFilters.Code?.[0],
        RedeemType: tableFilters.RedeemType?.[0],
        Currencies: tableFilters.CurrencyCode,
      },
    }));
  };

  return (
    <PageContent
      className="VoucherList"
      footer={<Pagination {...pagination.props} />}
    >
      <PageHeader
        title={t("PAYMENT_VOUCHER_LIST_TITLE")}
        extra={extraItems}
        breadcrumb={getBreadcrumbProps?.()}
      />
      <VoucherCreateModalForm
        visible={isCreateFormVisible}
        onCancel={() => setIsCreateFormVisible(false)}
      />
      <Table<IVoucherModel>
        rowKey={(row) => `${row.Guid}${row.Id}`}
        columns={columns}
        dataSource={dataLoader.data?.Entities}
        loading={dataLoader.loading}
        pagination={false}
        tableLayout="auto"
        onChange={handleTableChange}
        className="VoucherListTable"
      />
    </PageContent>
  );
};

function TimeDisplay(timeString: string | undefined) {
  if (!timeString) {
    return null;
  }
  const [datePart, timePart] = TimeHelper.format(timeString).split(" ");
  return (
    <>
      <span className="nowrapOnHyphen">{datePart}</span>{" "}
      <span className="nowrapOnHyphen">{timePart}</span>
    </>
  );
}
