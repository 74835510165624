import { Button, Col, Form, Row } from "@bms/common-ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FieldType, formLayouts } from "./LeagueFormUtils";
import {
  ILeagueFormProps,
  useLeagueFormController,
} from "./useLeagueFormController";
import {
  CountryCodeField,
  CreatedByField,
  GroupNoField,
  LevelField,
  NameField,
} from "./Fields";

export const LeagueForm = (props: ILeagueFormProps) => {
  const { t } = useTranslation();
  const { isEditMode, league, section, hiddenFields } = props;
  const {
    formLayout,
    formFieldNames,
    sendable,
    form,
    onFieldsChange,
    onFinish,
    userCreator,
    onUserSelect,
    onUserClear,
  } = useLeagueFormController(props);

  const isHidden = (key: FieldType) =>
    !!hiddenFields && hiddenFields.includes(key);

  const formFieldsMapping: { [key: string]: () => JSX.Element } = {
    Name: () => (
      <NameField
        key="LeagueForm--NameField"
        isHidden={isHidden("Name")}
        isEditMode={isEditMode}
        league={league}
      />
    ),
    GroupNo: () => (
      <GroupNoField
        key="LeagueForm--GroupNoField"
        isHidden={isHidden("GroupNo")}
        isEditMode={isEditMode}
        league={league}
      />
    ),
    Level: () => (
      <LevelField
        key="LeagueForm--LevelField"
        isHidden={isHidden("Level")}
        isEditMode={isEditMode}
        league={league}
      />
    ),
    CountryCode: () => (
      <CountryCodeField
        key="LeagueForm--CountryCodeField"
        isEditMode={isEditMode}
        isHidden={isHidden("CountryCode")}
        league={league}
      />
    ),
    CreatedBy: () => (
      <CreatedByField
        key="LeagueForm--CreatedByField"
        isEditMode={isEditMode}
        isHidden={isHidden("CreatedBy")}
        onSelect={onUserSelect}
        onClear={onUserClear}
        userCreator={userCreator}
      />
    ),
  };

  const formFields = useMemo(
    () =>
      formFieldNames.map((field: string) =>
        formFieldsMapping[field] ? formFieldsMapping[field]() : null
      ),
    [formFieldNames, league, userCreator]
  );

  return (
    <Form
      {...formLayout}
      form={form}
      name={section ? `LeagueForm-${section}` : "LeagueForm"}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      className="LeagueForm"
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          {formFields}
          {isEditMode && (
            <Form.Item {...formLayouts.tailFormItem}>
              <Button type="primary" htmlType="submit" disabled={!sendable}>
                {t("BUTTON_SAVE")}
              </Button>
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};
