import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  EntityListModel,
  PaginationModel,
  ISportTeamPlayerSearchFilterModel,
  ISportTeamPlayerModel,
  ISportTeamPlayerListModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class SportTeamPlayerService extends SimpleServiceBase<
  ISportTeamPlayerModel,
  ISportTeamPlayerListModel,
  number,
  ISportTeamPlayerSearchFilterModel
> {
  get url(): string {
    return "/SportTeamsPlayers";
  }

  public getTeamsPlayersList = (
    data: ISportTeamPlayerModel,
    pagination: PaginationModel
  ): Observable<EntityListModel<ISportTeamPlayerModel>> =>
    new Observable(
      (observer: Observer<EntityListModel<ISportTeamPlayerModel>>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: pagination,
          },
          data,
          method: HTTP_METHOD.POST,
          url: `/SportTeamsPlayers/Search`,
        })
    );

  public getTeamPlayer = (id: number): Observable<ISportTeamPlayerModel> =>
    new Observable(
      (observer: Observer<ISportTeamPlayerModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id },
          },
          method: HTTP_METHOD.GET,
          url: `/SportTeamsPlayers/Get`,
        })
    );

  public createTeamPlayer = (
    data: ISportTeamPlayerModel
  ): Observable<ISportTeamPlayerModel> =>
    new Observable(
      (observer: Observer<ISportTeamPlayerModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/SportTeamsPlayers/Insert`,
        })
    );

  public updateTeamPlayer = (
    data: ISportTeamPlayerModel
  ): Observable<ISportTeamPlayerModel> =>
    new Observable(
      (observer: Observer<ISportTeamPlayerModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `/SportTeamsPlayers/Update`,
        })
    );

  public deleteTeamPlayer = (
    data: ISportTeamPlayerModel
  ): Observable<ISportTeamPlayerModel> =>
    new Observable(
      (observer: Observer<ISportTeamPlayerModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id: data.Id },
          },
          method: HTTP_METHOD.DELETE,
          url: `/SportTeamsPlayers/Delete`,
        })
    );
}
