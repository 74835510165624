import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  EntityListModel,
  PaginationModel,
  ISportTeamStaffModel,
  ISportTeamStaffListModel,
  ISportTeamStaffSearchFilterModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class SportTeamStaffService extends SimpleServiceBase<
  ISportTeamStaffModel,
  ISportTeamStaffListModel,
  number,
  ISportTeamStaffSearchFilterModel
> {
  get url(): string {
    return "/SportTeamStaff";
  }

  public getTeamStaffList = (
    data: ISportTeamStaffModel,
    pagination: PaginationModel
  ): Observable<EntityListModel<ISportTeamStaffModel>> =>
    new Observable(
      (observer: Observer<EntityListModel<ISportTeamStaffModel>>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: pagination,
          },
          data,
          method: HTTP_METHOD.POST,
          url: `/SportTeamStaff/Search`,
        })
    );

  public getTeamStaff = (id: number): Observable<ISportTeamStaffModel> =>
    new Observable(
      (observer: Observer<ISportTeamStaffModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id },
          },
          method: HTTP_METHOD.GET,
          url: `/SportTeamStaff/Get`,
        })
    );

  public createTeamStaff = (
    data: ISportTeamStaffModel
  ): Observable<ISportTeamStaffModel> =>
    new Observable(
      (observer: Observer<ISportTeamStaffModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/SportTeamStaff/Insert`,
        })
    );

  public updateTeamStaff = (
    data: ISportTeamStaffModel
  ): Observable<ISportTeamStaffModel> =>
    new Observable(
      (observer: Observer<ISportTeamStaffModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `/SportTeamStaff/Update`,
        })
    );

  public deleteTeamStaff = (
    data: ISportTeamStaffModel
  ): Observable<ISportTeamStaffModel> =>
    new Observable(
      (observer: Observer<ISportTeamStaffModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id: data.Id },
          },
          method: HTTP_METHOD.DELETE,
          url: `/SportTeamStaff/Delete`,
        })
    );
}
