import {
  IUserModel,
  UserAssetPurchasesService,
  useServiceCaller,
} from "@bms/common-services";
import { useAppFeedback } from "@bms/common-ui";
import { FormModal } from "../../../../components";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  UserGrantProductModalForm,
  IUserGrantProductForm,
} from "./UserGrantProductModalForm";

const userAssetPurchasesService = new UserAssetPurchasesService().promisify();

interface IUserGrantProductModalProps {
  open: boolean;
  user: IUserModel;
  onSuccess?: () => void;
  onClose: () => void;
}

export const UserGrantProductModal: React.FC<IUserGrantProductModalProps> = ({
  user,
  onSuccess,
  onClose,
  open,
}) => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const [grantProduct, { processing }] = useServiceCaller(
    async (formValues: IUserGrantProductForm) => {
      const result = await userAssetPurchasesService.grantProduct({
        UserId: user.Id,
        AssetId: formValues.Asset.id,
        EndDate:
          formValues.AvailableTo?.set("hour", 24)
            .set("minute", 59)
            .set("second", 59)
            .toISOString() ?? undefined,
      });

      if (!result.ok) {
        return notification.error({
          message: t("GRANT_PRODUCT_FAILURE_MESSAGE"),
          description: result.error?.Message,
        });
      }

      notification.success({
        message: t("GRANT_PRODUCT_SUCCESS_MESSAGE"),
      });

      return onSuccess?.();
    },
    [user.Id]
  );

  return (
    <FormModal
      isLoading={processing}
      isVisible={open}
      isNewForm={true}
      isDeleteButtonEnabled={false}
      createFormTitle={t("GRANT_PRODUCT_MODAL_TITLE", {
        fullName: user.FullName,
      })}
      modalClassName="UserGrantProductModalForm"
      submitFormName="UserGrantProductModalForm"
      confirmButtonTransKey="GRANT_PRODUCT_MODAL_GRANT_ACTION"
      cancelButtonTransKey="GRANT_PRODUCT_MODAL_CANCEL_ACTION"
      onCloseModal={onClose}
    >
      <UserGrantProductModalForm onSubmit={grantProduct} />
    </FormModal>
  );
};
