/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { Form, TextArea } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
import { CUSTOM_METADATA_FILEDS } from "../AssetFormUtils";

interface IAIPromptFieldProps extends IAssetFormFieldCommonProps {}

export const AIPromptField = ({
  isHidden,
  isEditMode,
  asset,
}: IAIPromptFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      key={CUSTOM_METADATA_FILEDS.AI_PROMPT}
      name={CUSTOM_METADATA_FILEDS.AI_PROMPT}
      label={t("MODEL_AI_PROMPT")}
      initialValue={isEditMode ? asset?.CustomMetadata?.AIPrompt || "" : ""}
    >
      <TextArea
        placeholder={t("MODEL_AI_PROMPT_PLACEHOLDER")}
        autoSize={{ minRows: 1 }}
        allowClear
      />
    </Form.Item>
  );
};
