import { PaymentStatus } from "@bms/common-services";
import React from "react";
import { Tag } from "../Tag";

interface PaymentStatusTagProps {
  status?: PaymentStatus;
  text?: string;
}

export const PaymentStatusTag: React.FC<PaymentStatusTagProps> = ({
  status,
  text,
}) => {
  let color;

  switch (status) {
    case PaymentStatus.Completed:
      color = "#418841";
      break;
    default:
      color = "#787878";
  }

  return <Tag color={color}>{text || "?"}</Tag>;
};
