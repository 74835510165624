import React, { useMemo, useState } from "react";
import {
  IAnalyticsPaymentListFilterModel,
  IAnalyticsPaymentModel,
  TimeHelper,
  useConfig,
} from "@bms/common-services";
import {
  Button,
  DatePicker,
  DomainTag,
  ICurrentArrayFilters,
  IFiltersSchema,
  IFiltersUpdater,
  InputNumber,
  ITableColumnFilterDropdownProps,
  ITableColumnProps,
  Link,
  Text,
} from "@bms/common-ui";
import { Trans, useTranslation } from "react-i18next";
import { ROUTES } from "../constants";
import { ROUTES as COMMON_ROUTES } from "../../../constants";
import { ROUTES as USER_ROUTES } from "../../User/constants";
import { PaymentListTableDataProviderFilterModel } from "../types";
import { useAssetPurchasePeriodTypeDataProvider } from "./useAssetPurchasePeriodTypeDataProvider";

interface IDataFilters {
  current: IAnalyticsPaymentListFilterModel;
  clear: () => void;
  update: (
    updater: IFiltersUpdater<PaymentListTableDataProviderFilterModel>
  ) => void;
  readonly asTableArray: ICurrentArrayFilters<IFiltersSchema>;
  readonly anyActive: boolean;
}

const defaults: ITableColumnProps<IAnalyticsPaymentModel> = {
  ellipsis: true,
  align: "center",
};

const generatePaymentOption = (option: string) => ({
  key: option,
  value: option,
  label: option,
});

const paymentStatusOptions = [
  generatePaymentOption("CREATED"),
  generatePaymentOption("COMPLETED"),
  generatePaymentOption("FAILED"),
];

const smallControlsStyle = {
  width: "100%",
  marginTop: "5px",
  marginBottom: "5px",
};

export const usePaymentListColumns = (filters: IDataFilters) => {
  const { t } = useTranslation();
  const { config } = useConfig();

  const { purchasePeriodTypes } = useAssetPurchasePeriodTypeDataProvider();

  const columns: Array<ITableColumnProps<
    IAnalyticsPaymentModel
  >> = useMemo(() => {
    const cols: ITableColumnProps<IAnalyticsPaymentModel>[] = [
      {
        key: "PaymentKey",
        dataIndex: "PaymentKey",
        title: t("MODEL_PAYMENT_KEY"),
        ellipsis: true,
        render: (_: string, row) => {
          return (
            <Link to={`${ROUTES.PAYMENT_DETAILS}?paymentKey=${row.PaymentKey}`}>
              {row.PaymentKey}
            </Link>
          );
        },
      },
      {
        key: "UserName",
        dataIndex: "UserName",
        title: t("MODEL_PURCHASED_BY"),
        ...defaults,
        render: (_: string, row) => (
          <Link to={`${USER_ROUTES.USER_DETAILS}/${row.UserId}`}>
            {row.UserName}
          </Link>
        ),
      },
      {
        key: "UserEmail",
        dataIndex: "UserEmail",
        title: t("MODEL_PAYMENT_EMAIL"),
        ...defaults,
        render: (_: string, row) => (
          <Text ellipsis copyable>
            {row.UserEmail}
          </Text>
        ),
      },
      {
        key: "Title",
        dataIndex: "Title",
        title: t("MODEL_PURCHASED_PRODUCT_NAME"),
        ...defaults,
        render: (_: string, row) => {
          switch (row.PaymentSource) {
            case "PAYMENT_SOURCE_VOUCHER":
              return (
                <Link
                  to={`${ROUTES.VOUCHER_DETAILS}/${row.PurchasedVoucherId}`}
                >
                  {row.Title}
                </Link>
              );
            case "PAYMENT_SOURCE_ASSET":
              return (
                <Link to={`${COMMON_ROUTES.ASSET_DETAILS}/${row.AssetId}`}>
                  {row.Title}
                </Link>
              );
            default:
              return <span>{row.Title}</span>;
          }
        },
      },
      {
        key: "PaymentTypes",
        dataIndex: "PaymentType",
        title: t("MODEL_TYPE_NAME"),
        filters: purchasePeriodTypes.map((periodType) => ({
          text: periodType.label,
          value: periodType.value,
        })),
        filteredValue: filters.asTableArray.PaymentTypes || null,
        render: (_: string, row) => (
          <DomainTag
            key={row.PaymentKey}
            domain="purchase-period"
            noMargin
            colorRotate={row.PaymentType}
            value={
              purchasePeriodTypes.find((it) => it.value === row.PaymentType)
                ?.label || row.PaymentType
            }
          />
        ),
      },
      {
        key: "Price",
        dataIndex: "Price",
        className: "PaymentList__Price",
        title: t("MODEL_PRICE"),
        ellipsis: true,
        align: "right",
        width: 140,
        render: (_, row) => {
          return (
            <>
              {row?.OriginalPrice && row?.OriginalPrice !== row?.Price && (
                <span className="PaymentList__OriginalPrice">
                  {`${row.OriginalPrice.toFixed(2)}`}&nbsp;&nbsp;
                </span>
              )}
              {`${row.Price.toFixed(2)}`.replace(".", ",")}
            </>
          );
        },
        filteredValue:
          filters.asTableArray.PriceFrom ||
          filters.asTableArray.PriceTo ||
          null,
        filterDropdown: (event: ITableColumnFilterDropdownProps) => {
          const { confirm } = event;

          const [priceFrom, setPriceFrom] = useState(filters.current.PriceFrom);
          const [priceTo, setPriceTo] = useState(filters.current.PriceTo);

          return (
            <div style={{ padding: 8 }}>
              <InputNumber
                placeholder={t("PAYMENT_LIST_PRICE_FROM")}
                style={smallControlsStyle}
                value={priceFrom}
                onChange={(e) => typeof e === "number" && setPriceFrom(e)}
              />
              <InputNumber
                placeholder={t("PAYMENT_LIST_PRICE_TO")}
                style={smallControlsStyle}
                value={priceTo}
                onChange={(e) => typeof e === "number" && setPriceTo(e)}
              />

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  type="link"
                  size="small"
                  disabled={priceFrom === undefined && priceTo === undefined}
                  onClick={() => {
                    setPriceFrom(undefined);
                    setPriceTo(undefined);

                    filters.update((oldFilters) => ({
                      ...oldFilters,
                      PriceFrom: undefined,
                      PriceTo: undefined,
                    }));
                  }}
                >
                  <Trans i18nKey="BUTTON_RESET">Reset</Trans>
                </Button>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    const newFilters: IAnalyticsPaymentListFilterModel = {};
                    if (priceFrom !== undefined) {
                      newFilters.PriceFrom = priceFrom;
                    }
                    if (priceTo !== undefined) {
                      newFilters.PriceTo = priceTo;
                    }
                    filters.update((oldFilters) => ({
                      ...oldFilters,
                      ...newFilters,
                    }));
                    confirm();
                  }}
                >
                  <Trans i18nKey="BUTTON_OK">OK</Trans>
                </Button>
              </div>
            </div>
          );
        },
      },
      {
        key: "Currency",
        dataIndex: "Currency",
        title: t("MODEL_CURRENCY"),
        width: 100,
        ...defaults,
        render: (_: string, row) => (
          <DomainTag
            key={row.PaymentKey}
            domain="currency"
            noMargin
            value={row.Currency}
          />
        ),
      },
    ];

    if (config?.Voucher?.Enabled) {
      cols.push({
        key: "VoucherCode",
        dataIndex: "VoucherCode",
        title: t("PAYMENT_VOUCHER_VOUCHER_CODE_COLUMN"),
        width: 120,
        ...defaults,
        render: (_: string, row) => (
          <Link to={`${ROUTES.VOUCHER_DETAILS}/${row.VoucherId}`}>
            {row.VoucherCode}
          </Link>
        ),
      });
    }

    cols.push(
      {
        key: "PaymentDate",
        dataIndex: "PaymentDate",
        title: t("MODEL_PAYMENT_DATE"),
        align: "center",
        width: 200,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        filteredValue:
          filters.asTableArray.PaymentDateFrom ||
          filters.asTableArray.PaymentDateTo ||
          null,

        filterDropdown: (event: ITableColumnFilterDropdownProps) => {
          const { confirm } = event;

          const [dateFrom, setDateFrom] = useState<any>(
            filters.current.PaymentDateFrom
          );
          const [dateTo, setDateTo] = useState<any>(
            filters.current.PaymentDateTo
          );

          return (
            <div style={{ padding: 8 }}>
              <DatePicker
                showTime={true}
                placeholder={t("SALES_REVENUE_DATE_FROM")}
                style={smallControlsStyle}
                value={dateFrom}
                onChange={setDateFrom}
              />
              <DatePicker
                showTime={true}
                placeholder={t("SALES_REVENUE_DATE_TO")}
                style={smallControlsStyle}
                value={dateTo}
                onChange={setDateTo}
              />

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  type="link"
                  size="small"
                  disabled={!dateFrom && !dateTo}
                  onClick={() => {
                    setDateFrom(undefined);
                    setDateTo(undefined);

                    filters.update((oldFilters) => ({
                      ...oldFilters,
                      PaymentDateFrom: undefined,
                      PaymentDateTo: undefined,
                    }));
                  }}
                >
                  <Trans i18nKey="BUTTON_RESET">Reset</Trans>
                </Button>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    const newFilters: IAnalyticsPaymentListFilterModel = {};
                    if (dateFrom) {
                      newFilters.PaymentDateFrom = TimeHelper.toString(
                        dateFrom.toDate()
                      );
                    }
                    if (dateTo) {
                      newFilters.PaymentDateTo = TimeHelper.toString(
                        dateTo.toDate()
                      );
                    }
                    filters.update((oldFilters) => ({
                      ...oldFilters,
                      ...newFilters,
                    }));
                    confirm();
                  }}
                >
                  <Trans i18nKey="BUTTON_OK">OK</Trans>
                </Button>
              </div>
            </div>
          );
        },
        render: (_: any, row) =>
          row.PaymentDate ? TimeHelper.format(row.PaymentDate) : null,
      },
      {
        key: "PaymentStatuses",
        dataIndex: "PaymentStatus",
        title: t("MODEL_PAYMENT_STATUS"),
        align: "center",
        width: 140,
        filters: paymentStatusOptions.map((assetType) => ({
          text: assetType.label,
          value: assetType.value,
        })),
        filteredValue: filters.asTableArray.PaymentStatuses || null,
        render: (_: string, row) => (
          <DomainTag
            domain="payment-status"
            noMargin
            value={row.PaymentStatus}
          />
        ),
      }
    );

    return cols;
  }, [filters, purchasePeriodTypes, config]);

  return columns;
};
