import {
  InputSearch,
  Button,
  Icon,
  Tooltip,
  Pagination,
  Tag,
  Popconfirm,
  Link,
} from "@bms/common-ui";

import {
  CountriesService,
  GenderService,
  ISportPlayerModel,
  SportAgeCategoryService,
  SportPlayerPositionsService,
  SportPlayersService,
  TimeHelper,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";

import {
  ITableColumnProps,
  ITableFilter,
  PageContent,
  PageHeader,
  Table,
  useAppFeedback,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../constants/routes";
import {
  generateBreadcrumb,
  useTableDataProvider,
} from "../../../../../helpers";
import { IBreadcrumbProps } from "@bms/common-ui";
import { FilterCleanIcon } from "../../../../../resources/icons";
import { useMemo, useState } from "react";
import { ROUTES as USER_ROUTES } from "../../../../User/constants";
import { AddSportPlayerModal } from "../AddSportPlayerModal";
import { RouteComponentProps } from "react-router";
import { PlayerForm } from "../PlayerForm";
import { useCreateDataPagerSource } from "../../../hooks";

const playersService = new SportPlayersService().promisify();
const ageCategoriesService = new SportAgeCategoryService().promisify();
const positionsService = new SportPlayerPositionsService().promisify();
const countriesService = new CountriesService().promisify();
const genderService = new GenderService().promisify();

export const PlayersList = ({ history, location }: RouteComponentProps) => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const [isModalOpen, setModalOpen] = useState(false);

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.PLAYERS}`,
        breadcrumbName: t("MENU_CMS_PLAYERS"),
      },
    ]);
  };

  const ageCategoriesLoader = useDataLoader({
    loader: () => ageCategoriesService.getCategories(),
    deps: [],
  });

  const positionsLoader = useDataLoader({
    loader: () => positionsService.getPlayerPositions(),
    deps: [],
  });

  const countriesLoader = useDataLoader({
    loader: () => countriesService.select(),
    deps: [],
  });

  const gendersLoader = useDataLoader({
    loader: () => genderService.select(),
    deps: [],
  });

  const countries = useMemo(() => countriesLoader.data ?? [], [
    countriesLoader.data,
  ]);

  const {
    dataLoader: playersLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      AgeCategories: "strings",
      Positions: "strings",
      Countries: "strings",
      Genders: "strings",
    },
    loader: (filters, pagination) => {
      return playersService.search({
        ...filters,
        ...pagination,
      });
    },
    deps: [],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const dataPagerSource = useCreateDataPagerSource(playersLoader.data, {
    ...filters.current,
    ...pagination.current,
  });

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
    }));

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value,
    }));

  const getColumnsProps = (): Array<ITableColumnProps<ISportPlayerModel>> => {
    return [
      {
        key: "FirstName",
        dataIndex: "FirstName",
        title: t("MODEL_FIRST_NAME"),
        render: (_, row: ISportPlayerModel) => {
          const playerDetailLocation = {
            pathname: `${ROUTES.PLAYER_DETAILS}/${row.Id}`,
            state: {
              from: location,
              dataPagerSource: dataPagerSource,
            },
          };
          return <Link to={playerDetailLocation}>{row.FirstName}</Link>;
        },
      },
      {
        key: "LastName",
        dataIndex: "LastName",
        title: t("MODEL_LAST_NAME"),
      },
      {
        key: "AgeCategories",
        dataIndex: "AgeCategories",
        title: t("MODEL_AGE_CATEGORY_NAME"),
        filters: ageCategoriesLoader.data?.map((ageCategory) => ({
          text: ageCategory.DisplayName || ageCategory.Name,
          value: ageCategory.Code,
        })),
        filterSearch: true,
        filteredValue: filters.asTableArray.AgeCategories,
        render: (_, row: ISportPlayerModel) => row.AgeCategoryDisplayName,
      },
      {
        key: "Positions",
        dataIndex: "Positions",
        title: t("MODEL_POSITION_NAME"),
        filters: positionsLoader.data?.map((position) => ({
          text: position.DisplayName || position.Name,
          value: position.Code,
        })),
        filterSearch: true,
        filteredValue: filters.asTableArray.Positions,
        render: (_, row: ISportPlayerModel) =>
          row.PositionDisplayName || row.PositionCode,
      },
      {
        key: "ShirtNumber",
        dataIndex: "ShirtNumber",
        align: "center",
        title: t("MODEL_SHIRT_NUMBER"),
      },
      {
        key: "DateOfBirth",
        dataIndex: "DateOfBirth",
        width: "200px",
        title: t("MODEL_DATE_OF_BIRTH"),
        render: (_, row: ISportPlayerModel) =>
          row.DateOfBirth
            ? TimeHelper.format(row.DateOfBirth, "YYYY-MM-DD")
            : null,
      },
      {
        key: "Countries",
        dataIndex: "Countries",
        width: "200px",
        align: "center",
        title: t("MODEL_COUNTRY_NAME"),
        filters: countries.map((country) => ({
          text: country.Name,
          value: country.Code,
        })),
        filterSearch: true,
        filteredValue: filters.asTableArray.Countries,
        render: (_, row: ISportPlayerModel) =>
          row.CountryName && (
            <Tag
              colorRotate={countries.findIndex(
                ({ Name }) => Name === row.CountryName
              )}
            >
              {row.CountryName}
            </Tag>
          ),
      },
      {
        key: "LanguageCode",
        dataIndex: "LanguageCode",
        align: "center",
        title: t("MODEL_LANGUAGE_NAME"),
      },
      {
        key: "Genders",
        dataIndex: "Genders",
        title: t("MODEL_GENDER_NAME"),
        filters: gendersLoader.data?.map((gender) => ({
          text: gender.DisplayName,
          value: gender.Code,
        })),
        filteredValue: filters.asTableArray.Genders,
        render: (_, row: ISportPlayerModel) => row.GenderDisplayName,
      },
      {
        key: "Created",
        dataIndex: "Created",
        title: t("MODEL_CREATED"),
        render: (_, row: ISportPlayerModel) =>
          row.Created
            ? TimeHelper.format(row.Created, "YYYY-MM-DD HH:mm")
            : null,
      },
      {
        key: "CreatedBy",
        dataIndex: "CreatedBy",
        title: t("MODEL_CREATED_BY"),
        render: (_, row: ISportPlayerModel) => (
          <Link to={`${USER_ROUTES.USER_DETAILS}/${row.CreatedBy}`}>
            {row.CreatedByFullName}
          </Link>
        ),
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_, player: ISportPlayerModel) => (
          <div className="people-table__actions">
            <Popconfirm
              title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
              onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
                e?.preventDefault();
                const result = await playersService.deletePlayer(player!);
                if (result.ok) {
                  await playersLoader.refresh();
                } else {
                  notification.error({
                    message: t("DELETE_ELEMENT_FAILTURE"),
                    description: result.error?.Message,
                  });
                }
              }}
              okText={t("BUTTON_YES")}
              cancelText={t("BUTTON_NO")}
            >
              <Button
                danger={true}
                icon={<Icon type="delete" />}
                title={t("DELETE_ELEMENT")}
              />
            </Popconfirm>
          </div>
        ),
      },
    ];
  };

  const [createPlayer, createPlayerState] = useServiceCaller(
    async (data: ISportPlayerModel) => {
      const result = await playersService.createPlayer(data);
      if (result.ok) {
        setModalOpen(false);
        notification.success({
          message: t("SPORT_PLAYER_CREATE_SUCCESS"),
        });
        history.push(`${ROUTES.PLAYER_DETAILS}/${result.data.Id}`);
      } else {
        notification.error({
          message: t("SPORT_PLAYER_CREATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  return (
    <>
      <AddSportPlayerModal
        visible={isModalOpen}
        close={() => setModalOpen(false)}
        processing={createPlayerState.processing || playersLoader.loading}
      >
        <PlayerForm
          isEditMode={false}
          onSubmit={createPlayer}
          hiddenFields={["CreatedBy"]}
        />
      </AddSportPlayerModal>
      <PageContent footer={<Pagination {...pagination.props} />}>
        <PageHeader
          title={t("MENU_CMS_PLAYERS")}
          breadcrumb={getBreadcrumbProps()}
          extra={
            <>
              <InputSearch
                key="search"
                placeholder={t("SEARCH_PLACEHOLDER")}
                value={fullTextSearch}
                onChange={({ target: { value } }) => setFullTextSearch(value)}
                onSearch={onSearch}
                style={{ width: 250 }}
                allowClear
              />
              <Tooltip overlay={t("MENU_OPTION_CLEAR_FILTERS")}>
                <Button
                  key="clear-filters"
                  shape="circle"
                  className="people-table-clear-button"
                  icon={<FilterCleanIcon />}
                  onClick={filters.clear}
                  title={t("MENU_OPTION_CLEAR_FILTERS")}
                />
              </Tooltip>
              <Button
                key="reload"
                shape="circle"
                icon={<Icon type="reload" />}
                onClick={playersLoader.refresh}
                title={t("BUTTON_REFRESH_TITLE")}
              />
              <Button
                key="add"
                shape="circle"
                type="primary"
                icon={<Icon type="plus" />}
                onClick={() => setModalOpen(true)}
                title={t("BUTTON_ADD")}
              />
            </>
          }
        />
        <Table<ISportPlayerModel>
          rowKey="Id"
          columns={getColumnsProps()}
          dataSource={playersLoader.data?.Entities}
          loading={playersLoader.loading}
          pagination={false}
          onChange={onTableChange}
        />
      </PageContent>
    </>
  );
};
