import { Form, Input } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ITeamFormFieldCommonProps } from "./ITeamFormFieldCommonProps";

export const DescriptionField = ({
  team,
  isEditMode,
  isHidden,
}: ITeamFormFieldCommonProps) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="Description"
      label={t("MODEL_DESCRIPTION")}
      key="Description"
      initialValue={isEditMode ? team?.Description || "" : ""}
    >
      <Input placeholder={t("MODEL_DESCRIPTION_PLACEHOLDER")} />
    </Form.Item>
  );
};
