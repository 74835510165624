import {
  IVoucherRedemptionListModel,
  IVoucherRedemptionSearchFilterModel,
} from "../../models/Vouchers";
import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { HTTP_METHOD } from "../../constants/http";
import { PromisifiableBase } from "../Base";

export class VoucherRedemptionsService extends PromisifiableBase {
  get url(): string {
    return "/VoucherRedemptions";
  }

  public search = (
    filter: IVoucherRedemptionSearchFilterModel = {
      IncludeCount: true,
      PageNumber: 1,
      PageSize: 10,
    } as IVoucherRedemptionSearchFilterModel,
    params = {}
  ): Observable<IVoucherRedemptionListModel> =>
    new Observable(
      (observer: Observer<IVoucherRedemptionListModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params,
          },
          data: filter,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Search`,
        })
    );
}
