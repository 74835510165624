import {
  IErrorModel,
  INTERNAL_USERS_PROFILE_CODES,
  IProfilesListModel,
  IUserModel,
  IUserProfileModel,
  RecordStatus,
  TimeHelper,
} from "@bms/common-services";
import {
  Button,
  Choose,
  ChooseOption,
  Col,
  Form,
  FormFieldRule,
  IChooseLabeledValue,
  IChooseValue,
  Icon,
  IFormValues,
  Input,
  Row,
  Checkbox,
  useValidate,
} from "@bms/common-ui";
import React from "react";
import { useTranslation, WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";

const PhoneNumberField: React.FC = () => {
  const [t] = useTranslation();
  const validate = useValidate();
  return (
    <Form.Item
      name="PhoneNumber"
      label={t("USER_ACCOUNT_FORM_PHONE_NUMBER_LABEL", "Phone number")}
      rules={[...validate.phone(), ...validate.internationalPhone()]}
    >
      <Input
        addonBefore={<Icon type="phone" />}
        placeholder={t(
          "USER_ACCOUNT_FORM_PHONE_NUMBER_PLACEHOLDER",
          "Enter phone number"
        )}
      />
    </Form.Item>
  );
};

const FullNameField: React.FC = () => {
  const [t] = useTranslation();
  const validate = useValidate();

  return (
    <Form.Item
      name="FullName"
      label={t("USER_ACCOUNT_FORM_FULL_NAME_LABEL", "Full name")}
      rules={[...validate.required(), ...validate.fullName()]}
    >
      <Input
        placeholder={t(
          "USER_ACCOUNT_FORM_FULL_NAME_PLACEHOLDER",
          "Enter full name"
        )}
        maxLength={150}
      />
    </Form.Item>
  );
};

const EmailField: React.FC = () => {
  const [t] = useTranslation();
  const validate = useValidate();

  return (
    <Form.Item
      name="Email"
      label={t("USER_ACCOUNT_FORM_EMAIL_LABEL", "E-mail")}
      rules={validate.email()}
    >
      <Input
        addonBefore="@"
        placeholder={t("USER_ACCOUNT_FORM_EMAIL_PLACEHOLDER", "Enter e-mail")}
      />
    </Form.Item>
  );
};

export interface IUserAccountInformationFormStateProps {
  actionType?: string;
  error?: IErrorModel;
  profiles: IProfilesListModel;
  isProcessingData: boolean;
}

export interface IUserAccountInformationFormDispatchProps {
  updateUser: ActionCreator<Action>;
  selectProfiles: ActionCreator<Action>;
}

export interface IUserAccountInformationFormOwnProps {
  user?: IUserModel;
  isActive: boolean;
  viewType: "customers" | "administrators" | "all";
}

export interface IUserAccountInformationFormProps
  extends IUserAccountInformationFormStateProps,
    IUserAccountInformationFormDispatchProps,
    IUserAccountInformationFormOwnProps,
    WithTranslation {
  children?: React.ReactNode;
}

export interface IUserAccountInformationFormState {
  userProfiles: IUserProfileModel[];
}

export class UserAccountInformationForm extends React.PureComponent<
  IUserAccountInformationFormProps,
  IUserAccountInformationFormState
> {
  public static defaultProps: IUserAccountInformationFormOwnProps = {
    isActive: false,
    viewType: "all",
  };

  public state: Readonly<IUserAccountInformationFormState> = {
    userProfiles: this.props.user?.Profiles ? this.props.user?.Profiles : [],
  };

  public componentDidMount() {
    const { selectProfiles, profiles } = this.props;

    if (!profiles || !profiles.Entities || profiles.Entities.length === 0) {
      selectProfiles();
    }
  }

  private renderLockedField = () => {
    const { user, t } = this.props;
    return (
      <Form.Item label={t("USER_ACCOUNT_FORM_LOCKED_LABEL", "Locked")}>
        <Checkbox checked={user?.Locked} onClick={() => false} />
      </Form.Item>
    );
  };

  private renderLockoutEndField = () => {
    const { user, t } = this.props;

    if (!user?.LockoutEnd) {
      return null;
    }

    return (
      <Form.Item
        label={t("USER_ACCOUNT_FORM_LOCKOUT_END_LABEL", "Lockout End")}
      >
        <label>{TimeHelper.format(user?.LockoutEnd)}</label>
      </Form.Item>
    );
  };

  public onFinish = (values: IFormValues) => {
    const { user, updateUser } = this.props;
    const { userProfiles } = this.state;

    if (!user) {
      return;
    }

    user.PhoneNumber = values.PhoneNumber;
    user.Email = values.Email;
    user.FullName = values.FullName.trim();
    user.Profiles = userProfiles;

    updateUser(user);
  };

  public onProfileSelect = (value: IChooseValue) => {
    const { user } = this.props;
    let userProfiles = [...this.state.userProfiles];
    // @ts-ignore
    const { key, label } = value;
    const index = userProfiles.findIndex((row) => row.ProfileId === +key);

    if (index >= 0) {
      const profile = userProfiles[index];
      profile.RecordStatus = RecordStatus.NoChange;
    } else {
      const profile: IUserProfileModel = {
        UserId: user?.Id ?? -1,
        ProfileId: key,
        ProfileName: label,
        RecordStatus: RecordStatus.Inserted,
      };

      userProfiles.push(profile);
    }

    this.setState({ userProfiles });
  };

  public onProfileDeselect = (value: IChooseValue) => {
    let userProfiles = [...this.state.userProfiles];
    // @ts-ignore
    const { key, label } = value;
    const index = userProfiles.findIndex((row) => row.ProfileId === +key);

    if (index >= 0) {
      const profile = userProfiles[index];

      switch (profile.RecordStatus) {
        case RecordStatus.NoChange:
          profile.RecordStatus = RecordStatus.Deleted;
          break;
        case RecordStatus.Inserted:
          userProfiles.splice(index, 1);
          break;
        default:
          profile.RecordStatus = RecordStatus.Deleted;
          break;
      }
    }

    this.setState({ userProfiles });
  };

  public renderProfileField = () => {
    const { t, profiles, viewType } = this.props;
    const { userProfiles } = this.state;

    const fieldOptions: {
      initialValue?: IChooseLabeledValue[];
      rules: FormFieldRule[];
    } = {
      rules: [
        {
          required: true,
          message: t(
            "USER_ACCOUNT_FORM_REQUIRE_VALIDATION_MESSAGE",
            "Please select user profile"
          ),
        },
      ],
      initialValue: userProfiles
        .filter((row) => row.RecordStatus !== RecordStatus.Deleted)
        .map((row) => {
          return {
            key: `${row.ProfileId}`,
            value: `${row.ProfileId}`,
            label: row.ProfileName,
          };
        }),
    };

    const options: React.ReactElement[] = [];
    const profilesOptions = profiles.Entities.filter(({ Code }) =>
      viewType === "customers"
        ? !INTERNAL_USERS_PROFILE_CODES.includes(Code)
        : true
    ).filter(
      (p) =>
        userProfiles.findIndex(
          (up) =>
            up.ProfileId === p.Id && up.RecordStatus !== RecordStatus.Deleted
        ) < 0
    );

    for (const [index, profile] of profilesOptions.entries()) {
      options.push(
        <ChooseOption key={index} value={profile.Id}>{profile.Name}</ChooseOption>
      );
    }

    return (
      <Form.Item
        name="ProfileId"
        label={t("USER_ACCOUNT_FORM_PROFILE_LABEL", "Profile")}
        {...fieldOptions}
      >
        <Choose
          mode="multiple"
          labelInValue={true}
          defaultActiveFirstOption={false}
          filterOption={false}
          placeholder={t(
            "USER_ACCOUNT_FORM_PROFILE_PLACEHOLDER",
            "Select a user profile"
          )}
          children={options}
          onSelect={this.onProfileSelect}
          onDeselect={this.onProfileDeselect}
        />
      </Form.Item>
    );
  };

  public render() {
    const { children, t, user } = this.props;

    return (
      <Form
        name="UserAccountInformationForm"
        labelCol={{
          xs: { span: 24 },
          sm: { span: 12 },
          md: { span: 8 },
          lg: { span: 6 },
          xl: { span: 4 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 12 },
          md: { span: 16 },
          lg: { span: 18 },
          xl: { span: 20 },
        }}
        onFinish={this.onFinish}
        initialValues={{
          Email: user?.Email,
          FullName: user?.FullName,
          PhoneNumber: user?.PhoneNumber,
          Locked: user?.Locked,
        }}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            <FullNameField />
            <PhoneNumberField />
            <EmailField />
            {this.renderProfileField()}
            {this.renderLockedField()}
            {this.renderLockoutEndField()}
          </Col>
          <Col>{children}</Col>
          <Col>
            <Form.Item wrapperCol={{ style: { textAlign: "right" } }}>
              <Button type="primary" htmlType="submit">
                {t("BUTTON_SAVE", "Save")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}
