import { ReactElement, useState } from "react";
import { Col, Form, Link, Row } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { UserBrowserModal } from "../../../../../User/components/UserBrowserModal";
import { ROUTES as USER_ROUTES } from "../../../../../User/constants";
import { IUserModel } from "@bms/common-services";
import { ITeamFormFieldCommonProps } from "./ITeamFormFieldCommonProps";

interface ICreatedByFieldProps extends ITeamFormFieldCommonProps {
  onSelect?: (row?: IUserModel) => void;
  onClear?: () => void;
  userCreator: {
    Id?: string | number;
    FullName?: string | number;
  };
}

// Keeping editable props for future use
export const CreatedByField = ({
  isEditMode,
  isHidden,
  onSelect,
  userCreator,
}: ICreatedByFieldProps) => {
  const [userBrowserVisible, setUserBrowserVisible] = useState(false);
  const { t } = useTranslation();
  if (!isEditMode || isHidden) {
    return null;
  }

  const onUserSelect = (row: IUserModel) => {
    setUserBrowserVisible(false);
    onSelect?.(row);
  };
  const creatorUserIdView: ReactElement | string = userCreator.Id ? (
    <Link to={`${USER_ROUTES.USER_DETAILS}/${userCreator.Id}`}>
      {userCreator.FullName}
    </Link>
  ) : (
    t("COMMON_UNDEFINED")
  );

  return (
    <Form.Item name="CreatorUserId" label={t("MODEL_CREATOR")}>
      <Row gutter={8}>
        <Col flex="auto">
          <Form.Item>{creatorUserIdView}</Form.Item>
          <UserBrowserModal
            profiles={["CREATOR"]}
            visible={userBrowserVisible}
            onCancel={() => setUserBrowserVisible(false)}
            onSelect={onUserSelect}
          />
        </Col>
      </Row>
    </Form.Item>
  );
};
