import React from "react";
import { useHistory } from "react-router-dom";
import { Tabs as AntTabs } from "antd";
import { TabsProps as AntTabsProps } from "antd/lib/tabs";
import { UrlHelper } from "../../helpers";

export interface ITabsProps extends AntTabsProps {
  queryParamName?: string;
  children?: React.ReactNode;
}

export const Tabs = (props: ITabsProps) => {
  const { children, onChange, queryParamName = "tab", ...rest } = props;
  const history = useHistory();
  const onTabChange = (tab: string) => {
    const location = {
      ...history.location,
      search: UrlHelper.joinQueries(history.location.search, {
        [queryParamName]: tab,
      }),
    };
    history.replace(location);
    onChange && onChange(tab);
  };

  return (
    <AntTabs onChange={onTabChange} {...rest}>
      {children}
    </AntTabs>
  );
};
