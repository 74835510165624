import { IAnalyticsSalesRevenueAssetModel, IAnalyticsSalesRevenueCreatorModel } from "@bms/common-services";

export const groupByCreator = (data: IAnalyticsSalesRevenueAssetModel[]) => {
  const groupedData = new Map<string, IAnalyticsSalesRevenueCreatorModel>();
  
  data.forEach(({ Period, CreatorUserId, CreatorUserName, Count }) => {
    const key = `${Period}-${CreatorUserId}`;
    const existing = groupedData.get(key) ?? { Period, CreatorUserId, CreatorUserName, Count: 0 };

    existing.Count += Count;

    groupedData.set(key, existing);
  });
  
  return [...groupedData.values()].sort((a, b) =>
    a.Period !== b.Period ? a.Period.localeCompare(b.Period) : a.CreatorUserId - b.CreatorUserId
  );
}