import { Form, Input, required } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IPeopleFormFieldCommonProps } from "./IPeopleFormFieldCommonProps";

interface ILastNameFieldProps extends IPeopleFormFieldCommonProps {}

export const LastNameField = ({
  isHidden,
  isEditMode,
  people,
}: ILastNameFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="LastName"
      label={t("MODEL_LAST_NAME")}
      key="lastName"
      initialValue={isEditMode ? people?.LastName || "" : ""}
      rules={[required()]}
    >
      <Input placeholder={t("MODEL_LAST_NAME_PLACEHOLDER")} />
    </Form.Item>
  );
};
