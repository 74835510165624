import { Form } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IPlayerFormFieldCommonProps } from "./IPlayerFormFieldCommonProps";
import {
  IListModelBase,
  IModelBase,
  SportPlayerPositionModel,
  SportPlayerPositionsService,
} from "@bms/common-services";
import { LazyChoose } from "../../../../../../components";

interface IPositionIdFieldProps extends IPlayerFormFieldCommonProps {}

const positionsService = new SportPlayerPositionsService();

let positions: SportPlayerPositionModel[];

export const PositionIdField = ({
  isHidden,
  isEditMode,
  player,
}: IPositionIdFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="PositionId"
      label={t("MODEL_POSITION_NAME")}
      initialValue={
        (isEditMode && {
          value: player?.PositionId,
          label: player?.PositionDisplayName || player?.PositionCode,
        }) ||
        {}
      }
      rules={[{ required: true }]}
    >
      <LazyChoose<
        SportPlayerPositionModel & IModelBase,
        SportPlayerPositionModel & IModelBase
      >
        loader={async () => {
          if (!positions) {
            positions = await positionsService.getPlayerPositions().toPromise();
          }
          const data: IListModelBase<
            SportPlayerPositionModel & IModelBase,
            {}
          > = {
            TotalCount: positions.length,
            Entities: positions,
          };
          return {
            data,
            ok: true,
          };
        }}
        candidateToOption={(item) => ({
          label: item.DisplayName || item.Code,
          value: `${item.Id}`,
        })}
        multiple={false}
        selectedToOption={(item) => ({
          label: item.DisplayName || item.Code,
          value: `${item.Id}`,
          item,
        })}
      />
    </Form.Item>
  );
};
