import { ITEM_NAMES } from "../constants";
import { useTranslation } from "react-i18next";
import { IVoucherModel, TimeHelper } from "@bms/common-services";
import { Form } from "@bms/common-ui";

interface ICreatedDateItemProps {
  voucher: IVoucherModel;
}

export const CreatedDateItem = ({ voucher }: ICreatedDateItemProps) => {
  const [t] = useTranslation();

  return (
    <Form.Item name={ITEM_NAMES.CREATED} label={t("MODEL_CREATED")}>
      <span>
        {voucher?.Created
          ? TimeHelper.format(voucher.Created)
          : null}
      </span>
    </Form.Item>
  );
};
