import { DatePicker, Form, required } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IMatchesFormFieldCommonProps } from "./IMatchesFormFieldCommonProps";
import dayjs from "dayjs";

interface IStartDateFieldProps extends IMatchesFormFieldCommonProps {}

export const StartDateField = ({
  isHidden,
  isEditMode,
  match,
}: IStartDateFieldProps) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="StartDateTime"
      label={t("MODEL_START_DATE_TIME")}
      key="StartDateTime"
      initialValue={
        isEditMode && match?.StartDateTime
          ? new Date(match?.StartDateTime)
          : undefined
      }
      rules={[{ required: true, message: t("REQUIRED_VALIDATION_MESSAGE") }]}
    >
      <DatePicker
        placeholder={t("MODEL_START_DATE_TIME_PLACEHOLDER")}
        style={{ width: "100%" }}
        format="YYYY-MM-DD HH:mm"
        showTime={{ defaultValue: dayjs("00:00:00", "HH:mm:ss") }}
      />
    </Form.Item>
  );
};
