import { Form, InputNumber } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ISportTeamPlayerFormFieldCommonProps } from "./ISportTeamPlayerFormFieldCommonProps";

interface IShirtNumberFieldProps extends ISportTeamPlayerFormFieldCommonProps {}

export const ShirtNumberField = ({
  isEditMode,
  player,
}: IShirtNumberFieldProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name="ShirtNumber"
      label={t("MODEL_SHIRT_NUMBER")}
      key="shirtNumber"
      initialValue={isEditMode ? player?.ShirtNumber || undefined : undefined}
    >
      <InputNumber min={0} precision={0} />
    </Form.Item>
  );
};
