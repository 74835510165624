import { SectionGrid, SectionGridItem } from "@bms/common-ui";
import { ICommonTeamDetailsTabProps } from "./ICommonTeamDetailsTabProps";
import { useTranslation } from "react-i18next";
import { TeamForm } from "../../TeamForm";

export const DetailsTab = ({
  team,
  updateTeamState,
  updateTeam,
}: ICommonTeamDetailsTabProps) => {
  const { t } = useTranslation();

  return (
    <SectionGrid>
      <SectionGridItem
        header={t("SPORT_TEAM_DETAILS_SECTION_GENERAL_INFORMATION")}
        processing={updateTeamState?.processing}
      >
        <TeamForm
          key={`TeamForm-${team?.RowVersion}`}
          isEditMode
          team={team}
          onSubmit={updateTeam}
        />
      </SectionGridItem>
    </SectionGrid>
  );
};
