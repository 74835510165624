/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AppKey } from "../../enums/AppKey";
import { AppKeyHelper } from "../../helpers/appKey.helper";
import { BooleanHelper } from "../../helpers/boolean.helper";
import { StringHelper } from "../../helpers/string.helper";

export class AppConfig {
  static get ApiUrl(): string {
    return StringHelper.toString(process.env.REACT_APP_API_URL);
  }

  static get AppCode(): string {
    return StringHelper.toString(process.env.REACT_APP_CODE);
  }

  static get AppKey(): AppKey {
    return AppKeyHelper.getValue(process.env.REACT_APP_KEY);
  }

  static get AppName(): string {
    return StringHelper.toString(process.env.REACT_APP_NAME);
  }

  static get DataProvider(): string {
    return StringHelper.toString(process.env.REACT_APP_DATA_PROVIDER);
  }

  static get TenantOrigin(): string {
    return StringHelper.toString(process.env.REACT_APP_TENANT_ORIGIN_URL);
  }

  static get FeatureFastAiChannels(): boolean {
    return BooleanHelper.toBool(
      process.env.REACT_APP_FEATURE_FAST_AI_CHANNELS,
      false
    );
  }
}
