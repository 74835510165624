import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { SportPlayerPositionModel } from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { PromisifiableBase } from "../Base";

export class SportPlayerPositionsService extends PromisifiableBase {
  public getPlayerPositions = (): Observable<SportPlayerPositionModel[]> =>
    new Observable(
      (observer: Observer<SportPlayerPositionModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `/SportPlayerPositions/Select`,
        })
    );

  public getPlayerPosition = (
    id: number
  ): Observable<SportPlayerPositionModel> =>
    new Observable(
      (observer: Observer<SportPlayerPositionModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id },
          },
          method: HTTP_METHOD.GET,
          url: `/SportPlayerPositions/Get`,
        })
    );

  public createPlayerPosition = (
    data: SportPlayerPositionModel
  ): Observable<SportPlayerPositionModel> =>
    new Observable(
      (observer: Observer<SportPlayerPositionModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/SportPlayerPositions/Insert`,
        })
    );

  public updatePlayerPosition = (
    data: SportPlayerPositionModel
  ): Observable<SportPlayerPositionModel> =>
    new Observable(
      (observer: Observer<SportPlayerPositionModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `/SportPlayerPositions/Update`,
        })
    );

  public deletePlayerPosition = (
    data: SportPlayerPositionModel
  ): Observable<SportPlayerPositionModel> =>
    new Observable(
      (observer: Observer<SportPlayerPositionModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id: data.Id },
          },
          method: HTTP_METHOD.DELETE,
          url: `/SportPlayerPositions/Delete`,
        })
    );
}
