import React from "react";
import { DatePicker as AntDatePicker } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import { Dayjs } from "dayjs";

export type IDateRangeValue = [Dayjs, Dayjs] | null

export declare type IDateRangePickerProps = RangePickerProps;

export const DateRangePicker: React.FC<IDateRangePickerProps> = (props) => {
  return <AntDatePicker.RangePicker {...props} />;
};
