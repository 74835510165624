import {
  AssetImageService,
  AssetImageType,
  AssetImageTypeHelper,
  AssetStore,
  CommonStore,
  IAssetImageModel,
  ICommonAppState,
  IErrorModel,
  OperationResultType,
  PlatformTypeHelper,
  StorageService,
} from "@bms/common-services";
import {
  Choose,
  ChooseOption,
  Col,
  Dragger,
  Form,
  Icon,
  IFormValues,
  Input,
  IUploadChangeEvent,
  IUploadFile,
  required,
  Row,
  Switch,
  Spin,
  useAppFeedback,
} from "@bms/common-ui";
import { optimizeImage, cropImage, cropImageBlob } from "../../../../helpers";
import { assign, filter, get, noop, takeRight } from "lodash";

import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IAssetImagesTypesInUse } from "../AssetImages";
import ReactCrop, {
  Crop,
  PercentCrop,
  PixelCrop,
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import "./AssetImageModal.scss";

export interface IAssetImageModalProps {
  assetId: number;
  assetImage: IAssetImageModel;
  assetImagesTypesInUse: IAssetImagesTypesInUse;
}

const assetImageService = new AssetImageService();
const storageService: StorageService = StorageService.getInstance();

const assetSelector = (state: ICommonAppState) => state.asset;
const commonSelector = (state: ICommonAppState) => state.common;

const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

export const AssetImageModal: React.FC<IAssetImageModalProps> = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { notification } = useAppFeedback();
  const [fileList, setFileList] = useState<IUploadFile[]>([]);
  const [file, setFile] = useState<File>();
  const [currentAspectRatio, setCurrentAspectRatio] = useState<number>(NaN);

  const abortControllerRef = useRef<AbortController>();
  const imgRef = useRef<HTMLImageElement>(null);

  const [suggestedResolution, setSuggestedResolution] = useState({
    width: 0,
    height: 0,
    ratioW: 0,
    ratioH: 0,
  });

  const [imageResolution, setImageResolution] = useState({
    width: 0,
    height: 0,
  });

  const [crop, setCrop] = useState<Crop>();
  const [completeCrop, setCompleteCrop] = useState<PixelCrop>();
  const [imageType, setImageType] = useState<string | undefined>();
  const [imagePlfatform, setImagePlatform] = useState<string | undefined>();
  const [isProcessingImage, setIsProcessingImage] = useState(false);

  const { assetImage, assetId, assetImagesTypesInUse } = props;

  const [assetImageState, setAssetImageState] = useState<IAssetImageModel>(
    assetImage
  );

  const { assetImageTypes } = useSelector(assetSelector);
  const { platforms, isLoadingData } = useSelector(commonSelector);

  const {
    data: assetTypesData = [],
    isFetching: assetTypesFetching,
  } = assetImageTypes;

  const suggestedAspectRatio = useMemo(() => {
    const hasValidSuggestedRatio =
      suggestedResolution.ratioW !== 0 && suggestedResolution.ratioH !== 0;

    if (!hasValidSuggestedRatio) {
      return;
    }

    return suggestedResolution.ratioW / suggestedResolution.ratioH;
  }, [suggestedResolution]);

  const hasMismatchedRatio =
    !!imgRef.current &&
    !!currentAspectRatio &&
    !!suggestedAspectRatio &&
    Math.abs(suggestedAspectRatio! - currentAspectRatio) > 0.1;

  const wasNewFileUploaded = Boolean(fileList.length);

  const getPlatformTypes = useCallback(
    () => dispatch(CommonStore.Actions.selectPlatforms()),
    [dispatch]
  );

  const getAssetImageTypes = useCallback(
    () => dispatch(AssetStore.Actions.getAssetImageTypes()),
    [dispatch]
  );

  const updateAssetImage = useCallback(
    (data: IAssetImageModel) =>
      dispatch(AssetStore.Actions.updateAssetImage(data)),
    [dispatch]
  );

  const addAssetImage = useCallback(
    (data: IAssetImageModel) =>
      dispatch(AssetStore.Actions.addAssetImage(data)),
    [dispatch]
  );

  const updateCropState = useCallback(() => {
    if (
      (!imgRef.current?.width && !imgRef.current?.height) ||
      !suggestedAspectRatio
    ) {
      return;
    }
    const { width, height } = imgRef.current;

    const newCrop = centerAspectCrop(width, height, suggestedAspectRatio);

    form.setFieldValue("UseImageCrop", true);
    setCrop(newCrop);
    setCompleteCrop(convertToPixelCrop(newCrop, width, height));
  }, [imgRef.current, suggestedAspectRatio]);

  const resetCropState = useCallback(() => {
    setCrop(undefined);
    setCompleteCrop(undefined);
  }, [setCrop, setCompleteCrop]);

  useEffect(() => {
    getPlatformTypes();
    getAssetImageTypes();

    const selectedResolution = assetImageState.AssetImageTypeCode || "";
    changeImageType(selectedResolution);
  }, [getPlatformTypes, getAssetImageTypes]);

  useEffect(() => {
    if (!hasMismatchedRatio) {
      resetCropState();
      return;
    }

    updateCropState();
  }, [
    currentAspectRatio,
    updateCropState,
    resetCropState,
    imageType,
    imagePlfatform,
    fileList,
  ]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
      md: { span: 6 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
      md: { span: 18 },
      lg: { span: 18 },
    },
  };

  const onImageChange = (e: IUploadChangeEvent) => {
    const { fileList } = e;
    const image = takeRight(fileList);

    if (image.length) {
      image[0].status = "done";
    }

    setFileList(image);
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const { naturalWidth, naturalHeight } = e.currentTarget;
    setCurrentAspectRatio(naturalWidth / naturalHeight);
  };

  const changeImageType = (value: string) => {
    const platform = PlatformTypeHelper.getValue(imagePlfatform);
    const assetImageType = AssetImageTypeHelper.getValue(value);

    setSuggestedResolution(
      AssetImageTypeHelper.getSuggestedResolution(platform, assetImageType)
    );
    setImageType(value);
  };

  const changePlatformType = (value: string) => {
    const platform = PlatformTypeHelper.getValue(value);
    const assetImageType = AssetImageTypeHelper.getValue(imageType);

    setSuggestedResolution(
      AssetImageTypeHelper.getSuggestedResolution(platform, assetImageType)
    );
    setImagePlatform(value);
  };

  const renderTypeField = () => (
    <Form.Item
      name="AssetTypeCode"
      label={t("ASSET_IMAGE_TYPE")}
      key="type"
      initialValue={get(assetImage, "AssetImageTypeCode", "")}
      rules={[required()]}
    >
      <Choose
        placeholder={t("MODEL_TYPE_PLACEHOLDER")}
        loading={assetTypesFetching}
        disabled={assetTypesFetching}
        onChange={changeImageType}
      >
        {assetTypesData.map((assetType) => (
          <ChooseOption key={assetType.Code} value={assetType.Code}>
            {assetType.DisplayName}
          </ChooseOption>
        ))}
      </Choose>
    </Form.Item>
  );

  const renderRecomendedResolution = () => (
    <Form.Item name="Ratio" label={t("ASSET_IMAGE_FORM_RATIO")} key="Ratio">
      {t("ASSET_IMAGE_FORM_RECOMMENDED_RATIO", {
        ratioW: suggestedResolution?.ratioW,
        ratioH: suggestedResolution?.ratioH,
      })}
      {suggestedResolution?.width !== 0 && suggestedResolution?.height !== 0 && (
        <p>
          {t("ASSET_IMAGE_FORM_RECOMMENDED_RESOLUTION", {
            width: suggestedResolution?.width,
            height: suggestedResolution?.height,
          })}
        </p>
      )}
    </Form.Item>
  );

  const renderConversionField = () => (
    <Form.Item
      name="OptimizeImage"
      key="OptimizeImage"
      label={t("ASSET_IMAGE_OPTIMIZE")}
    >
      <Form.Item
        name="UseImageOptimizer"
        key="UseImageOptimizer"
        valuePropName="checked"
        initialValue={true}
      >
        <Switch />
      </Form.Item>
      <p>{t("ASSET_IMAGE_OPTIMIZE_DESCRIPTION")}</p>
    </Form.Item>
  );

  const renderCropField = () => (
    <Form.Item name="CropImage" key="CropImage" label={t("MODEL_CROP")}>
      <Form.Item
        name="UseImageCrop"
        key="UseImageCrop"
        valuePropName="checked"
        initialValue={hasMismatchedRatio}
      >
        <Switch
          onChange={(checked) => {
            if (checked && hasMismatchedRatio) {
              updateCropState();
            } else {
              resetCropState();
            }
          }}
        />
      </Form.Item>
      <p className="incorrect-aspect-ratio-msg">
        <Icon type="warning" />
        <span>
          {t("ASSET_IMAGE_ASPECT_RATIO_WARNING", {
            ratioW: suggestedResolution?.ratioW,
            ratioH: suggestedResolution?.ratioH,
          })}
        </span>
      </p>
    </Form.Item>
  );

  const renderPlatformField = () => (
    <Form.Item
      name="Platform"
      label={t("ASSET_IMAGE_PLATFORM")}
      key="Platform"
      initialValue={get(assetImage, "PlatformCode", "ANY")}
      rules={[required()]}
    >
      <Choose
        placeholder={t("ASSET_IMAGE_FORM_PLATFORM_PLACEHOLDER")}
        loading={isLoadingData}
        disabled={isLoadingData}
        onChange={changePlatformType}
      >
        {platforms.map((platform) => (
          <ChooseOption key={platform.Code} value={platform.Code}>
            {platform.DisplayName}
          </ChooseOption>
        ))}
      </Choose>
    </Form.Item>
  );

  const renderPathField = () => (
    <Form.Item
      name="Path"
      label={t("ASSET_IMAGE_FORM_PATH")}
      key="Path"
      initialValue={get(assetImage, "Path", "")}
    >
      <Input placeholder={t("ASSET_IMAGE_FORM_PATH_PLACEHOLDER")} />
    </Form.Item>
  );

  const beforeUpload = (file: File) => {
    setUpWidthAndHeightFromFile(file);
    setFile(file);
    return true;
  };

  const handleRemove = () => {
    setFile(undefined);
    form.setFieldValue("UseImageCrop", false);
  };

  const setUpWidthAndHeightFromFile = (file: File) => {
    const image = new Image();
    image.onload = function () {
      setImageResolution({
        width: image.width,
        height: image.height,
      });
    };
    image.src = URL.createObjectURL(file);
  };

  const renderUploadField = () => (
    <Form.Item
      name="Upload"
      label={t("ASSET_IMAGE_FORM_UPLOAD")}
      key="Upload"
      getValueFromEvent={onImageChange}
      valuePropName="file"
    >
      <Dragger
        name="Upload"
        accept="image/*"
        className="AssetImageModal_Dagger"
        multiple={false}
        showUploadList={true}
        beforeUpload={beforeUpload}
        fileList={fileList}
        onRemove={handleRemove}
      >
        {file || assetImage?.Url ? (
          <Spin spinning={isProcessingImage}>
            <div className={`AssetImageModal_Dagger_Preview`}>
              <div
                onClick={(e: React.MouseEvent) => {
                  // Preventing image change when crop is enabled
                  if (crop) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              >
                <ReactCrop
                  locked
                  crop={crop}
                  onChange={(_crop: PixelCrop, percentCrop: PercentCrop) => {
                    setCrop(percentCrop);
                  }}
                  onComplete={(_crop: PixelCrop, _percentCrop: PercentCrop) => {
                    setCompleteCrop(_crop);
                  }}
                  onDragEnd={(e: PointerEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  aspect={
                    suggestedResolution.ratioW / suggestedResolution.ratioH
                  }
                  circularCrop={
                    AssetImageTypeHelper.getValue(imageType) ===
                    AssetImageType.Round
                  }
                >
                  <img
                    className="full-width"
                    //crossOrigin="anonymous" --> Adding this will enable us to develop cropping for already loaded images, not only new ones
                    alt="preview"
                    src={file ? URL.createObjectURL(file) : assetImage?.Url}
                    ref={imgRef}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              </div>
            </div>
          </Spin>
        ) : (
          <>
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">{t("DRAG_AND_DROP_INFO")}</p>
          </>
        )}
      </Dragger>
    </Form.Item>
  );

  const onUploadFailed = (error: IErrorModel) => {
    setIsProcessingImage(false);
    return notification.error({
      message: t("ASSET_IMAGE_UPLOAD_FAILURE"),
      description: error?.Message,
    });
  };

  const onFinish = async (values: IFormValues) => {
    const { AssetTypeCode, Platform, Path, UseImageOptimizer } = values;

    let CurrentPath = Path;

    if (fileList.length === 0 && !CurrentPath) {
      return notification.error({
        message: t("ADD_ASSET_IMAGE_VALIDATION_PROVIDE_IMAGE"),
      });
    }

    if (
      assetImagesTypesInUse[Platform].includes(AssetTypeCode) &&
      assetImage.AssetImageTypeCode !== AssetTypeCode
    ) {
      return notification.error({
        message: t("ADD_ASSET_IMAGE_VALIDATION_TYPE"),
      });
    }

    let Width = imageResolution.width || assetImage.Width;
    let Height = imageResolution.height || assetImage.Height;
    let FileInUse = file;

    setIsProcessingImage(true);

    try {
      // crop existing image
      if (
        completeCrop &&
        imgRef.current &&
        !FileInUse &&
        !wasNewFileUploaded &&
        assetImage.AssetId &&
        assetImage.Url
      ) {
        const response = await fetch(assetImage.Url);

        if (response.ok) {
          const image = await response.blob();
          const croppedImage = await cropImageBlob(
            completeCrop,
            image,
            imgRef.current
          );

          const uploadFileInfo = await assetImageService
            .getUploadFileInfo(assetImage.AssetId)
            .toPromise();

          if (uploadFileInfo) {
            CurrentPath = uploadFileInfo.Path;
            Width = croppedImage.width;
            Height = croppedImage.height;

            const uploadResult = await storageService.uploadFile(
              croppedImage.file,
              uploadFileInfo,
              assetImage.Guid,
              noop,
              abortControllerRef.current
            );

            if (uploadResult.ResultType === OperationResultType.Error) {
              onUploadFailed({ Message: uploadResult.Message });
              return;
            }

            if (assetImage.Id) {
              updateAssetImage({
                ...assetImageState,
                Path: uploadFileInfo.Path,
              });
            } else {
              addAssetImage({
                ...assetImageState,
                Path: uploadFileInfo.Path,
              });
            }

            setIsProcessingImage(false);
            return;
          }
        }
      }

      // crop uploaded image
      if (completeCrop && imgRef.current && FileInUse) {
        const { type, name } = fileList[0];
        const { file: croppedFile, width, height } = await cropImage(
          completeCrop,
          imgRef.current,
          type,
          name
        );

        FileInUse = croppedFile;
        Width = width;
        Height = height;

        setImageResolution({
          width: Width,
          height: Height,
        });
        setFile(FileInUse);
      }

      if (UseImageOptimizer && FileInUse && suggestedResolution) {
        const { width: maxWidth, height: maxHeight } = suggestedResolution;

        const { file: convertedFile, width, height } = await optimizeImage(
          FileInUse,
          maxWidth,
          maxHeight
        );

        FileInUse = convertedFile;
        Width = width;
        Height = height;

        setImageResolution({
          width: Width,
          height: Height,
        });
        setFile(FileInUse);
      }

      const assetImageToSave: IAssetImageModel = assign({}, assetImage, {
        AssetId: assetId,
        PlatformCode: Platform,
        PlatformDisplayName: filter(platforms, { Code: Platform })[0]
          .DisplayName,
        Path: CurrentPath,
        AssetImageTypeCode: AssetTypeCode,
        AssetImageTypeDisplayName: filter(assetTypesData, {
          Code: AssetTypeCode,
        })[0].DisplayName,
        Width,
        Height,
      });

      setAssetImageState(assetImageToSave);

      if (wasNewFileUploaded && FileInUse) {
        const uploadFileInfo = await assetImageService
          .getUploadFileInfo(assetId)
          .toPromise();

        if (uploadFileInfo) {
          const uploadResult = await storageService.uploadFile(
            FileInUse,
            uploadFileInfo,
            assetImageToSave.Guid,
            noop,
            abortControllerRef.current
          );

          if (uploadResult.ResultType === OperationResultType.Error) {
            onUploadFailed({ Message: uploadResult.Message });
            return;
          }

          assetImageToSave.Path = uploadFileInfo.Path;
        }
      }

      if (assetImage.Id) {
        updateAssetImage(assetImageToSave);
      } else {
        addAssetImage(assetImageToSave);
      }
    } catch (error) {
      onUploadFailed(error as IErrorModel);
    }
  };

  return (
    <Form
      id="AssetImageModal"
      name="AssetImageModal"
      className="AssetImageModal"
      form={form}
      {...formItemLayout}
      onFinish={onFinish}
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          {renderTypeField()}
          {renderRecomendedResolution()}
          {renderPlatformField()}
          {renderPathField()}
          {renderUploadField()}
          {hasMismatchedRatio && renderCropField()}
          {wasNewFileUploaded && renderConversionField()}
        </Col>
      </Row>
    </Form>
  );
};
