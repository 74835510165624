import React from "react";
import { useTranslation } from "react-i18next";
import { SectionGrid, SectionGridItem } from "@bms/common-ui";
import { ICommonAssetDetailsTabProps } from "./ICommonAssetDetailsTabProps";
import { Components as PaymentComponents } from "../../../../Payment";

export const VouchersRedemptionsTab = ({
  asset,
}: ICommonAssetDetailsTabProps) => {
  const { t } = useTranslation();

  return (
    <SectionGrid>
      <SectionGridItem>
        <PaymentComponents.VoucherRedemptionList
          title={t("PAYMENT_VOUCHER_REDEMPTIONS_LIST_TITLE")}
          assetId={asset?.Id}
        />
      </SectionGridItem>
    </SectionGrid>
  );
};
