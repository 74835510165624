import React from "react";
import {
  ProductsService,
  useConfig,
  useDataLoader,
} from "@bms/common-services";
import {
  Button,
  Icon,
  PageContent,
  PageHeader,
  Spin,
  TabPane,
  Tabs,
  useSearchParamsTabs,
  IBreadcrumbProps,
  SectionGrid,
  SectionGridItem,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { ProductForm } from "../ProductForm";
import { ProductRelatedProductsList } from "../ProductRelatedProductsList";
import { generateBreadcrumb } from "../../../../helpers";
import { ROUTES } from "../../constants/routes";
import { UserSubscriptionsTable } from "../../../User/components";
import { Components as PaymentComponents } from "../../../Payment";

import "./ProductDetails.scss";

const productsService = new ProductsService().promisify();

export const ProductDetails: React.FC = () => {
  const id = parseInt(useParams<{ id: string }>().id, 10);
  const { config } = useConfig();
  const { t } = useTranslation();
  const history = useHistory();
  const [activeTabKey, setActiveTabKey] = useSearchParamsTabs("DETAILS");

  const { data: product, loading, refresh } = useDataLoader({
    loader: () => productsService.get(id),
    deps: [id],
  });

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.PRODUCTS_LIST}`,
        breadcrumbName: t("PRODUCT_LIST_TITLE"),
      },
      {
        path: `${ROUTES.PRODUCTS_LIST}/${id}`,
        breadcrumbName: product?.Title ?? t("PRODUCT_DETAILS_TITLE"),
      },
    ]);
  };

  return (
    <>
      <Spin spinning={loading}>
        <PageContent>
          <PageHeader
            title={product?.Title || t("PRODUCT_DETAILS_TITLE")}
            breadcrumb={getBreadcrumbProps()}
            onBack={history.goBack}
            extra={
              <>
                <Button
                  key="action-reload"
                  icon={<Icon type="reload" />}
                  onClick={refresh}
                  title={t("BUTTON_REFRESH_TITLE")}
                  shape="circle"
                />
              </>
            }
          />
          <Tabs
            activeKey={activeTabKey}
            destroyInactiveTabPane
            onTabClick={setActiveTabKey}
          >
            <TabPane key="DETAILS" tab={t("PRODUCT_DETAILS_TAB")}>
              <ProductForm product={product} />
            </TabPane>
            <TabPane key="RELATED_PRODUCTS" tab={t("PRODUCT_RELATED_PRODUCTS")}>
              {product && <ProductRelatedProductsList product={product} />}
            </TabPane>
            <TabPane key="SUBSCRIPTIONS" tab={t("PRODUCT_SUBSCRIPTIONS")}>
              {product && (
                <SectionGrid>
                  <SectionGridItem>
                    <UserSubscriptionsTable assetId={product?.Id} />
                  </SectionGridItem>
                </SectionGrid>
              )}
            </TabPane>
            {config?.Voucher?.Enabled && product && (
              <TabPane key="VOUCHERS" tab={t("PRODUCT_VOUCHERS")}>
                <SectionGrid>
                  <SectionGridItem>
                    <PaymentComponents.VoucherRedemptionList
                      assetId={product?.Id}
                      title={t("PAYMENT_VOUCHER_REDEMPTIONS_LIST_TITLE")}
                    />
                  </SectionGridItem>
                </SectionGrid>
              </TabPane>
            )}
          </Tabs>
        </PageContent>
      </Spin>
    </>
  );
};
