import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  EntityListModel,
  PaginationModel,
  IPeopleModel,
  IPeopleListModel,
  IPeopleSearchFilterModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class PeopleService extends SimpleServiceBase<
  IPeopleModel,
  IPeopleListModel,
  number,
  IPeopleSearchFilterModel
> {
  get url(): string {
    return "/People";
  }

  public getPeopleList = (
    data: IPeopleModel,
    pagination: PaginationModel
  ): Observable<EntityListModel<IPeopleModel>> =>
    new Observable(
      (observer: Observer<EntityListModel<IPeopleModel>>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: pagination,
          },
          data,
          method: HTTP_METHOD.POST,
          url: `/People/Search`,
        })
    );

  public getPeople = (id: number): Observable<IPeopleModel> =>
    new Observable(
      (observer: Observer<IPeopleModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id },
          },
          method: HTTP_METHOD.GET,
          url: `/People/Get`,
        })
    );

  public createPeople = (data: IPeopleModel): Observable<IPeopleModel> =>
    new Observable(
      (observer: Observer<IPeopleModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/People/Insert`,
        })
    );

  public updatePeople = (data: IPeopleModel): Observable<IPeopleModel> =>
    new Observable(
      (observer: Observer<IPeopleModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `/People/Update`,
        })
    );

  public deletePeople = (data: IPeopleModel): Observable<IPeopleModel> =>
    new Observable(
      (observer: Observer<IPeopleModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id: data.Id },
          },
          method: HTTP_METHOD.DELETE,
          url: `/People/Delete`,
        })
    );
}
