import { DatePicker, Form } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IMatchesFormFieldCommonProps } from "./IMatchesFormFieldCommonProps";
import dayjs from "dayjs";

interface IEndDateFieldProps extends IMatchesFormFieldCommonProps {}

export const EndDateField = ({
  isHidden,
  isEditMode,
  match,
}: IEndDateFieldProps) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="EndDateTime"
      label={t("MODEL_END_DATE_TIME")}
      key="EndDateTime"
      initialValue={
        isEditMode && match?.EndDateTime
          ? new Date(match?.EndDateTime)
          : undefined
      }
    >
      <DatePicker
        placeholder={t("MODEL_END_DATE_TIME_PLACEHOLDER")}
        style={{ width: "100%" }}
        format="YYYY-MM-DD HH:mm"
        showTime={{ defaultValue: dayjs("00:00:00", "HH:mm:ss") }}
      />
    </Form.Item>
  );
};
