import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  EntityListModel,
  PaginationModel,
  ISportLeagueListModel,
  ISportLeagueSearchFilterModel,
  ISportLeagueModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class SportLeaguesService extends SimpleServiceBase<
  ISportLeagueModel,
  ISportLeagueListModel,
  number,
  ISportLeagueSearchFilterModel
> {
  get url(): string {
    return "/SportLeagues";
  }

  public getLeaguesList = (
    data: ISportLeagueModel,
    pagination: PaginationModel
  ): Observable<EntityListModel<ISportLeagueModel>> =>
    new Observable(
      (observer: Observer<EntityListModel<ISportLeagueModel>>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: pagination,
          },
          data,
          method: HTTP_METHOD.POST,
          url: `/SportLeagues/Search`,
        })
    );

  public getLeague = (id: number): Observable<ISportLeagueModel> =>
    new Observable(
      (observer: Observer<ISportLeagueModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id },
          },
          method: HTTP_METHOD.GET,
          url: `/SportLeagues/Get`,
        })
    );

  public createLeague = (
    data: ISportLeagueModel
  ): Observable<ISportLeagueModel> =>
    new Observable(
      (observer: Observer<ISportLeagueModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/SportLeagues/Insert`,
        })
    );

  public updateLeague = (
    data: ISportLeagueModel
  ): Observable<ISportLeagueModel> =>
    new Observable(
      (observer: Observer<ISportLeagueModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `/SportLeagues/Update`,
        })
    );

  public deleteLeague = (
    data: ISportLeagueModel
  ): Observable<ISportLeagueModel> =>
    new Observable(
      (observer: Observer<ISportLeagueModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id: data.Id },
          },
          method: HTTP_METHOD.DELETE,
          url: `/SportLeagues/Delete`,
        })
    );

  public selectLeagues = (): Observable<ISportLeagueModel[]> =>
    new Observable(
      (observer: Observer<ISportLeagueModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `/SportLeagues/Select`,
        })
    );
}
