import { ITEM_NAMES } from "../constants";
import { useTranslation } from "react-i18next";
import { IVoucherModel } from "@bms/common-services";
import { Form } from "@bms/common-ui";

interface IRedeemedCountItemProps {
  voucher: IVoucherModel;
}

export const RedeemedCountItem = ({ voucher }: IRedeemedCountItemProps) => {
  const [t] = useTranslation();

  return (
    <Form.Item
      name={ITEM_NAMES.REDEEMED_COUNT}
      label={t("PAYMENT_VOUCHER_REDEEM_COUNT_COLUMN")}
    >
      <label>{voucher.RedeemedCount}</label>
    </Form.Item>
  );
};
