import { SimpleServiceBase } from "../Base";
import {
  IVoucherListModel,
  IVoucherModel,
  IVoucherSearchFilterModel,
} from "../../models/Vouchers";
import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "..";
import { HTTP_METHOD } from "../..";

export class VouchersService extends SimpleServiceBase<
  IVoucherModel,
  IVoucherListModel,
  number,
  IVoucherSearchFilterModel
> {
  get url(): string {
    return "/Vouchers";
  }

  public delete = (data: IVoucherModel): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.DELETE,
          url: `${this.url}/Delete/?id=${data.Id}`,
        })
    );
}
