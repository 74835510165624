import { DateRangePicker } from "@bms/common-ui";
import { FilterDropdownProps } from "antd/lib/table/interface";

export const getDateRange = (date: string) => {
  const splittedDate = date.split("#");
  const start = splittedDate[0];
  const end = splittedDate[1];
  return { start, end };
};

export const renderDateRangeFilter = ({
  confirm,
  setSelectedKeys,
}: FilterDropdownProps) => {
  return (
    <DateRangePicker
      allowClear
      showTime
      format="YYYY-MM-DD HH:mm"
      onChange={(value) => {
        const [startDate, endDate] = value || [null, null];
        setSelectedKeys(
          value ? [`${startDate?.toISOString()}#${endDate?.toISOString()}`] : []
        );
        confirm();
      }}
    />
  );
};
