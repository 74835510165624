import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  UploadFileInfoModel,
  IAssetImageModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { ServiceBase } from "../Base";

export class AssetImageService extends ServiceBase<
  IAssetImageModel,
  number
> {
  get url(): string {
    return "/AssetImages";
  }

  public getUploadFileInfo = (
    assetId: number
  ): Observable<UploadFileInfoModel> =>
    new Observable(
      (observer: Observer<UploadFileInfoModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetUploadFileInfo?assetId=${assetId}`,
        })
    );
}
