import { SectionGrid, SectionGridItem } from "@bms/common-ui";
import { ICommonPlayerDetailsTabProps } from "./ICommonPlayerDetailsTabProps";
import { PlayerForm } from "../../PlayerForm";
import { useTranslation } from "react-i18next";
import { IPeopleModel } from "@bms/common-services";
import { IServiceCallerState } from "@bms/common-services";
import { PeopleForm } from "../../../../../People/components";

interface Props extends ICommonPlayerDetailsTabProps {
  person?: IPeopleModel;
  updatePersonState?: IServiceCallerState<void>;
  updatePerson?: (person: IPeopleModel) => Promise<void>;
}

export const DetailsTab = ({
  player,
  updatePlayerState,
  updatePlayer,
  person,
  updatePersonState,
  updatePerson,
}: Props) => {
  const { t } = useTranslation();

  return (
    <SectionGrid>
      <SectionGridItem
        header={t("SPORT_PLAYER_DETAILS_SECTION_PERSON_INFORMATION")}
        processing={updatePersonState?.processing}
      >
        <PeopleForm
          key={`PeopleForm-${player?.RowVersion}`}
          isEditMode
          people={person}
          onSubmit={updatePerson}
          hiddenFields={["CreatedBy"]}
        />
      </SectionGridItem>
      <SectionGridItem
        header={t("SPORT_PLAYER_DETAILS_SECTION_SPORT_INFORMATION")}
        processing={updatePlayerState?.processing}
      >
        <PlayerForm
          key={`PlayerForm-${player?.RowVersion}`}
          isEditMode
          player={player}
          onSubmit={updatePlayer}
          hiddenFields={["FirstName", "LastName"]}
        />
      </SectionGridItem>
    </SectionGrid>
  );
};
