import {
  IErrorModel,
  ISportClubSearchFilterModel,
  ISportLeagueSearchFilterModel,
  ISportMatchSearchFilterModel,
  ISportPlayerSearchFilterModel,
  ISportTeamSearchFilterModel,
  SportClubsService,
  SportLeaguesService,
  SportMatchService,
  SportPlayersService,
  SportTeamService,
} from "@bms/common-services";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { useAppFeedback } from "@bms/common-ui";
import { ROUTES } from "../constants";

interface IBaseSearchFilter {
  PageNumber?: number;
  PageSize?: number;
}

interface IBaseListModel<T> {
  Entities: T[];
  TotalCount: number;
}

interface IDataPagerSource<TFilter extends IBaseSearchFilter> {
  Entities: number[];
  TotalCount: number;
  Filter?: TFilter;
}

export interface IDataPager {
  enabled: boolean;
  movePreviousEnabled: boolean;
  moveNextEnabled: boolean;
  moveNext: () => void;
  movePrevious: () => void;
}

interface IDataPagerConfig {
  routePath: string;
  searchService: {
    search: (filter: any) => Promise<any>;
  };
}

export const useCreateDataPagerSource = <
  TModel extends { Id: number },
  TFilter extends IBaseSearchFilter
>(
  list?: IBaseListModel<TModel>,
  filter?: TFilter
): IDataPagerSource<TFilter> | undefined => {
  return useMemo(() => {
    const defaultFilter = {
      PageNumber: 1,
      PageSize: 10,
      ...filter,
    } as TFilter;

    if (list?.Entities && list.Entities.length > 0) {
      return {
        Filter: defaultFilter,
        Entities: list.Entities.map((row) => row.Id),
        TotalCount: list.TotalCount,
      };
    }
    return undefined;
  }, [list, filter]);
};

export const useDataPager = <TFilter extends IBaseSearchFilter>(
  id: number,
  config: IDataPagerConfig
): [IDataPager, boolean] => {
  const { t } = useTranslation();
  const { replace } = useHistory();
  const { notification } = useAppFeedback();
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(false);

  const dataPagerSource = useMemo<IDataPagerSource<TFilter> | undefined>(() => {
    if (location.state && location.state.hasOwnProperty("dataPagerSource")) {
      const state = location.state as {
        dataPagerSource: IDataPagerSource<TFilter>;
      };
      return state.dataPagerSource;
    }
    return undefined;
  }, [location, id]);

  const onMovePrevious = useCallback(async () => {
    if (
      dataPagerSource?.Filter &&
      dataPagerSource.Entities &&
      dataPagerSource.Entities.length > 0
    ) {
      const index = dataPagerSource.Entities.findIndex((rowId) => rowId == id);
      if (index > 0) {
        const newLocation = {
          ...location,
          pathname: `${config.routePath}/${
            dataPagerSource.Entities[index - 1]
          }`,
        };
        replace(newLocation);
      } else if (index === 0) {
        const pageNumber = dataPagerSource.Filter.PageNumber || 1;
        const filter = {
          ...dataPagerSource.Filter,
          PageNumber: pageNumber - 1,
        };
        try {
          setLoading(true);
          const response = await config.searchService.search(filter);

          if (response.Entities && response.Entities.length > 0) {
            const newDataPager = {
              Filter: filter,
              Entities: response.Entities.map((row: any) => row.Id),
              TotalCount: response.TotalCount,
            };
            const newLocation = {
              ...location,
              pathname: `${config.routePath}/${
                newDataPager.Entities[newDataPager.Entities.length - 1]
              }`,
              state: {
                ...location.state,
                dataPagerSource: newDataPager,
              },
            };
            replace(newLocation);
          }
        } catch (error) {
          const err = error as IErrorModel;
          notification.error({
            message: t(
              "LOADING_DATA_ERROR_MESSAGE",
              "There was an error while loading data."
            ),
            description: err?.Message,
          });
        } finally {
          setLoading(false);
        }
      }
    }
  }, [id, config, dataPagerSource]);

  const onMoveNext = useCallback(async () => {
    if (
      dataPagerSource?.Filter &&
      dataPagerSource.Entities &&
      dataPagerSource.Entities.length > 0
    ) {
      const index = dataPagerSource.Entities.findIndex((rowId) => rowId == id);

      if (index >= 0 && index < dataPagerSource.Entities.length - 1) {
        const newLocation = {
          ...location,
          pathname: `${config.routePath}/${
            dataPagerSource.Entities[index + 1]
          }`,
        };
        replace(newLocation);
      } else if (index === dataPagerSource.Entities.length - 1) {
        const pageNumber = dataPagerSource.Filter.PageNumber || 1;
        const filter = {
          ...dataPagerSource.Filter,
          PageNumber: pageNumber + 1,
        };
        try {
          setLoading(true);
          const response = await config.searchService.search(filter);

          if (response.Entities && response.Entities.length > 0) {
            const newDataPager = {
              Filter: filter,
              Entities: response.Entities.map((row: any) => row.Id),
              TotalCount: response.TotalCount,
            };
            const newLocation = {
              ...location,
              pathname: `${config.routePath}/${newDataPager.Entities[0]}`,
              state: {
                ...location.state,
                dataPagerSource: newDataPager,
              },
            };
            replace(newLocation);
          }
        } catch (error) {
          const err = error as IErrorModel;
          notification.error({
            message: t(
              "LOADING_DATA_ERROR_MESSAGE",
              "There was an error while loading data."
            ),
            description: err?.Message,
          });
        } finally {
          setLoading(false);
        }
      }
    }
  }, [id, config, dataPagerSource]);

  const dataPager = useMemo(() => {
    const pager = {
      enabled: false,
      movePreviousEnabled: false,
      moveNextEnabled: false,
      moveNext: onMoveNext,
      movePrevious: onMovePrevious,
    };

    if (dataPagerSource) {
      pager.enabled = true;

      if (
        dataPagerSource.Filter &&
        dataPagerSource.Entities &&
        dataPagerSource.Entities.length > 0
      ) {
        const pageSize =
          dataPagerSource.Filter.PageSize || dataPagerSource.Entities.length;
        const pageNumber = dataPagerSource.Filter.PageNumber || 1;
        const index = dataPagerSource.Entities.findIndex(
          (rowId) => rowId == id
        );
        const entireIndex = pageSize * (pageNumber - 1) + index;

        pager.movePreviousEnabled = entireIndex > 0;
        pager.moveNextEnabled =
          entireIndex >= 0 && entireIndex < dataPagerSource.TotalCount - 1;
      }
    }

    return pager;
  }, [id, dataPagerSource, onMoveNext, onMovePrevious]);

  return [dataPager, loading];
};

export const useClubsDataPager = (id: number): [IDataPager, boolean] => {
  return useDataPager<ISportClubSearchFilterModel>(id, {
    routePath: ROUTES.CLUB_DETAILS,
    searchService: new SportClubsService().promisify(),
  });
};

export const useMatchesDataPager = (id: number): [IDataPager, boolean] => {
  return useDataPager<ISportMatchSearchFilterModel>(id, {
    routePath: ROUTES.MATCH_DETAILS,
    searchService: new SportMatchService().promisify(),
  });
};

export const usePlayersDataPager = (id: number): [IDataPager, boolean] => {
  return useDataPager<ISportPlayerSearchFilterModel>(id, {
    routePath: ROUTES.PLAYER_DETAILS,
    searchService: new SportPlayersService().promisify(),
  });
};

export const useTeamsDataPager = (id: number): [IDataPager, boolean] => {
  return useDataPager<ISportTeamSearchFilterModel>(id, {
    routePath: ROUTES.TEAM_DETAILS,
    searchService: new SportTeamService().promisify(),
  });
};

export const useLeaguesDataPager = (id: number): [IDataPager, boolean] => {
  return useDataPager<ISportLeagueSearchFilterModel>(id, {
    routePath: ROUTES.LEAGUE_DETAILS,
    searchService: new SportLeaguesService().promisify(),
  });
};
