import {
  IBreadcrumbProps,
  PageHeader,
  PageContent,
  Icon,
  Button,
  Tabs,
  TabPane,
  useSearchParamsTabs,
  Spin,
} from "@bms/common-ui";
import { generateBreadcrumb } from "../../../../../helpers";
import { ROUTES } from "../../../constants";

import { useParams } from "react-router";

import { useTranslation } from "react-i18next";
import { useTeamDetailsController } from "./useTeamDetailsController";
import { DetailsTab, PlayersTab, StaffTab } from "./tabs";

export const TeamDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [activeTabKey] = useSearchParamsTabs("DETAILS");

  const {
    refresh,
    updateTeam,
    onBackClick,
    onDeleteClick,
    loading,
    dataPager,
    dataPagerLoading,
    updateTeamState,
    team,
  } = useTeamDetailsController();

  const teamName = team?.Name;

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.TEAMS}`,
        breadcrumbName: t("MENU_CMS_TEAMS"),
      },
      {
        path: `${ROUTES.TEAM_DETAILS}/${id}`,
        breadcrumbName: teamName ?? t("MENU_CMS_TEAM_DETAILS"),
      },
    ]);
  };

  return (
    <Spin spinning={loading || dataPagerLoading}>
      <PageContent>
        <PageHeader
          title={teamName ?? t("MENU_CMS_TEAM_DETAILS")}
          breadcrumb={getBreadcrumbProps()}
          onBack={onBackClick}
          extra={
            <>
              {dataPager.enabled && (
                <>
                  <Button
                    key="action-move-previous"
                    icon={<Icon type="arrow-left" />}
                    onClick={dataPager.movePrevious}
                    title={t("BUTTON_MOVE_PREVIOUS")}
                    shape="circle"
                    disabled={!dataPager.movePreviousEnabled}
                  />
                  <Button
                    key="action-move-next"
                    icon={<Icon type="arrow-right" />}
                    title={t("BUTTON_MOVE_NEXT")}
                    onClick={dataPager.moveNext}
                    shape="circle"
                    disabled={!dataPager.moveNextEnabled}
                  />
                  <Button
                    key="action-reload"
                    icon={<Icon type="reload" />}
                    onClick={refresh}
                    title={t("BUTTON_REFRESH_TITLE")}
                    shape="circle"
                  />
                  <Button
                    danger
                    key="action-delete"
                    icon={<Icon type="delete" />}
                    title={t("BUTTON_DELETE")}
                    onClick={onDeleteClick}
                    shape="circle"
                  />
                </>
              )}
            </>
          }
        />
        <Tabs activeKey={activeTabKey} destroyInactiveTabPane>
          <TabPane key="DETAILS" tab={t("SPORT_TEAM_DETAILS_TAB_DETAILS")}>
            <DetailsTab
              updateTeamState={updateTeamState}
              updateTeam={updateTeam}
              team={team}
            />
          </TabPane>
          <TabPane key="PLAYERS" tab={t("SPORT_TEAM_DETAILS_TAB_PLAYERS")}>
            <PlayersTab team={team} />
          </TabPane>
          <TabPane key="STAFF" tab={t("SPORT_TEAM_DETAILS_TAB_STAFF")}>
            <StaffTab team={team} />
          </TabPane>
        </Tabs>
      </PageContent>
    </Spin>
  );
};
