import {
  IApplicationComponentLayoutModel,
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  PropertyType,
  RecordStatus,
} from "@bms/common-services";
import { ComponentLayoutPropertyModel } from "./ComponentLayoutPropertyModel";
import { ComponentPropertyModel } from "./ComponentPropertyModel";
import { LayoutOptionsModel } from "./LayoutOptionsModel";

export class TextWidgetComponentPropertyModel extends ComponentPropertyModel {
  Title?: string;

  BackgroundColor?: string;

  FontColor?: string;

  Content?: string;

  IsBrandingVisible?: boolean;

  IconUrl?: string;

  IconAbsoluteUrl?: string;

  IsVisible: boolean = true;

  constructor(component?: IApplicationComponentModel) {
    super();

    if (component) {
      this.init(component);
    }
  }

  public getLayoutProperty(
    component: IApplicationComponentModel,
    layoutOptions: LayoutOptionsModel
  ): ComponentLayoutPropertyModel {
    if (!component.Layouts) {
      component.Layouts = [];
    }

    let componentLayout = component.Layouts.find(
      (row: IApplicationComponentLayoutModel) =>
        row.PlatformCode === layoutOptions.Platform
    );

    if (!componentLayout) {
      componentLayout = {
        RecordStatus: RecordStatus.Inserted,
        ApplicationComponentId: component.Id,
        PlatformCode: layoutOptions.Platform,
        PositionX: 0,
        PositionY: layoutOptions.CurrentPositionY,
        Width: 12,
        Height: 6,
      };

      component.Layouts.push(componentLayout);
    }

    return {
      PositionX: componentLayout.PositionX,
      PositionY: componentLayout.PositionY,
      Width: componentLayout.Width,
      Height: componentLayout.Height,
      IsResizable: true,
      IsDraggable: true,
    };
  }

  initProperties(component: IApplicationComponentModel): void {
    if (!component.Properties) {
      component.Properties = [];
    }

    let titleProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Title"
    );

    if (!titleProperty) {
      this.onPropertyChange("Title", component);
    }

    let isBrandingVisibleProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "IsBrandingVisible"
    );

    if (!isBrandingVisibleProperty) {
      this.onPropertyChange("IsBrandingVisible", component);
    }

    let iconUrlProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "IconUrl"
    );

    if (!iconUrlProperty) {
      this.onPropertyChange("IconUrl", component);
    }
  }

  setProperties(component: IApplicationComponentModel): void {
    const isNewlyCreatedComponent =
      !Boolean(component.Properties?.length) && component.Id < 0;

    let titleProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Title"
    );

    if (titleProperty && titleProperty.Value) {
      this.Title = titleProperty.Value.StringValue;
    }

    let backgroundColorProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "BackgroundColor"
    );

    if (backgroundColorProperty && backgroundColorProperty.Value) {
      this.BackgroundColor = backgroundColorProperty.Value.StringValue;
    }

    let fontColorProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "FontColor"
    );

    if (fontColorProperty && fontColorProperty.Value) {
      this.FontColor = fontColorProperty.Value.StringValue;
    }

    let contentProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Content"
    );

    if (contentProperty && contentProperty.Value) {
      this.Content = contentProperty.Value.StringValue;
    }

    let isBrandingVisibleProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "IsBrandingVisible"
    );

    if (isBrandingVisibleProperty && isBrandingVisibleProperty.Value) {
      this.IsBrandingVisible = isBrandingVisibleProperty.Value.BooleanValue;
    }

    let iconUrlProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "IconUrl"
    );

    if (iconUrlProperty && iconUrlProperty.Value) {
      this.IconUrl = iconUrlProperty.Value.StringValue;
      this.IconAbsoluteUrl = iconUrlProperty.Value.UrlValue;
    }

    const isVisibleProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "IsVisible"
    );

    if (
      isVisibleProperty &&
      isVisibleProperty.Value &&
      typeof isVisibleProperty.Value.BooleanValue === "boolean"
    ) {
      this.IsVisible = isVisibleProperty.Value.BooleanValue;
    } else if (isNewlyCreatedComponent) {
      // Attach default visibility property to new component.
      this.onPropertyChange("IsVisible", component);
    }
  }

  setProperty(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Title":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "BackgroundColor":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "FontColor":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "Content":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "IsBrandingVisible":
        property.PropertyType = PropertyType.Boolean;
        property.IsEnumerable = false;
        break;
      case "IconUrl":
        property.PropertyType = PropertyType.Url;
        property.IsEnumerable = false;
        break;
      case "IsVisible":
        property.PropertyType = PropertyType.Boolean;
        property.IsEnumerable = false;
        break;
    }
  }

  setPropertyValue(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Title":
        if (property.Value) {
          property.Value.StringValue = this.Title;
        }
        break;
      case "BackgroundColor":
        if (property.Value) {
          property.Value.StringValue = this.BackgroundColor;
        }
        break;
      case "FontColor":
        if (property.Value) {
          property.Value.StringValue = this.FontColor;
        }
        break;
      case "Content":
        if (property.Value) {
          property.Value.StringValue = this.Content;
        }
        break;
      case "IsBrandingVisible":
        if (property.Value) {
          property.Value.BooleanValue = this.IsBrandingVisible;
        }
        break;
      case "IconUrl":
        if (property.Value) {
          property.Value.StringValue = this.IconUrl;
          property.Value.UrlValue = this.IconAbsoluteUrl;
        }
        break;
      case "IsVisible":
        if (property.Value) {
          property.Value.BooleanValue = this.IsVisible;
        }
        break;
    }
  }
}
