import { SectionGrid, SectionGridItem } from "@bms/common-ui";
import { ICommonMatchDetailsTabProps } from "./ICommonMatchDetailsTabProps";
import { useTranslation } from "react-i18next";
import { MatchesForm } from "../../MatchesForm";

export const DetailsTab = ({
  match,
  updateMatchState,
  updateMatch,
}: ICommonMatchDetailsTabProps) => {
  const { t } = useTranslation();

  return (
    <SectionGrid>
      <SectionGridItem
        header={t("SPORT_MATCH_DETAILS_SECTION_GENERAL_INFORMATION")}
        processing={updateMatchState?.processing}
      >
        <MatchesForm
          key={`MatchForm-${match?.RowVersion}`}
          isEditMode
          matches={match}
          onSubmit={updateMatch}
          section="general"
        />
      </SectionGridItem>
    </SectionGrid>
  );
};
