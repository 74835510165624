import {
  IBreadcrumbProps,
  PageHeader,
  PageContent,
  Tabs,
  useSearchParamsTabs,
  Spin,
  Button,
  Icon,
  TabPane,
} from "@bms/common-ui";
import { generateBreadcrumb } from "../../../../../helpers";
import { ROUTES } from "../../../constants";

import { useParams } from "react-router";

import { useTranslation } from "react-i18next";
import { useClubDetailsController } from "./useClubDetailsController";
import { DetailsTab, TeamsTab } from "./Tabs";

export const ClubDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [activeTabKey] = useSearchParamsTabs("DETAILS");

  const {
    refresh,
    updateClub,
    onBackClick,
    onDeleteClick,
    loading,
    dataPager,
    dataPagerLoading,
    updateClubState,
    club,
  } = useClubDetailsController();

  const clubName = club?.Name;

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.CLUBS}`,
        breadcrumbName: t("MENU_CMS_CLUBS"),
      },
      {
        path: `${ROUTES.CLUB_DETAILS}/${id}`,
        breadcrumbName: clubName ?? t("MENU_CMS_CLUB_DETAILS"),
      },
    ]);
  };

  return (
    <Spin spinning={loading || dataPagerLoading}>
      <PageContent>
        <PageHeader
          title={clubName ?? t("MENU_CMS_CLUB_DETAILS")}
          breadcrumb={getBreadcrumbProps()}
          onBack={onBackClick}
          extra={
            <>
              {dataPager.enabled && (
                <>
                  <Button
                    key="action-move-previous"
                    icon={<Icon type="arrow-left" />}
                    onClick={dataPager.movePrevious}
                    title={t("BUTTON_MOVE_PREVIOUS")}
                    shape="circle"
                    disabled={!dataPager.movePreviousEnabled}
                  />
                  <Button
                    key="action-move-next"
                    icon={<Icon type="arrow-right" />}
                    title={t("BUTTON_MOVE_NEXT")}
                    onClick={dataPager.moveNext}
                    shape="circle"
                    disabled={!dataPager.moveNextEnabled}
                  />
                  <Button
                    key="action-reload"
                    icon={<Icon type="reload" />}
                    onClick={refresh}
                    title={t("BUTTON_REFRESH_TITLE")}
                    shape="circle"
                  />
                  <Button
                    danger
                    key="action-delete"
                    icon={<Icon type="delete" />}
                    title={t("BUTTON_DELETE")}
                    onClick={onDeleteClick}
                    shape="circle"
                  />
                </>
              )}
            </>
          }
        />
        <Tabs activeKey={activeTabKey} destroyInactiveTabPane>
          <TabPane key="DETAILS" tab={t("SPORT_CLUB_DETAILS_TAB_DETAILS")}>
            <DetailsTab
              updateClubState={updateClubState}
              updateClub={updateClub}
              club={club}
            />
          </TabPane>
          <TabPane key="TEAMS" tab={t("SPORT_CLUB_DETAILS_TAB_TEAMS")}>
            <TeamsTab club={club} />
          </TabPane>
        </Tabs>
      </PageContent>
    </Spin>
  );
};
