import { CountriesService, CountryModel, IListModelBase } from "@bms/common-services";
import { Form } from "@bms/common-ui";

import { useTranslation } from "react-i18next";

import { ITeamFormFieldCommonProps } from "./ITeamFormFieldCommonProps";
import { LazyChoose } from "../../../../../../components";

interface ICountryCodeFieldProps extends ITeamFormFieldCommonProps {}

const countriesService = new CountriesService();

let countries: CountryModel[];

export const CountryCodeField = ({
  isEditMode,
  isHidden,
  team,
}: ICountryCodeFieldProps) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="CountryCode"
      label={t("MODEL_COUNTRY_NAME")}
      key="CountryCode"
      initialValue={
        (isEditMode && {
          label: team?.CountryName,
          value: team?.CountryCode,
        }) ||
        []
      }
    >
      <LazyChoose<CountryModel, any>
        disableRecordStatus
        loader={async () => {
          if (!countries) {
            countries = await countriesService.select().toPromise();
          }
          const data: IListModelBase<CountryModel, {}> = {
            TotalCount: countries.length,
            Entities: countries,
          };
          return {
            data,
            ok: true,
          };
        }}
        candidateToOption={(item) => ({
          label: item.Name,
          value: `${item.Code}`,
        })}
        selectedToOption={(item) => ({
          label: item.Name!,
          value: `${item.Code!}`,
        })}
        multiple={false}
      />
    </Form.Item>
  );
};
