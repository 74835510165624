import { Col, Form, Row } from "@bms/common-ui";
import { useMemo } from "react";
import {
  ISportTeamStaffFormProps,
  useSportTeamStaffFormController,
} from "./useSportTeamStaffController";
import { DateFromField, DateToField, PersonField, RoleIdField } from "./Fields";

export const SportTeamStaffForm = (props: ISportTeamStaffFormProps) => {
  const { isEditMode, staff } = props;
  const {
    formLayout,
    formFieldNames,
    form,
    onFieldsChange,
    onFinish,
    person,
    onPersonSelect,
    onPersonClear,
  } = useSportTeamStaffFormController(props);

  const formFieldsMapping: { [key: string]: () => JSX.Element } = {
    PersonField: () => (
      <PersonField
        key="SportTeamStaffForm--PersonField"
        isEditMode={isEditMode}
        genericPerson={person}
        onSelect={onPersonSelect}
        onClear={onPersonClear}
      />
    ),
    DateFrom: () => (
      <DateFromField
        key="SportTeamStaffForm--DateFromField"
        isEditMode={isEditMode}
        staff={staff}
      />
    ),
    DateTo: () => (
      <DateToField
        key="SportTeamStaffForm--DateToField"
        isEditMode={isEditMode}
        staff={staff}
      />
    ),
    RoleId: () => (
      <RoleIdField
        key="SportTeamStaffForm--RoleIdField"
        isEditMode={isEditMode}
        staff={staff}
      />
    ),
  };

  const formFields = useMemo(
    () =>
      formFieldNames.map((field: string) =>
        formFieldsMapping[field] ? formFieldsMapping[field]() : null
      ),
    [formFieldNames, person]
  );

  return (
    <Form
      {...formLayout}
      form={form}
      name={"SportTeamStaffForm"}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      className="SportTeamStaffForm"
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>{formFields}</Col>
      </Row>
    </Form>
  );
};
