import React from "react";
import { useTranslation } from "react-i18next";
import {
  AssetService,
  IAssetPeopleModel,
  IPersonInAssetModel,
  PersonInAssetType,
} from "@bms/common-services";
import { Form } from "@bms/common-ui";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
import { LazyChoose } from "../../../../../components";

interface IPersonFieldProps extends IAssetFormFieldCommonProps {
  name: string;
  label: string;
}

const assetService = new AssetService().promisify();

export const PersonField = ({
  name,
  label,
  isEditMode,
  asset,
  isHidden,
}: IPersonFieldProps) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  if (isEditMode) {
    let value: string | undefined = t("COMMON_UNDEFINED");
    const persons = asset?.People?.filter?.(
      (p) => p.PersonInAssetRoleCode === PersonInAssetType.Person
    );

    if (persons && persons.length > 0) {
      value = persons[0].AssetPersonFullName;
    }

    return (<Form.Item
      name={name}
      label={label}
      key={name}
    >
      <label>{value}</label>
    </Form.Item>)
  }

  return (
    <Form.Item
      name={name}
      label={label}
      key={name}
      initialValue={
        (isEditMode &&
          asset?.People?.filter?.(
            (p) => p.PersonInAssetRoleCode === PersonInAssetType.Person
          )) ||
        []
      }
    >
      <LazyChoose<IAssetPeopleModel, IPersonInAssetModel>

        multiple={false}
        loader={(search: string) =>
          assetService.searchAssetPeople({
            PageNumber: 1,
            PageSize: 100,
            FullTextSearch: search,
          })
        }
        candidateToOption={(item) => ({
          label: item.FullName,
          value: `${item.Id}`,
          item: {
            AssetId: asset?.Id,
            AssetPersonFullName: item.FullName,
            AssetPersonId: item.Id,
            PersonInAssetRoleCode: PersonInAssetType.Person,
            RowVersion: item.RowVersion,
          },
        })}
        selectedToOption={(item) => ({
          label: item.AssetPersonFullName!,
          value: `${item.AssetPersonId}`,
          item,
        })}
      />
    </Form.Item>
  );
};
