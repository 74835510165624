import { Form, Input, required } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ITeamFormFieldCommonProps } from "./ITeamFormFieldCommonProps";

interface ICityFieldProps extends ITeamFormFieldCommonProps {}

export const CityField = ({ isHidden, isEditMode, team }: ICityFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="City"
      label={t("MODEL_CITY")}
      key="city"
      initialValue={isEditMode ? team?.City || "" : ""}
      rules={[required()]}
    >
      <Input placeholder={t("MODEL_CITY_PLACEHOLDER")} />
    </Form.Item>
  );
};
