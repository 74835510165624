import { Button, Col, Form, Icon, Input, required, Row } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ISportPlayerModel } from "@bms/common-services";
import { useState } from "react";
import { ISportTeamPlayerFormFieldCommonProps } from "./ISportTeamPlayerFormFieldCommonProps";
import { SyncedPlayer } from "../useSportTeamPlayerController";
import { SportPlayerBrowserModal } from "../../SportPlayerBrowserModal";

interface IPlayerFieldProps extends ISportTeamPlayerFormFieldCommonProps {
  onSelect?: (row?: ISportPlayerModel) => void;
  onClear?: () => void;
  genericPlayer: SyncedPlayer;
}

export const PlayerField = ({
  genericPlayer,
  onSelect,
  onClear,
}: IPlayerFieldProps) => {
  const { t } = useTranslation();

  const [playerBrowserVisible, setPlayerBrowserVisible] = useState(false);

  const onPlayerSelect = (row: ISportPlayerModel) => {
    setPlayerBrowserVisible(false);
    onSelect?.(row);
  };

  const defaultValue =
    genericPlayer.FirstName || genericPlayer.LastName
      ? `${genericPlayer.FirstName || ""} ${genericPlayer.LastName || ""}`
      : "";

  return (
    <Form.Item
      name="PlayerId"
      label={t("MODEL_PLAYER")}
      key="playerId"
      initialValue={defaultValue}
      rules={[required()]}
    >
      <Row gutter={8}>
        <Col flex="auto">
          <Input
            placeholder={t("MODEL_PLAYER_SELECT_PLACEHOLDER")}
            disabled
            value={defaultValue}
          />
          <SportPlayerBrowserModal
            visible={playerBrowserVisible}
            onCancel={() => setPlayerBrowserVisible(false)}
            onSelect={onPlayerSelect}
          />
        </Col>
        <Col style={{ textAlign: "right" }}>
          {genericPlayer.Id && (
            <Button
              icon={<Icon type="delete" />}
              onClick={onClear}
              style={{ marginRight: "8px" }}
            />
          )}
          <Button
            icon={<Icon type="edit" />}
            onClick={() => setPlayerBrowserVisible(true)}
          />
        </Col>
      </Row>
    </Form.Item>
  );
};
