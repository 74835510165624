import {
  AssetImageType,
  AssetService,
  AssetStore,
  AssetType,
  IAssetModel,
  IAssetSearchFilterModel,
  RecordStatus,
  StreamType,
  TimeHelper,
  useDataLoader,
  ServiceResponse,
  IErrorModel,
  AppConfig,
} from "@bms/common-services";
import {
  AgeRestrictionTag,
  Button,
  Heading,
  Icon,
  InputSearch,
  ITableColumnFilterDropdownProps,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  Link,
  setTableColumnSearchProps,
  Slider,
  DatePickerPagerType,
  DatePickerPager,
  TableWithDraggableSorter,
  Popconfirm,
  Empty,
  PageContent,
  useAppFeedback,
  Dropdown,
  MenuItemClickEvent,
  Tag,
} from "@bms/common-ui";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ROUTES } from "../../constants";
import { AddAssetModal } from "../AddAssetModal";
import { AssetForm } from "../AssetForm";
import { useAssetDetailsController } from "../AssetDetails/useAssetDetailsController";
import { useCreateAssetDataPagerSource } from "../../hooks";
import { isEqual } from "lodash";
import { ClearOutlined } from "@ant-design/icons";
import { FilterCleanIcon } from "../../../../resources/icons";
import { AssetBrowserModal } from "../AssetBrowserModal";

import "./AssetChildrenList.scss";

const assetService = new AssetService().promisify();

export interface IAssetChildrenType {
  refresh: () => void;
}

export interface IAssetChildrenProps {
  title: string;
  asset?: IAssetModel;
  tabType?: string;
}

export const AssetChildrenList = React.forwardRef<
  IAssetChildrenType,
  IAssetChildrenProps
>(({ title, asset, tabType = "" }: IAssetChildrenProps, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { notification } = useAppFeedback();

  const currentDate = TimeHelper.getCurrentDate();
  const [isAssetAddModalOpen, setAssetAddModalOpen] = useState<boolean>(false);
  const [isAssetBrowserModalOpen, setAssetBrowserModalOpen] = useState<boolean>(
    false
  );

  const assetType = asset?.AssetTypeCode;

  const baseFilter = {
    AgeRestrictionFrom: undefined,
    AgeRestrictionTo: undefined,
    IncludeCount: true,
    IncludeImages: assetType === AssetType.Channel,
    Parents: asset?.Id ? [asset.Id] : undefined,
    StartDateTimeFrom:
      assetType === AssetType.Channel && !AppConfig.FeatureFastAiChannels
        ? TimeHelper.toString(currentDate)
        : undefined,
    StartDateTimeTo:
      assetType === AssetType.Channel && !AppConfig.FeatureFastAiChannels
        ? TimeHelper.toString(
            TimeHelper.getDateWithOffset(currentDate, 1, "day")
          )
        : undefined,
    Title: undefined,
    Types: [tabType],
  };

  const clearFilter = {
    ...baseFilter,
    PageSize: 10,
    PageNumber: 1,
    Types: [tabType],
  };

  const [filter, setFilter] = useState<IAssetSearchFilterModel>(clearFilter);

  const isAnyFilterActive = useMemo(() => {
    let currentFilter = { ...filter };
    delete currentFilter.PageSize;
    delete currentFilter.PageNumber;

    return !isEqual(currentFilter, baseFilter);
  }, [filter]);

  const assetsLoader = useDataLoader({
    loader: () => assetService.search(filter),
    deps: [filter],
  });

  useImperativeHandle(ref, () => ({
    refresh() {
      assetsLoader.refresh();
    },
  }));

  const assets = assetsLoader.data ?? { Entities: [], TotalCount: 0 };

  const [fullTextSearchValue, setFullTextSearchValue] = useState<string>("");

  const getAssetTypes = useCallback(
    () => dispatch(AssetStore.Actions.getAssetTypes()),
    [dispatch]
  );

  useEffect(() => {
    getAssetTypes();
  }, []);

  const onClearFiltersClick = () => {
    setFilter(clearFilter);
    setFullTextSearchValue("");
  };

  const onAddButtonOptionClick = (e: MenuItemClickEvent) => {
    switch (e.key) {
      case "ADD_NEW":
        setAssetAddModalOpen(true);
        break;
      case "ADD_EXISTING":
        setAssetBrowserModalOpen(true);
        break;
    }
  };

  const dataPagerSource = useCreateAssetDataPagerSource(assets, filter);

  const getColumnsProps = (): Array<ITableColumnProps<IAssetModel>> => {
    const titleColumn: ITableColumnProps<IAssetModel> = {
      key: "Title",
      dataIndex: "Title",
      title: t("MODEL_TITLE"),
      ellipsis: true,
      filteredValue: filter.Title ? [filter.Title] : null,
      render: (_: string, row: IAssetModel) => {
        const link = {
          pathname: `${ROUTES.ASSET_DETAILS}/${row.Id}`,
          state: { dataPagerSource: dataPagerSource },
        };
        return <Link to={link}>{row.Title}</Link>;
      },
      ...setTableColumnSearchProps("Title", t("MODEL_TITLE")),
    };
    const descriptionColumn: ITableColumnProps<IAssetModel> = {
      key: "Description",
      dataIndex: "Description",
      ellipsis: true,
      title: t("MODEL_DESCRIPTION"),
    };
    const typeColumn: ITableColumnProps<IAssetModel> = {
      key: "Types",
      dataIndex: "Types",
      align: "center",
      width: "180px",
      title: t("MODEL_TYPE_CODE"),
      render: (_, row: IAssetModel) => (
        <Tag colorRotate={row.AssetTypeCode}>{row.AssetTypeDisplayName}</Tag>
      ),
    };
    const yearColumn: ITableColumnProps<IAssetModel> = {
      key: "Year",
      dataIndex: "Year",
      title: t("MODEL_YEAR"),
      width: "70px",
    };
    const durationColumn: ITableColumnProps<IAssetModel> = {
      key: "DurationMiliseconds",
      dataIndex: "DurationMiliseconds",
      title: t("MODEL_DURATION_MILISECONDS"),
      width: "100px",
      align: "center",
      render: (text: string, row: IAssetModel) => {
        const durationMiliseconds =
          row.DurationMiliseconds ||
          asset?.Contents?.find((c) => c.StreamTypeCode === StreamType.Main)
            ?.DurationMiliseconds;

        return durationMiliseconds
          ? TimeHelper.formatPlayTimeFromMiliseconds(durationMiliseconds)
          : undefined;
      },
    };
    const ageRestrictionColumn: ITableColumnProps<IAssetModel> = {
      key: "AssetAgeRestrictionValueMin",
      dataIndex: "AssetAgeRestrictionValueMin",
      title: t("MODEL_AGE_RESTRICTION"),
      align: "center",
      width: "165px",
      filteredValue:
        filter.AgeRestrictionFrom !== undefined &&
        filter.AgeRestrictionTo !== undefined
          ? [filter.AgeRestrictionFrom, filter.AgeRestrictionTo]
          : null,
      filterDropdown: (event: ITableColumnFilterDropdownProps) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = event;
        const filterValue: [number, number] | undefined =
          selectedKeys && selectedKeys.length > 1
            ? [Number(selectedKeys[0]), Number(selectedKeys[1])]
            : undefined;

        return (
          <div style={{ padding: 8 }}>
            <Slider
              range
              min={0}
              max={18}
              value={filterValue || [0, 0]}
              key="AssetAgeRestrictionValueMin"
              onChange={(value: number[]) => {
                if (setSelectedKeys) {
                  setSelectedKeys(value || []);
                }
              }}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                type="link"
                size="small"
                disabled={!filterValue}
                onClick={() => {
                  if (clearFilters) {
                    if (setSelectedKeys) {
                      setSelectedKeys([]);
                    }

                    clearFilters();
                  }
                }}
              >
                <Trans i18nKey="BUTTON_RESET">Reset</Trans>
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  if (confirm) {
                    confirm();
                  }
                }}
                size="small"
              >
                <Trans i18nKey="BUTTON_OK">OK</Trans>
              </Button>
            </div>
          </div>
        );
      },
      render: (_, row: IAssetModel) => (
        <AgeRestrictionTag age={row.AssetAgeRestrictionValueMin} />
      ),
    };
    const availableFromColumn: ITableColumnProps<IAssetModel> = {
      key: "AvailableFrom",
      dataIndex: "AvailableFrom",
      width: "200px",
      align: "center",
      title: t("MODEL_AVAILABLE_FROM"),
      render: (text: string) => TimeHelper.format(new Date(text)),
    };
    const availableToColumn: ITableColumnProps<IAssetModel> = {
      key: "AvailableTo",
      dataIndex: "AvailableTo",
      width: "200px",
      align: "center",
      title: t("MODEL_AVAILABLE_TO"),
      render: (text: string) => {
        if (!text) {
          return null;
        }
        return TimeHelper.format(new Date(text));
      },
    };
    const startDateColumn: ITableColumnProps<IAssetModel> = {
      key: "StartDateTime",
      dataIndex: "StartDateTime",
      width: "200px",
      align: "center",
      title: t("MODEL_START_DATE_TIME"),
      render: (text: string) => {
        if (!text) {
          return null;
        }
        return TimeHelper.format(new Date(text));
      },
    };
    const endDateColumn: ITableColumnProps<IAssetModel> = {
      key: "EndDateTime",
      dataIndex: "EndDateTime",
      width: "200px",
      align: "center",
      title: t("MODEL_END_DATE_TIME"),
      render: (text: string) => {
        if (!text) {
          return null;
        }
        return TimeHelper.format(new Date(text));
      },
    };
    const orderInParentColumn: ITableColumnProps<IAssetModel> = {
      key: "OrderInParent",
      dataIndex: "OrderInParent",
      width: "80px",
      align: "center",
      title: t("MODEL_NO"),
    };
    const imageColumn: ITableColumnProps<IAssetModel> = {
      key: "Image",
      dataIndex: "Image",
      align: "center",
      width: "130px",
      title: t("MODEL_IMAGE"),
      render: (_: any, row: IAssetModel) => {
        const image = row.Images?.find(
          (img) => img.AssetImageTypeCode === AssetImageType.Frame
        );

        if (image) {
          return <img src={image.Url} alt="" className="asetImagePreview" />;
        }

        return null;
      },
    };
    const actionsColumn: ITableColumnProps<IAssetModel> = {
      key: "Actions",
      dataIndex: "Actions",
      align: "center",
      width: "110px",
      title: t("TABLE_ACTIONS_COLUMN"),
      render: (_, asset: IAssetModel) => (
        <div className="asset-table__actions">
          <Popconfirm
            title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              let result: ServiceResponse<IAssetModel, IErrorModel>;

              switch (asset.AssetTypeCode) {
                case AssetType.Video:
                case AssetType.Advertisement:
                  const updatedAsset: IAssetModel = {
                    ...asset,
                  };
                  delete updatedAsset.ParentAssetId;

                  result = await assetService.updateAsset(updatedAsset);
                  break;
                default:
                  result = await assetService.deleteAsset(asset!);
                  break;
              }

              if (result.ok) {
                await assetsLoader.refresh();
              } else {
                notification.error({
                  message: t("DELETE_ELEMENT_FAILTURE"),
                  description: result.error?.Message,
                });
              }
            }}
            okText={t("BUTTON_YES")}
            cancelText={t("BUTTON_NO")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT")}
            />
          </Popconfirm>
        </div>
      ),
    };

    switch (assetType) {
      case AssetType.Series:
        return [
          orderInParentColumn,
          titleColumn,
          descriptionColumn,
          yearColumn,
          durationColumn,
          ageRestrictionColumn,
        ];
      case AssetType.Season:
        return [
          orderInParentColumn,
          titleColumn,
          durationColumn,
          ageRestrictionColumn,
        ];
      case AssetType.Channel:
        if (AppConfig.FeatureFastAiChannels) {
          return [
            imageColumn,
            titleColumn,
            typeColumn,
            startDateColumn,
            endDateColumn,
            actionsColumn,
          ];
        }
        return [
          imageColumn,
          titleColumn,
          startDateColumn,
          endDateColumn,
          actionsColumn,
        ];
      default:
        return [
          titleColumn,
          descriptionColumn,
          yearColumn,
          durationColumn,
          ageRestrictionColumn,
          availableFromColumn,
          availableToColumn,
        ];
    }
  };

  const onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    const searchFilter: IAssetSearchFilterModel = {
      ...filter,
      Parents: asset?.Id ? [asset.Id] : undefined,
      IncludeCount: true,
      PageNumber: pagination.current,
      PageSize: pagination.pageSize,
      Title: filters.Title?.length ? `${filters.Title[0]}` : undefined,
    };

    if (filters.AssetAgeRestrictionValueMin?.length === 2) {
      searchFilter.AgeRestrictionFrom = +filters.AssetAgeRestrictionValueMin[0];
      searchFilter.AgeRestrictionTo = +filters.AssetAgeRestrictionValueMin[1];
    } else {
      searchFilter.AgeRestrictionFrom = undefined;
      searchFilter.AgeRestrictionTo = undefined;
    }

    setFilter(searchFilter);
  };

  const onSearch = (value: string) => {
    const searchFilter: IAssetSearchFilterModel = {
      ...filter,
      Parents: asset?.Id ? [asset.Id] : undefined,
      FullTextSearch: value,
    };

    setFilter(searchFilter);
  };

  const onMoveRow = async (dragIndex: number, hoverIndex: number) => {
    const draggedAssetChild = assets.Entities[dragIndex];
    const hoveredAssetChild = assets.Entities[hoverIndex];

    const childToUpdate: IAssetModel = {
      ...draggedAssetChild,
      OrderInParent: hoveredAssetChild.OrderInParent ?? 1,
      RecordStatus: RecordStatus.Updated,
    };

    const orderChanged =
      draggedAssetChild.OrderInParent !== childToUpdate.OrderInParent;
    const draggedToNewPosition =
      draggedAssetChild.Guid !== hoveredAssetChild.Guid;

    if (draggedToNewPosition && orderChanged) {
      const updateStatus = await assetService.updateAsset(childToUpdate);

      if (updateStatus.ok) {
        assetsLoader.refresh();
      }
    }
  };

  const renderHandleWrapper = (handle: React.ReactNode) =>
    isAnyFilterActive ? (
      <Popconfirm
        trigger="hover"
        placement="right"
        title={t(
          "CLEAR_FILTERS_TO_REORDER_LABEL",
          "Clear filters to reorder the list."
        )}
        onConfirm={onClearFiltersClick}
        okText={t("MENU_OPTION_CLEAR_FILTERS")}
        cancelText={t("BUTTON_CANCEL")}
      >
        {handle}
      </Popconfirm>
    ) : (
      handle
    );

  const tablePagination: ITablePaginationConfig = {
    current: filter?.PageNumber,
    defaultPageSize: 10,
    pageSize: assets.Filter?.PageSize,
    pageSizeOptions: ["10", "30", "50", "100"],
    showSizeChanger: true,
    showTotal: (total, range) =>
      t("TABLE_PAGINATION_TOTAL", {
        rangeFrom: range[0],
        rangeTo: range[1],
        total: total,
      }),
    total: assets.TotalCount ?? 0,
  };

  const columns = getColumnsProps();
  const {
    createChildAsset,
    createChildAssetState,
  } = useAssetDetailsController();

  const onCreateChildAsset = async (data: IAssetModel) => {
    const isOk = await createChildAsset(data, asset);

    if (isOk) {
      setAssetAddModalOpen(false);
    }
  };

  const selectedType = (): AssetType[] | undefined => {
    switch (assetType) {
      case AssetType.Album:
        return [AssetType.Podcast];
      case AssetType.Season:
        return [AssetType.Episode];
      case AssetType.Series:
        return [AssetType.Season];
      case AssetType.Channel:
        return AppConfig.FeatureFastAiChannels
          ? [AssetType.Program, AssetType.Video, AssetType.Advertisement]
          : [AssetType.Program];
      default:
        return undefined;
    }
  };

  const onSelect = async (selectedAsset: IAssetModel) => {
    const updatedAsset: IAssetModel = {
      ...selectedAsset,
      ParentAssetId: asset?.Id,
    };

    const updateStatus = await assetService.updateAsset(updatedAsset);

    if (updateStatus.ok) {
      notification.success({
        message: t("ASSET_CHILDREN_INSERT_CHILDREN_SUCCESSFULLY"),
      });
      assetsLoader.refresh();
    } else {
      notification.error({
        message: t("ASSET_CHILDREN_INSERT_CHILDREN_FAILURE"),
        description: updateStatus.error.Message,
      });
    }
  };

  return (
    <>
      <PageContent>
        <Heading
          title={title}
          actionsClassName="AssetChildrenList__actions"
          actions={
            <>
              <div>
                {assetType === AssetType.Channel &&
                  !AppConfig.FeatureFastAiChannels && (
                    <DatePickerPager
                      defaultValue={Date.now()}
                      pager={DatePickerPagerType.Days}
                      onChange={(value) => {
                        const dateValue = value
                          ? TimeHelper.getDate(value)
                          : undefined;
                        const searchFilter: IAssetSearchFilterModel = {
                          ...filter,
                          PageNumber: 1,
                          StartDateTimeFrom: dateValue
                            ? TimeHelper.toString(dateValue)
                            : undefined,
                          StartDateTimeTo: dateValue
                            ? TimeHelper.toString(
                                TimeHelper.getDateWithOffset(
                                  dateValue,
                                  1,
                                  "day"
                                )
                              )
                            : undefined,
                        };

                        setFilter(searchFilter);
                      }}
                    />
                  )}
              </div>
              <div className="right">
                <InputSearch
                  key="search"
                  placeholder={t("SEARCH_PLACEHOLDER")}
                  value={fullTextSearchValue}
                  onChange={({ target: { value } }) =>
                    setFullTextSearchValue(value)
                  }
                  onSearch={onSearch}
                  style={{ width: 250 }}
                  allowClear={true}
                />
                <Button
                  key="clear"
                  shape="circle"
                  icon={<Icon component={FilterCleanIcon} />}
                  onClick={onClearFiltersClick}
                  title={t("MENU_OPTION_CLEAR_FILTERS")}
                />
                {/* <Button
                  key="add"
                  shape="circle"
                  type="primary"
                  icon={<Icon type="plus" />}
                  onClick={() => setAssetAddModalOpen(true)}
                  title={t(
                    asset?.AssetTypeCode === AssetType.Album
                      ? "ASSET_CHILDREN_LIST_ADD_PODCAST_TITLE"
                      : asset?.AssetTypeCode === AssetType.Season
                      ? "ASSET_CHILDREN_LIST_ADD_SEASON_TITLE"
                      : "ASSET_CHILDREN_LIST_ADD_PROGRAM_TITLE"
                  )}
                /> */}
                <Dropdown
                  key="add-options"
                  menu={{
                    onClick: onAddButtonOptionClick,
                    items: [
                      {
                        label: t("ASSET_ADD_NEW"),
                        key: "ADD_NEW",
                      },
                      {
                        label: t("ASSET_ADD_EXISTING"),
                        key: "ADD_EXISTING",
                      },
                    ],
                  }}
                >
                  <Button
                    key="add-options"
                    shape="circle"
                    type="primary"
                    icon={<Icon type="plus" />}
                    title={t(
                      asset?.AssetTypeCode === AssetType.Album
                        ? "ASSET_CHILDREN_LIST_ADD_PODCAST_TITLE"
                        : asset?.AssetTypeCode === AssetType.Season
                        ? "ASSET_CHILDREN_LIST_ADD_SEASON_TITLE"
                        : "ASSET_CHILDREN_LIST_ADD_PROGRAM_TITLE"
                    )}
                  ></Button>
                </Dropdown>
              </div>
            </>
          }
        />

        <TableWithDraggableSorter<IAssetModel>
          locale={{
            emptyText: (
              <>
                <Empty />
                {isAnyFilterActive && (
                  <Button
                    icon={<ClearOutlined />}
                    onClick={onClearFiltersClick}
                  >
                    {t("MENU_OPTION_CLEAR_FILTERS")}
                  </Button>
                )}
              </>
            ),
          }}
          dragType="handler"
          columns={columns}
          dataSource={assets.Entities}
          rowKey="Id"
          loading={assetsLoader.loading}
          pagination={assets.TotalCount > 10 && tablePagination}
          onChange={onTableChange}
          onMoveRow={onMoveRow}
          dragDisabled={isAnyFilterActive}
          renderHandleWrapper={renderHandleWrapper}
        />
      </PageContent>
      <AddAssetModal
        visible={isAssetAddModalOpen}
        close={() => setAssetAddModalOpen(false)}
        processing={createChildAssetState.processing}
      >
        <AssetForm
          isEditMode={false}
          parent={asset}
          tabType={tabType}
          parentAssetsCount={assets.TotalCount}
          onSubmit={onCreateChildAsset}
        />
      </AddAssetModal>
      <AssetBrowserModal
        visible={isAssetBrowserModalOpen}
        selectionMode="single"
        selectedType={selectedType()}
        onCancel={() => setAssetBrowserModalOpen(false)}
        onSelect={onSelect}
      />
    </>
  );
});
