import { SectionGrid, SectionGridItem } from "@bms/common-ui";
import { ICommonLeagueDetailsTabProps } from "./ICommonLeagueDetailsTabProps";
import { useTranslation } from "react-i18next";
import { LeagueForm } from "../../LeagueForm";

export const DetailsTab = ({
  league,
  updateLeagueState,
  updateLeague,
}: ICommonLeagueDetailsTabProps) => {
  const { t } = useTranslation();

  return (
    <SectionGrid>
      <SectionGridItem
        header={t("SPORT_LEAGUE_DETAILS_SECTION_GENERAL_INFORMATION")}
        processing={updateLeagueState?.processing}
      >
        <LeagueForm
          key={`LeagueForm-${league?.RowVersion}`}
          isEditMode
          league={league}
          onSubmit={updateLeague}
        />
      </SectionGridItem>
    </SectionGrid>
  );
};
