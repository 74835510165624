import { ActionsObservable, StateObservable } from "redux-observable";
import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import {
  ICreateAgeCategoryAction,
  ICreatePlayerPositionAction,
  ICreateStaffRoleAction,
  ICreateStatisticsUnitAction,
  IDeleteAgeCategoryAction,
  IDeletePlayerPositionAction,
  IDeleteStaffRoleAction,
  IDeleteStatisticsUnitAction,
  IGetAgeCategoriesAction,
  IGetAgeCategoryAction,
  IGetPlayerPositionAction,
  IGetPlayerPositionsAction,
  IGetStaffRoleAction,
  IGetStaffRolesAction,
  IGetStatisticsUnitAction,
  IGetStatisticsUnitsAction,
  IUpdateAgeCategoryAction,
  IUpdatePlayerPositionAction,
  IUpdateStaffRoleAction,
  IUpdateStatisticsUnitAction,
} from "./types";
import * as Consts from "./consts";
import { ICommonAppState } from "../types";
import {
  IErrorModel,
  ISportTeamStaffRoleModel,
  SportAgeCategoryModel,
  SportPlayerPositionModel,
  SportStatisticsUnitModel,
} from "../../models";
import {
  createAgeCategoryFailure,
  createAgeCategorySuccess,
  updateAgeCategorySuccess,
  deleteAgeCategorySuccess,
  updateAgeCategoryFailure,
  deleteAgeCategoryFailure,
  getAgeCategoriesSuccess,
  getAgeCategoriesFailure,
  getAgeCategorySuccess,
  getAgeCategoryFailure,
  getPlayerPositionSuccess,
  getPlayerPositionFailure,
  getPlayerPositionsSuccess,
  getPlayerPositionsFailure,
  createPlayerPositionSuccess,
  createPlayerPositionFailure,
  updatePlayerPositionSuccess,
  updatePlayerPositionFailure,
  deletePlayerPositionSuccess,
  deletePlayerPositionFailure,
  getStaffRoleSuccess,
  getStaffRoleFailure,
  getStaffRolesSuccess,
  getStaffRolesFailure,
  createStaffRoleSuccess,
  createStaffRoleFailure,
  updateStaffRoleSuccess,
  updateStaffRoleFailure,
  deleteStaffRoleSuccess,
  deleteStaffRoleFailure,
  getStatisticsUnitSuccess,
  getStatisticsUnitFailure,
  getStatisticsUnitsSuccess,
  getStatisticsUnitsFailure,
  createStatisticsUnitSuccess,
  createStatisticsUnitFailure,
  updateStatisticsUnitSuccess,
  updateStatisticsUnitFailure,
  deleteStatisticsUnitSuccess,
  deleteStatisticsUnitFailure,
} from "./actions";
import {
  SportAgeCategoryService,
  SportTeamStaffRoleService,
  SportPlayerPositionsService,
  SportStatisticsUnitsService,
} from "../../services";

const sportAgeCategoryService: SportAgeCategoryService = new SportAgeCategoryService();
const sportPlayerPositionsService: SportPlayerPositionsService = new SportPlayerPositionsService();
const sportTeamStaffRoleService: SportTeamStaffRoleService = new SportTeamStaffRoleService();
const sportStatisticsUnitService: SportStatisticsUnitsService = new SportStatisticsUnitsService();

// #################### AGE CATEGORIES ####################
const getAgeCategoryEpic = (
  action$: ActionsObservable<IGetAgeCategoryAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_AGE_CATEGORY),
    switchMap((action: IGetAgeCategoryAction) =>
      sportAgeCategoryService.getCategory(action.id).pipe(
        map((response: SportAgeCategoryModel) => {
          return getAgeCategorySuccess(response);
        }),
        catchError((error: IErrorModel) => of(getAgeCategoryFailure(error)))
      )
    )
  );

const getAgeCategoriesEpic = (
  action$: ActionsObservable<IGetAgeCategoriesAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_AGE_CATEGORIES),
    switchMap((action: IGetAgeCategoriesAction) =>
      sportAgeCategoryService.getCategories().pipe(
        map((response: SportAgeCategoryModel[]) => {
          return getAgeCategoriesSuccess(response);
        }),
        catchError((error: IErrorModel) => of(getAgeCategoriesFailure(error)))
      )
    )
  );

const createAgeCategoryEpic = (
  action$: ActionsObservable<ICreateAgeCategoryAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.CREATE_AGE_CATEGORY),
    switchMap((action: ICreateAgeCategoryAction) =>
      sportAgeCategoryService.createCategory(action.data).pipe(
        map((response: SportAgeCategoryModel) => {
          action.callback && action.callback();
          return createAgeCategorySuccess(response);
        }),
        catchError((error: IErrorModel) => of(createAgeCategoryFailure(error)))
      )
    )
  );

const updateAgeCategoryEpic = (
  action$: ActionsObservable<IUpdateAgeCategoryAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_AGE_CATEGORY),
    switchMap((action: IUpdateAgeCategoryAction) =>
      sportAgeCategoryService.updateCategory(action.data).pipe(
        map((response: SportAgeCategoryModel) => {
          action.callback && action.callback();
          return updateAgeCategorySuccess(response);
        }),
        catchError((error: IErrorModel) => of(updateAgeCategoryFailure(error)))
      )
    )
  );

const deleteAgeCategoryEpic = (
  action$: ActionsObservable<IDeleteAgeCategoryAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_AGE_CATEGORY),
    switchMap((action: IDeleteAgeCategoryAction) =>
      sportAgeCategoryService.deleteCategory(action.data).pipe(
        map(() => {
          return deleteAgeCategorySuccess();
        }),
        catchError((error: IErrorModel) => of(deleteAgeCategoryFailure(error)))
      )
    )
  );

// #################### PLAYER POSITIONS ####################

const getPlayerPositionEpic = (
  action$: ActionsObservable<IGetPlayerPositionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_PLAYER_POSITION),
    switchMap((action: IGetPlayerPositionAction) =>
      sportPlayerPositionsService.getPlayerPosition(action.id).pipe(
        map((response: SportPlayerPositionModel) => {
          return getPlayerPositionSuccess(response);
        }),
        catchError((error: IErrorModel) => of(getPlayerPositionFailure(error)))
      )
    )
  );

const getPlayerPositionsEpic = (
  action$: ActionsObservable<IGetPlayerPositionsAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_PLAYER_POSITIONS),
    switchMap((action: IGetPlayerPositionsAction) =>
      sportPlayerPositionsService.getPlayerPositions().pipe(
        map((response: SportPlayerPositionModel[]) => {
          return getPlayerPositionsSuccess(response);
        }),
        catchError((error: IErrorModel) => of(getPlayerPositionsFailure(error)))
      )
    )
  );

const createPlayerPositionEpic = (
  action$: ActionsObservable<ICreatePlayerPositionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.CREATE_PLAYER_POSITION),
    switchMap((action: ICreatePlayerPositionAction) =>
      sportPlayerPositionsService.createPlayerPosition(action.data).pipe(
        map((response: SportPlayerPositionModel) => {
          action.callback && action.callback();
          return createPlayerPositionSuccess(response);
        }),
        catchError((error: IErrorModel) =>
          of(createPlayerPositionFailure(error))
        )
      )
    )
  );

const updatePlayerPositionEpic = (
  action$: ActionsObservable<IUpdatePlayerPositionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_PLAYER_POSITION),
    switchMap((action: IUpdatePlayerPositionAction) =>
      sportPlayerPositionsService.updatePlayerPosition(action.data).pipe(
        map((response: SportPlayerPositionModel) => {
          action.callback && action.callback();
          return updatePlayerPositionSuccess(response);
        }),
        catchError((error: IErrorModel) =>
          of(updatePlayerPositionFailure(error))
        )
      )
    )
  );

const deletePlayerPositionEpic = (
  action$: ActionsObservable<IDeletePlayerPositionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_PLAYER_POSITION),
    switchMap((action: IDeletePlayerPositionAction) =>
      sportPlayerPositionsService.deletePlayerPosition(action.data).pipe(
        map(() => {
          return deletePlayerPositionSuccess();
        }),
        catchError((error: IErrorModel) =>
          of(deletePlayerPositionFailure(error))
        )
      )
    )
  );

// #################### STAFF ROLES ####################

const getStaffRoleEpic = (
  action$: ActionsObservable<IGetStaffRoleAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_STAFF_ROLE),
    switchMap((action: IGetStaffRoleAction) =>
      sportTeamStaffRoleService.getRole(action.id).pipe(
        map((response: ISportTeamStaffRoleModel) => {
          return getStaffRoleSuccess(response);
        }),
        catchError((error: IErrorModel) => of(getStaffRoleFailure(error)))
      )
    )
  );

const getStaffRolesEpic = (
  action$: ActionsObservable<IGetStaffRolesAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_STAFF_ROLES),
    switchMap((action: IGetStaffRolesAction) =>
      sportTeamStaffRoleService.getRoles().pipe(
        map((response: ISportTeamStaffRoleModel[]) => {
          return getStaffRolesSuccess(response);
        }),
        catchError((error: IErrorModel) => of(getStaffRolesFailure(error)))
      )
    )
  );

const createStaffRoleEpic = (
  action$: ActionsObservable<ICreateStaffRoleAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.CREATE_STAFF_ROLE),
    switchMap((action: ICreateStaffRoleAction) =>
      sportTeamStaffRoleService.createRole(action.data).pipe(
        map((response: ISportTeamStaffRoleModel) => {
          return createStaffRoleSuccess(response);
        }),
        catchError((error: IErrorModel) => of(createStaffRoleFailure(error)))
      )
    )
  );

const updateStaffRoleEpic = (
  action$: ActionsObservable<IUpdateStaffRoleAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_STAFF_ROLE),
    switchMap((action: IUpdateStaffRoleAction) =>
      sportTeamStaffRoleService.updateRole(action.data).pipe(
        map((response: ISportTeamStaffRoleModel) => {
          return updateStaffRoleSuccess(response);
        }),
        catchError((error: IErrorModel) => of(updateStaffRoleFailure(error)))
      )
    )
  );

const deleteStaffRoleEpic = (
  action$: ActionsObservable<IDeleteStaffRoleAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_STAFF_ROLE),
    switchMap((action: IDeleteStaffRoleAction) =>
      sportTeamStaffRoleService.deleteRole(action.data).pipe(
        map(() => {
          return deleteStaffRoleSuccess();
        }),
        catchError((error: IErrorModel) => of(deleteStaffRoleFailure(error)))
      )
    )
  );

// #################### STATISTICS UNITS ####################

const getStatisticsUnitEpic = (
  action$: ActionsObservable<IGetStatisticsUnitAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_STATISTICS_UNIT),
    switchMap((action: IGetStatisticsUnitAction) =>
      sportStatisticsUnitService.getUnit(action.code).pipe(
        map((response: SportStatisticsUnitModel) => {
          return getStatisticsUnitSuccess(response);
        }),
        catchError((error: IErrorModel) => of(getStatisticsUnitFailure(error)))
      )
    )
  );

const getStatisticsUnitsEpic = (
  action$: ActionsObservable<IGetStatisticsUnitsAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_STATISTICS_UNITS),
    switchMap((action: IGetStatisticsUnitsAction) =>
      sportStatisticsUnitService.getUnits().pipe(
        map((response: SportStatisticsUnitModel[]) => {
          return getStatisticsUnitsSuccess(response);
        }),
        catchError((error: IErrorModel) => of(getStatisticsUnitsFailure(error)))
      )
    )
  );

const createStatisticsUnitEpic = (
  action$: ActionsObservable<ICreateStatisticsUnitAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.CREATE_STATISTICS_UNIT),
    switchMap((action: ICreateStatisticsUnitAction) =>
      sportStatisticsUnitService.createUnit(action.data).pipe(
        map((response: SportStatisticsUnitModel) => {
          return createStatisticsUnitSuccess(response);
        }),
        catchError((error: IErrorModel) =>
          of(createStatisticsUnitFailure(error))
        )
      )
    )
  );

const updateStatisticsUnitEpic = (
  action$: ActionsObservable<IUpdateStatisticsUnitAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_STATISTICS_UNIT),
    switchMap((action: IUpdateStatisticsUnitAction) =>
      sportStatisticsUnitService.updateUnit(action.data).pipe(
        map((response: SportStatisticsUnitModel) => {
          return updateStatisticsUnitSuccess(response);
        }),
        catchError((error: IErrorModel) =>
          of(updateStatisticsUnitFailure(error))
        )
      )
    )
  );

const deleteStatisticsUnitEpic = (
  action$: ActionsObservable<IDeleteStatisticsUnitAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_STATISTICS_UNIT),
    switchMap((action: IDeleteStatisticsUnitAction) =>
      sportStatisticsUnitService.deleteUnit(action.data).pipe(
        map(() => {
          return deleteStatisticsUnitSuccess();
        }),
        catchError((error: IErrorModel) =>
          of(deleteStatisticsUnitFailure(error))
        )
      )
    )
  );

export const sportEpics = [
  createAgeCategoryEpic,
  updateAgeCategoryEpic,
  deleteAgeCategoryEpic,
  getAgeCategoriesEpic,
  getAgeCategoryEpic,
  getPlayerPositionEpic,
  getPlayerPositionsEpic,
  createPlayerPositionEpic,
  updatePlayerPositionEpic,
  deletePlayerPositionEpic,
  getStaffRoleEpic,
  getStaffRolesEpic,
  createStaffRoleEpic,
  updateStaffRoleEpic,
  deleteStaffRoleEpic,
  getStatisticsUnitEpic,
  getStatisticsUnitsEpic,
  createStatisticsUnitEpic,
  updateStatisticsUnitEpic,
  deleteStatisticsUnitEpic,
];
