import { ReactNode } from "react";
import { FormModal } from "../../../../components";
import { useTranslation } from "react-i18next";

interface IAddPeopleModal {
  visible: boolean;
  close: () => void;
  processing: boolean;
  children?: ReactNode;
}

export const AddPeopleModal = ({
  visible,
  close,
  processing,
  children,
}: IAddPeopleModal) => {
  const { t } = useTranslation();

  return (
    <FormModal
      isLoading={processing}
      createFormTitle={t("PEOPLE_ADD_NEW")}
      editFormTitle={t("PEOPLE_ADD_NEW")}
      isNewForm
      isVisible={visible}
      isDeleteButtonEnabled={false}
      modalClassName="AddPeopleModal"
      submitFormName="PeopleForm"
      onCloseModal={close}
    >
      {children}
    </FormModal>
  );
};
