import {
  IPeopleModel,
  ISportPlayerModel,
  PeopleService,
  SportPlayersService,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { useAppFeedback, useSyncedState } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { usePlayersDataPager } from "../../../hooks";
import { ROUTES } from "../../../constants";

const playersService = new SportPlayersService().promisify();
const peopleService = new PeopleService().promisify();

export const usePlayerDetailsController = () => {
  const id = parseInt(useParams<{ id: string }>()["id"]);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [dataPager, dataPagerLoading] = usePlayersDataPager(id);

  const { data, loading, refresh: refreshPlayer } = useDataLoader({
    loader: () => playersService.getPlayer(id),
    deps: [id],
  });

  const {
    data: peopleData,
    loading: peopleLoading,
    refresh: refreshPeople,
  } = useDataLoader({
    loader: () => peopleService.getPeople(data?.PersonId!),
    deps: [data?.PersonId],
  });

  const [player, setPlayer] = useSyncedState(() => data, [data]);
  const [person, setPerson] = useSyncedState(() => peopleData, [peopleData]);

  const { modal, notification } = useAppFeedback();

  const [updatePlayer, updatePlayerState] = useServiceCaller(
    async (data: ISportPlayerModel) => {
      const result = await playersService.updatePlayer(data);
      if (result.ok) {
        setPlayer(result.data);
        notification.success({
          message: t("SPORT_PLAYER_DETAILS__PLAYER_UPDATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("SPORT_PLAYER_DETAILS__PLAYER_UPDATE_FAILURE"),
          description: result.error.Message,
        });
      }
    },
    []
  );

  const [updatePerson, updatePersonState] = useServiceCaller(
    async (data: IPeopleModel) => {
      const result = await peopleService.updatePeople(data);
      if (result.ok) {
        setPerson(result.data);
        refreshPlayer();
        notification.success({
          message: t("SPORT_PLAYER_DETAILS__PLAYER_UPDATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("SPORT_PLAYER_DETAILS__PLAYER_UPDATE_FAILURE"),
          description: result.error.Message,
        });
      }
    },
    []
  );

  const [deletePlayer] = useServiceCaller(async () => {
    const result = await playersService.deletePlayer(player!);
    if (result.ok) {
      notification.success({
        message: t("SPORT_PLAYER_DETAILS__PLAYER_DELETE_SUCCESS"),
      });
      history.push(ROUTES.PLAYERS);
    } else {
      notification.error({
        message: t("SPORT_PLAYER_DETAILS__PLAYER_DELETE_FAILURE"),
        description: result.error?.Message,
      });
    }
  }, [player]);

  const onDeleteClick = () => {
    if (!player) {
      return;
    }

    modal.confirm({
      title: t("COMMON_DELETE"),
      content: t("SPORT_PLAYER_DETAILS__PLAYER_DELETE_MESSAGE", {
        playerName: player?.FirstName,
      }),
      okText: t("BUTTON_DELETE"),
      cancelText: t("BUTTON_CANCEL"),
      onOk: deletePlayer,
    });
  };

  const onBackClick = () => {
    if (location.state && location.state.hasOwnProperty("from")) {
      // use location from router state
      const state = location.state as { from: Location };
      history.push(state.from);
    } else {
      // use people list location
      history.goBack();
    }
  };

  const refresh = () => {
    refreshPlayer();
    refreshPeople();
  };

  return {
    updatePlayerState,
    updatePersonState,
    player,
    person,
    loading,
    peopleLoading,
    dataPager,
    dataPagerLoading,
    refresh,
    updatePlayer,
    updatePerson,
    onDeleteClick,
    onBackClick,
    t,
  };
};
