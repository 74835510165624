import React, { memo } from "react";
import { Text } from "../Typography";
interface ILabeledValueTextProps {
  label?: any;
  value?: any;
  separator?: React.ReactElement;
}

export const LabeledValueText = memo(
  ({ label, value, separator = <>&nbsp;</> }: ILabeledValueTextProps) => {
    const hasMultipleValues = Array.isArray(value) && value.length > 0;

    return (
      <p>
        <Text>{label}:&nbsp;</Text>
        <Text style={{ fontWeight: 700 }}>
          {hasMultipleValues ? (
            value.map((v, index) => {
              const isLastElement = index === value.length - 1;
              return (
                <React.Fragment key={`${v}-${index}`}>
                  <strong>{v}</strong>
                  {isLastElement ? null : separator}
                </React.Fragment>
              );
            })
          ) : (
            <strong>{value}</strong>
          )}
        </Text>
      </p>
    );
  }
);
