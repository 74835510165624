import React, { useEffect, useState } from "react";
import {
  IAnalyticsPaymentListFilterModel,
  IAnalyticsPaymentModel,
} from "@bms/common-services";
import {
  ITableCurrentDataSource,
  ITableFilter,
  ITablePaginationConfig,
  PageContent,
  Pagination,
  Table,
} from "@bms/common-ui";
import { usePaymentListColumns, usePaymentListDataProvider } from "../../hooks";
import { PaymentListHeader } from "./PaymentListHeader";
import { PaymantImportModal } from "../PaymantImportModal";

import "./PaymentList.scss";

export const PaymentList = () => {
  const [isPaymentImportVisible, setIsPaymentImportVisible] = useState(false);
  const {
    dataLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = usePaymentListDataProvider();

  const columns = usePaymentListColumns(filters);

  const handleTableChange = (
    _: ITablePaginationConfig,
    tableFilters: ITableFilter,
    sorter: any,
    __: ITableCurrentDataSource<any>
  ) => {
    const newFilters: IAnalyticsPaymentListFilterModel = {
      PaymentStatuses: (tableFilters.PaymentStatuses || []) as string[],
      PaymentTypes: (tableFilters.PaymentTypes || []) as string[],
    };
    switch (sorter.field) {
      case "PaymentDate":
        newFilters.SortAscending = sorter.order === "ascend";
        break;
      default:
    }

    filters.update((oldFilters) => ({
      ...oldFilters,
      ...newFilters,
    }));
  };

  useEffect(() => {
    filters.clear();
  }, []);

  const onPaymentImportSuccess = () => {
    setIsPaymentImportVisible(false);
    dataLoader.refresh();
  };

  return (
    <PageContent
      className="PaymentList"
      footer={<Pagination {...pagination.props} />}
    >
      <PaymentListHeader
        onRefresh={dataLoader.refresh}
        onInport={() => setIsPaymentImportVisible(true)}
        fullTextSearch={fullTextSearch}
        setFullTextSearch={setFullTextSearch}
        filters={filters}
      />
      <Table<IAnalyticsPaymentModel>
        rowKey={(row) => `${row.PaymentKey + row.PaymentDate}`}
        columns={columns}
        dataSource={dataLoader.data?.Entities}
        loading={dataLoader.loading}
        pagination={false}
        sortDirections={["descend", "ascend"]}
        onChange={handleTableChange}
      />
      <PaymantImportModal
        visible={isPaymentImportVisible}
        onCancel={() => setIsPaymentImportVisible(false)}
        onSuccess={onPaymentImportSuccess}
      />
    </PageContent>
  );
};
