import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  IUserSubscriptionsListModel,
  IUserSubscriptionsSearchFilterModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { PromisifiableBase } from "../Base";

export class UserSubscriptionsService extends PromisifiableBase {
  get url(): string {
    return "/UserSubscriptions";
  }
  public getSubscriptionsList = (
    filter: IUserSubscriptionsSearchFilterModel
  ): Observable<IUserSubscriptionsListModel> =>
    new Observable(
      (observer: Observer<IUserSubscriptionsListModel>) =>
        new AxiosSubscriber(observer, {
          data: filter,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Search`,
        })
    );
}
