export const ROUTES = {
  PAYMENT_BASE: "/payment",
  PAYMENT_LIST: "/payment/list",
  PAYMENT_DETAILS: "/payment/details",
  SALES_REVENUE: "/sales-revenue",
  CRYPTO_COIN_PRICES: "/crypto-coin-prices",
  VOUCHER_DETAILS: "/voucher/details",
  VOUCHER_LIST: "/voucher/list",
  VOUCHER_PRICE_LIST: "/voucher-price-list",
};
