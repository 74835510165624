import {
  GenderModel,
  GenderService,
  IListModelBase,
  IModelBase,
} from "@bms/common-services";
import { Form } from "@bms/common-ui";
import { LazyChoose } from "../../../../../components";
import { IPeopleFormFieldCommonProps } from "./IPeopleFormFieldCommonProps";
import { useTranslation } from "react-i18next";

interface IPeopleFieldProps extends IPeopleFormFieldCommonProps {}

const genderService = new GenderService();

let genders: GenderModel[];

export const GenderCodeField = ({
  isEditMode,
  people,
  isHidden,
}: IPeopleFieldProps) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="GenderCode"
      label={t("MODEL_GENDER_NAME")}
      initialValue={
        (isEditMode && {
          label: people?.GenderDisplayName,
          value: people?.GenderCode,
        }) ||
        []
      }
    >
      <LazyChoose<GenderModel & IModelBase, GenderModel & IModelBase>
        loader={async () => {
          if (!genders) {
            genders = await genderService.select().toPromise();
          }
          const data: IListModelBase<GenderModel & IModelBase, {}> = {
            TotalCount: genders.length,
            Entities: genders,
          };
          return {
            data,
            ok: true,
          };
        }}
        candidateToOption={(item) => ({
          label: item.DisplayName,
          value: `${item.Code}`,
        })}
        multiple={false}
        selectedToOption={(item) => ({
          label: item.DisplayName,
          value: `${item.Code}`,
          item,
        })}
      />
    </Form.Item>
  );
};
