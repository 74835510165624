import { Button, Col, Form, Row } from "@bms/common-ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FieldType, formLayouts } from "./TeamFormUtils";
import { ITeamFormProps, useTeamFormController } from "./useTeamFormController";
import {
  CityField,
  CountryCodeField,
  CreatedByField,
  DescriptionField,
  FoundationDateField,
  NameField,
  StadiumField,
} from "./Fields";
import { ClubIdField } from "./Fields/ClubIdField";

export const TeamForm = (props: ITeamFormProps) => {
  const { t } = useTranslation();
  const { isEditMode, team, section, hiddenFields } = props;
  const {
    formLayout,
    formFieldNames,
    sendable,
    form,
    onFieldsChange,
    onFinish,
    userCreator,
    onUserSelect,
    onUserClear,
    club,
    onClubSelect,
    onClubClear,
  } = useTeamFormController(props);

  const isHidden = (key: FieldType) =>
    !!hiddenFields && hiddenFields.includes(key);

  const formFieldsMapping: { [key: string]: () => JSX.Element } = {
    Name: () => (
      <NameField
        key="TeamForm--NameField"
        isHidden={isHidden("Name")}
        isEditMode={isEditMode}
        team={team}
      />
    ),
    City: () => (
      <CityField
        key="TeamForm--CityField"
        isHidden={isHidden("City")}
        isEditMode={isEditMode}
        team={team}
      />
    ),
    Stadium: () => (
      <StadiumField
        key="TeamForm--StadiumField"
        isHidden={isHidden("Stadium")}
        isEditMode={isEditMode}
        team={team}
      />
    ),
    FoundationDate: () => (
      <FoundationDateField
        key="TeamForm--FoundationDateField"
        isHidden={isHidden("FoundationDate")}
        isEditMode={isEditMode}
        team={team}
      />
    ),
    Description: () => (
      <DescriptionField
        key="TeamForm--DescriptionField"
        isHidden={isHidden("Description")}
        isEditMode={isEditMode}
        team={team}
      />
    ),
    CreatedBy: () => (
      <CreatedByField
        key="TeamForm--CreatedByField"
        isEditMode={isEditMode}
        isHidden={isHidden("CreatedBy")}
        onSelect={onUserSelect}
        onClear={onUserClear}
        userCreator={userCreator}
      />
    ),
    ClubId: () => (
      <ClubIdField
        key="TeamForm--ClubIdField"
        isHidden={isHidden("ClubId")}
        isEditMode={isEditMode}
        team={team}
        onSelect={onClubSelect}
        onClear={onClubClear}
        club={club}
      />
    ),
    CountryCode: () => (
      <CountryCodeField
        key="TeamForm--CountryCodeField"
        isHidden={isHidden("CountryCode")}
        isEditMode={isEditMode}
        team={team}
      />
    ),
  };

  const formFields = useMemo(
    () =>
      formFieldNames.map((field: string) =>
        formFieldsMapping[field] ? formFieldsMapping[field]() : null
      ),
    [formFieldNames, team, userCreator, club]
  );

  return (
    <Form
      {...formLayout}
      form={form}
      name={section ? `TeamForm-${section}` : "TeamForm"}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      className="TeamForm"
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          {formFields}
          {isEditMode && (
            <Form.Item {...formLayouts.tailFormItem}>
              <Button type="primary" htmlType="submit" disabled={!sendable}>
                {t("BUTTON_SAVE")}
              </Button>
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};
