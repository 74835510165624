import React, { useMemo, useRef, useState } from "react";
import { getRandomColorHex, ITableColumnProps, ITablePaginationConfig, PageContent, RadioGroup, Table, TabPane, Tabs, useSearchParamsTabs } from "@bms/common-ui";
import { useSalesRevenueDataProvider } from "../../hooks";
import { SalesRevenueChart } from "./SalesRevenueChart";
import { SalesRevenueHeader } from "./SalesRevenueHeader";
import {
  SalesRevenuePaymentTypeSalesData,
  SalesRevenueSalesDataCard,
} from "./SalesRevenueCards";
import { 
  IAnalyticsSalesRevenueAssetModel, 
  IAnalyticsSalesRevenueCreatorModel, 
  TimeHelper 
} from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { groupByCreator } from "./utils";

import "./SalesRevenue.scss";

export type TGroupBy = "asset" | "creator";

export interface IAnalyticsSalesRevenueAssetWithColorsModel
  extends IAnalyticsSalesRevenueAssetModel {
  Color: string;
}

export const SalesRevenue = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [assetsListWithColors, setAssetsListWithColors] = useState<
    IAnalyticsSalesRevenueAssetWithColorsModel[]
  >([]);
  const [groupBy, setGroupBy] = useState<TGroupBy>("asset");
  const [activeTabKey] = useSearchParamsTabs("CHART");
  const {
    data,
    filter,
    loading,
    refreshData,
    setFilter,
    resetFilter,
  } = useSalesRevenueDataProvider();

  if (data?.Results && assetsListWithColors?.length === 0) {
    const datas = data.Results.map((o) => ({
      ...o,
      Color: getRandomColorHex(),
    }));
    setAssetsListWithColors(datas);
  }

  const assetTitleColumn = {
    key: "AssetTitle",
    dataIndex: "AssetTitle",
    title: t("MODEL_TITLE"),
    ellipsis: false,
  };

  const creatorColumn = {
    key: "CreatorUserName",
    dataIndex: "CreatorUserName",
    title: t("MODEL_CREATOR"),
    ellipsis: false,
    render: (row: string) => <p>{row || t("COMMON_UNKNOWN")}</p>,
  };

  const periodColumn = {
    key: "Period",
    dataIndex: "Period",
    title: t("MODEL_PERIOD"),
    ellipsis: true,
    render: (row: string) => {
      let stringWithTokens: string

      switch (filter.AggregationPeriod) {
        case "Day":
          stringWithTokens = "YYYY-MM-DD"
          break;
        case "Month":
          stringWithTokens = "YYYY-MM"
          break;
        case "Year":
        default:
          stringWithTokens = "YYYY"
      }

      return <p>{TimeHelper.format(row, stringWithTokens)}</p>;
    },
  };

  const countColumn = {
    key: "Count",
    dataIndex: "Count",
    title: t("MODEL_COUNT"),
    ellipsis: false,
    width: "100px"
  };

  const assetColumns: ITableColumnProps<IAnalyticsSalesRevenueAssetModel>[] = [
    assetTitleColumn, 
    creatorColumn, 
    periodColumn, 
    countColumn
  ];

  const creatorColumns: ITableColumnProps<IAnalyticsSalesRevenueCreatorModel>[] = [
    creatorColumn,
    periodColumn, 
    countColumn
  ];

  const paginationConfig = (): ITablePaginationConfig => (
    {
      showTotal: (total, range) =>
        t("TABLE_PAGINATION_TOTAL", {
          rangeFrom: range[0],
          rangeTo: range[1],
          total: total,
        }),
      showSizeChanger: true,
      defaultPageSize: 10,
      pageSizeOptions: ["10", "20", "50", "100"],
    }
  );
  const renderSumary = () => {
    if (!data?.Summary) {
      return null;
    }

    const summaryView: React.ReactNode[] = [];

    for (let currency in data?.Summary) {
      summaryView.push(
        <TabPane key={currency} tab={currency}>
          <div className="SalesRevenue__cards-container">
            <SalesRevenueSalesDataCard data={data.Summary[currency]} isLoading={loading} />
            <SalesRevenuePaymentTypeSalesData data={data.Summary[currency]} isLoading={loading} />
          </div>
        </TabPane >
      );
    }

    return summaryView;
  }

  const groupedData = useMemo(() => {
    if (!data?.Results) {
      return undefined;
    }

    return groupByCreator(data.Results)
  }, [data])

  return (
    <PageContent ref={ref} className="SalesRevenue">
      <SalesRevenueHeader
        filter={filter}
        setFilter={setFilter}
        refreshData={refreshData}
        resetFilter={resetFilter}
      />
      <Tabs activeKey={activeTabKey}>
        <TabPane key="CHART" tab={t("CHART")}>
          <SalesRevenueChart
            data={data?.Results}
            assetsListWithColors={assetsListWithColors}
            filter={filter}
            isLoading={loading}
            aggregationPeriod={filter.AggregationPeriod}
          />
            <Tabs queryParamName={'currencyTab'} className="SalesRevenue__Tabs">
              {renderSumary()}
            </Tabs>
        </TabPane>
        <TabPane key="TABLE" tab={t("TABLE")}>
          <div className="SalesRevenue__Table-GroupBy">
            <label>{t("SALES_REVENUE_CHART_GROUP_BY")}:</label>
            <RadioGroup
              data={[
                {
                  value: "asset",
                  name: t("SALES_REVENUE_CHART_GROUP_BY_ASSET"),
                },
                {
                  value: "creator",
                  name: t("SALES_REVENUE_CHART_GROUP_BY_CREATOR"),
                },
              ]}
              value={groupBy}
              onChange={(e) => {
                e.preventDefault();
                setGroupBy(e.target.value);
              }}
            />
          </div>
          {groupBy === "creator" ?  (
            <Table<IAnalyticsSalesRevenueCreatorModel>
              rowKey="Id"
              columns={creatorColumns}
              dataSource={groupedData}
              loading={loading}
              pagination={paginationConfig()}
            />
          ) : (
            <Table<IAnalyticsSalesRevenueAssetModel>
              rowKey="Id"
              columns={assetColumns}
              dataSource={data?.Results}
              loading={loading}
              pagination={paginationConfig()}
            />
          )}
        </TabPane>
      </Tabs>
    </PageContent>
  );
};
