import { SportStatisticsUnitModel } from "@bms/common-services";
import {
  Col,
  Form,
  IFormValues,
  Input,
  required,
  Row,
  Spin,
  Switch,
} from "@bms/common-ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type IStatisticsUnitsFormProps = {
  statisticsUnit: SportStatisticsUnitModel;
  isProcessing: boolean;
  insertStatisticsUnit: (statisticsUnit: SportStatisticsUnitModel) => void;
  updateStatisticsUnit: (statisticsUnit: SportStatisticsUnitModel) => void;
};

export const StatisticsUnitsForm = ({
  isProcessing,
  insertStatisticsUnit,
  updateStatisticsUnit,
  statisticsUnit,
}: IStatisticsUnitsFormProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [translationsBrowserVisible, setTranslationsBrowserVisible] = useState(
    false
  );

  const isNewStatisticsUnit = !statisticsUnit.Code?.length;

  const onFinish = async (values: IFormValues) => {
    const statisticsUnitContent = values as SportStatisticsUnitModel;

    if (isNewStatisticsUnit) {
      insertStatisticsUnit(statisticsUnitContent);
      return;
    }
    updateStatisticsUnit(statisticsUnitContent);
  };

  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <Spin spinning={isProcessing}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={!isNewStatisticsUnit ? { ...statisticsUnit } : {}}
        className="DictionaryStatisticsUnitModal"
        name="DictionaryStatisticsUnitsForm"
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            <Form.Item
              name="Code"
              label={t("MODEL_CODE", "Code")}
              rules={[required()]}
            >
              <Input placeholder={t("MODEL_CODE", "Code")} />
            </Form.Item>

            <Form.Item
              name="Name"
              label={t("MODEL_NAME", "Name")}
              rules={[required()]}
            >
              <Input placeholder={t("MODEL_NAME", "Name")} />
            </Form.Item>
            <Form.Item>
              <Row gutter={8}>
                <Col>
                  <Form.Item name="UpToDate" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="UpToDate">
                    <label>{t("MODEL_UP_TO_DATE", "Up to date")}</label>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
