import {
  GuidHelper,
  useServiceCaller,
  TimeHelper,
  VoucherPriceListService,
  IVoucherPriceListItemModel,
  CurrencyStore,
  IVoucherPriceListFilterModel,
} from "@bms/common-services";
import {
  IBreadcrumbProps,
  ITableColumnProps,
  PageContent,
  PageHeader,
  Button,
  Table,
  DomainTag,
  useAppFeedback,
  Pagination,
  ITableFilter,
  Tooltip,
} from "@bms/common-ui";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { FormModal } from "../../../../components";
import { generateBreadcrumb, useTableDataProvider } from "../../../../helpers";
import { ROUTES } from "../../constants";
import { VoucherPriceListForm } from "./VoucherPriceListForm";
import { useDispatch, useSelector } from "react-redux";
import { FilterCleanIcon } from "../../../../resources/icons";
import { TFilterSchemaBasedOnFilterModelWithoutPagination } from "helpers/searchFilters";

const voucherPriceListService = new VoucherPriceListService().promisify();

type TVoucherPriceListFilterSchema = TFilterSchemaBasedOnFilterModelWithoutPagination<
  IVoucherPriceListFilterModel
>;

const filtersSchema: TVoucherPriceListFilterSchema = {
  CurrencyCode: "string",
  UpToDate: "boolean",
  FullTextSearch: "string",
};

const defaultVoucher: IVoucherPriceListItemModel = {
  Id: -1,
  Guid: "1",
  Price: 0,
  CurrencyId: -1,
  AvailableFrom: TimeHelper.getCurrentDate().toISOString(),
};

export const VoucherPrices = () => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const { data: currenciesData } = useSelector(CurrencyStore.Selectors.currenciesSelector);
  const dispatch = useDispatch();

  const isCurrencyDataFetched = Boolean(currenciesData);
  const isCurrencyDataAvailable =
    isCurrencyDataFetched && Boolean(currenciesData?.length);

  useEffect(() => {
    if (!isCurrencyDataFetched) {
      dispatch(CurrencyStore.Actions.selectCurrencies());
    }
  }, [isCurrencyDataFetched, dispatch]);

  const [showVoucherPriceListModal, setShowVoucherPriceListModal] = useState(
    false
  );
  const [editableVoucherPriceList, setEditableVoucherPriceList] = useState<
    IVoucherPriceListItemModel
  >(defaultVoucher);

  const {
    dataLoader: voucherPriceListLoader,
    filters,
    pagination,
  } = useTableDataProvider({
    filtersSchema,
    loader: (filters, pagination) =>
      voucherPriceListService.search({ ...filters, ...pagination }),
    deps: [],
    onError: (error) =>
      notification.error({
        message: t(
          "LOADING_DATA_ERROR_MESSAGE",
          "There was an error while loading data."
        ),
        description: error.Message,
      }),
  });

  useEffect(() => {
    const firstCurrency = currenciesData?.[0];
    if (!firstCurrency) {
      return;
    }
    defaultVoucher.CurrencyId = firstCurrency.Id;
    defaultVoucher.CurrencyCode = firstCurrency.Code;
    defaultVoucher.Price = firstCurrency.MinPayment;
  }, [currenciesData]);

  const [insertVoucher, { processing: insertProcessing }] = useServiceCaller(
    async (voucher: IVoucherPriceListItemModel) => {
      const result = await voucherPriceListService.insert({
        ...voucher,
        Guid: GuidHelper.newGuid(),
      });
      if (result.ok) {
        notification.success({
          message: t("VOUCHER_PRICE_LIST_INSERT_SUCCESS"),
        });
        await voucherPriceListLoader.refresh();
        closeModal();
      } else {
        notification.error({
          message: t("VOUCHER_PRICE_LIST_INSERT_FAILURE"),
          description: result.error?.Message,
        });
      }
    },
    []
  );

  const [updateVoucher, { processing: updateProcessing }] = useServiceCaller(
    async (voucher: IVoucherPriceListItemModel) => {
      const result = await voucherPriceListService.update(voucher);
      if (result.ok) {
        notification.success({
          message: t("VOUCHER_PRICE_LIST_UPDATE_SUCCESS"),
        });
        await voucherPriceListLoader.refresh();
        closeModal();
      } else {
        notification.error({
          message: t("VOUCHER_PRICE_LIST_UPDATE_FAILURE"),
          description: result.error?.Message,
        });
      }
    },
    []
  );

  const [deletevoucher, { processing: deleteProcessing }] = useServiceCaller(
    async (voucher: IVoucherPriceListItemModel) => {
      const result = await voucherPriceListService.delete(voucher);
      if (result.ok) {
        notification.success({
          message: t("VOUCHER_PRICE_LIST_DELETE_SUCCESS"),
        });
        await voucherPriceListLoader.refresh();
        closeModal();
      } else {
        notification.error({
          message: t("VOUCHER_PRICE_LIST_DELETE_FAILURE"),
          description: result.error?.Message,
        });
      }
    },
    []
  );

  const currenciesCodes = currenciesData?.map((currency) => currency.Code);

  const columns = useMemo<Array<ITableColumnProps<IVoucherPriceListItemModel>>>(
    () => [
      {
        key: "Price",
        dataIndex: "Price",
        title: t("MODEL_PRICE"),
        width: "120px",
        render: (_, row: IVoucherPriceListItemModel) => {
          return (
            <a
              title={String(row.Price)}
              className="imageUrl"
              onClick={() => {
                setShowVoucherPriceListModal(true);
                setEditableVoucherPriceList(row);
              }}
            >
              {row.Price}
            </a>
          );
        },
      },
      {
        key: "CurrencyCode",
        dataIndex: "CurrencyCode",
        title: t("MODEL_CURRENCY"),
        width: "120px",
        align: "center",
        render: (_, row: IVoucherPriceListItemModel) => (
          <DomainTag domain="currency" noMargin value={row.CurrencyCode} />
        ),
        filters:
          currenciesCodes?.map((currencyCode) => ({
            text: currencyCode,
            value: currencyCode,
          })) ?? [],
        filteredValue: filters.asTableArray.CurrencyCode || null,
        filterMultiple: true,
      },
      {
        key: "AvailableFrom",
        dataIndex: "AvailableFrom",
        title: t("MODEL_AVAILABLE_FROM"),
        width: "200px",
        align: "center",
        render: (_, row: IVoucherPriceListItemModel) =>
          row.AvailableFrom ? TimeHelper.format(row.AvailableFrom) : null,
      },
      {
        key: "AvailableTo",
        dataIndex: "AvailableTo",
        title: t("MODEL_AVAILABLE_TO"),
        width: "200px",
        align: "center",
        render: (_, row: IVoucherPriceListItemModel) =>
          row.AvailableTo ? TimeHelper.format(row.AvailableTo) : null,
        filters: [
          {
            text: t("MODEL_UP_TO_DATE"),
            value: true,
          },
        ],
        filteredValue: filters.asTableArray.UpToDate || null,
        filterMultiple: false,
      },
    ],
    [currenciesCodes, filters]
  );

  const onRefreshClick = () => {
    voucherPriceListLoader.refresh();
  };

  const onDeletevoucher = () => {
    if (editableVoucherPriceList) {
      deletevoucher(editableVoucherPriceList);
    }
  };

  const onAddNewvoucherClick = () => {
    setShowVoucherPriceListModal(true);
  };

  const closeModal = () => {
    setShowVoucherPriceListModal(false);
    setEditableVoucherPriceList(defaultVoucher);
  };

  const getExtraButtons = () => {
    return (
      <>
        <Tooltip
          key="clear-filter-tooltip"
          overlay={t("MENU_OPTION_CLEAR_FILTERS", "")}
        >
          <Button
            key="VoucherListHeaderClearBtn"
            shape="circle"
            icon={<FilterCleanIcon />}
            onClick={filters.clear}
            title={t("BUTTON_RESET")}
          />
        </Tooltip>
        <Button
          key="reload"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={onRefreshClick}
          title={t("VOUCHER_PRICE_LIST_REFRESH")}
        />
        <Button
          key="add"
          shape="circle"
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAddNewvoucherClick}
          title={t("VOUCHER_PRICE_LIST_ADD_NEW")}
          disabled={!isCurrencyDataAvailable}
        />
      </>
    );
  };

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.VOUCHER_PRICE_LIST}`,
        breadcrumbName: t("VOUCHER_PRICE_LIST_TITLE"),
      },
    ]);
  };

  const handleTableChange = (_: unknown, tableFilters: ITableFilter) => {
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...{
        CurrencyCode: tableFilters.CurrencyCode,
        UpToDate: tableFilters.AvailableTo?.[0],
      },
    }));
  };

  return (
    <>
      <PageContent footer={<Pagination {...pagination.props} />}>
        <PageHeader
          title={t("VOUCHER_PRICE_LIST_TITLE")}
          extra={getExtraButtons()}
          breadcrumb={getBreadcrumbProps()}
        />
        <Table<IVoucherPriceListItemModel>
          rowKey="Id"
          columns={columns}
          dataSource={voucherPriceListLoader.data?.Entities}
          loading={voucherPriceListLoader.loading}
          pagination={false}
          onChange={handleTableChange}
        />
      </PageContent>

      <FormModal
        isLoading={insertProcessing || updateProcessing || deleteProcessing}
        isVisible={showVoucherPriceListModal}
        isNewForm={editableVoucherPriceList.Id < 0}
        isDeleteButtonEnabled={editableVoucherPriceList.Id >= 0}
        createFormTitle={t("VOUCHER_PRICES_LIST_MODAL_NEW")}
        editFormTitle={t("VOUCHER_PRICES_LIST_MODAL_EDIT")}
        modalClassName="VoucherPricesListModal"
        submitFormName="VoucherPricesListForm"
        onCloseModal={closeModal}
        onDeleteButtonClick={onDeletevoucher}
      >
        <VoucherPriceListForm
          voucher={editableVoucherPriceList}
          onCreate={insertVoucher}
          onUpdate={updateVoucher}
        />
      </FormModal>
    </>
  );
};
