import { Form, Input, required } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ILeagueFormFieldCommonProps } from "./ILeagueFormFieldCommonProps";

interface INameFieldProps extends ILeagueFormFieldCommonProps {}

export const NameField = ({
  isHidden,
  isEditMode,
  league,
}: INameFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="Name"
      label={t("MODEL_NAME")}
      key="name"
      initialValue={isEditMode ? league?.Name || "" : ""}
      rules={[required()]}
    >
      <Input placeholder={t("MODEL_NAME_PLACEHOLDER")} />
    </Form.Item>
  );
};
