import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../constants/http";
import { GenderModel } from "../models";
import { AxiosSubscriber } from "./AxiosSubscriber";
import { PromisifiableBase } from "./Base";

export class GenderService extends PromisifiableBase {
  get url(): string {
    return "/Genders";
  }

  public select = (): Observable<GenderModel[]> =>
    new Observable(
      (observer: Observer<GenderModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    );
}
