import { Form, Input } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IClubFormFieldCommonProps } from "./IClubFormFieldCommonProps";

export const WebsiteField = ({
  club,
  isEditMode,
  isHidden,
}: IClubFormFieldCommonProps) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="WebsiteUrl"
      label={t("MODEL_WEBSITE_URL")}
      key="WebsiteUrl"
      initialValue={isEditMode ? club?.WebsiteUrl || "" : ""}
    >
      <Input placeholder={t("MODEL_WEBSITE_URL_PLACEHOLDER")} />
    </Form.Item>
  );
};
