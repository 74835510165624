import { ITEM_NAMES } from "../constants";
import { ICurrencyModel } from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { Choose, ChooseOption, Form } from "@bms/common-ui";

interface IDiscountCurrencyItemProps {
  currenciesData: ICurrencyModel[] | undefined;
  isLoadingCurrencies: boolean;
}

export const DiscountCurrencyItem = ({
  currenciesData,
  isLoadingCurrencies,
}: IDiscountCurrencyItemProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name={ITEM_NAMES.CURRENCY_ID}
      label={t("MODEL_CURRENCY")}
      rules={[
        {
          required: true,
          message: t("REQUIRED_VALIDATION_MESSAGE"),
        },
      ]}
    >
      {currenciesData?.length ? (
        <Choose
          placeholder={t("MODEL_CURRENCY_PLACEHOLDER")}
          loading={isLoadingCurrencies}
        >
          {currenciesData?.map((currency) => (
            <ChooseOption key={currency.Id} value={currency.Id}>
              {currency.Code}
            </ChooseOption>
          ))}
        </Choose>
      ) : (
        <span>{t("MODEL_CURRENCY_PLACEHOLDER")}</span>
      )}
    </Form.Item>
  );
};
