import {
  IBreadcrumbProps,
  PageHeader,
  PageContent,
  Icon,
  Button,
  Tabs,
  TabPane,
  useSearchParamsTabs,
  Spin,
} from "@bms/common-ui";
import { generateBreadcrumb } from "../../../../../helpers";
import { ROUTES } from "../../../constants";

import { useParams } from "react-router";

import { useTranslation } from "react-i18next";
import { usePlayerDetailsController } from "./usePlayerDetailsController";
import { DetailsTab } from "./tabs";

export const PlayerDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [activeTabKey] = useSearchParamsTabs("DETAILS");

  const {
    refresh,
    updatePlayer,
    onBackClick,
    onDeleteClick,
    loading,
    updatePlayerState,
    player,
    dataPager,
    dataPagerLoading,
    peopleLoading,
    person,
    updatePersonState,
    updatePerson,
  } = usePlayerDetailsController();

  const playerName =
    player?.FirstName || player?.LastName
      ? `${player?.FirstName} ${player?.LastName}`
      : "";

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.PLAYERS}`,
        breadcrumbName: t("MENU_CMS_PLAYERS"),
      },
      {
        path: `${ROUTES.PLAYER_DETAILS}/${id}`,
        breadcrumbName: playerName ?? t("MENU_CMS_PLAYER_DETAILS"),
      },
    ]);
  };

  return (
    <Spin spinning={loading || dataPagerLoading || peopleLoading}>
      <PageContent>
        <PageHeader
          title={playerName ?? t("MENU_CMS_PLAYER_DETAILS")}
          breadcrumb={getBreadcrumbProps()}
          onBack={onBackClick}
          extra={
            <>
              {dataPager.enabled && (
                <>
                  <Button
                    key="action-move-previous"
                    icon={<Icon type="arrow-left" />}
                    onClick={dataPager.movePrevious}
                    title={t("BUTTON_MOVE_PREVIOUS")}
                    shape="circle"
                    disabled={!dataPager.movePreviousEnabled}
                  />
                  <Button
                    key="action-move-next"
                    icon={<Icon type="arrow-right" />}
                    title={t("BUTTON_MOVE_NEXT")}
                    onClick={dataPager.moveNext}
                    shape="circle"
                    disabled={!dataPager.moveNextEnabled}
                  />
                </>
              )}
              <Button
                key="action-reload"
                icon={<Icon type="reload" />}
                onClick={refresh}
                title={t("BUTTON_REFRESH_TITLE")}
                shape="circle"
              />
              <Button
                danger
                key="action-delete"
                icon={<Icon type="delete" />}
                title={t("BUTTON_DELETE")}
                onClick={onDeleteClick}
                shape="circle"
              />
            </>
          }
        />
        <Tabs activeKey={activeTabKey} destroyInactiveTabPane>
          <TabPane key="DETAILS" tab={t("SPORT_PLAYER_DETAILS_TAB_DETAILS")}>
            <DetailsTab
              updatePlayerState={updatePlayerState}
              updatePlayer={updatePlayer}
              player={player}
              person={person}
              updatePersonState={updatePersonState}
              updatePerson={updatePerson}
            />
          </TabPane>
        </Tabs>
      </PageContent>
    </Spin>
  );
};
