import {
  Link,
  Button,
  Icon,
  ITableFilter,
  PageContent,
  PageHeader,
  Popconfirm,
  Table,
  useAppFeedback,
  ITableColumnProps,
  Tooltip,
  InputSearch,
  Pagination,
  setTableColumnSearchProps,
  Tag,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../constants/routes";
import {
  generateBreadcrumb,
  useTableDataProvider,
} from "../../../../../helpers";
import { IBreadcrumbProps } from "@bms/common-ui";
import {
  CountriesService,
  ISportClubModel,
  SportClubsService,
  TimeHelper,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { ROUTES as USER_ROUTES } from "../../../../User/constants";
import { FilterCleanIcon } from "../../../../../resources/icons";
import { useCreateDataPagerSource } from "../../../hooks";
import { useMemo, useState } from "react";
import { RouteComponentProps } from "react-router";
import { AddClubModal } from "../AddClubModal";
import { ClubForm } from "../ClubForm";

const clubsService = new SportClubsService().promisify();
const countriesService = new CountriesService().promisify();

export const ClubsList = ({ history, location }: RouteComponentProps) => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();
  const [modalOpen, setModalOpen] = useState(false);

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.CLUBS}`,
        breadcrumbName: t("MENU_CMS_CLUBS"),
      },
    ]);
  };

  const countriesLoader = useDataLoader({
    loader: () => countriesService.select(),
    deps: [],
  });

  const countries = useMemo(() => countriesLoader.data ?? [], [
    countriesLoader.data,
  ]);

  const {
    dataLoader: clubsLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      Name: "string",
      City: "string",
      Countries: "strings",
    },
    loader: (filters, pagination) => {
      return clubsService.search({
        ...filters,
        ...pagination,
      });
    },
    deps: [],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const dataPagerSource = useCreateDataPagerSource(clubsLoader.data, {
    ...filters.current,
    ...pagination.current,
  });

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
    }));

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value,
    }));

  const getColumnsProps = (): Array<ITableColumnProps<ISportClubModel>> => {
    return [
      {
        key: "Name",
        dataIndex: "Name",
        title: t("MODEL_NAME"),
        render: (_, row: ISportClubModel) => {
          const clubDetailLocation = {
            pathname: `${ROUTES.CLUB_DETAILS}/${row.Id}`,
            state: {
              from: location,
              dataPagerSource: dataPagerSource,
            },
          };
          return <Link to={clubDetailLocation}>{row.Name}</Link>;
        },
        ...setTableColumnSearchProps("Name", t("MODEL_NAME")),
      },
      {
        key: "City",
        dataIndex: "City",
        title: t("MODEL_CITY"),
        ...setTableColumnSearchProps("City", t("MODEL_CITY")),
      },
      {
        key: "Countries",
        dataIndex: "Countries",
        width: "200px",
        align: "center",
        title: t("MODEL_COUNTRY_NAME"),
        filters: countries.map((country) => ({
          text: country.Name,
          value: country.Code,
        })),
        filterSearch: true,
        filteredValue: filters.asTableArray.Countries,
        render: (_, row: ISportClubModel) =>
          row.CountryCode && (
            <Tag
              colorRotate={countries.findIndex(
                ({ Code }) => Code === row.CountryCode
              )}
            >
              {row.CountryName}
            </Tag>
          ),
      },
      {
        key: "FoundationDate",
        dataIndex: "FoundationDate",
        title: t("MODEL_FOUNDATION_DATE"),
        render: (_, row: ISportClubModel) =>
          row.FoundationDate
            ? TimeHelper.format(row.FoundationDate, "YYYY-MM-DD")
            : null,
      },
      {
        key: "WebsiteUrl",
        dataIndex: "WebsiteUrl",
        title: t("MODEL_WEBSITE_URL"),
        render: (_, row: ISportClubModel) =>
          row.WebsiteUrl ? (
            <Link to={row.WebsiteUrl} target="_blank">
              {row.WebsiteUrl}
            </Link>
          ) : null,
      },
      {
        key: "Created",
        dataIndex: "Created",
        title: t("MODEL_CREATED"),
        render: (_, row: ISportClubModel) =>
          row.Created
            ? TimeHelper.format(row.Created, "YYYY-MM-DD HH:mm")
            : null,
      },
      {
        key: "CreatedBy",
        dataIndex: "CreatedBy",
        title: t("MODEL_CREATED_BY"),
        render: (_, row: ISportClubModel) => (
          <Link to={`${USER_ROUTES.USER_DETAILS}/${row.CreatedBy}`}>
            {row.CreatedByFullName}
          </Link>
        ),
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_, club: ISportClubModel) => (
          <>
            <Popconfirm
              title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
              onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
                e?.preventDefault();
                const result = await clubsService.deleteClub(club!);
                if (result.ok) {
                  await clubsLoader.refresh();
                } else {
                  notification.error({
                    message: t("DELETE_ELEMENT_FAILTURE"),
                    description: result.error?.Message,
                  });
                }
              }}
              okText={t("BUTTON_YES")}
              cancelText={t("BUTTON_NO")}
            >
              <Button
                danger
                icon={<Icon type="delete" />}
                title={t("DELETE_ELEMENT")}
              />
            </Popconfirm>
          </>
        ),
      },
    ];
  };

  const [createClub, createClubState] = useServiceCaller(
    async (data: ISportClubModel) => {
      const result = await clubsService.createClub(data);
      if (result.ok) {
        setModalOpen(false);
        notification.success({
          message: t("SPORT_CLUB_CREATE_SUCCESS"),
        });
        history.push(`${ROUTES.CLUB_DETAILS}/${result.data.Id}`);
      } else {
        notification.error({
          message: t("SPORT_CLUB_CREATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  return (
    <>
      <AddClubModal
        visible={modalOpen}
        close={() => setModalOpen(false)}
        processing={createClubState.processing || clubsLoader.loading}
      >
        <ClubForm
          isEditMode={false}
          onSubmit={createClub}
          hiddenFields={[
            "Description",
            "FoundationDate",
            "WebsiteUrl",
            "CreatedBy",
          ]}
        />
      </AddClubModal>
      <PageContent footer={<Pagination {...pagination.props} />}>
        <PageHeader
          title={t("MENU_CMS_CLUBS")}
          breadcrumb={getBreadcrumbProps()}
          extra={
            <>
              <InputSearch
                key="search"
                placeholder={t("SEARCH_PLACEHOLDER")}
                value={fullTextSearch}
                onChange={({ target: { value } }) => setFullTextSearch(value)}
                onSearch={onSearch}
                style={{ width: 250 }}
                allowClear
              />
              <Tooltip overlay={t("MENU_OPTION_CLEAR_FILTERS")}>
                <Button
                  key="clear-filters"
                  shape="circle"
                  icon={<FilterCleanIcon />}
                  onClick={filters.clear}
                  title={t("MENU_OPTION_CLEAR_FILTERS")}
                />
              </Tooltip>
              <Button
                key="reload"
                shape="circle"
                icon={<Icon type="reload" />}
                onClick={clubsLoader.refresh}
                title={t("BUTTON_REFRESH_TITLE")}
              />
              <Button
                key="add"
                shape="circle"
                type="primary"
                icon={<Icon type="plus" />}
                onClick={() => setModalOpen(true)}
                title={t("BUTTON_ADD")}
              />
            </>
          }
        />
        <Table<ISportClubModel>
          rowKey="Id"
          columns={getColumnsProps()}
          dataSource={clubsLoader.data?.Entities}
          loading={clubsLoader.loading}
          pagination={false}
          onChange={onTableChange}
        />
      </PageContent>
    </>
  );
};
