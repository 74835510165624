import { Form, InputNumber } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ILeagueFormFieldCommonProps } from "./ILeagueFormFieldCommonProps";

interface IGroupNoFieldProps extends ILeagueFormFieldCommonProps {}

export const GroupNoField = ({
  isHidden,
  isEditMode,
  league,
}: IGroupNoFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="GroupNo"
      label={t("MODEL_GROUP_NO")}
      key="groupNo"
      initialValue={isEditMode ? league?.GroupNo || undefined : undefined}
      rules={[{ required: true }]}
    >
      <InputNumber min={0} precision={0} />
    </Form.Item>
  );
};
