import { Form, Link } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ROUTES as USER_ROUTES } from "../../../../User/constants";
import { IVoucherModel } from "@bms/common-services";
import { ITEM_NAMES } from "../constants";

interface ICreatedByItemProps {
  voucher: IVoucherModel;
}

export const CreatedByItem = ({ voucher }: ICreatedByItemProps) => {
  const [t] = useTranslation();

  return (
    <Form.Item
      name={ITEM_NAMES.CREATED_BY}
      label={t(voucher?.PriceId ? "MODEL_BOUGHT_BY" : "MODEL_CREATED_BY")}
    >
      {voucher.CreatedBy ? (
        <Link to={`${USER_ROUTES.USER_DETAILS}/${voucher.CreatedBy}`}>
          {voucher.CreatedByFullName}
        </Link>
      ) : (
        <div>{t("PAYMENT_VOUCHER_FORM_SELECT_PERSON_PLACEHOLDER")}</div>
      )}
    </Form.Item>
  );
};
