import {
  ILanguageModel,
  IListModelBase,
  LanguageService,
} from "@bms/common-services";
import { Form } from "@bms/common-ui";
import { IPeopleFormFieldCommonProps } from "../..";
import { LazyChoose } from "../../../../../components";
import { useTranslation } from "react-i18next";

interface ILanguageCodeFieldProps extends IPeopleFormFieldCommonProps {}

const languageService = new LanguageService();

let languages: ILanguageModel[];

export const LanguageCodeField = ({
  isEditMode,
  isHidden,
  people,
}: ILanguageCodeFieldProps) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="LanguageCode"
      label={t("MODEL_LANGUAGE_NAME")}
      key="LanguageCode"
      initialValue={
        (isEditMode && {
          label: people?.LanguageName,
          value: people?.LanguageCode,
        }) ||
        []
      }
    >
      <LazyChoose<ILanguageModel, any>
        disableRecordStatus
        loader={async () => {
          if (!languages) {
            languages = await languageService.select().toPromise();
          }
          const data: IListModelBase<ILanguageModel, {}> = {
            TotalCount: languages.length,
            Entities: languages,
          };
          return {
            data,
            ok: true,
          };
        }}
        candidateToOption={(item) => ({
          label: item.Name,
          value: `${item.Code}`,
        })}
        selectedToOption={(item) => ({
          label: item.Name!,
          value: `${item.Code!}`,
        })}
        multiple={false}
      />
    </Form.Item>
  );
};
