import {
  IVoucherModel,
  TimeHelper,
  VoucherDiscountType,
  CurrencyStore,
} from "@bms/common-services";
import {
  Button,
  Col,
  Form,
  IFormValues,
  Row,
  SectionGrid,
  SectionGridItem,
  Spin,
  useSendable,
} from "@bms/common-ui";
import {
  ITEM_NAMES,
  DiscountTypeItem,
  DiscountValueItem,
  DiscountCurrencyItem,
  RedeemTypeItem,
  ValidToItem,
  RedemptionLimitItem,
  CreatedByItem,
  UserItem,
  RedeemedCountItem,
  CreatedDateItem,
  TUserSelectorInputValue,
  DiscountCurrentValueItem,
} from "../../VoucherFormItems";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const formLayout = {
  labelCol: {
    xs: { span: 8 },
    lg: { span: 6 },
    xl: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 16 },
    lg: { span: 18 },
    xl: { span: 20 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    style: {
      textAlign: "right",
    } as React.CSSProperties,
  },
};

interface IDetailsTabProps {
  voucher: IVoucherModel;
  updateVoucher: (voucher: IVoucherModel) => void;
}

export const DetailsTab: React.FC<IDetailsTabProps> = ({
  voucher,
  updateVoucher,
}) => {
  const { t } = useTranslation();
  const { onFieldsChange, sendable } = useSendable();

  const [form] = Form.useForm();
  const discountTypeValue = Form.useWatch(ITEM_NAMES.DISCOUNT_TYPE, form);
  const isPercentageDiscount =
    discountTypeValue === VoucherDiscountType.Percentage;

  const {
    data: currenciesData,
    isFetching: isFetchingCurrencies,
  } = useSelector(CurrencyStore.Selectors.currenciesSelector);

  const onFinish = (values: IFormValues) => {
    const updatedVoucherData: Partial<IVoucherModel> = {
      DiscountType: values[ITEM_NAMES.DISCOUNT_TYPE],
      Value: values[ITEM_NAMES.VALUE],
      CurrencyId: values[ITEM_NAMES.CURRENCY_ID],
      RedeemType: values[ITEM_NAMES.REDEEM_TYPE],
      ValidTo: values[ITEM_NAMES.VALID_TO]
        ? values[ITEM_NAMES.VALID_TO].toISOString()
        : undefined,
      RedemptionLimit: values[ITEM_NAMES.REDEMPTION_LIMIT] || undefined,
      RedeemedCount: values[ITEM_NAMES.REDEEMED_COUNT],
      UserId: values[ITEM_NAMES.USER]
        ? values[ITEM_NAMES.USER].userId
        : undefined,
    };

    updateVoucher({
      ...voucher,
      ...updatedVoucherData,
    });
  };

  return (
    <Spin spinning={isFetchingCurrencies || !currenciesData?.length}>
      <SectionGrid>
        <SectionGridItem header={t("COMMON_GENERAL_INFORMATION_TILE")}>
          <Form
            form={form}
            name="VoucherDetailsForm"
            {...formLayout}
            className="VoucherDetailsForm"
            disabled={!voucher.Editable}
            onFinish={onFinish}
            onFieldsChange={onFieldsChange}
            initialValues={{
              [ITEM_NAMES.DISCOUNT_TYPE]: voucher.DiscountType,
              [ITEM_NAMES.VALUE]: voucher.Value,
              [ITEM_NAMES.CURRENCY_ID]: voucher.CurrencyId,
              [ITEM_NAMES.REDEEM_TYPE]: voucher.RedeemType,
              [ITEM_NAMES.VALID_TO]: voucher.ValidTo
                ? TimeHelper.getDateTime(voucher.ValidTo)
                : null,
              [ITEM_NAMES.REDEMPTION_LIMIT]: voucher.RedemptionLimit,
              [ITEM_NAMES.REDEEMED_COUNT]: voucher.RedeemedCount,
              [ITEM_NAMES.CREATED_BY]: voucher.CreatedBy,
              [ITEM_NAMES.USER]: voucher.UserId
                ? ({
                    userId: voucher.UserId,
                    userFullName: voucher.UserFullName,
                  } as TUserSelectorInputValue)
                : undefined,
            }}
          >
            <Row
              direction="column"
              justify="space-between"
              className="full-height"
            >
              <Col>
                <DiscountTypeItem />
                <DiscountValueItem form={form} />
                {isPercentageDiscount ? null : (
                  <DiscountCurrencyItem
                    currenciesData={currenciesData}
                    isLoadingCurrencies={Boolean(isFetchingCurrencies)}
                  />
                )}
                <RedeemTypeItem form={form} />
                <ValidToItem />
                <RedemptionLimitItem form={form} />
                <UserItem />
                <RedeemedCountItem voucher={voucher} />
                <DiscountCurrentValueItem voucher={voucher} />
                <CreatedDateItem voucher={voucher} />
                <CreatedByItem voucher={voucher} />
                <Form.Item {...tailFormItemLayout}>
                  <Button type="primary" htmlType="submit" disabled={!sendable}>
                    {t("BUTTON_SAVE")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </SectionGridItem>
      </SectionGrid>
    </Spin>
  );
};
