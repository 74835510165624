import { ReactNode } from "react";
import { FormModal } from "../../../../../components";
import { useTranslation } from "react-i18next";

interface IAddSportTeamModal {
  visible: boolean;
  close: () => void;
  processing: boolean;
  children?: ReactNode;
}

export const AddSportTeamModal = ({
  visible,
  close,
  processing,
  children,
}: IAddSportTeamModal) => {
  const { t } = useTranslation();

  return (
    <FormModal
      isLoading={processing}
      createFormTitle={t("SPORT_TEAM_ADD_NEW")}
      editFormTitle={t("SPORT_TEAM_ADD_NEW")}
      isNewForm
      isVisible={visible}
      isDeleteButtonEnabled={false}
      modalClassName="AddSportTeamModal"
      submitFormName="TeamForm"
      onCloseModal={close}
    >
      {children}
    </FormModal>
  );
};
