import { useMemo } from "react";
import { IPeopleModel, PeopleService } from "@bms/common-services";
import {
  ITableColumnProps,
  Link,
  setTableColumnSearchProps,
  useAppFeedback,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useTableDataProvider } from "../../../../helpers";
import { ROUTES } from "../..";

const peopleService = new PeopleService().promisify();

export const usePeopleBrowserTableColumns = () => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const {
    dataLoader: peopleLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      FirstName: "string",
      MiddleName: "string",
      LastName: "string",
    },
    loader: (filters, pagination) => {
      return peopleService.search({
        ...filters,
        ...pagination,
      });
    },
    deps: [],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const getColumnsProps = (): Array<ITableColumnProps<IPeopleModel>> => {
    return [
      {
        key: "FirstName",
        dataIndex: "FirstName",
        title: t("MODEL_FIRST_NAME"),
        ellipsis: true,
        filteredValue: filters.asTableArray.FirstName,
        render: (_, row: IPeopleModel) => {
          const peopleDetailLocation = {
            pathname: `${ROUTES.PEOPLE_DETAILS}/${row.Id}`,
          };
          return <Link to={peopleDetailLocation}>{row.FirstName}</Link>;
        },
        ...setTableColumnSearchProps("FirstName", t("MODEL_FIRST_NAME")),
      },
      {
        key: "MiddleName",
        dataIndex: "MiddleName",
        title: t("MODEL_MIDDLE_NAME"),
        filteredValue: filters.asTableArray.MiddleName,
        ...setTableColumnSearchProps("MiddleName", t("MODEL_MIDDLE_NAME")),
      },
      {
        key: "LastName",
        dataIndex: "LastName",
        title: t("MODEL_LAST_NAME"),
        filteredValue: filters.asTableArray.LastName,
        ...setTableColumnSearchProps("LastName", t("MODEL_LAST_NAME")),
      },
    ];
  };
  const columns = useMemo(() => getColumnsProps(), []);

  return {
    columns,
    fullTextSearch,
    filters,
    peopleLoader,
    pagination,
    setFullTextSearch,
  };
};
