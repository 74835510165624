import {
  ApplicationConfigurationStore,
  AssetStore,
  AuthStore,
  CommonStore,
  ConfigStore,
  CurrencyStore,
  setAppStore,
  SourceEntitiesStore,
  TextComponentsStore,
  UserStore,
  MediaStore,
  SportStore,
} from "@bms/common-services";
import {
  connectRouter,
  routerMiddleware,
  RouterState,
} from "connected-react-router";
import { History } from "history";
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Middleware,
  Reducer,
  Store,
} from "redux";
import { combineEpics, createEpicMiddleware } from "redux-observable";

export interface IAppState {
  applicationConfiguration: ApplicationConfigurationStore.Types.IApplicationConfigurationState;
  asset: AssetStore.Types.IAssetState;
  auth: AuthStore.Types.IAuthState;
  sport: SportStore.Types.ISportState;
  common: CommonStore.Types.ICommonState;
  config: ConfigStore.Types.IConfigState;
  router?: RouterState;
  textComponents: TextComponentsStore.Types.ITextComponentsState;
  user: UserStore.Types.IUserState;
  currencies: CurrencyStore.Types.ICurrenciesState;
  media: MediaStore.Types.IMediaState;
}

export type AppActions = AuthStore.Types.AuthActionsTypes;

let appStore: Store<IAppState, AppActions>;

export function dispatch(action: AnyAction) {
  if (appStore && appStore.dispatch) {
    appStore.dispatch(action);
  }
}

const epicMiddleware = createEpicMiddleware<
  AppActions,
  AppActions,
  IAppState
>();

const reducers = {
  applicationConfiguration:
    ApplicationConfigurationStore.Reducers.applicationConfigurationReducer,
  asset: AssetStore.Reducers.assetReducer,
  auth: AuthStore.Reducers.authReducer,
  common: CommonStore.Reducers.commonReducer,
  config: ConfigStore.Reducers.configReducer,
  textComponents: TextComponentsStore.Reducers.textComponentsReducer,
  user: UserStore.Reducers.userReducer,
  currencies: CurrencyStore.Reducers.currenciesReducer,
  sourceEntities: SourceEntitiesStore.Reducers.sourceEntitiesReducer,
  media: MediaStore.Reducers.mediaReducer,
  sport: SportStore.Reducers.sportReducer,
};

export const rootEpics = combineEpics(
  ...ApplicationConfigurationStore.Epics.applicationConfigurationEpics,
  ...AssetStore.Epics.assetEpics,
  ...AuthStore.Epics.authEpics,
  ...CommonStore.Epics.commonEpics,
  ...ConfigStore.Epics.configEpics,
  ...TextComponentsStore.Epics.textComponentsEpics,
  ...SourceEntitiesStore.Epics.sourceEntitiesEpics,
  ...UserStore.Epics.userEpics,
  ...CurrencyStore.Epics.currenciesEpics,
  ...MediaStore.Epics.mediaEpics,
  ...SportStore.Epics.sportEpics
);

const composeEnhancers =
  (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export class ReduxStoreConfigurator {
  private middlewares: Middleware[] = [epicMiddleware];

  private initialState: IAppState = {
    applicationConfiguration:
      ApplicationConfigurationStore.Reducers.initialState,
    asset: AssetStore.Reducers.initialState,
    auth: AuthStore.Reducers.initialState,
    common: CommonStore.Reducers.initialState,
    config: ConfigStore.Reducers.initialState,
    textComponents: TextComponentsStore.Reducers.initialState,
    user: UserStore.Reducers.initialState,
    currencies: CurrencyStore.Reducers.initialState,
    media: MediaStore.Reducers.initialState,
    sport: SportStore.Reducers.initialState,
  };

  private history: History | undefined = undefined;

  constructor(
    history?: History,
    middlewares?: Middleware[],
    initialState?: IAppState
  ) {
    this.history = history;

    if (middlewares && middlewares.length > 0) {
      this.middlewares = this.middlewares.concat(...middlewares);
    }

    this.initialState = initialState || this.initialState;
  }

  public initStore() {
    appStore = this.configureStore();
    epicMiddleware.run(rootEpics);
    setAppStore(appStore);

    return appStore;
  }

  private createRootReducer(history?: History) {
    let appReducer: Reducer = combineReducers(reducers);

    if (history) {
      appReducer = combineReducers({
        router: connectRouter(history),
        ...reducers,
      });
    }

    return (state: IAppState | undefined, action: AppActions) => {
      let newState: IAppState | undefined = state;

      if (action.type === AuthStore.Consts.SIGN_OUT_SUCCESS) {
        newState = undefined;
      }

      return appReducer(newState, action);
    };
  }

  private createEnhancer(middlewares: Middleware[], history?: History) {
    let enhancer = composeEnhancers(applyMiddleware(...middlewares));

    if (history) {
      enhancer = composeEnhancers(
        applyMiddleware(routerMiddleware(history), ...middlewares)
      );
    }
    return enhancer;
  }

  private configureStore() {
    const enhancer = this.createEnhancer(this.middlewares, this.history);
    const rootReducer = this.createRootReducer(this.history);
    const store = createStore(rootReducer, this.initialState, enhancer);
    return store;
  }
}
