import {
  Button,
  Icon,
  ITableFilter,
  PageContent,
  PageHeader,
  Popconfirm,
  Table,
  useAppFeedback,
  ITableColumnProps,
  Tooltip,
  InputSearch,
  Pagination,
  Link,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../constants/routes";
import {
  generateBreadcrumb,
  useTableDataProvider,
} from "../../../../../helpers";
import { IBreadcrumbProps } from "@bms/common-ui";
import {
  ISportMatchModel,
  SportMatchService,
  SportTeamService,
  TimeHelper,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { FilterCleanIcon } from "../../../../../resources/icons";
import { useCreateDataPagerSource } from "../../../hooks";
import { useState } from "react";
import { RouteComponentProps } from "react-router";
import { AddSportMatchModal } from "../AddSportMatchModal";
import { getDateRange, renderDateRangeFilter } from "./matchesListUtils";
import { MatchesForm } from "../MatchesForm";

const matchesService = new SportMatchService().promisify();
const teamsService = new SportTeamService().promisify();

export const MatchesList = ({ history, location }: RouteComponentProps) => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();
  const [modalOpen, setModalOpen] = useState(false);

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.MATCHES}`,
        breadcrumbName: t("MENU_CMS_MATCHES"),
      },
    ]);
  };

  const teamsLoader = useDataLoader({
    loader: () => teamsService.selectTeams(),
    deps: [],
  });

  const {
    dataLoader: matchesLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      HomeTeamId: "number",
      AwayTeamId: "number",
      StartDateTimeFrom: "string",
      EndDateTimeFrom: "string",
    },
    loader: (filters, pagination) => {
      let dateStartFrom = null;
      let dateStartTo = null;

      let dateEndFrom = null;
      let dateEndTo = null;

      if (filters.StartDateTimeFrom) {
        const { start, end } = getDateRange(filters.StartDateTimeFrom);

        dateStartFrom = start;
        dateStartTo = end;
      }

      if (filters.EndDateTimeFrom) {
        const { start, end } = getDateRange(filters.EndDateTimeFrom);

        dateEndFrom = start;
        dateEndTo = end;
      }

      delete filters.StartDateTimeFrom;
      delete filters.EndDateTimeFrom;

      return matchesService.search({
        ...filters,
        ...pagination,
        ...(dateStartFrom && { StartDateTimeFrom: dateStartFrom }),
        ...(dateStartTo && { StartDateTimeTo: dateStartTo }),
        ...(dateEndFrom && { EndDateTimeFrom: dateEndFrom }),
        ...(dateEndTo && { EndDateTimeTo: dateEndTo }),
      });
    },
    deps: [],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const dataPagerSource = useCreateDataPagerSource(matchesLoader.data, {
    ...filters.current,
    ...pagination.current,
  });

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
    }));

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value,
    }));

  const getColumnsProps = (): Array<ITableColumnProps<ISportMatchModel>> => {
    return [
      {
        key: "Matchup",
        dataIndex: "Matchup",
        title: t("MODEL_MATCHUP"),
        render: (_, match: ISportMatchModel) => {
          const matchDetailLocation = {
            pathname: `${ROUTES.MATCH_DETAILS}/${match.Id}`,
            state: {
              from: location,
              dataPagerSource: dataPagerSource,
            },
          };
          return (
            <Link to={matchDetailLocation}>
              {match.HomeTeamName} VS {match.AwayTeamName}
            </Link>
          );
        },
      },
      {
        key: "HomeTeamId",
        dataIndex: "HomeTeamId",
        title: t("MODEL_HOME_TEAM"),
        filters: teamsLoader.data?.map((team) => ({
          text: team.Name,
          value: team.Id,
        })),
        filterMultiple: false,
        filteredValue: filters.asTableArray.HomeTeamId,
        render: (_, match: ISportMatchModel) => (
          <Link to={`${ROUTES.TEAM_DETAILS}/${match.HomeTeamId}`}>
            {match.HomeTeamName}
          </Link>
        ),
      },
      {
        key: "AwayTeamId",
        dataIndex: "AwayTeamId",
        title: t("MODEL_AWAY_TEAM"),
        filters: teamsLoader.data?.map((team) => ({
          text: team.Name,
          value: team.Id,
        })),
        filterMultiple: false,
        filteredValue: filters.asTableArray.AwayTeamId,
        render: (_, match: ISportMatchModel) => (
          <Link to={`${ROUTES.TEAM_DETAILS}/${match.AwayTeamId}`}>
            {match.AwayTeamName}
          </Link>
        ),
      },
      {
        key: "HomeTeamScore",
        dataIndex: "HomeTeamScore",
        title: t("MODEL_HOME_TEAM_SCORE"),
      },
      {
        key: "AwayTeamScore",
        dataIndex: "AwayTeamScore",
        title: t("MODEL_AWAY_TEAM_SCORE"),
      },
      {
        key: "Stadium",
        dataIndex: "Stadium",
        title: t("MODEL_STADIUM"),
      },
      {
        key: "StartDateTimeFrom",
        dataIndex: "StartDateTimeFrom",
        title: t("MODEL_START_DATE_TIME"),
        render: (_, match: ISportMatchModel) =>
          match.StartDateTime
            ? TimeHelper.format(match.StartDateTime, "YYYY-MM-DD HH:mm")
            : null,
        filterDropdown: renderDateRangeFilter,
        filteredValue: filters.asTableArray.StartDateTime,
        filterMultiple: false,
      },
      {
        key: "EndDateTimeFrom",
        dataIndex: "EndDateTimeFrom",
        title: t("MODEL_END_DATE_TIME"),
        render: (_, match: ISportMatchModel) =>
          match.EndDateTime
            ? TimeHelper.format(match.EndDateTime, "YYYY-MM-DD HH:mm")
            : null,
        filterDropdown: renderDateRangeFilter,
        filteredValue: filters.asTableArray.StartDateTime,
        filterMultiple: false,
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_, match: ISportMatchModel) => (
          <>
            <Popconfirm
              title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
              onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
                e?.preventDefault();
                const result = await matchesService.deleteMatch(match!);
                if (result.ok) {
                  await matchesLoader.refresh();
                } else {
                  notification.error({
                    message: t("DELETE_ELEMENT_FAILTURE"),
                    description: result.error?.Message,
                  });
                }
              }}
              okText={t("BUTTON_YES")}
              cancelText={t("BUTTON_NO")}
            >
              <Button
                danger
                icon={<Icon type="delete" />}
                title={t("DELETE_ELEMENT")}
              />
            </Popconfirm>
          </>
        ),
      },
    ];
  };

  const [createMatch, createMatchState] = useServiceCaller(
    async (data: ISportMatchModel) => {
      const result = await matchesService.createMatch(data);
      if (result.ok) {
        setModalOpen(false);
        notification.success({
          message: t("SPORT_MATCH_CREATE_SUCCESS"),
        });
        history.push(`${ROUTES.MATCH_DETAILS}/${result.data.Id}`);
      } else {
        notification.error({
          message: t("SPORT_MATCH_CREATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  return (
    <>
      <AddSportMatchModal
        visible={modalOpen}
        close={() => setModalOpen(false)}
        processing={createMatchState.processing}
      >
        <MatchesForm
          isEditMode={false}
          onSubmit={createMatch}
          hiddenFields={[
            "EndDateTime",
            "CreatedBy",
            "Stadium",
            "HomeTeamScore",
            "AwayTeamScore",
          ]}
        />
      </AddSportMatchModal>
      <PageContent footer={<Pagination {...pagination.props} />}>
        <PageHeader
          title={t("MENU_CMS_MATCHES")}
          breadcrumb={getBreadcrumbProps()}
          extra={
            <>
              <InputSearch
                key="search"
                placeholder={t("SEARCH_PLACEHOLDER")}
                value={fullTextSearch}
                onChange={({ target: { value } }) => setFullTextSearch(value)}
                onSearch={onSearch}
                style={{ width: 250 }}
                allowClear
              />
              <Tooltip overlay={t("MENU_OPTION_CLEAR_FILTERS")}>
                <Button
                  key="clear-filters"
                  shape="circle"
                  icon={<FilterCleanIcon />}
                  onClick={filters.clear}
                  title={t("MENU_OPTION_CLEAR_FILTERS")}
                />
              </Tooltip>
              <Button
                key="reload"
                shape="circle"
                icon={<Icon type="reload" />}
                onClick={matchesLoader.refresh}
                title={t("BUTTON_REFRESH_TITLE")}
              />
              <Button
                key="add"
                shape="circle"
                type="primary"
                icon={<Icon type="plus" />}
                onClick={() => setModalOpen(true)}
                title={t("BUTTON_ADD")}
              />
            </>
          }
        />
        <Table<ISportMatchModel>
          rowKey="Id"
          columns={getColumnsProps()}
          dataSource={matchesLoader.data?.Entities}
          loading={matchesLoader.loading}
          pagination={false}
          onChange={onTableChange}
        />
      </PageContent>
    </>
  );
};
