import { ITEM_NAMES } from "../constants";
import { VoucherDiscountType } from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { Form, IFormInstance, InputNumber } from "@bms/common-ui";

interface IDiscountValueItemProps {
  form: IFormInstance;
}

export const DiscountValueItem = ({ form }: IDiscountValueItemProps) => {
  const [t] = useTranslation();
  const discountType = Form.useWatch(ITEM_NAMES.DISCOUNT_TYPE, form);
  const isPercentage = discountType === VoucherDiscountType.Percentage;

  return (
    <Form.Item
      name={ITEM_NAMES.VALUE}
      label={t("MODEL_VALUE")}
      rules={[
        {
          required: true,
          message: t("REQUIRED_VALIDATION_MESSAGE"),
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (
              getFieldValue(ITEM_NAMES.DISCOUNT_TYPE) ===
              VoucherDiscountType.Percentage
            ) {
              if (value > 100) {
                return Promise.reject(
                  new Error(
                    t("PAYMENT_VOUCHER_FORM_VALUE_RANGE_VALIDATION_MESSAGE")
                  )
                );
              }
            }
            return Promise.resolve();
          },
        }),
      ]}
      dependencies={[ITEM_NAMES.DISCOUNT_TYPE]}
    >
      <InputNumber
        placeholder={t("PAYMENT_VOUCHER_FORM_ENTER_VALUE_PLACEHOLDER")}
        style={{ width: "100%" }}
        precision={isPercentage ? 0 : 2}
        step={isPercentage ? 1 : 0.1}
        min={isPercentage ? 1 : 0.01}
        max={isPercentage ? 100 : Number.MAX_SAFE_INTEGER}
      />
    </Form.Item>
  );
};
