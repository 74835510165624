import { ICommonAppState } from "..";

export const sportSelector = (state: ICommonAppState) => state.sport;

export const ageCategorySelector = (state: ICommonAppState) =>
  state.sport.ageCategory;

export const ageCategoriesSelector = (state: ICommonAppState) =>
  state.sport.ageCategories;

export const playerPositionSelector = (state: ICommonAppState) =>
  state.sport.playerPosition;

export const playerPositionsSelector = (state: ICommonAppState) =>
  state.sport.playerPositions;

export const staffRoleSelector = (state: ICommonAppState) =>
  state.sport.staffRole;

export const staffRolesSelector = (state: ICommonAppState) =>
  state.sport.staffRoles;

export const statisticsUnitSelector = (state: ICommonAppState) =>
  state.sport.statisticsUnit;

export const statisticsUnitsSelector = (state: ICommonAppState) =>
  state.sport.statisticsUnits;
