import {
  IBreadcrumbProps,
  PageHeader,
  PageContent,
  Icon,
  Button,
  Tabs,
  TabPane,
  useSearchParamsTabs,
  Spin,
} from "@bms/common-ui";
import { generateBreadcrumb } from "../../../../../helpers";
import { ROUTES } from "../../../constants";

import { useParams } from "react-router";

import { useTranslation } from "react-i18next";
import { useMatchDetailsController } from "./useMatchDetailsController";
import { DetailsTab, LineupTab } from "./tabs";

export const MatchDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [activeTabKey] = useSearchParamsTabs("DETAILS");

  const {
    refresh,
    onBackClick,
    onDeleteClick,
    loading,
    dataPager,
    dataPagerLoading,
    updateMatchState,
    match,
    updateMatch,
  } = useMatchDetailsController();

  const matchName = `${match?.HomeTeamName || ""} VS ${
    match?.AwayTeamName || ""
  }`;

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.MATCHES}`,
        breadcrumbName: t("MENU_CMS_MATCHES"),
      },
      {
        path: `${ROUTES.MATCH_DETAILS}/${id}`,
        breadcrumbName: matchName ?? t("MENU_CMS_MATCH_DETAILS"),
      },
    ]);
  };

  return (
    <Spin spinning={loading || dataPagerLoading}>
      <PageContent>
        <PageHeader
          title={matchName ?? t("MENU_CMS_MATCH_DETAILS")}
          breadcrumb={getBreadcrumbProps()}
          onBack={onBackClick}
          extra={
            <>
              {dataPager.enabled && (
                <>
                  <Button
                    key="action-move-previous"
                    icon={<Icon type="arrow-left" />}
                    onClick={dataPager.movePrevious}
                    title={t("BUTTON_MOVE_PREVIOUS")}
                    shape="circle"
                    disabled={!dataPager.movePreviousEnabled}
                  />
                  <Button
                    key="action-move-next"
                    icon={<Icon type="arrow-right" />}
                    title={t("BUTTON_MOVE_NEXT")}
                    onClick={dataPager.moveNext}
                    shape="circle"
                    disabled={!dataPager.moveNextEnabled}
                  />
                  <Button
                    key="action-reload"
                    icon={<Icon type="reload" />}
                    onClick={refresh}
                    title={t("BUTTON_REFRESH_TITLE")}
                    shape="circle"
                  />
                  <Button
                    danger
                    key="action-delete"
                    icon={<Icon type="delete" />}
                    title={t("BUTTON_DELETE")}
                    onClick={onDeleteClick}
                    shape="circle"
                  />
                </>
              )}
            </>
          }
        />
        <Tabs activeKey={activeTabKey} destroyInactiveTabPane>
          <TabPane key="DETAILS" tab={t("SPORT_MATCH_DETAILS_TAB_DETAILS")}>
            <DetailsTab
              updateMatchState={updateMatchState}
              updateMatch={updateMatch}
              match={match}
            />
          </TabPane>
          <TabPane
            key="HOME_LINEUP"
            tab={t("SPORT_MATCH_DETAILS_TAB_LINEUP_HOME")}
          >
            <LineupTab
              match={match}
              team={{
                id: match?.HomeTeamId ?? "",
                name: match?.HomeTeamName ?? "",
              }}
            />
          </TabPane>
          <TabPane
            key="AWAY_LINEUP"
            tab={t("SPORT_MATCH_DETAILS_TAB_LINEUP_AWAY")}
          >
            <LineupTab
              match={match}
              team={{
                id: match?.AwayTeamId ?? "",
                name: match?.AwayTeamName ?? "",
              }}
            />
          </TabPane>
        </Tabs>
      </PageContent>
    </Spin>
  );
};
