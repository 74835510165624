import React, { memo } from "react";
import { LabeledValueText, Card, Skeleton } from "@bms/common-ui";
import { TimeHelper } from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { IAssetImpressionsDataCardProps } from "./IAssetImpressionsDataCardProps";

export const AssetImpressionsDataCard = memo(
  ({ data, isLoading }: IAssetImpressionsDataCardProps) => {
    const { t } = useTranslation();
    return (
      <Card style={{ width: "50%" }} title={t("ASSET_IMPRESSIONS_DATA")}>
        <Skeleton active={true} loading={isLoading} paragraph={true}>
          <LabeledValueText
            label={t("ASSET_IMPRESSIONS_TOTAL_COUNT")}
            value={data?.TotalCount}
          />
          <LabeledValueText
            label={t("ASSET_IMPRESSIONS_TOTAL_DURATION")}
            value={[
              data?.TotalDuration,
              TimeHelper.formatDurationDaysHoursMinutes(data?.TotalDuration),
            ]}
            separator={<>&nbsp;&nbsp;|&nbsp;&nbsp;</>}
          />
        </Skeleton>
      </Card>
    );
  }
);
