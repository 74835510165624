import { ICommonAppState } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ApplicationCustomProperties as ApplicationCustomPropertiesDefinition,
  ApplicationCustomPropertiesDispatchProps,
  ApplicationCustomPropertiesOwnProps,
  ApplicationCustomPropertiesStateProps,
} from "./ApplicationCustomProperties";

const mapStateToProps = (
  state: ICommonAppState
): ApplicationCustomPropertiesStateProps => ({
  isProcessingData: state.applicationConfiguration.isProcessingData,
});

const mapDispatchToProps = (
  dispatch: Dispatch
): ApplicationCustomPropertiesDispatchProps => ({});

export const ApplicationCustomProperties = connect<
  ApplicationCustomPropertiesStateProps,
  ApplicationCustomPropertiesDispatchProps,
  ApplicationCustomPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationCustomPropertiesDefinition));
