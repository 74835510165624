import { Form, InputNumber } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IPlayerFormFieldCommonProps } from "./IPlayerFormFieldCommonProps";

interface IShirtNumberFieldProps extends IPlayerFormFieldCommonProps {}

export const ShirtNumberField = ({
  isHidden,
  isEditMode,
  player,
}: IShirtNumberFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="ShirtNumber"
      label={t("MODEL_SHIRT_NUMBER")}
      key="shirtNumber"
      initialValue={isEditMode ? player?.ShirtNumber || undefined : undefined}
    >
      <InputNumber min={0} precision={0} />
    </Form.Item>
  );
};
