import { Button, Col, Form, Row } from "@bms/common-ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FieldType, formLayouts } from "./MatchesFormUtils";
import {
  IMatchesFormProps,
  useMatchesFormController,
} from "./useMatchesFormController";
import {
  AwayTeamField,
  EndDateField,
  HomeTeamField,
  StartDateField,
  CreatedByField,
  StadiumField,
  AwayTeamScoreField,
  HomeTeamScoreField,
} from "./Fields";

export const MatchesForm = (props: IMatchesFormProps) => {
  const { t } = useTranslation();
  const { isEditMode, matches, section, hiddenFields } = props;
  const {
    formLayout,
    formFieldNames,
    sendable,
    form,
    onFieldsChange,
    onFinish,
    homeTeam,
    awayTeam,
    onHomeTeamSelect,
    onAwayTeamSelect,
    onHomeTeamClear,
    onAwayTeamClear,
  } = useMatchesFormController(props);

  const isHidden = (key: FieldType) =>
    !!hiddenFields && hiddenFields.includes(key);

  const formFieldsMapping: { [key: string]: () => JSX.Element } = {
    HomeTeamId: () => (
      <HomeTeamField
        isHidden={isHidden("HomeTeamId")}
        key="MatchesForm--HomeTeamField"
        isEditMode={isEditMode}
        match={matches}
        homeTeam={homeTeam}
        onSelect={onHomeTeamSelect}
        onClear={onHomeTeamClear}
      />
    ),
    AwayTeamId: () => (
      <AwayTeamField
        isHidden={isHidden("AwayTeamId")}
        key="MatchesForm--AwayTeamField"
        isEditMode={isEditMode}
        match={matches}
        awayTeam={awayTeam}
        onSelect={onAwayTeamSelect}
        onClear={onAwayTeamClear}
      />
    ),
    StartDateTime: () => (
      <StartDateField
        isHidden={isHidden("StartDateTime")}
        key="MatchesForm--StartDateField"
        isEditMode={isEditMode}
        match={matches}
      />
    ),
    EndDateTime: () => (
      <EndDateField
        isHidden={isHidden("EndDateTime")}
        key="MatchesForm--EndDateField"
        isEditMode={isEditMode}
        match={matches}
      />
    ),
    CreatedBy: () => (
      <CreatedByField
        isHidden={isHidden("CreatedBy")}
        key="MatchesForm--CreatedByField"
        isEditMode={isEditMode}
        match={matches}
      />
    ),
    Stadium: () => (
      <StadiumField
        isHidden={isHidden("Stadium")}
        key="MatchesForm--StadiumField"
        isEditMode={isEditMode}
        match={matches}
      />
    ),
    HomeTeamScore: () => (
      <HomeTeamScoreField
        isHidden={isHidden("HomeTeamScore")}
        key="MatchesForm--HomeTeamScoreField"
        isEditMode={isEditMode}
        match={matches}
      />
    ),
    AwayTeamScore: () => (
      <AwayTeamScoreField
        isHidden={isHidden("AwayTeamScore")}
        key="MatchesForm--AwayTeamScoreField"
        isEditMode={isEditMode}
        match={matches}
      />
    ),
  };

  const formFields = useMemo(
    () =>
      formFieldNames.map((field: string) =>
        formFieldsMapping[field] ? formFieldsMapping[field]() : null
      ),
    [formFieldNames, homeTeam, awayTeam]
  );

  return (
    <Form
      {...formLayout}
      form={form}
      name={section ? `MatchesForm-${section}` : "MatchesForm"}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      className="MatchesForm"
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          {formFields}
          {isEditMode && (
            <Form.Item {...formLayouts.tailFormItem}>
              <Button type="primary" htmlType="submit" disabled={!sendable}>
                {t("BUTTON_SAVE")}
              </Button>
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};
