import {
  AssetContentService,
  AssetContentType,
  AssetService,
  AssetType,
  BooleanHelper,
  ContentStatus,
  FileHelper,
  GuidHelper,
  IAssetContentModel,
  IAssetContentStreamTypeModel,
  IAssetModel,
  ICommonAppState,
  ICompleteMultipartUploadFileModel,
  IErrorModel,
  OperationResultType,
  STORAGE,
  StorageService,
  StreamType,
  UploadType,
  UploadTypeHelper,
  useConfig,
} from "@bms/common-services";
import {
  Choose,
  ChooseOption,
  Dragger,
  Form,
  Icon,
  Input,
  IFormValues,
  IUploadChangeEvent,
  IUploadFile,
  required,
  useAppFeedback,
} from "@bms/common-ui";

import { takeRight } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FormModal } from "../../../../components";

const assetService = new AssetService();
const assetContentService = new AssetContentService();
const storageService: StorageService = StorageService.getInstance();
const assetSelector = (state: ICommonAppState) => state.asset;
const languagesSelector = (state: ICommonAppState) => state.common;

const MIN_MULTIPART_FILE_SIZE = 5_000_000;

const ASSET_UPLOAD_DIRECT_DISABLED = BooleanHelper.toBool(
  process.env.REACT_APP_ASSET_CONTENT_UPLOAD_DIRECT_DISABLED,
  false
);

const ASSET_UPLOAD_TRANSCODING_DISABLED = BooleanHelper.toBool(
  process.env.REACT_APP_ASSET_CONTENT_UPLOAD_TRANSCODING_DISABLED,
  false
);

const ANY_VIDEO_UPLOAD_DISABLED =
  ASSET_UPLOAD_DIRECT_DISABLED || ASSET_UPLOAD_TRANSCODING_DISABLED;

const SUBTITLES_TTML_FILE_EXT = [".ttml", ".dfxp", ".xml"];
const SUBTITLES_FILE_TYPES_TO_ACCEPT = ["text/*", ...SUBTITLES_TTML_FILE_EXT];

const FIELDS_NAME = {
  AssetContentStreamTypeCode: "AssetContentStreamTypeCode",
  AssetUploadTypeCode: "AssetUploadTypeCode",
  StreamVariant: "StreamVariant",
  AssetContentTypeCode: "AssetContentTypeCode",
  ContentFile: "ContentFile",
  AssetSubtitleLanguage: "AssetSubtitleLanguage",
  AssetSubtitleLabel: "AssetSubtitleLabel",
};

const UPLOAD_TYPE_PER_STREAM_TYPE = {
  [StreamType.Source]: [UploadType.Direct],
  [StreamType.Main]: [UploadType.Direct, UploadType.Transcoding],
  [StreamType.Trial]: [UploadType.Direct, UploadType.Transcoding],
  [StreamType.Subtitle]: [UploadType.Direct],
};

type TUploadTypePerStreamTypeKeys = keyof typeof UPLOAD_TYPE_PER_STREAM_TYPE;

const isUploadTypeKeyGuard = (
  keyCandidate: any
): keyCandidate is TUploadTypePerStreamTypeKeys => {
  return (
    typeof keyCandidate === "string" &&
    UPLOAD_TYPE_PER_STREAM_TYPE[
      keyCandidate as TUploadTypePerStreamTypeKeys
    ] !== undefined
  );
};

const getDefaultUploadTypeForStream = (
  streamType: TUploadTypePerStreamTypeKeys,
  asset?: IAssetModel
) => {
  switch (streamType) {
    case StreamType.Source:
      return UploadType.Direct;
    case StreamType.Main:
    case StreamType.Trial:
      if (
        asset?.AssetTypeCode === AssetType.Podcast ||
        asset?.AssetTypeCode === AssetType.Album
      ) {
        return UploadType.Direct;
      }
      return UploadType.Transcoding;
    case StreamType.Subtitle:
      return UploadType.Direct;
    default:
      return UploadType.Direct;
  }
};

type TUploadTypeHelperOption = ReturnType<
  typeof UploadTypeHelper.getOptions
>[number];

const getFilterForUploadTypesBasedOnStreamType = (
  contentStreamType: string | undefined
) => {
  if (!isUploadTypeKeyGuard(contentStreamType)) {
    return () => false;
  }

  const availableUploadTypes = UPLOAD_TYPE_PER_STREAM_TYPE[contentStreamType];

  return (uploadTypeHelperOption: TUploadTypeHelperOption) =>
    availableUploadTypes.includes(uploadTypeHelperOption.value);
};

const getAllowedFileTypesForStream = (
  contentStreamTypeCode?: IAssetContentStreamTypeModel["Code"],
  assetContentStreamTypesSource?: IAssetContentStreamTypeModel[]
) => {
  if (!contentStreamTypeCode || !assetContentStreamTypesSource) {
    return [];
  }
  const isSelectedStreamType = (streamType: IAssetContentStreamTypeModel) =>
    streamType.Code === contentStreamTypeCode;

  return (
    assetContentStreamTypesSource.find(isSelectedStreamType)?.ContentTypes ?? []
  );
};

const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};
interface IAssetContentUploadModalProps {
  visible: boolean;
  asset?: IAssetModel;
  assetContent?: IAssetContentModel;
  onCancel?: () => void;
  onSuccess?: () => void;
}
interface IAssetContentUploadModalState {
  contentFile?: File;
  fileList: IUploadFile[];
  assetContentGuid?: string;
  ContentTypeCode?: AssetContentType;
  ContentStreamTypeCode?: string;
}

interface IProgressEvent {
  percent: number;
}

export const AssetContentUploadModal = ({
  visible,
  asset,
  assetContent,
  onCancel,
  onSuccess,
}: IAssetContentUploadModalProps) => {
  const { config } = useConfig();
  const { assetContentTypes, assetContentStreamTypes } = useSelector(
    assetSelector
  );
  const { languages, isLoadingData: isLoadingLanguages } = useSelector(
    languagesSelector
  );
  const [processing, setProcessing] = useState<boolean>(false);
  const [state, setState] = useState<IAssetContentUploadModalState>({
    contentFile: undefined,
    fileList: [],
    assetContentGuid: undefined,
  });

  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const assetType = asset?.AssetTypeCode;
  const isAlbumOrPodcast =
    assetType === AssetType.Podcast || assetType === AssetType.Album;

  const isParentalType =
    asset?.AssetTypeCode === AssetType.Album ||
    asset?.AssetTypeCode === AssetType.Season ||
    asset?.AssetTypeCode === AssetType.Series;

  const [form] = Form.useForm();
  const contentTypeState = Form.useWatch(
    FIELDS_NAME.AssetContentTypeCode,
    form
  );
  const streamVariant = Form.useWatch(FIELDS_NAME.StreamVariant, form);

  const { modal } = useAppFeedback();

  const contentStreamTypeState = Form.useWatch(
    FIELDS_NAME.AssetContentStreamTypeCode,
    form
  );

  const abortControllerRef = useRef<AbortController>();

  const isAudio =
    asset?.AssetTypeCode === AssetType.Podcast ||
    asset?.AssetTypeCode === AssetType.Album;
  const isSubtitles = contentStreamTypeState === StreamType.Subtitle;

  const filteredContentType = assetContentTypes.data?.filter((type) => {
    switch (asset?.AssetTypeCode) {
      case AssetType.Album:
      case AssetType.Podcast:
        return type.Code.includes("audio");
      case AssetType.Article:
        return type.Code.includes("text");
      default:
        return !type.Code.startsWith("text") && !type.Code.startsWith("audio");
    }
  });

  useEffect(() => {
    if (contentTypeState && contentStreamTypeState) {
      form.validateFields([FIELDS_NAME.AssetContentStreamTypeCode]);
    }
  }, [contentTypeState, contentStreamTypeState]);

  const onChangeContent = (event: IUploadChangeEvent) => {
    const { fileList } = event;
    const latestFiles = takeRight(fileList);
    let fileType;

    if (latestFiles?.length > 0) {
      latestFiles[0].status = "done";

      fileType = latestFiles[0].originFileObj?.type;

      if (!fileType && isSubtitles) {
        // Dirt solution to recognize and assign ttml subtitles type.
        const ttmlType = "application/ttml+xml";
        const allowedFileTypesForStream = getAllowedFileTypesForStream(
          contentStreamTypeState,
          assetContentStreamTypes.data
        );

        const isTtmlAllowed = allowedFileTypesForStream.includes(ttmlType);
        fileType = isTtmlAllowed ? ttmlType : undefined;
      }
    }

    form.setFieldValue(FIELDS_NAME.AssetContentTypeCode, fileType);

    setState((oldState) => ({
      ...oldState,
      fileList: latestFiles,
    }));
  };

  const onUploadProgress = (event: IProgressEvent) => {
    const { fileList } = state;
    const latestFiles = takeRight(fileList);

    if (latestFiles?.length > 0) {
      latestFiles[0].percent = event.percent || 0;
      latestFiles[0].status =
        latestFiles[0].percent === 100 ? "done" : "uploading";
    }

    setState((oldState) => ({
      ...oldState,
      fileList: latestFiles,
    }));
  };

  const onUploadFailed = (error: any) => {
    console.log("Something went wron during uploading file.", error);
    const appError = error as IErrorModel;
    notification.error({
      message: t("UPLOAD_ASSET_CONTENT_FAILURE"),
      description: appError?.Message,
    });
    setProcessing(false);
  };

  const onUploadSuccess = () => {
    notification.success({
      message: t("UPLOAD_ASSET_CONTENT_SUCCESS"),
    });
    setProcessing(false);
    setState((oldState) => ({
      ...oldState,
      fileList: [],
      contentFile: undefined,
      assetContentGuid: undefined,
    }));
    onSuccess?.();
  };

  const uploadAssetContent = async (
    file: File,
    assetContent: IAssetContentModel,
    uploadType: UploadType
  ) => {
    try {
      if (!assetContent.AssetId || !assetContent.Guid) {
        return;
      }

      const assetId: number = assetContent.AssetId;
      const assetContentGuid: string = assetContent.Guid;
      const contentType = file.type;

      const uploadFileInfo = await assetService
        .getAssetContentUploadFileInfo({
          AssetId: assetId,
          AssetContentGuid: assetContentGuid,
          AssetStreamTypeCode: assetContent.StreamTypeCode || StreamType.Main,
          ContentType: contentType,
          UploadType: uploadType,
        })
        .toPromise();

      assetContent.Path = uploadFileInfo.Path;

      abortControllerRef.current = new AbortController();

      storageService
        .uploadFile(
          file,
          uploadFileInfo,
          assetContentGuid,
          onUploadProgress,
          abortControllerRef.current
        )
        .then((result) => {
          if (result.ResultType === OperationResultType.Ok) {
            return assetService.addAssetContent(assetContent).toPromise();
          }
        })
        .then((assetContent?: IAssetContentModel) => {
          if (
            assetContent &&
            assetContent?.StreamTypeCode !== StreamType.Source &&
            uploadType === UploadType.Transcoding
          ) {
            return assetContentService
              .transcode({ AssetContentId: assetContent.Id })
              .toPromise();
          }
        })
        .then(() => {
          onUploadSuccess();
        })
        .catch((error) => {
          onUploadFailed(error);
        });
    } catch (error) {
      onUploadFailed(error);
    }
  };

  const uploadAssetContentMultipart = async (
    file: File,
    assetContent: IAssetContentModel,
    uploadType: UploadType
  ) => {
    try {
      if (!assetContent.AssetId || !assetContent.Guid) {
        return;
      }

      const assetId: number = assetContent.AssetId;
      const assetContentGuid: string = assetContent.Guid;
      const contentType = file.type; //"application/octet-stream",
      const chunkQuantity = storageService.getFileUploadChunksNumber(file);
      const uploadMultipartFileInfo = await assetService
        .getContentMultipartUploadFileInfo({
          AssetId: assetId,
          AssetContentGuid: assetContentGuid,
          AssetStreamTypeCode: assetContent.StreamTypeCode || StreamType.Main,
          ChunkQuantity: chunkQuantity,
          ContentType: contentType,
          UploadType: uploadType,
        })
        .toPromise();

      assetContent.Path = uploadMultipartFileInfo.Path;

      abortControllerRef.current = new AbortController();

      storageService
        .uploadFileMultipart(
          file,
          uploadMultipartFileInfo,
          assetContentGuid,
          onUploadProgress,
          abortControllerRef.current
        )
        .then((result) => {
          if (result.ResultType === OperationResultType.Ok) {
            const completeMultipartUpload: ICompleteMultipartUploadFileModel = {
              AssetId: assetId,
              AssetContentGuid: assetContentGuid,
              AssetStreamTypeCode: assetContent.StreamTypeCode || StreamType.Main,
              UploadType: uploadType,
              UploadId: uploadMultipartFileInfo.UploadId,
              ContentType: contentType,
              Parts: result.Result
                ? result.Result?.Parts.map((part) => {
                    return {
                      ETag: part.ETag,
                      Number: part.Number,
                    };
                  })
                : [],
            };

            return assetService
              .completeMultipartUploadFile(completeMultipartUpload)
              .toPromise();
          }
        })
        .then(() => {
          return assetContentService.insert(assetContent).toPromise();
        })
        .then((assetContent: IAssetContentModel) => {
          if (
            assetContent.StreamTypeCode !== StreamType.Source &&
            uploadType === UploadType.Transcoding
          ) {
            return assetContentService
              .transcode({ AssetContentId: assetContent.Id })
              .toPromise();
          }
        })
        .then(() => {
          onUploadSuccess();
        })
        .catch((error) => {
          const appError = error as IErrorModel;

          if (appError?.Code === STORAGE.UPLOAD_ABORT_ERROR_CODE) {
            assetService
              .abortMultipartUploadFile({
                AssetId: assetId,
                AssetContentGuid: assetContentGuid,
                AssetStreamTypeCode: assetContent.StreamTypeCode || StreamType.Main,
                UploadId: uploadMultipartFileInfo.UploadId,
                ContentType: contentType,
              })
              .toPromise();
          }

          onUploadFailed(error);
        });
    } catch (error) {
      onUploadFailed(error);
    }
  };

  const onFinish = async (values: IFormValues) => {
    const { contentFile, fileList, assetContentGuid } = state;
    const {
      AssetContentTypeCode,
      AssetContentStreamTypeCode,
      StreamVariant,
      AssetSubtitleLanguage,
      AssetSubtitleLabel,
      AssetUploadTypeCode,
    } = values;

    if (!asset || !contentFile || !assetContentGuid) {
      return;
    }

    const durationMs = isSubtitles
      ? undefined
      : await FileHelper.getMediaDurationFile(contentFile).then((durationSec) =>
          Math.floor(durationSec * 1000)
        );

    const assetContent: IAssetContentModel = {
      Guid: state.assetContentGuid,
      AssetId: asset?.Id,
      ContentTypeCode: AssetContentTypeCode,
      StreamTypeCode: AssetContentStreamTypeCode,
      StreamVariant: StreamVariant,
      ContentStatusCode:
        AssetUploadTypeCode === UploadType.Direct
          ? ContentStatus.Ready
          : ContentStatus.Queued,
      DurationMiliseconds: durationMs,
      LanguageCode: AssetSubtitleLanguage,
      Label: AssetSubtitleLabel,
    };

    if (
      contentFile.size > MIN_MULTIPART_FILE_SIZE &&
      config?.Storage?.IsMultipartUploadEnabled
    ) {
      uploadAssetContentMultipart(
        contentFile,
        assetContent,
        AssetUploadTypeCode
      );
    } else {
      uploadAssetContent(contentFile, assetContent, AssetUploadTypeCode);
    }

    if (fileList.length) {
      setState((oldState) => ({
        ...oldState,
        ContentTypeCode: AssetContentTypeCode,
        ContentStreamTypeCode: AssetContentStreamTypeCode,
      }));
    }

    setProcessing(true);
  };
  const onBeforeContentFileUpload = (file: File) => {
    setState((oldState) => ({
      ...oldState,
      contentFile: file,
      assetContentGuid: GuidHelper.newGuid(),
    }));

    return true;
  };

  const onContentFileChange = (e: IUploadChangeEvent) => {
    if (e?.file?.status === "removed") {
      return undefined;
    }
    return e && e.file && e.file.originFileObj;
  };

  const validateUpload = (
    _: any,
    file: File,
    callback: (error?: string) => void
  ) => {
    const allowedFileTypesForStream = getAllowedFileTypesForStream(
      contentStreamTypeState,
      assetContentStreamTypes.data
    );

    if (file?.type && allowedFileTypesForStream.includes(file.type)) {
      return callback();
    } else if (isSubtitles && file && !file.type) {
      // If it's subtitles and file type isn't present then it's probably ttml file.
      // Change if better solution found.
      const isFileWithTtmlExtension = SUBTITLES_TTML_FILE_EXT.some((ext) =>
        file.name.includes(ext)
      );
      if (isFileWithTtmlExtension) {
        return callback();
      }
    }

    return callback(t("UPLOAD_FILE_TYPE_IS_INCORRECT"));
  };

  const renderUpload = () => {
    return (
      <Form.Item
        key="ContentFile"
        name={FIELDS_NAME.ContentFile}
        valuePropName="ContentFile"
        getValueFromEvent={onContentFileChange}
        rules={[{ validator: validateUpload }]}
      >
        <Dragger
          className="AssetContentModal__Dragger"
          name="Upload"
          multiple={false}
          showUploadList={{
            showRemoveIcon: true,
            showPreviewIcon: false,
            showDownloadIcon: false,
          }}
          accept={`${
            isAudio
              ? "audio/*"
              : isSubtitles
              ? SUBTITLES_FILE_TYPES_TO_ACCEPT.join(",")
              : "video/*"
          }`}
          beforeUpload={onBeforeContentFileUpload}
          fileList={state.fileList}
          onChange={onChangeContent}
          disabled={processing || !Boolean(contentStreamTypeState)}
          progress={{
            strokeWidth: 10,
            format: (percent) =>
              percent && `${parseFloat(percent.toFixed(2))}%`,
          }}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="Inbox" />
          </p>
          <p className="ant-upload-text">{t("DRAG_AND_DROP_INFO")}</p>
        </Dragger>
      </Form.Item>
    );
  };

  const renderContentTypeField = () => {
    return (
      <Form.Item
        name={FIELDS_NAME.AssetContentTypeCode}
        label={t("ASSET_CONTENT_TYPE_LABEL")}
        key="contentType"
        hidden={contentStreamTypeState === StreamType.Subtitle}
        {...formLayout}
      >
        <Choose
          placeholder={t("ASSET_CONTENT_TYPE_PLACEHOLDER")}
          loading={assetContentTypes.isFetching}
          disabled={true}
        >
          {filteredContentType &&
            filteredContentType.map((assetContentType) => (
              <ChooseOption
                key={assetContentType.Code}
                value={assetContentType.Code}
              >
                {assetContentType.DisplayName}
              </ChooseOption>
            ))}
        </Choose>
      </Form.Item>
    );
  };

  const validateContentStreamVariantUniqueness = (
    _: any,
    value: string | number,
    callback: (error?: string) => void
  ) => {
    const index =
      asset?.Contents?.findIndex(
        (content) =>
          content.ContentTypeCode === contentTypeState &&
          content.StreamTypeCode === contentStreamTypeState &&
          content.StreamVariant === value
      ) ?? -1;

    return index >= 0
      ? callback(t("CONTENT_STREAM_VARIANT_EXISTS_FOR_STREAM_TYPE"))
      : callback();
  };

  const renderStreamVariantField = () => {
    if (contentStreamTypeState !== StreamType.Source) {
      return null;
    }

    return (
      <Form.Item
        name={FIELDS_NAME.StreamVariant}
        label={t("ASSET_CONTENT_STREAM_VARIANT_LABEL")}
        key="StreamVariant"
        rules={[{ validator: validateContentStreamVariantUniqueness }]}
        {...formLayout}
      >
        <Choose
          placeholder={t("ASSET_CONTENT_STREAM_VARIANT_PLACEHOLDER")}
          loading={assetContentTypes.isFetching}
          disabled={processing}
          allowClear={true}
        >
          <ChooseOption key="A" value="A">
            A
          </ChooseOption>
          <ChooseOption key="B" value="B">
            B
          </ChooseOption>
        </Choose>
      </Form.Item>
    );
  };

  const renderUploadTypeField = () => {
    return (
      <Form.Item
        name={FIELDS_NAME.AssetUploadTypeCode}
        label={t("ASSET_UPLOAD_TYPE_LABEL")}
        key="uploadType"
        {...formLayout}
      >
        <Choose
          placeholder={t("ASSET_UPLOAD_TYPE_PLACEHOLDER")}
          loading={assetContentTypes.isFetching}
          disabled={
            !contentStreamTypeState ||
            isAlbumOrPodcast ||
            processing ||
            ANY_VIDEO_UPLOAD_DISABLED
          }
        >
          {UploadTypeHelper.getOptions()
            .filter(
              getFilterForUploadTypesBasedOnStreamType(contentStreamTypeState)
            )
            .map((option) => (
              <ChooseOption key={option.value} value={option.value}>
                {option.text}
              </ChooseOption>
            ))}
        </Choose>
      </Form.Item>
    );
  };

  const onCancelUploading = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setState((oldState) => ({
      ...oldState,
      fileList: [],
      contentFile: undefined,
      assetContentGuid: undefined,
    }));
    onCancel?.();
  };

  const onCloseModal = () => {
    if (processing) {
      modal.confirm({
        title: t(
          "ASSET_CONTENT_UPLOAD_MODAL_CANCEL_UPLOADING_TITLE",
          "Cancel uploading"
        ),
        content: t(
          "ASSET_CONTENT_UPLOAD_MODAL_CANCEL_UPLOADING_MESSAGE",
          "Are you sure you want to cancel uploading?"
        ),
        okText: t("BUTTON_YES"),
        cancelText: t("BUTTON_NO"),
        onOk: onCancelUploading,
      });
    } else {
      onCancelUploading();
    }
  };

  const validateContentStreamTypeUniqueness = (
    _: any,
    value: string | number,
    callback: (error?: string) => void
  ) => {
    if (value === StreamType.Subtitle) {
      return callback();
    }
    const index =
      asset?.Contents?.findIndex(
        (content) =>
          content.ContentTypeCode === contentTypeState &&
          content.StreamTypeCode === value &&
          content.StreamVariant === streamVariant
      ) ?? -1;

    return index >= 0
      ? callback(t("CONTENT_STREAM_TYPE_EXISTS_FOR_CONTENT_TYPE"))
      : callback();
  };

  const renderContentStreamTypeField = () => {
    return (
      <Form.Item
        name={FIELDS_NAME.AssetContentStreamTypeCode}
        label={t("ASSET_CONTENT_STREAM_TYPE_LABEL")}
        key="streamType"
        initialValue={
          isParentalType ? StreamType.Trial : assetContent?.StreamTypeCode
        }
        rules={[required(), { validator: validateContentStreamTypeUniqueness }]}
        {...formLayout}
      >
        <Choose
          placeholder={t("ASSET_CONTENT_STREAM_TYPE_PLACEHOLDER")}
          loading={assetContentStreamTypes.isFetching}
          disabled={processing || isParentalType}
          onChange={(streamTypeValue) => {
            const allFieldsNames = Object.keys(form.getFieldsValue() || {});
            const otherFieldsNames = allFieldsNames.filter(
              (fieldName) =>
                fieldName !== FIELDS_NAME.AssetContentStreamTypeCode
            );
            form.resetFields(otherFieldsNames);
            setState({
              fileList: [],
              contentFile: undefined,
              assetContentGuid: undefined,
            });

            // Pick first/default asset upload type.
            if (!isUploadTypeKeyGuard(streamTypeValue)) {
              return;
            }
            const defaultUploadTypeForStreamType = getDefaultUploadTypeForStream(
              streamTypeValue,
              asset
            );
            form.setFieldValue(
              FIELDS_NAME.AssetUploadTypeCode,
              defaultUploadTypeForStreamType
            );
          }}
        >
          {assetContentStreamTypes.data
            ?.filter((row) => {
              switch (row.Code) {
                case StreamType.Source:
                  return !isAudio;
                case StreamType.Subtitle:
                  return !isAudio;
                default:
                  return true;
              }
            })
            .map((row) => (
              <ChooseOption key={row.Code} value={row.Code}>
                {row.DisplayName}
              </ChooseOption>
            ))}
        </Choose>
      </Form.Item>
    );
  };

  const renderSubtitleLanguageTypeField = () => {
    if (!isSubtitles) {
      return null;
    }

    return (
      <Form.Item
        name={FIELDS_NAME.AssetSubtitleLanguage}
        label={t("ASSET_CONTENT_SUBTITLE_LANGUAGE_LABEL")}
        key="selectLanguage"
        rules={[required()]}
        {...formLayout}
      >
        <Choose
          placeholder={t("ASSET_CONTENT_SUBTITLE_LANGUAGE_PLACEHOLDER")}
          loading={isLoadingLanguages}
          disabled={processing || !languages.length}
        >
          {languages.map((language) => (
            <ChooseOption key={language.Code} value={language.Code}>
              {language.Name}
            </ChooseOption>
          ))}
        </Choose>
      </Form.Item>
    );
  };

  const renderSubtitleLabelField = () => {
    if (!isSubtitles) {
      return null;
    }

    return (
      <Form.Item
        name={FIELDS_NAME.AssetSubtitleLabel}
        label={t("ASSET_CONTENT_SUBTITLE_LABEL_LABEL")}
        key="language"
        rules={[required()]}
        {...formLayout}
      >
        <Input
          disabled={processing}
          placeholder={t("ASSET_CONTENT_SUBTITLE_LABEL_PLACEHOLDER")}
          maxLength={256}
        />
      </Form.Item>
    );
  };

  return (
    <FormModal
      isVisible={visible}
      isLoading={false}
      isNewForm={true}
      isDisabled={processing}
      isDeleteButtonEnabled={false}
      createFormTitle={t(
        "ASSET_CONTENT_UPLOAD_MODAL_TITLE",
        "Upload asset content"
      )}
      editFormTitle={t(
        "ASSET_CONTENT_UPLOAD_MODAL_TITLE",
        "Upload asset content"
      )}
      modalClassName="AssetContentModal"
      submitFormName="AssetContentUploadForm"
      onCloseModal={onCloseModal}
    >
      <Form
        form={form}
        name="AssetContentUploadForm"
        className="AssetContentForm"
        onFinish={onFinish}
      >
        {renderContentStreamTypeField()}
        {renderUploadTypeField()}
        {renderStreamVariantField()}
        {renderContentTypeField()}
        {renderSubtitleLanguageTypeField()}
        {renderSubtitleLabelField()}
        {renderUpload()}
      </Form>
    </FormModal>
  );
};
