import { ICommonAppState } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ApplicationHomeProperties as ApplicationHomePropertiesDefinition,
  ApplicationHomePropertiesDispatchProps,
  ApplicationHomePropertiesOwnProps,
  ApplicationHomePropertiesStateProps,
} from "./ApplicationHomeProperties";

const mapStateToProps = (
  state: ICommonAppState
): ApplicationHomePropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): ApplicationHomePropertiesDispatchProps => ({});

export const ApplicationHomeProperties = connect<
  ApplicationHomePropertiesStateProps,
  ApplicationHomePropertiesDispatchProps,
  ApplicationHomePropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationHomePropertiesDefinition));
