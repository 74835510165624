import {
  Button,
  IBreadcrumbProps,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  Link,
  PageContent,
  PageHeader,
  Pagination,
  Popconfirm,
  setTableColumnSearchProps,
  Table,
  Tag,
  Tooltip,
  useAppFeedback,
} from "@bms/common-ui";
import { generateBreadcrumb, useTableDataProvider } from "../../../../helpers";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../constants";
import { FilterCleanIcon } from "../../../../resources/icons";
import {
  CountriesService,
  IPeopleModel,
  PeopleService,
  useDataLoader,
  GenderService,
  useServiceCaller,
} from "@bms/common-services";
import { useCreatePeopleDataPagerSource } from "../../hooks";
import { RouteComponentProps } from "react-router";
import { AddPeopleModal } from "../AddPeopleModal";
import { useMemo, useState } from "react";
import { PeopleForm } from "../PeopleForm";

const peopleService = new PeopleService().promisify();
const countriesService = new CountriesService().promisify();
const genderService = new GenderService().promisify();

export const PeopleList = ({ location, history }: RouteComponentProps) => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();
  const [isModalOpen, setModalOpen] = useState(false);

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.PEOPLE_LIST}`,
        breadcrumbName: t("MENU_CMS_PEOPLE_LIST"),
      },
    ]);
  };

  const countriesLoader = useDataLoader({
    loader: () => countriesService.select(),
    deps: [],
  });

  const gendersLoader = useDataLoader({
    loader: () => genderService.select(),
    deps: [],
  });

  const countries = useMemo(() => countriesLoader.data ?? [], [
    countriesLoader.data,
  ]);

  const {
    dataLoader: peopleLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      FirstName: "string",
      MiddleName: "string",
      LastName: "string",
      Countries: "numbers",
      GenderCode: "string",
    },
    loader: (filters, pagination) => {
      return peopleService.search({
        ...filters,
        ...pagination,
      });
    },
    deps: [],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const dataPagerSource = useCreatePeopleDataPagerSource(peopleLoader.data, {
    ...filters.current,
    ...pagination.current,
  });

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
    }));

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value,
    }));

  const getColumnsProps = (): Array<ITableColumnProps<IPeopleModel>> => {
    return [
      {
        key: "FirstName",
        dataIndex: "FirstName",
        title: t("MODEL_FIRST_NAME"),
        ellipsis: true,
        filteredValue: filters.asTableArray.FirstName,
        render: (_, row: IPeopleModel) => {
          const peopleDetailLocation = {
            pathname: `${ROUTES.PEOPLE_DETAILS}/${row.Id}`,
            state: {
              from: location,
              dataPagerSource: dataPagerSource,
            },
          };
          return <Link to={peopleDetailLocation}>{row.FirstName}</Link>;
        },
        ...setTableColumnSearchProps("FirstName", t("MODEL_FIRST_NAME")),
      },
      {
        key: "MiddleName",
        dataIndex: "MiddleName",
        title: t("MODEL_MIDDLE_NAME"),
        filteredValue: filters.asTableArray.MiddleName,
        ...setTableColumnSearchProps("MiddleName", t("MODEL_MIDDLE_NAME")),
      },
      {
        key: "LastName",
        dataIndex: "LastName",
        title: t("MODEL_LAST_NAME"),
        filteredValue: filters.asTableArray.LastName,
        ...setTableColumnSearchProps("LastName", t("MODEL_LAST_NAME")),
      },
      {
        key: "Countries",
        dataIndex: "Countries",
        width: "200px",
        align: "center",
        title: t("MODEL_COUNTRY_NAME"),
        filters: countries.map((country) => ({
          text: country.Name,
          value: country.Id,
        })),
        filterSearch: true,
        filteredValue: filters.asTableArray.Countries,
        render: (_, row: IPeopleModel) =>
          row.CountryCode && (
            <Tag
              colorRotate={countries.findIndex(
                ({ Code }) => Code === row.CountryCode
              )}
            >
              {row.CountryName}
            </Tag>
          ),
      },
      {
        key: "GenderCode",
        dataIndex: "GenderCode",
        title: t("MODEL_GENDER_NAME"),
        filters: gendersLoader.data?.map((gender) => ({
          text: gender.DisplayName,
          value: gender.Code,
        })),
        filterMultiple: false,
        filteredValue: filters.asTableArray.GenderCode,
        render: (_, row: IPeopleModel) => row.GenderDisplayName,
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_, people: IPeopleModel) => (
          <div className="people-table__actions">
            <Popconfirm
              title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
              onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
                e?.preventDefault();
                const result = await peopleService.deletePeople(people!);
                if (result.ok) {
                  await peopleLoader.refresh();
                } else {
                  notification.error({
                    message: t("DELETE_ELEMENT_FAILTURE"),
                    description: result.error?.Message,
                  });
                }
              }}
              okText={t("BUTTON_YES")}
              cancelText={t("BUTTON_NO")}
            >
              <Button
                danger={true}
                icon={<Icon type="delete" />}
                title={t("DELETE_ELEMENT")}
              />
            </Popconfirm>
          </div>
        ),
      },
    ];
  };

  const [createPeople, createPeopleState] = useServiceCaller(
    async (data: IPeopleModel) => {
      const result = await peopleService.createPeople(data);
      if (result.ok) {
        setModalOpen(false);
        notification.success({
          message: t("PEOPLE_CREATE_SUCCESS"),
        });
        history.push(`${ROUTES.PEOPLE_DETAILS}/${result.data.Id}`);
      } else {
        notification.error({
          message: t("PEOPLE_CREATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  return (
    <>
      <AddPeopleModal
        visible={isModalOpen}
        close={() => setModalOpen(false)}
        processing={createPeopleState.processing || peopleLoader.loading}
      >
        <PeopleForm
          isEditMode={false}
          onSubmit={createPeople}
          hiddenFields={[
            "DateOfBirth",
            "GenderCode",
            "MiddleName",
            "CountryCode",
            "LanguageCode",
          ]}
        />
      </AddPeopleModal>
      <PageContent footer={<Pagination {...pagination.props} />}>
        <PageHeader
          title={t("MENU_CMS_PEOPLE_LIST")}
          breadcrumb={getBreadcrumbProps()}
          extra={
            <>
              <InputSearch
                key="search"
                placeholder={t("SEARCH_PLACEHOLDER")}
                value={fullTextSearch}
                onChange={({ target: { value } }) => setFullTextSearch(value)}
                onSearch={onSearch}
                style={{ width: 250 }}
                allowClear
              />
              <Tooltip overlay={t("MENU_OPTION_CLEAR_FILTERS")}>
                <Button
                  key="clear-filters"
                  shape="circle"
                  className="people-table-clear-button"
                  icon={<FilterCleanIcon />}
                  onClick={filters.clear}
                  title={t("MENU_OPTION_CLEAR_FILTERS")}
                />
              </Tooltip>
              <Button
                key="reload"
                shape="circle"
                icon={<Icon type="reload" />}
                onClick={peopleLoader.refresh}
                title={t("BUTTON_REFRESH_TITLE")}
              />
              <Button
                key="add"
                shape="circle"
                type="primary"
                icon={<Icon type="plus" />}
                onClick={() => setModalOpen(true)}
                title={t("BUTTON_ADD")}
              />
            </>
          }
        />

        <Table<IPeopleModel>
          rowKey="Id"
          columns={getColumnsProps()}
          dataSource={peopleLoader.data?.Entities}
          loading={peopleLoader.loading}
          pagination={false}
          onChange={onTableChange}
        />
      </PageContent>
    </>
  );
};
