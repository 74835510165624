import { Button, Col, Form, Row } from "@bms/common-ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FieldType, formLayouts } from "./PeopleFormUtils";
import {
  IPeopleFormProps,
  usePeopleFormController,
} from "./usePeopleFormController";
import {
  FirstNameField,
  LastNameField,
  CreatedByField,
  MiddleNameField,
  DateOfBirthField,
  GenderCodeField,
  CountryCodeField,
  LanguageCodeField,
} from "./Fields";

export const PeopleForm = (props: IPeopleFormProps) => {
  const { t } = useTranslation();
  const { isEditMode, people, section, hiddenFields } = props;
  const {
    formLayout,
    formFieldNames,
    sendable,
    userCreator,
    form,
    onFieldsChange,
    onUserClear,
    onUserSelect,
    onFinish,
  } = usePeopleFormController(props);

  const isHidden = (key: FieldType) =>
    !!hiddenFields && hiddenFields.includes(key);

  const formFieldsMapping: { [key: string]: () => JSX.Element } = {
    FirstName: () => (
      <FirstNameField
        key="PeopleForm--FirstNameField"
        isHidden={isHidden("FirstName")}
        isEditMode={isEditMode}
        people={people}
      />
    ),
    MiddleName: () => (
      <MiddleNameField
        key="PeopleForm--MiddleNameField"
        isHidden={isHidden("MiddleName")}
        isEditMode={isEditMode}
        people={people}
      />
    ),
    LastName: () => (
      <LastNameField
        key="PeopleForm--LastNameField"
        isHidden={isHidden("LastName")}
        isEditMode={isEditMode}
        people={people}
      />
    ),
    CreatedBy: () => (
      <CreatedByField
        key="PeopleForm--CreatedByField"
        isEditMode={isEditMode}
        isHidden={isHidden("CreatedBy")}
        onSelect={onUserSelect}
        onClear={onUserClear}
        userCreator={userCreator}
      />
    ),
    DateOfBirth: () => (
      <DateOfBirthField
        key="PeopleForm--DateOfBirthField"
        isHidden={isHidden("DateOfBirth")}
        isEditMode={isEditMode}
        people={people}
      />
    ),
    GenderCode: () => (
      <GenderCodeField
        key="PeopleForm--GenderCodeField"
        isHidden={isHidden("GenderCode")}
        isEditMode={isEditMode}
        people={people}
      />
    ),
    CountryCode: () => (
      <CountryCodeField
        key="PeopleForm--CountryCodeField"
        isHidden={isHidden("CountryCode")}
        isEditMode={isEditMode}
        people={people}
      />
    ),
    LanguageCode: () => (
      <LanguageCodeField
        key="PeopleForm--LanguageCodeField"
        isHidden={isHidden("LanguageCode")}
        isEditMode={isEditMode}
        people={people}
      />
    ),
  };

  const formFields = useMemo(
    () =>
      formFieldNames.map((field: string) =>
        formFieldsMapping[field] ? formFieldsMapping[field]() : null
      ),
    [formFieldNames, userCreator, people]
  );

  return (
    <Form
      {...formLayout}
      form={form}
      name={section ? `PeopleForm-${section}` : "PeopleForm"}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      className="PeopleForm"
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          {formFields}
          {isEditMode && (
            <Form.Item {...formLayouts.tailFormItem}>
              <Button type="primary" htmlType="submit" disabled={!sendable}>
                {t("BUTTON_SAVE")}
              </Button>
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};
