import {
  IVoucherModel,
  TimeHelper,
  useServiceCaller,
  VoucherDiscountType,
  VoucherRedeemType,
  VouchersService,
  CurrencyStore,
} from "@bms/common-services";
import { Form, useAppFeedback } from "@bms/common-ui";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ROUTES } from "../../constants";
import { FormModal } from "../../../../components";
import { useSelector } from "react-redux";
import {
  DiscountCurrencyItem,
  DiscountTypeItem,
  DiscountValueItem,
  ITEM_NAMES,
  RedeemTypeItem,
  RedemptionLimitItem,
  ValidToItem,
} from "../VoucherFormItems";

import "./VoucherCreateModalForm.scss";

const voucherService = new VouchersService().promisify();

interface VoucherCreateModalFormProps {
  visible: boolean;
  onCancel: () => void;
}

export const VoucherCreateModalForm: React.FC<VoucherCreateModalFormProps> = ({
  visible,
  onCancel,
}) => {
  const [t] = useTranslation();
  const { notification } = useAppFeedback();
  const { push } = useHistory();

  const { data: currenciesData } = useSelector(
    CurrencyStore.Selectors.currenciesSelector
  );

  const [createVoucher, { processing }] = useServiceCaller(
    async (data: IVoucherModel) => {
      const result = await voucherService.insert(data);
      if (!result.ok) {
        notification.error({
          message: t("PAYMENT_VOUCHER_FORM_INSERT_FAILURE"),
        });
      } else {
        notification.success({
          message: t("PAYMENT_VOUCHER_FORM_INSERT_SUCCESS"),
        });
        if (result.data.Id) {
          push(`${ROUTES.VOUCHER_DETAILS}/${result.data.Id}`);
        }
      }
    },
    []
  );

  return (
    <FormModal
      isVisible={visible}
      isLoading={processing}
      isNewForm={true}
      isDeleteButtonEnabled={false}
      createFormTitle={t(
        "PAYMENT_VOUCHER_CREATE_MODAL_TITLE",
        "Create a new voucher"
      )}
      modalClassName="VoucherCreateForm"
      submitFormName="VoucherCreateForm"
      onCloseModal={onCancel}
      isDisabled={Boolean(!currenciesData?.length)}
    >
      <VoucherForm createVoucher={createVoucher} />
    </FormModal>
  );
};

interface IVoucherFormProps {
  createVoucher: (voucher: IVoucherModel) => void;
}

const VoucherForm = ({ createVoucher }: IVoucherFormProps) => {
  const [t] = useTranslation();

  const [form] = Form.useForm();

  const discountTypeValue = Form.useWatch(ITEM_NAMES.DISCOUNT_TYPE, form);
  const isPercentageDiscount =
    discountTypeValue === VoucherDiscountType.Percentage;

  const {
    data: currenciesData,
    isFetching: isFetchingCurrencies,
  } = useSelector(CurrencyStore.Selectors.currenciesSelector);

  const onFinish = useCallback((values: any) => {
    const payload: IVoucherModel = {
      Id: -1,
      DiscountType: values[ITEM_NAMES.DISCOUNT_TYPE],
      Value: values[ITEM_NAMES.VALUE],
      CurrencyId: values[ITEM_NAMES.CURRENCY_ID],
      RedeemType: values[ITEM_NAMES.REDEEM_TYPE],
    };

    const validTo = values[ITEM_NAMES.VALID_TO];
    if (validTo) {
      payload.ValidTo = TimeHelper.toISOString(validTo);
    }

    const redemptionLimit = values[ITEM_NAMES.REDEMPTION_LIMIT];
    if (redemptionLimit) {
      payload.RedemptionLimit = redemptionLimit;
    }

    createVoucher(payload);
  }, []);

  return (
    <Form
      form={form}
      name="VoucherCreateForm"
      className="VoucherCreateForm"
      layout="vertical"
      onFinish={onFinish}
      disabled={!Boolean(currenciesData?.length)}
      initialValues={{
        [ITEM_NAMES.DISCOUNT_TYPE]: VoucherDiscountType.Fixed,
        [ITEM_NAMES.VALUE]: 1,
        [ITEM_NAMES.REDEEM_TYPE]: VoucherRedeemType.OneTime,
        [ITEM_NAMES.CURRENCY_ID]: currenciesData
          ? currenciesData?.[0]?.Id
          : undefined,
      }}
    >
      <DiscountTypeItem />
      {isPercentageDiscount ? (
        <DiscountValueItem form={form} />
      ) : (
        <div className="HalfWidthItem">
          <DiscountValueItem form={form} />
          <DiscountCurrencyItem
            currenciesData={currenciesData}
            isLoadingCurrencies={Boolean(isFetchingCurrencies)}
          />
        </div>
      )}
      <RedeemTypeItem form={form} />
      <ValidToItem />
      <RedemptionLimitItem form={form} />
    </Form>
  );
};
