import { Form, Input, required } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IClubFormFieldCommonProps } from "./IClubFormFieldCommonProps";

interface INameFieldProps extends IClubFormFieldCommonProps {}

export const NameField = ({ isHidden, isEditMode, club }: INameFieldProps) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="Name"
      label={t("MODEL_NAME")}
      key="name"
      initialValue={isEditMode ? club?.Name || "" : ""}
      rules={[required()]}
    >
      <Input placeholder={t("MODEL_NAME_PLACEHOLDER")} />
    </Form.Item>
  );
};
