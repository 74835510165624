import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import {
  CurrencyStore,
  IVoucherModel,
  useDataLoader,
  useServiceCaller,
  VouchersService,
} from "@bms/common-services";
import {
  Button,
  Icon,
  PageContent,
  PageHeader,
  Spin,
  Tabs,
  Text,
  useSearchParamsTabs,
  IBreadcrumbProps,
  useAppFeedback,
  TabPane,
  SectionGrid,
  SectionGridItem,
} from "@bms/common-ui";
import { generateBreadcrumb } from "../../../../helpers";
import { ROUTES } from "../../constants/routes";
import { DetailsTab } from "./Tabs/DetailsTab";
import { RedemptionsTab } from "./Tabs";

const vouchersService = new VouchersService().promisify();

export const VoucherDetails: React.FC = () => {
  const id = parseInt(useParams<{ id: string }>().id, 10);
  const { t } = useTranslation();
  const history = useHistory();
  const [activeTabKey, setActiveTabKey] = useSearchParamsTabs("DETAILS");
  const { modal, notification } = useAppFeedback();

  const { data: voucher, loading, refresh: refreshData } = useDataLoader({
    loader: () => vouchersService.get(id),
    deps: [id],
  });

  const [
    updateData,
    { processing: isProcessingUpdateVoucher },
  ] = useServiceCaller(async (voucher: IVoucherModel) => {
    const result = await vouchersService.update(voucher);
    if (!result.ok) {
      notification.error({
        message: t("PAYMENT_VOUCHER_DETAILS_TAB_UPDATE_FAILURE"),
      });
    } else {
      notification.success({
        message: t("PAYMENT_VOUCHER_DETAILS_TAB_UPDATE_SUCCESS"),
      });
      await refreshData();
    }
  }, []);

  const [
    deleteData,
    { processing: isProcessingDeleteVoucher },
  ] = useServiceCaller(async (voucher: IVoucherModel) => {
    const result = await vouchersService.delete(voucher);
    if (!result.ok) {
      notification.error({
        message: t("PAYMENT_VOUCHER_DETAILS_TAB_DELETE_FAILURE"),
      });
    } else {
      notification.success({
        message: t("PAYMENT_VOUCHER_DETAILS_TAB_DELETE_SUCCESS"),
      });
      history.goBack();
    }
  }, []);

  const { data: currenciesData } = useSelector(
    CurrencyStore.Selectors.currenciesSelector
  );
  const dispatch = useDispatch();

  const isCurrencyDataPresent = currenciesData !== undefined;
  useEffect(() => {
    if (!isCurrencyDataPresent) {
      dispatch(CurrencyStore.Actions.selectCurrencies());
    }
  }, [isCurrencyDataPresent, dispatch]);

  const onDeleteClick = () => {
    if (!voucher) {
      return;
    }
    modal.confirm({
      title: t("COMMON_DELETE"),
      content: t("PAYMENT_VOUCHER_DETAILS_ACTION_DELETE_MESSAGE", {
        code: voucher?.Code,
      }),
      okText: t("BUTTON_DELETE"),
      cancelText: t("BUTTON_CANCEL"),
      onOk: () => deleteData(voucher),
    });
  };

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.VOUCHER_LIST}`,
        breadcrumbName: t("PAYMENT_VOUCHER_LIST_TITLE"),
      },
      {
        path: `${ROUTES.VOUCHER_DETAILS}/${id}`,
        breadcrumbName:
          voucher?.Code || t("PAYMENT_VOUCHER_DETAILS_TITLE_CODE_PLACEHOLDER"),
      },
    ]);
  };

  return (
    <>
      <Spin
        spinning={
          loading || isProcessingUpdateVoucher || isProcessingDeleteVoucher
        }
      >
        <PageContent>
          <PageHeader
            title={
              <Text copyable>
                {voucher?.Code ||
                  t("PAYMENT_VOUCHER_DETAILS_TITLE_CODE_PLACEHOLDER")}
              </Text>
            }
            breadcrumb={getBreadcrumbProps()}
            onBack={history.goBack}
            extra={
              <>
                <Button
                  key="action-reload"
                  icon={<Icon type="reload" />}
                  onClick={refreshData}
                  title={t("BUTTON_REFRESH_TITLE")}
                  shape="circle"
                />
                {voucher?.Editable && (
                  <Button
                    danger
                    key="action-delete"
                    icon={<Icon type="delete" />}
                    title={t("BUTTON_DELETE")}
                    onClick={onDeleteClick}
                    shape="circle"
                  />
                )}
              </>
            }
          />
          <Tabs
            activeKey={activeTabKey}
            onTabClick={setActiveTabKey}
            destroyInactiveTabPane
          >
            <TabPane
              key="DETAILS"
              tab={t("PAYMENT_VOUCHER_DETAILS_TAB_DETAILS_TITLE")}
            >
              {voucher && !loading && (
                <DetailsTab voucher={voucher} updateVoucher={updateData} />
              )}
            </TabPane>
            <TabPane
              key="REDEMPTIONS"
              tab={t("PAYMENT_VOUCHER_DETAILS_TAB_REDEMPTIONS_TITLE")}
            >
              <SectionGrid>
                <SectionGridItem>
                  {voucher && !loading && <RedemptionsTab voucher={voucher} />}
                </SectionGridItem>
              </SectionGrid>
            </TabPane>
          </Tabs>
        </PageContent>
      </Spin>
    </>
  );
};
