import { IPeopleModel, IUserModel } from "@bms/common-services";
import { Form, IFormValues, useSendable, useSyncedState } from "@bms/common-ui";
import { pick } from "lodash";
import { useEffect, useMemo } from "react";
import {
  FieldType,
  formLayouts,
  getSectionsFields,
  ISection,
} from "./PeopleFormUtils";

export interface IPeopleFormProps {
  isEditMode: boolean;
  people?: IPeopleModel;
  section?: ISection;
  hiddenFields?: FieldType[];
  onSubmit?: (data: IPeopleModel) => void;
}

export const usePeopleFormController = ({
  isEditMode,
  people,
  section,
  onSubmit,
}: IPeopleFormProps) => {
  const [form] = Form.useForm();
  const { onFieldsChange, resetSendable, setDirty, sendable } = useSendable();

  const [userCreator, setUserCreator] = useSyncedState<{
    Id?: string | number;
    FullName?: string | number;
  }>(
    () => ({
      Id: people?.CreatedBy,
      FullName: people?.CreatedByFullName,
    }),
    [people]
  );

  const formLayout = useMemo(
    () => (isEditMode ? formLayouts.formItem : formLayouts.modalFormItem),
    [isEditMode]
  );

  const formFieldNames = useMemo(() => {
    const everySectionFields: FieldType[] = [
      "FirstName",
      "LastName",
      "MiddleName",
      "GenderCode",
      "CountryCode",
      "DateOfBirth",
      "CreatedBy",
      "LanguageCode",
    ];

    if (section) {
      return getSectionsFields()[section];
    }

    return everySectionFields;
  }, [section]);

  const onFinish = async (_values: IFormValues) => {
    const values = { ..._values };
    const picked: any = {};

    const parserMapper: IFormValues = {};

    formFieldNames
      .filter((it) => parserMapper[it] !== undefined)
      .forEach((it) => (picked[it] = parserMapper[it]));

    const peopleDetails: IPeopleModel = {
      ...people,
      ...values,
      ...picked,
      CreatedBy: userCreator.Id,
      CreatedByFullName: userCreator.FullName,
      ...(values.GenderCode && { GenderCode: values.GenderCode.value }),
      ...(values.CountryCode && {
        CountryCode: undefined,
        CountryName: undefined,
        CountryId: Number(values.CountryCode.value),
      }),
      ...(values.LanguageCode && {
        LanguageCode: values.LanguageCode.value,
        LanguageName: undefined,
      }),
    };

    onSubmit?.(peopleDetails);
  };

  const onUserSelect = (row?: IUserModel) => {
    setUserCreator({ Id: row?.Id, FullName: row?.FullName || row?.Id });
    setDirty();
  };

  const onUserClear = () => onUserSelect();

  useEffect(() => {
    resetSendable();
    form.resetFields();
  }, [JSON.stringify(pick(people, formFieldNames))]);

  return {
    formLayout,
    formFieldNames,
    sendable,
    userCreator,
    form,
    onFieldsChange,
    onUserClear,
    onUserSelect,
    onFinish,
  };
};
