import {
  ISportPlayerModel,
  ISportTeamModel,
  ISportTeamPlayerModel,
} from "@bms/common-services";
import { Form, IFormValues, useSendable, useSyncedState } from "@bms/common-ui";
import { pick } from "lodash";
import { useEffect, useMemo } from "react";
import { EVERY_FORM_FIELDS, formLayouts } from "./SportTeamPlayerFormUtils";

export interface ISportTeamPlayerFormProps {
  isEditMode: boolean;
  player?: ISportTeamPlayerModel;
  team?: ISportTeamModel;
  onSubmit?: (data: ISportTeamPlayerModel) => void;
}

export type SyncedPlayer = {
  Id?: string | number;
  FirstName?: string;
  LastName?: string;
  ShirtNumber?: number;
};

export const useSportTeamPlayerFormController = ({
  isEditMode,
  player,
  team,
  onSubmit,
}: ISportTeamPlayerFormProps) => {
  const [form] = Form.useForm();
  const { onFieldsChange, setDirty } = useSendable();

  const formLayout = useMemo(
    () => (isEditMode ? formLayouts.formItem : formLayouts.modalFormItem),
    [isEditMode]
  );

  const [genericPlayer, setGenericPlayer] = useSyncedState<SyncedPlayer>(
    () => ({
      Id: player?.PlayerId,
      FirstName: player?.FirstName,
      LastName: player?.LastName,
      ShirtNumber: player?.ShirtNumber,
    }),
    [player]
  );

  const onFinish = async (_values: IFormValues) => {
    const values = { ..._values };
    const picked: any = {};

    const parserMapper: IFormValues = {};

    EVERY_FORM_FIELDS.filter((it) => parserMapper[it] !== undefined).forEach(
      (it) => (picked[it] = parserMapper[it])
    );

    const playerDetails: Partial<ISportTeamPlayerModel> = {
      Id: player?.Id,
      DateFrom: values.DateFrom,
      DateTo: values.DateTo,
      TeamId: team?.Id,
      PlayerId: Number(genericPlayer.Id),
      ShirtNumber: values.ShirtNumber || genericPlayer.ShirtNumber,
    };

    onSubmit?.(playerDetails as any);
  };

  useEffect(() => {
    form.resetFields();
  }, [JSON.stringify(pick(player, EVERY_FORM_FIELDS))]);

  const onPlayerSelect = (player?: ISportPlayerModel) => {
    setGenericPlayer({
      Id: player?.Id,
      FirstName: player?.FirstName,
      LastName: player?.LastName,
      ShirtNumber: player?.ShirtNumber,
    });
    setDirty();
  };

  const onPlayerClear = () => onPlayerSelect();

  useEffect(() => {
    if (!genericPlayer) {
      return;
    }
    form.setFieldValue(
      "PlayerId",
      `${genericPlayer.FirstName} ${genericPlayer.LastName}`
    );
    form.setFieldValue("ShirtNumber", genericPlayer.ShirtNumber);
  }, [genericPlayer]);

  return {
    formLayout,
    formFieldNames: EVERY_FORM_FIELDS,
    form,
    onFieldsChange,
    onFinish,
    onPlayerSelect,
    onPlayerClear,
    genericPlayer,
  };
};
