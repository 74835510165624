import { Form, Input, required } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IPeopleFormFieldCommonProps } from "./IPeopleFormFieldCommonProps";

interface IFirstNameFieldProps extends IPeopleFormFieldCommonProps {}

export const FirstNameField = ({
  isHidden,
  isEditMode,
  people,
}: IFirstNameFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="FirstName"
      label={t("MODEL_FIRST_NAME")}
      key="firstName"
      initialValue={isEditMode ? people?.FirstName || "" : ""}
      rules={[required()]}
    >
      <Input placeholder={t("MODEL_FIRST_NAME_PLACEHOLDER")} />
    </Form.Item>
  );
};
