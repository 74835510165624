import React, { useCallback, useState } from "react";
import { Button, Col, Form, IFormInstance, Icon, Row } from "@bms/common-ui";
import { AssetService, AssetType, IAssetModel } from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
import { ILazyChooseLoader, LazyChoose } from "../../../../../components";
import { AssetBrowserModal } from "../../AssetBrowserModal";

interface IParentFieldProps extends IAssetFormFieldCommonProps {
  parent?: IAssetModel;
  parentId?: number;
  parentTitle?: string;
  type?: AssetType;
  form: IFormInstance;
  onChange?: (parentId?: number) => void;
  onSelect?: (asset?: IAssetModel) => void;
  onClear?: () => void;
}

const inferParentAssetType = (type?: AssetType) => {
  switch (type) {
    case AssetType.Season:
      return [AssetType.Series];
    case AssetType.Episode:
      return [AssetType.Season, AssetType.Series];
    case AssetType.Podcast:
      return [AssetType.Album];
    case AssetType.Program:
      return [AssetType.Channel];
    case AssetType.Recipe:
      return [AssetType.Person];
    default:
      return undefined;
  }
};

const assetService = new AssetService().promisify();

export const ParentField = ({
  isEditMode,
  isHidden = false,
  parentId,
  parentTitle,
  parent,
  type,
  form,
  onChange,
  onSelect,
  onClear,
}: IParentFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }
  const [assetBrowserVisible, setAssetBrowserVisible] = useState(false);

  const parentType = inferParentAssetType(type);
  const isRequired = type === AssetType.Podcast || type === AssetType.Recipe ? false : !isEditMode;

  const onParentChange = useCallback((asset?: IAssetModel) => {
    if (!asset?.Id) {
      form.setFieldsValue({ OrderInParent: undefined });
    }
    onChange?.(asset?.Id);
  }, []);

  const loadCandidates: ILazyChooseLoader<IAssetModel> = async (
    search: string
  ) => {
    return assetService.search({
      PageNumber: 1,
      PageSize: 100,
      Types: parentType,
      Title: search,
    });
  };

  const onParentSelect = (asset: IAssetModel) => {
    setAssetBrowserVisible(false);

    if (!asset?.Id) {
      form.setFieldsValue({ OrderInParent: undefined });
    }

    onSelect?.(asset);
  };

  const onParentClear = () => {
    form.setFieldsValue({ OrderInParent: null });
    onClear?.();
  };

  if (isEditMode) {
    return (
      <Form.Item label={t("MODEL_PARENT")} key="Parent">
        <Row gutter={8}>
          <Col flex="auto">
            <Form.Item>
              {parent?.ParentAssetTitle && parent?.ParentAssetId && (
                <>
                  <span> {parent.ParentAssetTitle}</span>
                  <span> / </span>
                </>
              )}
              <span>{parentTitle}</span>
            </Form.Item>
            <AssetBrowserModal
              visible={assetBrowserVisible}
              selectionMode="single"
              selectedType={parentType}
              onCancel={() => setAssetBrowserVisible(false)}
              onSelect={onParentSelect}
            />
          </Col>
          <Col style={{ textAlign: "right" }}>
            {type === AssetType.Podcast && (
              <Button
                icon={<Icon type="delete" />}
                onClick={onParentClear}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => setAssetBrowserVisible(true)}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  }

  return (
    <Form.Item
      name="ParentAssetId"
      label={t("MODEL_PARENT")}
      key="Parent"
      initialValue={
        parentId && {
          key: parentId,
          label: parentTitle,
          value: parentId,
        }
      }
      rules={[{ required: isRequired }]}
    >
      <LazyChoose<IAssetModel, IAssetModel>
        disabled={!!parent}
        multiple={false}
        allowClear={!isRequired}
        showSearch
        placeholder={t("MODEL_PARENT_PLACEHOLDER")}
        loader={loadCandidates}
        loaderDeps={[parentType]}
        candidateToOption={(item) => ({
          label: item.ParentAssetTitle
            ? `${item.ParentAssetTitle} / ${item.Title}`
            : item.Title!,
          value: `${item.Id}`,
          item,
        })}
        selectedToOption={(item) => ({
          label: item.Title!,
          value: `${item.Id!}`,
          item,
        })}
        onValueChange={onParentChange}
      />
    </Form.Item>
  );
};
