import { Form, Input, required } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ITeamFormFieldCommonProps } from "./ITeamFormFieldCommonProps";

interface IStadiumFieldProps extends ITeamFormFieldCommonProps {}

export const StadiumField = ({
  isHidden,
  isEditMode,
  team,
}: IStadiumFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="Stadium"
      label={t("MODEL_STADIUM")}
      key="stadium"
      initialValue={isEditMode ? team?.Stadium || "" : ""}
      rules={[required()]}
    >
      <Input placeholder={t("MODEL_STADIUM_PLACEHOLDER")} />
    </Form.Item>
  );
};
