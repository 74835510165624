import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  EntityListModel,
  PaginationModel,
  ISportClubModel,
  ISportClubListModel,
  ISportClubSearchFilterModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class SportClubsService extends SimpleServiceBase<
  ISportClubModel,
  ISportClubListModel,
  number,
  ISportClubSearchFilterModel
> {
  get url(): string {
    return "/SportClubs";
  }

  public getClubsList = (
    data: ISportClubModel,
    pagination: PaginationModel
  ): Observable<EntityListModel<ISportClubModel>> =>
    new Observable(
      (observer: Observer<EntityListModel<ISportClubModel>>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: pagination,
          },
          data,
          method: HTTP_METHOD.POST,
          url: `/SportClubs/Search`,
        })
    );

  public getClub = (id: number): Observable<ISportClubModel> =>
    new Observable(
      (observer: Observer<ISportClubModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id },
          },
          method: HTTP_METHOD.GET,
          url: `/SportClubs/Get`,
        })
    );

  public createClub = (data: ISportClubModel): Observable<ISportClubModel> =>
    new Observable(
      (observer: Observer<ISportClubModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/SportClubs/Insert`,
        })
    );

  public updateClub = (data: ISportClubModel): Observable<ISportClubModel> =>
    new Observable(
      (observer: Observer<ISportClubModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `/SportClubs/Update`,
        })
    );

  public deleteClub = (data: ISportClubModel): Observable<ISportClubModel> =>
    new Observable(
      (observer: Observer<ISportClubModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id: data.Id },
          },
          method: HTTP_METHOD.DELETE,
          url: `/SportClubs/Delete`,
        })
    );
}
