import { DatePicker, Form } from "@bms/common-ui";
import { TimeHelper } from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { ISportTeamPlayerFormFieldCommonProps } from "./ISportTeamPlayerFormFieldCommonProps";

interface IDateFromFieldProps extends ISportTeamPlayerFormFieldCommonProps {}

export const DateFromField = ({ isEditMode, player }: IDateFromFieldProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name="DateFrom"
      label={t("MODEL_DATE_FROM")}
      key="DateFrom"
      initialValue={
        isEditMode && player?.DateFrom ? new Date(player?.DateFrom) : undefined
      }
      rules={[
        {
          type: "object",
          message: t("REQUIRED_VALIDATION_MESSAGE"),
        },
        {
          validator: (_, value) => {
            if (!value) {
              return Promise.resolve();
            }
            return TimeHelper.isBeforeCurrent(value, "day")
              ? Promise.resolve()
              : Promise.reject(t("DATE_FROM_VALIDATION_MESSAGE"));
          },
        },
      ]}
    >
      <DatePicker
        placeholder={t("MODEL_DATE_FROM_PLACEHOLDER")}
        style={{ width: "100%" }}
      />
    </Form.Item>
  );
};
