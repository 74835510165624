import { ISportMatchModel, ISportTeamModel } from "@bms/common-services";
import { Form, IFormValues, useSendable, useSyncedState } from "@bms/common-ui";
import { pick } from "lodash";
import { useEffect, useMemo } from "react";
import {
  FieldType,
  formLayouts,
  getSectionsFields,
  ISection,
} from "./MatchesFormUtils";

export interface IMatchesFormProps {
  isEditMode: boolean;
  matches?: ISportMatchModel;
  section?: ISection;
  hiddenFields?: FieldType[];
  onSubmit?: (data: ISportMatchModel) => void;
}

export type SyncedTeam = {
  Id?: string | number;
  Name?: string;
  Stadium?: string;
};

export const useMatchesFormController = ({
  isEditMode,
  matches,
  section,
  onSubmit,
}: IMatchesFormProps) => {
  const [form] = Form.useForm();
  const { onFieldsChange, resetSendable, setDirty, sendable } = useSendable();

  const formLayout = useMemo(
    () => (isEditMode ? formLayouts.formItem : formLayouts.modalFormItem),
    [isEditMode]
  );

  const [homeTeam, setHomeTeam] = useSyncedState<SyncedTeam>(
    () => ({
      Id: matches?.HomeTeamId,
      Name: matches?.HomeTeamName,
    }),
    [matches]
  );

  const [awayTeam, setAwayTeam] = useSyncedState<SyncedTeam>(
    () => ({
      Id: matches?.AwayTeamId,
      Name: matches?.AwayTeamName,
    }),
    [matches]
  );

  const formFieldNames = useMemo(() => {
    const everySectionFields: FieldType[] = [
      "HomeTeamId",
      "AwayTeamId",
      "StartDateTime",
      "EndDateTime",
      "Stadium",
      "HomeTeamScore",
      "AwayTeamScore",
      "CreatedBy",
    ];

    if (section) {
      return getSectionsFields()[section];
    }

    return everySectionFields;
  }, [section]);

  const onFinish = async (_values: IFormValues) => {
    const values = { ..._values };
    const picked: any = {};

    const parserMapper: IFormValues = {};

    formFieldNames
      .filter((it) => parserMapper[it] !== undefined)
      .forEach((it) => (picked[it] = parserMapper[it]));

    const matchesDetails: ISportMatchModel = {
      ...matches,
      ...values,
      ...picked,
      ...(homeTeam.Id && { HomeTeamId: Number(homeTeam.Id) }),
      ...(awayTeam.Id && { AwayTeamId: Number(awayTeam.Id) }),
      ...(homeTeam.Stadium && { Stadium: homeTeam.Stadium }),
      ...(values.HomeTeamScore && {
        HomeTeamScore: Number(values.HomeTeamScore),
      }),
      ...(values.AwayTeamScore && {
        AwayTeamScore: Number(values.AwayTeamScore),
      }),
    };

    onSubmit?.(matchesDetails);
  };

  const onHomeTeamSelect = (row?: ISportTeamModel) => {
    setHomeTeam({
      Id: row?.Id,
      Name: row?.Name,
      Stadium: row?.Stadium,
    });
    setDirty();
  };

  const onAwayTeamSelect = (row?: ISportTeamModel) => {
    setAwayTeam({
      Id: row?.Id,
      Name: row?.Name,
      Stadium: row?.Stadium,
    });
    setDirty();
  };

  const onHomeTeamClear = () => onHomeTeamSelect();
  const onAwayTeamClear = () => onAwayTeamSelect();

  useEffect(() => {
    resetSendable();
    form.resetFields();
  }, [JSON.stringify(pick(matches, formFieldNames))]);

  useEffect(() => {
    if (!homeTeam) {
      return;
    }
    form.setFieldValue("HomeTeamId", `${homeTeam.Name}`);
  }, [homeTeam]);

  useEffect(() => {
    if (!awayTeam) {
      return;
    }
    form.setFieldValue("AwayTeamId", `${awayTeam.Name}`);
  }, [awayTeam]);
  return {
    formLayout,
    formFieldNames,
    sendable,
    form,
    onFieldsChange,
    onFinish,
    onHomeTeamSelect,
    onAwayTeamSelect,
    onHomeTeamClear,
    onAwayTeamClear,
    homeTeam,
    awayTeam,
  };
};
