import {
  IPeopleModel,
  ISportPlayerModel,
  IUserModel,
} from "@bms/common-services";
import { Form, IFormValues, useSendable, useSyncedState } from "@bms/common-ui";
import { pick } from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
  FieldType,
  formLayouts,
  getSectionsFields,
  ISection,
} from "./PlayerFormUtils";

export interface IPlayerFormProps {
  isEditMode: boolean;
  player?: ISportPlayerModel;
  section?: ISection;
  hiddenFields?: FieldType[];
  onSubmit?: (data: ISportPlayerModel) => void;
}

export type SyncedPerson = {
  Id?: string | number;
  FirstName?: string;
  LastName?: string;
};

export const usePlayerFormController = ({
  isEditMode,
  player,
  section,
  onSubmit,
}: IPlayerFormProps) => {
  const [form] = Form.useForm();
  const { onFieldsChange, resetSendable, setDirty, sendable } = useSendable();

  const [userCreator, setUserCreator] = useSyncedState<{
    Id?: string | number;
    FullName?: string | number;
  }>(
    () => ({
      Id: player?.CreatedBy,
      FullName: player?.CreatedByFullName,
    }),
    [player]
  );

  const [person, setPerson] = useSyncedState<SyncedPerson>(
    () => ({
      Id: player?.PersonId,
      FirstName: player?.FirstName,
      LastName: player?.LastName,
    }),
    [player]
  );

  const formLayout = useMemo(
    () => (isEditMode ? formLayouts.formItem : formLayouts.modalFormItem),
    [isEditMode]
  );

  const formFieldNames = useMemo(() => {
    const everySectionFields: FieldType[] = [
      "FirstName",
      "LastName",
      "PositionId",
      "AgeCategoryId",
      "ShirtNumber",
      "CreatedBy",
    ];

    if (section) {
      return getSectionsFields()[section];
    }

    return everySectionFields;
  }, [section]);

  const onFinish = async (_values: IFormValues) => {
    const values = { ..._values };
    const picked: any = {};

    const parserMapper: IFormValues = {};

    formFieldNames
      .filter((it) => parserMapper[it] !== undefined)
      .forEach((it) => (picked[it] = parserMapper[it]));

    if (person.Id) {
      delete values.FirstName;
      delete values.LastName;
    }

    const playerDetails: ISportPlayerModel = {
      ...player,
      ...values,
      ...picked,
      CreatedBy: userCreator.Id,
      CreatedByFullName: userCreator.FullName,
      ...(person.Id && { PersonId: person.Id }),
      ...(values.PositionId && { PositionId: Number(values.PositionId.value) }),
      ...(values.AgeCategoryId && {
        AgeCategoryId: Number(values.AgeCategoryId.value),
      }),
    };

    onSubmit?.(playerDetails);
  };

  const onPersonSelect = (person?: IPeopleModel) => {
    setPerson({
      Id: person?.Id,
      FirstName: person?.FirstName,
      LastName: person?.LastName,
    });
    setDirty();
  };

  const onPersonClear = () => onPersonSelect();

  const onUserSelect = (row?: IUserModel) => {
    setUserCreator({ Id: row?.Id, FullName: row?.FullName || row?.Id });
    setDirty();
  };

  const onUserClear = () => onUserSelect();

  useEffect(() => {
    resetSendable();
    form.resetFields();
  }, [JSON.stringify(pick(player, formFieldNames))]);

  useEffect(() => {
    if (!person) {
      return;
    }
    form.setFieldsValue({
      FirstName: person.FirstName,
      LastName: person.LastName,
    });
  }, [person]);

  return {
    formLayout,
    formFieldNames,
    sendable,
    form,
    person,
    onFieldsChange,
    onPersonClear,
    onPersonSelect,
    onFinish,
    userCreator,
    onUserSelect,
    onUserClear,
  };
};
