import {
  IApplicationComponentLayoutModel,
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  Identifier,
  PropertyType,
  RecordStatus,
  ConvertHelper,
  PropertyTypeHelper,
  DataProvider,
} from "@bms/common-services";
import { ComponentLayoutPropertyModel } from "./ComponentLayoutPropertyModel";
import { ComponentPropertyModel } from "./ComponentPropertyModel";
import { LayoutOptionsModel } from "./LayoutOptionsModel";

export class EpgComponentPropertyModel extends ComponentPropertyModel {
  SourceId?: Identifier;

  SourceName?: string;

  Layout?: ComponentLayoutPropertyModel;

  IsVisible: boolean = true;

  constructor(component?: IApplicationComponentModel) {
    super();

    if (component) {
      this.init(component);
    }
  }

  public getLayoutProperty(
    component: IApplicationComponentModel,
    layoutOptions: LayoutOptionsModel
  ): ComponentLayoutPropertyModel {
    if (!component.Layouts) {
      component.Layouts = [];
    }

    let componentLayout = component.Layouts.find(
      (row: IApplicationComponentLayoutModel) =>
        row.PlatformCode === layoutOptions.Platform
    );

    let height = 1;

    if (!componentLayout) {
      componentLayout = {
        RecordStatus: RecordStatus.Inserted,
        ApplicationComponentId: component.Id,
        PlatformCode: layoutOptions.Platform,
        PositionX: 0,
        PositionY: layoutOptions.CurrentPositionY,
        Width: 12,
        Height: height,
      };

      component.Layouts.push(componentLayout);
    }

    componentLayout.Width = 12;

    if (componentLayout.Height !== height) {
      componentLayout.Height = height;
    }

    return {
      PositionX: componentLayout.PositionX,
      PositionY: componentLayout.PositionY,
      Width: componentLayout.Width,
      Height: componentLayout.Height,
      IsResizable: false,
      IsDraggable: true,
    };
  }

  setProperties(component: IApplicationComponentModel): void {
    const isNewlyCreatedComponent =
      !Boolean(component.Properties?.length) && component.Id < 0;

    let sourceIdProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "SourceId"
    );

    if (sourceIdProperty && sourceIdProperty.Value) {
      switch (PropertyTypeHelper.getValue(sourceIdProperty.PropertyType)) {
        case PropertyType.Integer:
          this.SourceId = sourceIdProperty.Value.IntegerValue;
          break;
        default:
          this.SourceId = sourceIdProperty.Value.StringValue;
          break;
      }
    }

    let sourceNameProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "SourceName"
    );

    if (sourceNameProperty && sourceNameProperty.Value) {
      this.SourceName = sourceNameProperty.Value.StringValue;
    }

    const isVisibleProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "IsVisible"
    );

    if (
      isVisibleProperty &&
      isVisibleProperty.Value &&
      typeof isVisibleProperty.Value.BooleanValue === "boolean"
    ) {
      this.IsVisible = isVisibleProperty.Value.BooleanValue;
    } else if (isNewlyCreatedComponent) {
      // Attach default visibility property to new component.
      this.onPropertyChange("IsVisible", component);
    }
  }

  setProperty(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "SourceId":
        property.PropertyType = property.PropertyType =
          PropertyTypeHelper.getValue(
            DataProvider.ApplicationConfiguration?.Options.IdentifierType
          ) ?? PropertyType.Integer;
        property.IsEnumerable = false;
        break;
      case "SourceName":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "IsVisible":
        property.PropertyType = PropertyType.Boolean;
        property.IsEnumerable = false;
        break;
    }
  }

  setPropertyValue(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "SourceId":
        if (property.Value) {
          switch (PropertyTypeHelper.getValue(property.PropertyType)) {
            case PropertyType.Integer:
              property.Value.IntegerValue = ConvertHelper.toNumber(
                this.SourceId
              );
              break;
            default:
              property.Value.StringValue = ConvertHelper.toString(
                this.SourceId
              );
              break;
          }
        }
        break;
      case "SourceName":
        if (property.Value) {
          property.Value.StringValue = this.SourceName;
        }
        break;
      case "IsVisible":
        if (property.Value) {
          property.Value.BooleanValue = this.IsVisible;
        }
        break;
    }
  }
}
