import { useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { PAGE_PARAM_NAME } from "./useSearchParamsPagination";

type IFilterSchemaType =
  | "string"
  | "strings"
  | "number"
  | "numbers"
  | "boolean";

export interface IFiltersSchema {
  [key: string]: IFilterSchemaType;
}

export type ICurrentFilters<T extends IFiltersSchema> = {
  [P in keyof T]?: T[P] extends "string"
    ? string
    : T[P] extends "strings"
    ? string[]
    : T[P] extends "number"
    ? number
    : T[P] extends "numbers"
    ? number[]
    : T[P] extends "boolean"
    ? boolean
    : never;
};

export type TTranslateToFilterSchemaValue<T> = T extends string
  ? "string"
  : T extends string[]
  ? "strings"
  : T extends number
  ? "number"
  : T extends number[]
  ? "numbers"
  : T extends boolean
  ? "boolean"
  : never;

export type TAvailableFilterValues =
  | boolean
  | number
  | number[]
  | string
  | string[];

export type ICurrentArrayFilters<T extends IFiltersSchema> = {
  [P in keyof T]?: T[P] extends "string" | "strings" | "number" | "numbers"
    ? string[] | null
    : T[P] extends "boolean"
    ? ("true" | "false")[] | null
    : never;
};

export type ICurrentOrArrrayFilters<T extends IFiltersSchema> = {
  [P in keyof T]?: ICurrentFilters<T>[P] | ICurrentArrayFilters<T>[P];
};

export type IFiltersUpdater<T extends IFiltersSchema> = (
  data: Partial<ICurrentFilters<T>>
) => ICurrentOrArrrayFilters<T>;

const parseParams = <T extends IFiltersSchema>(
  searchString: string,
  schema: T
) => {
  const searchParams = new URLSearchParams(searchString);
  const result: ICurrentFilters<T> = {};
  for (let key in schema) {
    const rawValues = searchParams.getAll(key);
    const rawValue = rawValues[0];
    let value;

    switch (schema[key]) {
      case "boolean":
        if (rawValue !== undefined) {
          if (rawValue === "true" || rawValue === "") {
            value = true;
          } else if (rawValues[0] === "false") {
            value = false;
          }
        }
        break;
      case "string":
        if (rawValue !== undefined) {
          value = rawValue;
        }
        break;
      case "strings":
        value = rawValues.length > 0 ? rawValues : undefined;
        break;
      case "number":
        if (rawValue !== undefined) {
          value = parseInt(rawValue);
          if (!Number.isSafeInteger(value)) {
            value === undefined;
          }
        }
        break;
      case "numbers":
        value = rawValues
          .map((raw) => parseInt(raw))
          .filter((parsed) => Number.isSafeInteger(parsed));
        if (value.length === 0) {
          value = undefined;
        }
        break;
    }
    result[key] = value as any;
  }
  return result;
};

const applyParams = <T extends IFiltersSchema>(
  schema: T,
  data: ICurrentOrArrrayFilters<T>,
  searchParamsInstance: URLSearchParams
) => {
  for (let key in schema) {
    searchParamsInstance.delete(key);
    const value = data[key];
    if (value === undefined || value === null) {
      continue;
    }
    switch (schema[key]) {
      case "boolean":
        if (Array.isArray(value)) {
          const hasTrue = (value as any[]).includes("true");
          const hasFalse = (value as any[]).includes("false");
          if ((hasTrue && !hasFalse) || (!hasTrue && hasFalse)) {
            searchParamsInstance.append(key, hasTrue ? "true" : "false");
          }
        } else {
          searchParamsInstance.append(key, value ? "true" : "false");
        }
        break;
      case "string":
      case "number":
        searchParamsInstance.append(
          key,
          `${Array.isArray(value) ? value[0] : value}`
        );
        break;
      case "strings":
      case "numbers":
        (value as string[]).forEach((item) =>
          searchParamsInstance.append(key, `${item}`)
        );
        break;
    }
  }
};

export const useSearchParamsFilters = <T extends IFiltersSchema>(
  schema: T,
  useLocalState?: boolean
) =>
  useLocalState
    ? useSearchParamsFiltersUsingLocalState(schema)
    : useSearchParamsFiltersUsingURLSearch(schema);

export const useSearchParamsFiltersUsingURLSearch = <T extends IFiltersSchema>(
  schema: T
) => {
  const location = useLocation();
  const history = useHistory();

  const onUpdate = useCallback(
    (searchParamsInstance: URLSearchParams) => {
      history.push({
        ...history.location,
        search: searchParamsInstance.toString(),
      });
    },
    [history]
  );

  return useSearchParamsFiltersInternals(schema, location.search, onUpdate);
};

export const useSearchParamsFiltersUsingLocalState = <T extends IFiltersSchema>(
  schema: T
) => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const onUpdate = useCallback((searchParamsInstance: URLSearchParams) => {
    setSearchQuery(searchParamsInstance.toString());
  }, []);

  return useSearchParamsFiltersInternals(schema, searchQuery, onUpdate);
};

const useSearchParamsFiltersInternals = <T extends IFiltersSchema>(
  schema: T,
  locationSearch: string,
  onUpdate: (search: URLSearchParams) => void
) => {
  const current = parseParams(locationSearch, schema);

  return useMemo(() => {
    const update = (updater: IFiltersUpdater<T>) => {
      const data = parseParams(locationSearch, schema);
      const updatedData = updater(data);
      const searchParamsInstance = new URLSearchParams(locationSearch);
      applyParams(schema, updatedData, searchParamsInstance);
      const search = searchParamsInstance.toString();
      if (search !== locationSearch) {
        searchParamsInstance.delete(PAGE_PARAM_NAME);
        onUpdate(searchParamsInstance);
      }
    };

    const clear = () => update(() => ({}));

    return {
      current,
      update,
      clear,
      get asTableArray(): ICurrentArrayFilters<IFiltersSchema> {
        let result: any = {};
        for (let key in schema) {
          const value = current[key];
          if (value === undefined || value === null) {
            result[key] = null;
          } else if (Array.isArray(current[key])) {
            result[key] = (current[key] as any[]).map((item: any) => `${item}`);
          } else {
            result[key] = [`${current[key]}`];
          }
        }
        return result as ICurrentArrayFilters<IFiltersSchema>;
      },
      get anyActive() {
        return Object.values(current).some((item) =>
          Array.isArray(item) ? item.length > 0 : item !== undefined
        );
      },
    };
  }, [JSON.stringify(current), onUpdate]);
};
