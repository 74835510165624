export enum InternalUserProfileCode {
  ADMINISTRATOR = "ADMINISTRATOR",
  SYS_APPLICATIONS = "SYS_APPLICATIONS",
  ANONYMOUS = "ANONYMOUS",
}

export const INTERNAL_USERS_PROFILE_CODES = [
  InternalUserProfileCode.SYS_APPLICATIONS.valueOf(),
  InternalUserProfileCode.ANONYMOUS.valueOf(),
];
