import { Form, Input } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IClubFormFieldCommonProps } from "./IClubFormFieldCommonProps";

export const DescriptionField = ({
  club,
  isEditMode,
  isHidden,
}: IClubFormFieldCommonProps) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="Description"
      label={t("MODEL_DESCRIPTION")}
      key="Description"
      initialValue={isEditMode ? club?.Description || "" : ""}
    >
      <Input placeholder={t("MODEL_DESCRIPTION_PLACEHOLDER")} />
    </Form.Item>
  );
};
