import { Button, Col, Form, Icon, Input, required, Row } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IPlayerFormFieldCommonProps } from "./IPlayerFormFieldCommonProps";
import { IPeopleModel } from "@bms/common-services";
import { useState } from "react";
import { SyncedPerson } from "../usePlayerFormController";
import { PeopleBrowserModal } from "../../../../../People/components";

interface IFirstNameFieldProps extends IPlayerFormFieldCommonProps {
  onSelect?: (row?: IPeopleModel) => void;
  onClear?: () => void;
  person: SyncedPerson;
}

export const FirstNameField = ({
  isHidden,
  isEditMode,
  player,
  onSelect,
  onClear,
  person,
}: IFirstNameFieldProps) => {
  const { t } = useTranslation();

  const [personBrowserVisible, setPersonBrowserVisible] = useState(false);

  if (isHidden) {
    return null;
  }

  const onPersonSelect = (row: IPeopleModel) => {
    setPersonBrowserVisible(false);
    onSelect?.(row);
  };

  return (
    <Form.Item
      name="FirstName"
      label={t("MODEL_FIRST_NAME")}
      key="firstName"
      initialValue={(isEditMode ? player?.FirstName : "") || ""}
      rules={[required()]}
    >
      <Row gutter={8}>
        <Col flex="auto">
          <Input
            placeholder={t("MODEL_FIRST_NAME_PLACEHOLDER")}
            disabled={!!person.FirstName}
            value={person.FirstName}
          />
          <PeopleBrowserModal
            visible={personBrowserVisible}
            onCancel={() => setPersonBrowserVisible(false)}
            onSelect={onPersonSelect}
          />
        </Col>
        <Col style={{ textAlign: "right" }}>
          {person.Id && (
            <Button
              icon={<Icon type="delete" />}
              onClick={onClear}
              style={{ marginRight: "8px" }}
            />
          )}
          <Button
            icon={<Icon type="edit" />}
            onClick={() => setPersonBrowserVisible(true)}
          />
        </Col>
      </Row>
    </Form.Item>
  );
};
