import { DatePicker, Form } from "@bms/common-ui";
import { ITEM_NAMES } from "../constants";
import { useTranslation } from "react-i18next";
import { TimeHelper } from "@bms/common-services";

export const ValidToItem = () => {
  const [t] = useTranslation();

  return (
    <Form.Item
      name={ITEM_NAMES.VALID_TO}
      label={t("PAYMENT_VOUCHER_VALID_TO_COLUMN")}
      rules={[
        {
          validator: (_, pickedData: string) => {
            if (pickedData && TimeHelper.isBeforeCurrent(pickedData)) {
              return Promise.reject(
                new Error(
                  t("PAYMENT_VOUCHER_FORM_VALID_TO_RANGE_VALIDATION_MESSAGE")
                )
              );
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <DatePicker
        showToday={true}
        showTime
        style={{ width: `100%` }}
        disabledDate={(current) =>
          current.isBefore(
            TimeHelper.getDateWithOffset(TimeHelper.getCurrentDate(), 1, "hour")
          )
        }
      />
    </Form.Item>
  );
};
