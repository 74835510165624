import {
  IBreadcrumbProps,
  PageHeader,
  PageContent,
  Spin,
  Button,
  Icon,
  Tabs,
  useSearchParamsTabs,
  TabPane,
} from "@bms/common-ui";
import { generateBreadcrumb } from "../../../../helpers";
import { ROUTES } from "../../constants";

import { useParams } from "react-router";
import { usePeopleDetailsController } from "./usePeopleDetailsController";
import { DetailsTab, LocalizationTab } from "./tabs";

export const PeopleDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [activeTabKey] = useSearchParamsTabs("DETAILS");

  const {
    refresh,
    updatePeople,
    onBackClick,
    onDeleteClick,
    t,
    loading,
    updatePeopleState,
    people,
    dataPager,
    dataPagerLoading,
  } = usePeopleDetailsController();

  const personName =
    people?.FirstName || people?.LastName
      ? `${people?.FirstName} ${people?.LastName}`
      : "";

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.PEOPLE_LIST}`,
        breadcrumbName: t("MENU_CMS_PEOPLE_LIST"),
      },
      {
        path: `${ROUTES.PEOPLE_LIST}/${id}`,
        breadcrumbName: personName ?? t("MENU_CMS_PEOPLE_DETAILS"),
      },
    ]);
  };

  return (
    <Spin spinning={loading || dataPagerLoading}>
      <PageContent>
        <PageHeader
          title={personName ?? t("MENU_CMS_PEOPLE_DETAILS")}
          onBack={onBackClick}
          breadcrumb={getBreadcrumbProps()}
          extra={
            <>
              {dataPager.enabled && (
                <>
                  <Button
                    key="action-move-previous"
                    icon={<Icon type="arrow-left" />}
                    onClick={dataPager.movePrevious}
                    title={t("BUTTON_MOVE_PREVIOUS")}
                    shape="circle"
                    disabled={!dataPager.movePreviousEnabled}
                  />
                  <Button
                    key="action-move-next"
                    icon={<Icon type="arrow-right" />}
                    title={t("BUTTON_MOVE_NEXT")}
                    onClick={dataPager.moveNext}
                    shape="circle"
                    disabled={!dataPager.moveNextEnabled}
                  />
                  <Button
                    key="action-reload"
                    icon={<Icon type="reload" />}
                    onClick={refresh}
                    title={t("BUTTON_REFRESH_TITLE")}
                    shape="circle"
                  />
                  <Button
                    danger
                    key="action-delete"
                    icon={<Icon type="delete" />}
                    title={t("BUTTON_DELETE")}
                    onClick={onDeleteClick}
                    shape="circle"
                  />
                </>
              )}
            </>
          }
        />
        <Tabs activeKey={activeTabKey} destroyInactiveTabPane>
          <TabPane key="DETAILS" tab={t("PEOPLE_DETAILS_TAB_DETAILS")}>
            <DetailsTab
              updatePeopleState={updatePeopleState}
              updatePeople={updatePeople}
              people={people}
            />
          </TabPane>
          <TabPane
            key="LOCALIZATION"
            tab={t("PEOPLE_DETAILS_TAB_LOCALIZATION")}
          >
            <LocalizationTab
              updatePeopleState={updatePeopleState}
              updatePeople={updatePeople}
              people={people}
            />
          </TabPane>
        </Tabs>
      </PageContent>
    </Spin>
  );
};
