import { AssetImageType, PlatformType } from "../enums";

export class AssetImageTypeHelper {
  static getValue(
    value?: string,
    defaultValue: AssetImageType = AssetImageType.Frame
  ): AssetImageType {
    switch (value) {
      case "COVER":
        return AssetImageType.Cover;
      case "FRAME":
        return AssetImageType.Frame;
      case "HIGHLIGHTS":
        return AssetImageType.Highlights;
      case "SQUARE":
        return AssetImageType.Square;
      case "BACKGROUND":
        return AssetImageType.Background;
      case "ROUND":
        return AssetImageType.Round;
      case "LANDSCAPE":
        return AssetImageType.HeroLandscape;
      default:
        return defaultValue;
    }
  }

  static getSuggestedResolution(
    platform: PlatformType,
    assetImageType: AssetImageType
  ): { width: number; height: number; ratioW: number; ratioH: number } {
    const resolutions: {
      [key in AssetImageType]?: {
        [key in PlatformType]?: {
          width: number;
          height: number;
          ratioW: number;
          ratioH: number;
        };
      };
    } = {
      [AssetImageType.Cover]: {
        [PlatformType.Any]: { width: 480, height: 640, ratioW: 3, ratioH: 4 },
        [PlatformType.AndroidPhone]: {
          width: 120,
          height: 0,
          ratioW: 3,
          ratioH: 4,
        },
        [PlatformType.AndroidTablet]: {
          width: 0,
          height: 0,
          ratioW: 3,
          ratioH: 4,
        },
        [PlatformType.iOSPhone]: {
          width: 120,
          height: 0,
          ratioW: 3,
          ratioH: 4,
        },
        [PlatformType.iPad]: { width: 0, height: 0, ratioW: 3, ratioH: 4 },
        [PlatformType.AndroidTV]: { width: 0, height: 0, ratioW: 2, ratioH: 3 },
        [PlatformType.AppleTV]: { width: 0, height: 0, ratioW: 9, ratioH: 16 },
        [PlatformType.Tizen]: { width: 288, height: 480, ratioW: 3, ratioH: 5 },
        [PlatformType.WebOS]: { width: 288, height: 480, ratioW: 3, ratioH: 5 },
        [PlatformType.Roku]: { width: 0, height: 0, ratioW: 340, ratioH: 485 },
        [PlatformType.Web]: { width: 480, height: 640, ratioW: 3, ratioH: 4 },
      },
      [AssetImageType.Frame]: {
        [PlatformType.Any]: { width: 720, height: 405, ratioW: 16, ratioH: 9 },
        [PlatformType.AndroidPhone]: {
          width: 150,
          height: 0,
          ratioW: 16,
          ratioH: 9,
        },
        [PlatformType.AndroidTablet]: {
          width: 0,
          height: 0,
          ratioW: 16,
          ratioH: 9,
        },
        [PlatformType.iOSPhone]: {
          width: 150,
          height: 0,
          ratioW: 16,
          ratioH: 9,
        },
        [PlatformType.iPad]: { width: 0, height: 0, ratioW: 16, ratioH: 9 },
        [PlatformType.AndroidTV]: {
          width: 640,
          height: 360,
          ratioW: 16,
          ratioH: 9,
        },
        [PlatformType.AppleTV]: { width: 0, height: 0, ratioW: 16, ratioH: 9 },
        [PlatformType.Tizen]: {
          width: 504,
          height: 284,
          ratioW: 126,
          ratioH: 71,
        },
        [PlatformType.WebOS]: {
          width: 504,
          height: 284,
          ratioW: 126,
          ratioH: 71,
        },
        [PlatformType.Roku]: { width: 0, height: 0, ratioW: 356, ratioH: 323 },
        [PlatformType.Web]: { width: 720, height: 405, ratioW: 16, ratioH: 9 },
      },
      [AssetImageType.Highlights]: {
        [PlatformType.Any]: { width: 1920, height: 720, ratioW: 16, ratioH: 6 },
        [PlatformType.AndroidPhone]: {
          width: 0,
          height: 0,
          ratioW: 16,
          ratioH: 6,
        },
        [PlatformType.AndroidTablet]: {
          width: 0,
          height: 0,
          ratioW: 16,
          ratioH: 6,
        },
        [PlatformType.iOSPhone]: { width: 0, height: 0, ratioW: 16, ratioH: 6 },
        [PlatformType.iPad]: { width: 0, height: 0, ratioW: 16, ratioH: 6 },
        [PlatformType.AndroidTV]: {
          width: 1920,
          height: 720,
          ratioW: 16,
          ratioH: 6,
        },
        [PlatformType.AppleTV]: {
          width: 1800,
          height: 576,
          ratioW: 25,
          ratioH: 8,
        },
        [PlatformType.Tizen]: {
          width: 1920,
          height: 720,
          ratioW: 16,
          ratioH: 6,
        },
        [PlatformType.WebOS]: {
          width: 1920,
          height: 720,
          ratioW: 16,
          ratioH: 6,
        },
        [PlatformType.Roku]: {
          width: 1670,
          height: 576,
          ratioW: 835,
          ratioH: 288,
        },
        [PlatformType.Web]: { width: 1920, height: 720, ratioW: 16, ratioH: 6 },
      },
      [AssetImageType.Square]: {
        [PlatformType.Any]: { width: 450, height: 450, ratioW: 1, ratioH: 1 },
        [PlatformType.AndroidPhone]: {
          width: 150,
          height: 150,
          ratioW: 1,
          ratioH: 1,
        },
        [PlatformType.AndroidTablet]: {
          width: 0,
          height: 0,
          ratioW: 1,
          ratioH: 1,
        },
        [PlatformType.iOSPhone]: {
          width: 150,
          height: 150,
          ratioW: 1,
          ratioH: 1,
        },
        [PlatformType.iPad]: { width: 0, height: 0, ratioW: 1, ratioH: 1 },
        [PlatformType.AndroidTV]: {
          width: 640,
          height: 640,
          ratioW: 1,
          ratioH: 1,
        },
        [PlatformType.AppleTV]: { width: 0, height: 0, ratioW: 1, ratioH: 1 },
        [PlatformType.Tizen]: { width: 233, height: 233, ratioW: 1, ratioH: 1 },
        [PlatformType.WebOS]: { width: 233, height: 233, ratioW: 1, ratioH: 1 },
        [PlatformType.Roku]: { width: 0, height: 0, ratioW: 1, ratioH: 1 },
        [PlatformType.Web]: { width: 450, height: 450, ratioW: 1, ratioH: 1 },
      },
      [AssetImageType.Round]: {
        [PlatformType.Any]: { width: 450, height: 450, ratioW: 1, ratioH: 1 },
        [PlatformType.AndroidPhone]: {
          width: 150,
          height: 150,
          ratioW: 1,
          ratioH: 1,
        },
        [PlatformType.AndroidTablet]: {
          width: 0,
          height: 0,
          ratioW: 1,
          ratioH: 1,
        },
        [PlatformType.iOSPhone]: {
          width: 150,
          height: 150,
          ratioW: 1,
          ratioH: 1,
        },
        [PlatformType.iPad]: { width: 0, height: 0, ratioW: 1, ratioH: 1 },
        [PlatformType.AndroidTV]: {
          width: 640,
          height: 640,
          ratioW: 1,
          ratioH: 1,
        },
        [PlatformType.AppleTV]: { width: 0, height: 0, ratioW: 1, ratioH: 1 },
        [PlatformType.Tizen]: { width: 288, height: 288, ratioW: 1, ratioH: 1 },
        [PlatformType.WebOS]: { width: 288, height: 288, ratioW: 1, ratioH: 1 },
        [PlatformType.Roku]: { width: 0, height: 0, ratioW: 1, ratioH: 1 },
        [PlatformType.Web]: { width: 450, height: 450, ratioW: 1, ratioH: 1 },
      },
      [AssetImageType.HeroLandscape]: {
        [PlatformType.Any]: {
          width: 1280,
          height: 240,
          ratioW: 16,
          ratioH: 3,
        },
        [PlatformType.AndroidPhone]: {
          width: 0,
          height: 0,
          ratioW: 0,
          ratioH: 0,
        },
        [PlatformType.AndroidTablet]: {
          width: 0,
          height: 0,
          ratioW: 0,
          ratioH: 0,
        },
        [PlatformType.iOSPhone]: { width: 0, height: 0, ratioW: 0, ratioH: 0 },
        [PlatformType.iPad]: { width: 0, height: 0, ratioW: 0, ratioH: 0 },
        [PlatformType.AndroidTV]: { width: 0, height: 0, ratioW: 0, ratioH: 0 },
        [PlatformType.AppleTV]: { width: 0, height: 0, ratioW: 0, ratioH: 0 },
        [PlatformType.Tizen]: { width: 0, height: 0, ratioW: 0, ratioH: 0 },
        [PlatformType.WebOS]: { width: 0, height: 0, ratioW: 0, ratioH: 0 },
        [PlatformType.Roku]: {
          width: 0,
          height: 0,
          ratioW: 0,
          ratioH: 0,
        },
        [PlatformType.Web]: {
          width: 1280,
          height: 240,
          ratioW: 16,
          ratioH: 3,
        },
      },
      [AssetImageType.Background]: {
        [PlatformType.Any]: {
          width: 1920,
          height: 1080,
          ratioW: 16,
          ratioH: 9,
        },
        [PlatformType.AndroidPhone]: {
          width: 0,
          height: 0,
          ratioW: 16,
          ratioH: 9,
        },
        [PlatformType.AndroidTablet]: {
          width: 0,
          height: 0,
          ratioW: 16,
          ratioH: 9,
        },
        [PlatformType.iOSPhone]: { width: 0, height: 0, ratioW: 16, ratioH: 9 },
        [PlatformType.iPad]: { width: 0, height: 0, ratioW: 16, ratioH: 9 },
        [PlatformType.AndroidTV]: {
          width: 1920,
          height: 1080,
          ratioW: 16,
          ratioH: 9,
        },
        [PlatformType.AppleTV]: {
          width: 1800,
          height: 576,
          ratioW: 25,
          ratioH: 8,
        },
        [PlatformType.Tizen]: {
          width: 1920,
          height: 1080,
          ratioW: 16,
          ratioH: 9,
        },
        [PlatformType.WebOS]: {
          width: 1920,
          height: 1080,
          ratioW: 16,
          ratioH: 9,
        },
        [PlatformType.Roku]: {
          width: 1670,
          height: 576,
          ratioW: 835,
          ratioH: 288,
        },
        [PlatformType.Web]: {
          width: 1920,
          height: 1080,
          ratioW: 16,
          ratioH: 9,
        },
      },
    };

    let resolution = resolutions[assetImageType]?.[platform];

    if (!resolution) {
      resolution = resolutions[assetImageType]?.[PlatformType.Any];
    }

    return resolution || { width: 0, height: 0, ratioW: 0, ratioH: 0 };
  }
}
