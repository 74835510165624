import {
  AssetStore,
  ComponentType,
  dispatch,
  GuidHelper,
  IApplicationComponentModel,
  IApplicationComponentPropertyValueModel,
  IApplicationComponentTypeModel,
  ICommonAppState,
  IErrorModel,
  PlatformType,
  RecordStatus,
} from "@bms/common-services";
import {
  Button,
  Empty,
  Heading,
  Icon,
  ITableColumnProps,
  ITableRowSelection,
  Modal,
  SectionGrid,
  SectionGridItem,
  Sider,
  TableWithDraggableSorter,
  useAppFeedback,
} from "@bms/common-ui";
import React, {
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationScreenContext } from "../../context";
import { ComponentTypeHelper, PlatformTypeHelper } from "../../../../helpers";
import { DesignerModule } from "../../modules";
import {
  getItemsPropertyValuesFromComponent,
  getUndeletedComponents,
  getValuesFromItemsProperty,
  sortComponentsBySequence,
} from "./utils";

import "./ApplicationSectionMenuItemsModal.scss";

export interface IApplicationSectionMenuItemAddModalProps {
  isLoadingData?: boolean;
  modalVisible: boolean;
  onItemsModalCancel: SetStateAction<any>;
  addComponentModalVisible?: boolean;
}

export const ApplicationSectionMenuItemsModal = ({
  ...props
}: IApplicationSectionMenuItemAddModalProps) => {
  const [hasChanges, setHasChanges] = useState(false);

  const [sectionMenuItems, setSectionMenuItems] = useState<
    IApplicationComponentPropertyValueModel[]
  >([]);

  const [selectedMenuItem, setSelectedMenuItem] = useState<
    IApplicationComponentPropertyValueModel | undefined
  >();

  const { screen, component, onNewChanges } = useContext(
    ApplicationScreenContext
  );

  const { onItemsModalCancel, modalVisible, isLoadingData } = props;

  const { t } = useTranslation();
  const { modal } = useAppFeedback();

  const getComponentsTypes = useSelector(
    (state: ICommonAppState) => state.applicationConfiguration.componentsTypes
  );

  useEffect(() => {
    const components = getComponents();

    if (!selectedMenuItem) {
      setSelectedMenuItem(components[0]);
    }

    setSectionMenuItems(components);
    setHasChanges(false);
  }, [hasChanges]);

  useEffect(() => {
    dispatch(
      AssetStore.Actions.searchAssetCategories({
        PageNumber: 1,
        PageSize: 999,
      })
    );
  }, []);

  const getComponents = (): IApplicationComponentPropertyValueModel[] => {
    if (sectionMenuItems.length === 0 && component) {
      let components: IApplicationComponentPropertyValueModel[] = [];

      let getItemsProperty = getItemsPropertyValuesFromComponent(component);

      if (getItemsProperty) {
        components = getValuesFromItemsProperty(getItemsProperty);
      }

      let sortedComponents = sortComponentsBySequence(components) || components;
      setSectionMenuItems(sortedComponents || []);

      return sortedComponents;
    } else {
      return sortComponentsBySequence(sectionMenuItems);
    }
  };

  const onComponentAdd = (itemComponent: IApplicationComponentModel) => {
    if (!component || !component.Properties || !screen) {
      return;
    }

    if (!screen.Components) {
      screen.Components = [];
    }

    const newComponent: IApplicationComponentModel = {
      ...itemComponent,
    };

    const getPropertyValues = [
      ...(component.Properties.find((component) => component.Name === "Items")
        ?.Values || []),
    ];

    const minId =
      getPropertyValues.length > 0
        ? getPropertyValues.reduce(
            (min, component) => (component.Id < min ? component.Id : min),
            getPropertyValues[0].Id
          )
        : 1;

    newComponent.Id = minId >= 0 ? -1 : minId - 1;
    newComponent.ApplicationConfigurationId = screen.ApplicationConfigurationId;
    newComponent.IsVisible = true;
    newComponent.RecordStatus = RecordStatus.Inserted;
    newComponent.Sequence =
      getPropertyValues.length > 0
        ? Math.max(
            ...getPropertyValues.map((c) => {
              return c.Component ? c.Component.Sequence + 1 : 0;
            })
          )
        : 0;

    if (!newComponent.Guid) {
      newComponent.Guid = GuidHelper.newGuid();
    }

    if (!newComponent.PlatformCode) {
      newComponent.PlatformCode = PlatformType.Any;
    }

    const findHighestSequencyValue =
      sectionMenuItems.length > 0
        ? [...sectionMenuItems]
            .map((item) => item.ApplicationComponentPropertyValueSequence)
            .reduce((acc, val) => {
              if (acc && val) {
                return acc > val ? acc : val;
              } else return 0;
            })
        : 0;

    setSectionMenuItems((prevState) => [
      ...prevState,
      {
        Id: minId,
        Guid: GuidHelper.newGuid(),
        ApplicationComponentPropertyId: component.Id,
        ApplicationComponentPropertyValueSequence:
          findHighestSequencyValue! + 1 || 0,
        RecordStatus: RecordStatus.Inserted,
        Component: newComponent,
      },
    ]);

    if (onNewChanges) {
      onNewChanges(true);
      setHasChanges(true);
    }
  };

  const onTableRow = (component: IApplicationComponentModel) => {
    const updateSelectedComponent = sectionMenuItems.filter(
      (items) => items.Component?.Guid === component.Guid
    )[0];

    return {
      onClick: () => {
        setSelectedMenuItem(updateSelectedComponent);
      },
    };
  };

  const onDeleteComponent = (
    itemToDelete: IApplicationComponentPropertyValueModel
  ) => {
    if (!sectionMenuItems) {
      return;
    }

    if (itemToDelete) {
      if (itemToDelete.RecordStatus === RecordStatus.Inserted) {
        const filterSectionMenu = sectionMenuItems.filter(
          (items) => items.Component?.Guid !== itemToDelete.Guid
        );

        setSectionMenuItems(filterSectionMenu);
      } else {
        const filterSectionMenu = sectionMenuItems.map((items) => {
          if (items.Component) {
            if (items.Component.Id === itemToDelete.Id) {
              items.RecordStatus = RecordStatus.Deleted;
              items.Component.RecordStatus = RecordStatus.Deleted;
            }
            return items;
          }
        });

        setSectionMenuItems(
          filterSectionMenu as IApplicationComponentPropertyValueModel[]
        );
      }
    }

    if (onNewChanges) {
      onNewChanges(true);
      setSelectedMenuItem(undefined);
      setHasChanges(true);
    }
  };

  const onDeleteClick = (item: any) => {
    modal.confirm({
      title: t("CONFIGURATION_BUTTON__DELETE_ITEM"),
      content: t(`CONFIGURATION_BUTTON__DELETE_ITEM_MESSAGE`),
      okText: t("BUTTON_OK"),
      cancelText: t("BUTTON_CANCEL"),
      onOk: () => onDeleteComponent(item),
    });
  };

  const getColumnsProps = (): Array<
    ITableColumnProps<IApplicationComponentModel>
  > => {
    const { t } = useTranslation();

    return [
      {
        key: "Name",
        dataIndex: "Name",
        title: t("CONFIGURATION_COMPONENT__NAME"),
        render: (text: any) => text,
      },
      {
        key: "ComponentTypeCode",
        dataIndex: "ComponentTypeCode",
        title: t("CONFIGURATION_COMPONENT__TYPE"),
        render: (_: any, row: IApplicationComponentModel) =>
          ComponentTypeHelper.getTag(row.ComponentTypeCode),
      },
      {
        key: "RemoveItemAction",
        dataIndex: "RemoveItemAction",
        title: t("CONFIGURATION_COMPONENT__ACTION"),
        width: "80px",
        render: (_: any, row: IApplicationComponentModel) => (
          <Button
            danger={true}
            icon={<Icon type="delete" />}
            title={t("DELETE_ITEM")}
            onClick={() => onDeleteClick(row)}
          />
        ),
      },
    ];
  };

  const onMoveRow = (dragIndex: number, hoverIndex: number) => {
    const components = [...sectionMenuItems];

    if (!components) {
      return;
    }

    const draggedComponent = components[dragIndex];
    const hoveredComponent = components[hoverIndex];

    if (!draggedComponent || !hoveredComponent) {
      return;
    }

    let componentsToUpdate: IApplicationComponentPropertyValueModel[] = [
      {
        ...draggedComponent,
        ApplicationComponentPropertyValueSequence:
          draggedComponent.ApplicationComponentPropertyValueSequence ===
          hoveredComponent.ApplicationComponentPropertyValueSequence
            ? hoveredComponent.ApplicationComponentPropertyValueSequence! + 1
            : hoveredComponent.ApplicationComponentPropertyValueSequence,
        RecordStatus:
          draggedComponent.RecordStatus === RecordStatus.Inserted
            ? RecordStatus.Inserted
            : RecordStatus.Updated,
      },
    ];

    // Update components order - required to render list before save
    if (
      draggedComponent.ApplicationComponentPropertyValueSequence! >
      hoveredComponent.ApplicationComponentPropertyValueSequence!
    ) {
      componentsToUpdate = componentsToUpdate.concat(
        components
          .filter(
            (c) =>
              c.ApplicationComponentPropertyValueSequence! >=
                hoveredComponent.ApplicationComponentPropertyValueSequence! &&
              c.ApplicationComponentPropertyValueSequence! <
                draggedComponent.ApplicationComponentPropertyValueSequence!
          )
          .map((i) => {
            i.ApplicationComponentPropertyValueSequence!++;
            i.RecordStatus =
              i.RecordStatus === RecordStatus.Inserted
                ? RecordStatus.Inserted
                : RecordStatus.Updated;
            return i;
          })
      );
    } else {
      componentsToUpdate = componentsToUpdate.concat(
        components
          .filter(
            (c) =>
              c.ApplicationComponentPropertyValueSequence! <=
                hoveredComponent.ApplicationComponentPropertyValueSequence! &&
              c.ApplicationComponentPropertyValueSequence! >
                draggedComponent.ApplicationComponentPropertyValueSequence!
          )
          .map((i) => {
            i.ApplicationComponentPropertyValueSequence!--;
            i.RecordStatus =
              i.RecordStatus === RecordStatus.Inserted
                ? RecordStatus.Inserted
                : RecordStatus.Updated;
            return i;
          })
      );
    }

    componentsToUpdate.reduce(
      (
        previous: IApplicationComponentPropertyValueModel,
        current: IApplicationComponentPropertyValueModel,
        index: number
      ) => {
        if (
          componentsToUpdate[index]
            .ApplicationComponentPropertyValueSequence ===
          previous.ApplicationComponentPropertyValueSequence
        ) {
          componentsToUpdate[index].ApplicationComponentPropertyValueSequence =
            componentsToUpdate[index]
              .ApplicationComponentPropertyValueSequence! + 1;
        }
        return current;
      }
    );

    onComponentsChange(componentsToUpdate);
  };

  if (!screen) {
    return <Empty>{t("CONFIGURATION_SCREEN__NO_SCREEN_DATA_MESSAGE")}</Empty>;
  }

  const columns = getColumnsProps();

  const componentsToView = getUndeletedComponents(
    sectionMenuItems
  ) as IApplicationComponentModel[];

  const rowSelection: ITableRowSelection<IApplicationComponentModel> = {
    onChange: (_: React.Key[], selectedRows: IApplicationComponentModel[]) => {
      if (selectedRows.length > 0) {
        const selectedComponent = selectedRows[0];

        const updateSelectedComponent = sectionMenuItems.filter(
          (items) => items.Component?.Guid === selectedComponent.Guid
        )[0];

        setSelectedMenuItem(updateSelectedComponent);
      }
    },
    type: "radio",
    selectedRowKeys: [
      sectionMenuItems.findIndex((c) => c.Guid == selectedMenuItem?.Guid),
    ],
  };

  const addSectionMenuItem = () => {
    const componentType = getComponentsTypes.find(
      (row: IApplicationComponentTypeModel) =>
        row.Code === ComponentType.SectionMenuItem
    );

    if (!componentType) {
      return;
    }

    return new Promise<IApplicationComponentModel>((resolve, reject) => {
      const component: IApplicationComponentModel = {
        Id: -1,
        Guid: GuidHelper.newGuid(),
        Name: componentType.Name,
        ComponentTypeCode: ComponentTypeHelper.getValue(componentType.Code),
        PlatformCode: PlatformType.Any,
        Sequence: 0,
        RecordStatus: RecordStatus.Inserted,
      };
      resolve(component);
    })
      .then((component: IApplicationComponentModel) => {
        onComponentAdd(component);
      })
      .catch((error: IErrorModel) => {});
  };

  const saveChangesOnClose = () => {
    if (onComponentChange && component?.Properties) {
      let newComponent = component?.Properties.find(
        (component) => component.Name === "Items"
      );

      if (newComponent && newComponent.Values) {
        newComponent.Values = sectionMenuItems;

        return onComponentChange(component);
      }
    }
  };

  const zeroWidthTriggerStyle: React.CSSProperties = {
    top: "60px",
    height: "33px",
    fontSize: "18px",
    lineHeight: "33px",
  };

  const onComponentsChange = (
    components: IApplicationComponentPropertyValueModel[]
  ) => {
    let sectionMenuItemsCopy = [...sectionMenuItems];

    for (const component of components) {
      const componentIndex = sectionMenuItems.findIndex(
        (row) => row?.Guid === component.Guid
      );

      if (componentIndex >= 0) {
        sectionMenuItemsCopy[componentIndex] = component;
      }
    }

    if (sectionMenuItemsCopy) {
      if (onNewChanges) {
        setSectionMenuItems(sectionMenuItemsCopy);
        setHasChanges(true);
        onNewChanges(true);
      }
    }
  };

  const onComponentChange = (component: IApplicationComponentModel) => {
    const componentIndex = sectionMenuItems.findIndex((row) => {
      if (row.Component) return row?.Component.Guid === component.Guid;
    });

    if (componentIndex >= 0) {
      let componentToUpdate = sectionMenuItems[componentIndex];

      if (onNewChanges) {
        setSelectedMenuItem(componentToUpdate);
        setHasChanges(true);
        onNewChanges(true);
      }
    }
  };

  const sider = useCallback(
    (selectedMenuItem: IApplicationComponentPropertyValueModel | undefined) => {
      if (!selectedMenuItem?.Component) {
        return <></>;
      }

      let componentTypeTag: React.ReactNode;

      if (component && component.RecordStatus !== RecordStatus.Deleted) {
        componentTypeTag = ComponentTypeHelper.getTag(
          selectedMenuItem?.Component?.ComponentTypeCode,
          {
            marginLeft: "16px",
          }
        );
      }

      return (
        <Sider
          className="ApplicationSectionMenuItems__Properties"
          collapsed={!selectedMenuItem}
          reverseArrow={true}
          collapsedWidth={0}
          width={440}
          zeroWidthTriggerStyle={zeroWidthTriggerStyle}
        >
          <SectionGrid>
            <SectionGridItem>
              <Heading
                title={
                  <>
                    {t("CONFIGURATION_PROPERTIES__TITLE")}
                    {componentTypeTag}
                  </>
                }
              />
              <DesignerModule.Components.ApplicationSectionMenuItemProperties
                key={`SectionMenuItemProperties-${selectedMenuItem.Component.Guid}`}
                component={selectedMenuItem.Component}
                onComponentChange={onComponentChange}
              />
            </SectionGridItem>
          </SectionGrid>
        </Sider>
      );
    },
    [selectedMenuItem, hasChanges]
  );

  return (
    <Modal
      style={{
        right: selectedMenuItem ? "220px" : "",
      }}
      title={t("CONFIGURATION_ITEMS__MODAL_TITLE")}
      onCancel={() => {
        onItemsModalCancel();
        saveChangesOnClose();
      }}
      width="700px"
      visible={modalVisible}
      footer={
        <>
          <Button
            key="cancel"
            onClick={() => {
              onItemsModalCancel();
              saveChangesOnClose();
            }}
          >
            {t("BUTTON_CANCEL")}
          </Button>
        </>
      }
    >
      <div className="SectionMenuItemAddForm">
        <div className="SectionMenuItemAddForm__Content">
          <div className="SectionMenuItemAddForm__Table-header">
            <div className="title">
              <h1>{t("CONFIGURATION_SECTION_MENU__ITEMS_LIST_TITLE")}</h1>
            </div>
            <div className="actions">
              <Button
                icon={<Icon type="plus" />}
                onClick={addSectionMenuItem}
                title={t("CONFIGURATION_BUTTON__ADD_ITEM")}
              />
            </div>
          </div>
          <TableWithDraggableSorter<IApplicationComponentModel>
            dragType="handler"
            columns={columns}
            dataSource={componentsToView}
            loading={isLoadingData}
            rowSelection={rowSelection}
            onRow={onTableRow}
            onMoveRow={onMoveRow}
          />
        </div>
      </div>
      {sider(selectedMenuItem)}
    </Modal>
  );
};
