import { ITEM_NAMES } from "../constants";
import { useTranslation } from "react-i18next";
import { VoucherRedeemType } from "@bms/common-services";
import { Form, IFormInstance, InputNumber } from "@bms/common-ui";

interface IRedemptionLimitItemProps {
  form: IFormInstance;
}

export const RedemptionLimitItem = ({ form }: IRedemptionLimitItemProps) => {
  const [t] = useTranslation();
  const redeemType = Form.useWatch(ITEM_NAMES.REDEEM_TYPE, form);

  if (redeemType === VoucherRedeemType.OneTime) {
    return null;
  }

  return (
    <Form.Item
      name={ITEM_NAMES.REDEMPTION_LIMIT}
      label={t("PAYMENT_VOUCHER_REDEMPTION_LIMIT_COLUMN")}
    >
      <InputNumber
        placeholder={t("PAYMENT_VOUCHER_FORM_REDEMPTION_LIMIT_PLACEHOLDER")}
        style={{ width: "100%" }}
        step={1}
        min={0}
        precision={0}
      />
    </Form.Item>
  );
};
