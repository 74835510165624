import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { ISportMatchTeamLineupModel } from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { PromisifiableBase } from "../Base";

export class SportMatchTeamLineupService extends PromisifiableBase {
  public getMatchTeamLineups = (
    matchId?: number,
    teamId?: number
  ): Observable<ISportMatchTeamLineupModel[]> =>
    new Observable(
      (observer: Observer<ISportMatchTeamLineupModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `/SportMatchTeamLineup/Select`,
          axiosConfig: {
            params: { matchId, teamId },
          },
        })
    );

  public getMatchTeamLineup = (
    id: number
  ): Observable<ISportMatchTeamLineupModel> =>
    new Observable(
      (observer: Observer<ISportMatchTeamLineupModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id },
          },
          method: HTTP_METHOD.GET,
          url: `/SportMatchTeamLineup/Get`,
        })
    );

  public createMatchTeamLineup = (
    data: ISportMatchTeamLineupModel
  ): Observable<ISportMatchTeamLineupModel> =>
    new Observable(
      (observer: Observer<ISportMatchTeamLineupModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/SportMatchTeamLineup/Insert`,
        })
    );

  public updateMatchTeamLineup = (
    data: ISportMatchTeamLineupModel
  ): Observable<ISportMatchTeamLineupModel> =>
    new Observable(
      (observer: Observer<ISportMatchTeamLineupModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `/SportMatchTeamLineup/Update`,
        })
    );

  public deleteMatchTeamLineup = (
    data: ISportMatchTeamLineupModel
  ): Observable<ISportMatchTeamLineupModel> =>
    new Observable(
      (observer: Observer<ISportMatchTeamLineupModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id: data.Id },
          },
          method: HTTP_METHOD.DELETE,
          url: `/SportMatchTeamLineup/Delete`,
        })
    );
}
