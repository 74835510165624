import React from "react";
import { useTranslation } from "react-i18next";
import { VoucherRedemptionList } from "../../VoucherRedemptionList";
import { IVoucherModel } from "@bms/common-services";

interface IRedemptionsTabProps {
  voucher: IVoucherModel;
}

export const RedemptionsTab = ({ voucher }: IRedemptionsTabProps) => {
  const { t } = useTranslation();

  return (
    <VoucherRedemptionList
      title={t("PAYMENT_VOUCHER_DETAILS_TAB_REDEMPTIONS_TITLE")}
      voucherId={voucher?.Id}
    />
  );
};
