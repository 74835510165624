import React, { useMemo } from "react";
import {
  PageContent,
  Pagination,
  PageHeader,
  IBreadcrumbProps,
  useAppFeedback,
  InputSearch,
  Tooltip,
  Button,
  Icon,
  Table,
  ITableFilter,
  Link,
  Tag,
  TagUpToDate,
  ITableColumnProps,
  TTranslateToFilterSchemaValue,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import {
  ISearchFilterModelBase,
  IUserSubscriptionsModel,
  IUserSubscriptionsSearchFilterModel,
  TimeHelper,
  UserSubscriptionsService,
} from "@bms/common-services";
import { useTableDataProvider } from "../../../../helpers";
import { FilterCleanIcon } from "./../../../../resources/icons";
import { ROUTES as COMMON_ROUTES } from "../../constants";
import { ProductModule } from "../../../";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import "./UserSubscriptionsTable.scss";

const userSubscriptionsService = new UserSubscriptionsService().promisify();

const filtersSchema: TSubscriptionListFilterSchema = {
  FullTextSearch: "string",
  UpToDate: "boolean",
  UserId: "number",
  AssetId: "number",
};

type IFilterModelWithoutPagination = Omit<
  IUserSubscriptionsSearchFilterModel,
  keyof ISearchFilterModelBase
> &
  Pick<ISearchFilterModelBase, "FullTextSearch">;

type TSubscriptionListFilterSchema = {
  [K in keyof Required<IFilterModelWithoutPagination>]: Required<
    TTranslateToFilterSchemaValue<IFilterModelWithoutPagination[K]>
  >;
};

interface IUserSubscriptionsTableProps {
  tableTitle?: string;
  userId?: number;
  assetId?: number;
  getBreadcrumbProps?: () => IBreadcrumbProps;
}

export const UserSubscriptionsTable = ({
  tableTitle,
  userId,
  assetId,
  getBreadcrumbProps,
}: IUserSubscriptionsTableProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { notification, modal } = useAppFeedback();

  const {
    dataLoader,
    filters,
    pagination,
    setFullTextSearch,
    fullTextSearch,
  } = useTableDataProvider({
    filtersSchema,
    debounce: 800,
    loader: (filters, pagination) =>
      userSubscriptionsService.getSubscriptionsList({
        ...filters,
        ...pagination,
        UserId: userId,
        AssetId: assetId,
      }),
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
    deps: [],
  });

  const extraItems = useMemo(() => {
    const onSearch = (text: string) => {
      filters.update((oldFilters) => ({
        ...oldFilters,
        FullTextSearch: text || undefined,
      }));
    };

    const onSearchInputChange = (el: React.ChangeEvent<HTMLInputElement>) => {
      const text = el.target.value;
      setFullTextSearch(text);
    };

    return [
      <InputSearch
        key="SubscriptionListHeaderSearchInput"
        placeholder={t("SEARCH_PLACEHOLDER", "Search...")}
        value={fullTextSearch}
        onSearch={onSearch}
        onChange={onSearchInputChange}
        style={{ width: 250 }}
        allowClear
      />,
      <Tooltip
        key="clear-filter-tooltip"
        overlay={t("MENU_OPTION_CLEAR_FILTERS", "")}
      >
        <Button
          key="SubscriptionListHeaderClearBtn"
          shape="circle"
          icon={<FilterCleanIcon />}
          onClick={filters.clear}
          title={t("BUTTON_RESET")}
        />
      </Tooltip>,
      <Button
        key="SubscriptionListHeaderRefreshBtn"
        shape="circle"
        icon={<Icon type="reload" />}
        onClick={dataLoader.refresh}
        title={t("BUTTON_REFRESH_TITLE")}
      />,
    ];
  }, [
    dataLoader.refresh,
    fullTextSearch,
    setFullTextSearch,
    filters.clear,
    filters.update,
  ]);

  const columns = useMemo<
    Array<ITableColumnProps<IUserSubscriptionsModel>>
  >(() => {
    const columnSubscriptionKey: ITableColumnProps<IUserSubscriptionsModel> = {
      key: "SubscriptionKey",
      dataIndex: "SubscriptionKey",
      title: t("MODEL_KEY"),
      className: "SubscriptionKey columnWithEllipsis",
      ellipsis: true,
      render: (_: string, row) => <>{row.SubscriptionKey}</>,
    };

    const columnSubscriptionProduct: ITableColumnProps<IUserSubscriptionsModel> = {
      key: "SubscriptionProduct",
      dataIndex: "AssetTitle",
      title: t("USER_SUBSCRIPTIONS_PRODUCT_COLUMN"),
      className: "ProductColumn columnWithEllipsis",
      ellipsis: true,
      render: (_: string, row) => (
        <Link to={`${ProductModule.ROUTES.PRODUCTS_DETAILS}/${row.AssetId}`}>
          {row.AssetTitle}
        </Link>
      ),
    };

    const columnUserName: ITableColumnProps<IUserSubscriptionsModel> = {
      key: "UserName",
      dataIndex: "UserName",
      title: t("MODEL_PURCHASED_BY"),
      render: (_: string, row) => (
        <Link to={`${COMMON_ROUTES.USER_DETAILS}/${row.UserId}`}>
          <p>{row.UserName}</p>
        </Link>
      ),
    };

    const columnUserEmail: ITableColumnProps<IUserSubscriptionsModel> = {
      key: "UserEmail",
      dataIndex: "UserEmail",
      title: t("MODEL_EMAIL"),
      render: (_: string, row) => <>{row.UserEmail}</>,
    };

    const columnActive: ITableColumnProps<IUserSubscriptionsModel> = {
      key: "Active",
      dataIndex: "Active",
      title: t("USER_SUBSCRIPTIONS_ACTIVE_COLUMN"),
      render: (_: string, row) => (
        <Tag colorRotate={Number(row.Active)}>
          {row.Active
            ? t("USER_SUBSCRIPTIONS_ACTIVE_COLUMN_ACTIVE_FILTER")
            : t("USER_SUBSCRIPTIONS_ACTIVE_COLUMN_INACTIVE_FILTER")}
        </Tag>
      ),
    };
    const columnUpToDate: ITableColumnProps<IUserSubscriptionsModel> = {
      key: "UpToDate",
      dataIndex: "UpToDate",
      title: t("MODEL_UP_TO_DATE"),
      render: (_: string, row) => <TagUpToDate value={row.UpToDate} />,
      filters: [
        {
          text: t("CLASS_LIST_TABLE_UPTODATE_COLUMN_FILTER"),
          value: true,
        },
        {
          text: t("CLASS_LIST_TABLE_OUTTODATE_COLUMN_FILTER"),
          value: false,
        },
      ],
      filteredValue: filters.asTableArray.UpToDate || null,
      filterMultiple: false,
    };

    const columnCreated: ITableColumnProps<IUserSubscriptionsModel> = {
      key: "Created",
      dataIndex: "Created",
      title: t("MODEL_CREATED"),
      render: (_: string, row) => TimeDisplay(row.Created),
    };

    const columnEndDate: ITableColumnProps<IUserSubscriptionsModel> = {
      key: "EndDate",
      dataIndex: "EndDate",
      title: t("MODEL_END_DATE_TIME"),
      render: (_: string, row) => TimeDisplay(row.EndDate),
    };

    const columnCancelled: ITableColumnProps<IUserSubscriptionsModel> = {
      key: "Cancelled",
      dataIndex: "Cancelled",
      title: t("MODEL_CANCELLED"),
      render: (_: string, row) => TimeDisplay(row.Cancelled),
    };

    const columnLastError: ITableColumnProps<IUserSubscriptionsModel> = {
      key: "LastError",
      dataIndex: "LastError",
      title: t("MODEL_LAST_ERROR"),
      align: "center",
      render: (_: string, row) =>
        row.LastError ? (
          <Button
            icon={<InfoCircleOutlined />}
            title={t("BUTTON_ERROR_INFO_TITLE")}
            onClick={() => {
              modal.error({
                title: t("MODEL_LAST_ERROR"),
                icon: <ExclamationCircleOutlined />,
                content: row.LastError,
              });
            }}
          />
        ) : null,
    };

    if (assetId) {
      return [
        columnSubscriptionKey,
        columnUserName,
        columnUserEmail,
        columnActive,
        columnUpToDate,
        columnCreated,
        columnEndDate,
        columnCancelled,
        columnLastError,
      ];
    }

    if (userId) {
      return [
        columnSubscriptionKey,
        columnSubscriptionProduct,
        columnActive,
        columnUpToDate,
        columnCreated,
        columnEndDate,
        columnCancelled,
        columnLastError,
      ];
    }

    return [
      columnSubscriptionKey,
      columnSubscriptionProduct,
      columnUserName,
      columnUserEmail,
      columnActive,
      columnUpToDate,
      columnCreated,
      columnEndDate,
      columnCancelled,
      columnLastError,
    ];
  }, [filters, language, assetId, userId]);

  const handleTableChange = (_: unknown, tableFilters: ITableFilter) => {
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...{ UpToDate: tableFilters.UpToDate?.[0] },
    }));
  };

  return (
    <PageContent
      className="SubscriptionList"
      footer={<Pagination {...pagination.props} />}
    >
      <PageHeader
        title={tableTitle}
        extra={extraItems}
        breadcrumb={getBreadcrumbProps?.()}
      />
      <Table<IUserSubscriptionsModel>
        rowKey={(row) => `${row.SubscriptionKey}${row.Id}`}
        columns={columns}
        dataSource={dataLoader.data?.Entities}
        loading={dataLoader.loading}
        pagination={false}
        tableLayout="auto"
        onChange={handleTableChange}
        className="SubscriptionListTable"
      />
    </PageContent>
  );
};

function TimeDisplay(timeString: string | undefined) {
  if (!timeString) {
    return null;
  }
  const [datePart, timePart] = TimeHelper.format(timeString).split(" ");
  return (
    <>
      <span className="nowrapOnHyphen">{datePart}</span>{" "}
      <span className="nowrapOnHyphen">{timePart}</span>
    </>
  );
}
