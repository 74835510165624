import { Col, Form, Row } from "@bms/common-ui";
import { useMemo } from "react";
import {
  ISportTeamPlayerFormProps,
  useSportTeamPlayerFormController,
} from "./useSportTeamPlayerController";
import {
  DateFromField,
  DateToField,
  PlayerField,
  ShirtNumberField,
} from "./Fields";

export const SportTeamPlayerForm = (props: ISportTeamPlayerFormProps) => {
  const { isEditMode, player } = props;
  const {
    formLayout,
    formFieldNames,
    form,
    onFieldsChange,
    onFinish,
    genericPlayer,
    onPlayerSelect,
    onPlayerClear,
  } = useSportTeamPlayerFormController(props);

  const formFieldsMapping: { [key: string]: () => JSX.Element } = {
    PlayerField: () => (
      <PlayerField
        key="SportTeamPlayerForm--PlayerField"
        isEditMode={isEditMode}
        genericPlayer={genericPlayer}
        onSelect={onPlayerSelect}
        onClear={onPlayerClear}
      />
    ),
    DateFrom: () => (
      <DateFromField
        key="SportTeamPlayerForm--DateFromField"
        isEditMode={isEditMode}
        player={player}
      />
    ),
    DateTo: () => (
      <DateToField
        key="SportTeamPlayerForm--DateToField"
        isEditMode={isEditMode}
        player={player}
      />
    ),
    ShirtNumber: () => (
      <ShirtNumberField
        key="SportTeamPlayerForm--ShirtNumberField"
        isEditMode={isEditMode}
        player={player}
      />
    ),
  };

  const formFields = useMemo(
    () =>
      formFieldNames.map((field: string) =>
        formFieldsMapping[field] ? formFieldsMapping[field]() : null
      ),
    [formFieldNames, player, genericPlayer]
  );

  return (
    <Form
      {...formLayout}
      form={form}
      name={"SportTeamPlayerForm"}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      className="SportTeamPlayerForm"
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>{formFields}</Col>
      </Row>
    </Form>
  );
};
