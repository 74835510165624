import {
  Link,
  Button,
  Icon,
  ITableFilter,
  PageContent,
  PageHeader,
  Popconfirm,
  Table,
  useAppFeedback,
  ITableColumnProps,
  Tooltip,
  InputSearch,
  Pagination,
  setTableColumnSearchProps,
  Tag,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../constants/routes";
import {
  generateBreadcrumb,
  useTableDataProvider,
} from "../../../../../helpers";
import { IBreadcrumbProps } from "@bms/common-ui";
import {
  ISportTeamModel,
  SportTeamService,
  TimeHelper,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { ROUTES as USER_ROUTES } from "../../../../User/constants";
import { FilterCleanIcon } from "../../../../../resources/icons";
import { useCreateDataPagerSource } from "../../../hooks";
import { useMemo, useState } from "react";
import { RouteComponentProps } from "react-router";
import { AddSportTeamModal } from "../AddSportTeamModal";
import { TeamForm } from "../TeamForm";
import { CountriesService } from "@bms/common-services";

const teamsService = new SportTeamService().promisify();
const countriesService = new CountriesService().promisify();

export const TeamsList = ({ history, location }: RouteComponentProps) => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();
  const [modalOpen, setModalOpen] = useState(false);

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.TEAMS}`,
        breadcrumbName: t("MENU_CMS_TEAMS"),
      },
    ]);
  };

  const countriesLoader = useDataLoader({
    loader: () => countriesService.select(),
    deps: [],
  });

  const countries = useMemo(() => countriesLoader.data ?? [], [
    countriesLoader.data,
  ]);

  const {
    dataLoader: teamsLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      Name: "string",
      City: "string",
      Stadium: "string",
      Countries: "strings",
    },
    loader: (filters, pagination) => {
      return teamsService.search({
        ...filters,
        ...pagination,
      });
    },
    deps: [],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const dataPagerSource = useCreateDataPagerSource(teamsLoader.data, {
    ...filters.current,
    ...pagination.current,
  });

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
    }));

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value,
    }));

  const getColumnsProps = (): Array<ITableColumnProps<ISportTeamModel>> => {
    return [
      {
        key: "Name",
        dataIndex: "Name",
        title: t("MODEL_NAME"),
        render: (_, row: ISportTeamModel) => {
          const teamDetailLocation = {
            pathname: `${ROUTES.TEAM_DETAILS}/${row.Id}`,
            state: {
              from: location,
              dataPagerSource: dataPagerSource,
            },
          };
          return <Link to={teamDetailLocation}>{row.Name}</Link>;
        },
        ...setTableColumnSearchProps("Name", t("MODEL_NAME")),
      },
      {
        key: "City",
        dataIndex: "City",
        title: t("MODEL_CITY"),
        ...setTableColumnSearchProps("City", t("MODEL_CITY")),
      },
      {
        key: "Stadium",
        dataIndex: "Stadium",
        title: t("MODEL_STADIUM"),
        ...setTableColumnSearchProps("Stadium", t("MODEL_STADIUM")),
      },
      {
        key: "ClubName",
        dataIndex: "ClubName",
        title: t("MODEL_CLUB"),
        render: (_, row: ISportTeamModel) => (
          <Link to={`${ROUTES.CLUB_DETAILS}/${row.ClubId}`}>
            {row.ClubName}
          </Link>
        ),
      },
      {
        key: "Countries",
        dataIndex: "Countries",
        width: "200px",
        align: "center",
        title: t("MODEL_COUNTRY_NAME"),
        filters: countries.map((country) => ({
          text: country.Name,
          value: country.Code,
        })),
        filterSearch: true,
        filteredValue: filters.asTableArray.Countries,
        render: (_, row: ISportTeamModel) =>
          row.CountryCode && (
            <Tag
              colorRotate={countries.findIndex(
                ({ Code }) => Code === row.CountryCode
              )}
            >
              {row.CountryName}
            </Tag>
          ),
      },
      {
        key: "FoundationDate",
        dataIndex: "FoundationDate",
        title: t("MODEL_FOUNDATION_DATE"),
        render: (_, row: ISportTeamModel) =>
          row.FoundationDate
            ? TimeHelper.format(row.FoundationDate, "YYYY-MM-DD")
            : null,
      },
      {
        key: "Created",
        dataIndex: "Created",
        title: t("MODEL_CREATED"),
        render: (_, row: ISportTeamModel) =>
          row.Created
            ? TimeHelper.format(row.Created, "YYYY-MM-DD HH:mm")
            : null,
      },
      {
        key: "CreatedBy",
        dataIndex: "CreatedBy",
        title: t("MODEL_CREATED_BY"),
        render: (_, row: ISportTeamModel) => (
          <Link to={`${USER_ROUTES.USER_DETAILS}/${row.CreatedBy}`}>
            {row.CreatedByFullName}
          </Link>
        ),
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_, team: ISportTeamModel) => (
          <>
            <Popconfirm
              title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
              onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
                e?.preventDefault();
                const result = await teamsService.deleteTeam(team!);
                if (result.ok) {
                  await teamsLoader.refresh();
                } else {
                  notification.error({
                    message: t("DELETE_ELEMENT_FAILTURE"),
                    description: result.error?.Message,
                  });
                }
              }}
              okText={t("BUTTON_YES")}
              cancelText={t("BUTTON_NO")}
            >
              <Button
                danger
                icon={<Icon type="delete" />}
                title={t("DELETE_ELEMENT")}
              />
            </Popconfirm>
          </>
        ),
      },
    ];
  };

  const [createTeam, createTeamState] = useServiceCaller(
    async (data: ISportTeamModel) => {
      const result = await teamsService.createTeam(data);
      if (result.ok) {
        setModalOpen(false);
        notification.success({
          message: t("SPORT_TEAM_CREATE_SUCCESS"),
        });
        history.push(`${ROUTES.TEAM_DETAILS}/${result.data.Id}`);
      } else {
        notification.error({
          message: t("SPORT_TEAM_CREATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  return (
    <>
      <AddSportTeamModal
        visible={modalOpen}
        close={() => setModalOpen(false)}
        processing={createTeamState.processing}
      >
        <TeamForm
          isEditMode={false}
          onSubmit={createTeam}
          hiddenFields={["CreatedBy", "Description"]}
        />
      </AddSportTeamModal>
      <PageContent footer={<Pagination {...pagination.props} />}>
        <PageHeader
          title={t("MENU_CMS_TEAMS")}
          breadcrumb={getBreadcrumbProps()}
          extra={
            <>
              <InputSearch
                key="search"
                placeholder={t("SEARCH_PLACEHOLDER")}
                value={fullTextSearch}
                onChange={({ target: { value } }) => setFullTextSearch(value)}
                onSearch={onSearch}
                style={{ width: 250 }}
                allowClear
              />
              <Tooltip overlay={t("MENU_OPTION_CLEAR_FILTERS")}>
                <Button
                  key="clear-filters"
                  shape="circle"
                  icon={<FilterCleanIcon />}
                  onClick={filters.clear}
                  title={t("MENU_OPTION_CLEAR_FILTERS")}
                />
              </Tooltip>
              <Button
                key="reload"
                shape="circle"
                icon={<Icon type="reload" />}
                onClick={teamsLoader.refresh}
                title={t("BUTTON_REFRESH_TITLE")}
              />
              <Button
                key="add"
                shape="circle"
                type="primary"
                icon={<Icon type="plus" />}
                onClick={() => setModalOpen(true)}
                title={t("BUTTON_ADD")}
              />
            </>
          }
        />
        <Table<ISportTeamModel>
          rowKey="Id"
          columns={getColumnsProps()}
          dataSource={teamsLoader.data?.Entities}
          loading={teamsLoader.loading}
          pagination={false}
          onChange={onTableChange}
        />
      </PageContent>
    </>
  );
};
