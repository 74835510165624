import {
  IErrorModel,
  ISportTeamStaffRoleModel,
  SportAgeCategoryModel,
  SportPlayerPositionModel,
  SportStatisticsUnitModel,
} from "../../models";
import {
  ICreateAgeCategoryAction,
  ICreateAgeCategoryFailureAction,
  ICreateAgeCategorySuccessAction,
  IDeleteAgeCategoryAction,
  IDeleteAgeCategoryFailureAction,
  IDeleteAgeCategorySuccessAction,
  IGetAgeCategoriesAction,
  IGetAgeCategoriesFailureAction,
  IUpdateAgeCategoryAction,
  IUpdateAgeCategoryFailureAction,
  IUpdateAgeCategorySuccessAction,
  IGetAgeCategoryAction,
  IGetAgeCategorySuccessAction,
  IGetAgeCategoryFailureAction,
  IGetAgeCategoriesSuccessAction,
  IGetPlayerPositionAction,
  IGetPlayerPositionFailureAction,
  IGetPlayerPositionSuccessAction,
  IGetPlayerPositionsAction,
  IGetPlayerPositionsFailureAction,
  IGetPlayerPositionsSuccessAction,
  ICreatePlayerPositionAction,
  ICreatePlayerPositionFailureAction,
  IUpdatePlayerPositionAction,
  ICreatePlayerPositionSuccessAction,
  IUpdatePlayerPositionSuccessAction,
  IUpdatePlayerPositionFailureAction,
  IDeletePlayerPositionAction,
  IDeletePlayerPositionSuccessAction,
  IGetStaffRoleAction,
  ICreateStaffRoleSuccessAction,
  ICreateStaffRoleFailureAction,
  IUpdateStaffRoleAction,
  IUpdateStaffRoleSuccessAction,
  IGetStaffRolesFailureAction,
  ICreateStaffRoleAction,
  IDeleteStaffRoleAction,
  IUpdateStaffRoleFailureAction,
  IDeleteStaffRoleSuccessAction,
  IGetStaffRolesAction,
  IGetStaffRoleFailureAction,
  IGetStaffRolesSuccessAction,
  IGetStaffRoleSuccessAction,
  IDeletePlayerPositionFailureAction,
  IDeleteStaffRoleFailureAction,
  IGetStatisticsUnitAction,
  IGetStatisticsUnitSuccessAction,
  IGetStatisticsUnitsFailureAction,
  IGetStatisticsUnitsSuccessAction,
  IGetStatisticsUnitsAction,
  IGetStatisticsUnitFailureAction,
  ICreateStatisticsUnitAction,
  ICreateStatisticsUnitSuccessAction,
  ICreateStatisticsUnitFailureAction,
  IUpdateStatisticsUnitAction,
  IUpdateStatisticsUnitSuccessAction,
  IDeleteStatisticsUnitAction,
  IUpdateStatisticsUnitFailureAction,
  IDeleteStatisticsUnitSuccessAction,
  IDeleteStatisticsUnitFailureAction,
} from "./types";
import * as Consts from "./consts";

// #################### AGE CATEGORIES ####################
export const getAgeCategory = (id: number): IGetAgeCategoryAction => {
  return {
    type: Consts.GET_AGE_CATEGORY,
    id,
  };
};

export const getAgeCategorySuccess = (
  data: SportAgeCategoryModel
): IGetAgeCategorySuccessAction => {
  return {
    payload: data,
    type: Consts.GET_AGE_CATEGORY_SUCCESS,
  };
};

export const getAgeCategoryFailure = (
  error?: IErrorModel
): IGetAgeCategoryFailureAction => {
  return {
    error,
    type: Consts.GET_AGE_CATEGORY_FAILURE,
  };
};

export const getAgeCategories = (): IGetAgeCategoriesAction => {
  return {
    type: Consts.GET_AGE_CATEGORIES,
  };
};

export const getAgeCategoriesSuccess = (
  data: SportAgeCategoryModel[]
): IGetAgeCategoriesSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_AGE_CATEGORIES_SUCCESS,
  };
};

export const getAgeCategoriesFailure = (
  error?: IErrorModel
): IGetAgeCategoriesFailureAction => {
  return {
    error,
    type: Consts.GET_AGE_CATEGORIES_FAILURE,
  };
};

export const createAgeCategory = (
  data: SportAgeCategoryModel,
  callback?: () => void
): ICreateAgeCategoryAction => {
  return {
    callback,
    data,
    type: Consts.CREATE_AGE_CATEGORY,
  };
};

export const createAgeCategorySuccess = (
  data: SportAgeCategoryModel
): ICreateAgeCategorySuccessAction => {
  return {
    payload: {
      data,
    },
    type: Consts.CREATE_AGE_CATEGORY_SUCCESS,
  };
};

export const createAgeCategoryFailure = (
  error?: IErrorModel
): ICreateAgeCategoryFailureAction => {
  return {
    error,
    type: Consts.CREATE_AGE_CATEGORY_FAILURE,
  };
};

export const updateAgeCategory = (
  data: SportAgeCategoryModel,
  callback?: () => void
): IUpdateAgeCategoryAction => {
  return {
    callback,
    data,
    type: Consts.UPDATE_AGE_CATEGORY,
  };
};

export const updateAgeCategorySuccess = (
  data: SportAgeCategoryModel
): IUpdateAgeCategorySuccessAction => {
  return {
    payload: {
      data,
    },
    type: Consts.UPDATE_AGE_CATEGORY_SUCCESS,
  };
};

export const updateAgeCategoryFailure = (
  error?: IErrorModel
): IUpdateAgeCategoryFailureAction => {
  return {
    error,
    type: Consts.UPDATE_AGE_CATEGORY_FAILURE,
  };
};

export const deleteAgeCategory = (
  data: SportAgeCategoryModel
): IDeleteAgeCategoryAction => {
  return {
    data,
    type: Consts.DELETE_AGE_CATEGORY,
  };
};

export const deleteAgeCategorySuccess = (): IDeleteAgeCategorySuccessAction => {
  return {
    type: Consts.DELETE_AGE_CATEGORY_SUCCESS,
  };
};

export const deleteAgeCategoryFailure = (
  error?: IErrorModel
): IDeleteAgeCategoryFailureAction => {
  return {
    error,
    type: Consts.DELETE_AGE_CATEGORY_FAILURE,
  };
};

// #################### PLAYER POSITIONS ####################
export const getPlayerPosition = (id: number): IGetPlayerPositionAction => {
  return {
    id,
    type: Consts.GET_PLAYER_POSITION,
  };
};

export const getPlayerPositionSuccess = (
  data: SportPlayerPositionModel
): IGetPlayerPositionSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_PLAYER_POSITION_SUCCESS,
  };
};

export const getPlayerPositionFailure = (
  error?: IErrorModel
): IGetPlayerPositionFailureAction => {
  return {
    error,
    type: Consts.GET_PLAYER_POSITION_FAILURE,
  };
};

export const getPlayerPositions = (): IGetPlayerPositionsAction => {
  return {
    type: Consts.GET_PLAYER_POSITIONS,
  };
};

export const getPlayerPositionsSuccess = (
  data: SportPlayerPositionModel[]
): IGetPlayerPositionsSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_PLAYER_POSITIONS_SUCCESS,
  };
};

export const getPlayerPositionsFailure = (
  error?: IErrorModel
): IGetPlayerPositionsFailureAction => {
  return {
    error,
    type: Consts.GET_PLAYER_POSITIONS_FAILURE,
  };
};

export const createPlayerPosition = (
  data: SportPlayerPositionModel,
  callback?: () => void
): ICreatePlayerPositionAction => {
  return {
    callback,
    data,
    type: Consts.CREATE_PLAYER_POSITION,
  };
};

export const createPlayerPositionSuccess = (
  data: SportPlayerPositionModel
): ICreatePlayerPositionSuccessAction => {
  return {
    payload: {
      data,
    },
    type: Consts.CREATE_PLAYER_POSITION_SUCCESS,
  };
};

export const createPlayerPositionFailure = (
  error?: IErrorModel
): ICreatePlayerPositionFailureAction => {
  return {
    error,
    type: Consts.CREATE_PLAYER_POSITION_FAILURE,
  };
};

export const updatePlayerPosition = (
  data: SportPlayerPositionModel,
  callback?: () => void
): IUpdatePlayerPositionAction => {
  return {
    callback,
    data,
    type: Consts.UPDATE_PLAYER_POSITION,
  };
};

export const updatePlayerPositionSuccess = (
  data: SportPlayerPositionModel
): IUpdatePlayerPositionSuccessAction => {
  return {
    payload: {
      data,
    },
    type: Consts.UPDATE_PLAYER_POSITION_SUCCESS,
  };
};

export const updatePlayerPositionFailure = (
  error?: IErrorModel
): IUpdatePlayerPositionFailureAction => {
  return {
    error,
    type: Consts.UPDATE_PLAYER_POSITION_FAILURE,
  };
};

export const deletePlayerPosition = (
  data: SportPlayerPositionModel
): IDeletePlayerPositionAction => {
  return {
    data,
    type: Consts.DELETE_PLAYER_POSITION,
  };
};

export const deletePlayerPositionSuccess = (): IDeletePlayerPositionSuccessAction => {
  return {
    type: Consts.DELETE_PLAYER_POSITION_SUCCESS,
  };
};

export const deletePlayerPositionFailure = (
  error?: IErrorModel
): IDeletePlayerPositionFailureAction => {
  return {
    error,
    type: Consts.DELETE_PLAYER_POSITION_FAILURE,
  };
};

// #################### STAFF ROLES ####################
export const getStaffRole = (id: number): IGetStaffRoleAction => {
  return {
    id,
    type: Consts.GET_STAFF_ROLE,
  };
};

export const getStaffRoleSuccess = (
  data: ISportTeamStaffRoleModel
): IGetStaffRoleSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_STAFF_ROLE_SUCCESS,
  };
};

export const getStaffRoleFailure = (
  error?: IErrorModel
): IGetStaffRoleFailureAction => {
  return {
    error,
    type: Consts.GET_STAFF_ROLE_FAILURE,
  };
};

export const getStaffRoles = (): IGetStaffRolesAction => {
  return {
    type: Consts.GET_STAFF_ROLES,
  };
};

export const getStaffRolesSuccess = (
  data: ISportTeamStaffRoleModel[]
): IGetStaffRolesSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_STAFF_ROLES_SUCCESS,
  };
};

export const getStaffRolesFailure = (
  error?: IErrorModel
): IGetStaffRolesFailureAction => {
  return {
    error,
    type: Consts.GET_STAFF_ROLES_FAILURE,
  };
};

export const createStaffRole = (
  data: ISportTeamStaffRoleModel,
  callback?: () => void
): ICreateStaffRoleAction => {
  return {
    callback,
    data,
    type: Consts.CREATE_STAFF_ROLE,
  };
};

export const createStaffRoleSuccess = (
  data: ISportTeamStaffRoleModel
): ICreateStaffRoleSuccessAction => {
  return {
    payload: {
      data,
    },
    type: Consts.CREATE_STAFF_ROLE_SUCCESS,
  };
};

export const createStaffRoleFailure = (
  error?: IErrorModel
): ICreateStaffRoleFailureAction => {
  return {
    error,
    type: Consts.CREATE_STAFF_ROLE_FAILURE,
  };
};

export const updateStaffRole = (
  data: ISportTeamStaffRoleModel,
  callback?: () => void
): IUpdateStaffRoleAction => {
  return {
    callback,
    data,
    type: Consts.UPDATE_STAFF_ROLE,
  };
};

export const updateStaffRoleSuccess = (
  data: ISportTeamStaffRoleModel
): IUpdateStaffRoleSuccessAction => {
  return {
    payload: {
      data,
    },
    type: Consts.UPDATE_STAFF_ROLE_SUCCESS,
  };
};

export const updateStaffRoleFailure = (
  error?: IErrorModel
): IUpdateStaffRoleFailureAction => {
  return {
    error,
    type: Consts.UPDATE_STAFF_ROLE_FAILURE,
  };
};

export const deleteStaffRole = (
  data: ISportTeamStaffRoleModel
): IDeleteStaffRoleAction => {
  return {
    data,
    type: Consts.DELETE_STAFF_ROLE,
  };
};

export const deleteStaffRoleSuccess = (): IDeleteStaffRoleSuccessAction => {
  return {
    type: Consts.DELETE_STAFF_ROLE_SUCCESS,
  };
};

export const deleteStaffRoleFailure = (
  error?: IErrorModel
): IDeleteStaffRoleFailureAction => {
  return {
    error,
    type: Consts.DELETE_STAFF_ROLE_FAILURE,
  };
};

// #################### STATISTICS UNITS ####################
export const getStatisticsUnit = (code: string): IGetStatisticsUnitAction => {
  return {
    code,
    type: Consts.GET_STATISTICS_UNIT,
  };
};

export const getStatisticsUnitSuccess = (
  data: SportStatisticsUnitModel
): IGetStatisticsUnitSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_STATISTICS_UNIT_SUCCESS,
  };
};

export const getStatisticsUnitFailure = (
  error?: IErrorModel
): IGetStatisticsUnitFailureAction => {
  return {
    error,
    type: Consts.GET_STATISTICS_UNIT_FAILURE,
  };
};

export const getStatisticsUnits = (): IGetStatisticsUnitsAction => {
  return {
    type: Consts.GET_STATISTICS_UNITS,
  };
};

export const getStatisticsUnitsSuccess = (
  data: SportStatisticsUnitModel[]
): IGetStatisticsUnitsSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_STATISTICS_UNITS_SUCCESS,
  };
};

export const getStatisticsUnitsFailure = (
  error?: IErrorModel
): IGetStatisticsUnitsFailureAction => {
  return {
    error,
    type: Consts.GET_STATISTICS_UNITS_FAILURE,
  };
};

export const createStatisticsUnit = (
  data: SportStatisticsUnitModel,
  callback?: () => void
): ICreateStatisticsUnitAction => {
  return {
    callback,
    data,
    type: Consts.CREATE_STATISTICS_UNIT,
  };
};

export const createStatisticsUnitSuccess = (
  data: SportStatisticsUnitModel
): ICreateStatisticsUnitSuccessAction => {
  return {
    payload: {
      data,
    },
    type: Consts.CREATE_STATISTICS_UNIT_SUCCESS,
  };
};

export const createStatisticsUnitFailure = (
  error?: IErrorModel
): ICreateStatisticsUnitFailureAction => {
  return {
    error,
    type: Consts.CREATE_STATISTICS_UNIT_FAILURE,
  };
};

export const updateStatisticsUnit = (
  data: SportStatisticsUnitModel,
  callback?: () => void
): IUpdateStatisticsUnitAction => {
  return {
    callback,
    data,
    type: Consts.UPDATE_STATISTICS_UNIT,
  };
};

export const updateStatisticsUnitSuccess = (
  data: SportStatisticsUnitModel
): IUpdateStatisticsUnitSuccessAction => {
  return {
    payload: {
      data,
    },
    type: Consts.UPDATE_STATISTICS_UNIT_SUCCESS,
  };
};

export const updateStatisticsUnitFailure = (
  error?: IErrorModel
): IUpdateStatisticsUnitFailureAction => {
  return {
    error,
    type: Consts.UPDATE_STATISTICS_UNIT_FAILURE,
  };
};

export const deleteStatisticsUnit = (
  data: SportStatisticsUnitModel
): IDeleteStatisticsUnitAction => {
  return {
    data,
    type: Consts.DELETE_STATISTICS_UNIT,
  };
};

export const deleteStatisticsUnitSuccess = (): IDeleteStatisticsUnitSuccessAction => {
  return {
    type: Consts.DELETE_STATISTICS_UNIT_SUCCESS,
  };
};

export const deleteStatisticsUnitFailure = (
  error?: IErrorModel
): IDeleteStatisticsUnitFailureAction => {
  return {
    error,
    type: Consts.DELETE_STATISTICS_UNIT_FAILURE,
  };
};

export const Actions = {
  getAgeCategories,
  getAgeCategoriesSuccess,
  getAgeCategoriesFailure,
  createAgeCategory,
  createAgeCategorySuccess,
  createAgeCategoryFailure,
  updateAgeCategory,
  updateAgeCategorySuccess,
  updateAgeCategoryFailure,
  deleteAgeCategory,
  deleteAgeCategorySuccess,
  deleteAgeCategoryFailure,
  getPlayerPosition,
  getPlayerPositionSuccess,
  getPlayerPositionFailure,
  getPlayerPositions,
  getPlayerPositionsSuccess,
  getPlayerPositionsFailure,
  createPlayerPosition,
  createPlayerPositionSuccess,
  createPlayerPositionFailure,
  updatePlayerPosition,
  updatePlayerPositionSuccess,
  updatePlayerPositionFailure,
  deletePlayerPosition,
  deletePlayerPositionSuccess,
  deletePlayerPositionFailure,
  getStaffRole,
  getStaffRoleSuccess,
  getStaffRoleFailure,
  getStaffRoles,
  getStaffRolesSuccess,
  getStaffRolesFailure,
  createStaffRole,
  createStaffRoleSuccess,
  createStaffRoleFailure,
  updateStaffRole,
  updateStaffRoleSuccess,
  updateStaffRoleFailure,
  deleteStaffRole,
  deleteStaffRoleSuccess,
  deleteStaffRoleFailure,
  getStatisticsUnit,
  getStatisticsUnitSuccess,
  getStatisticsUnitFailure,
  getStatisticsUnits,
  getStatisticsUnitsSuccess,
  getStatisticsUnitsFailure,
  createStatisticsUnit,
  createStatisticsUnitSuccess,
  createStatisticsUnitFailure,
  updateStatisticsUnit,
  updateStatisticsUnitSuccess,
  updateStatisticsUnitFailure,
  deleteStatisticsUnit,
  deleteStatisticsUnitSuccess,
  deleteStatisticsUnitFailure,
};
