import {
  ISportMatchTeamLineupModel,
  SportMatchTeamLineupService,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { ICommonMatchDetailsTabProps } from "./ICommonMatchDetailsTabProps";
import { useTranslation } from "react-i18next";
import {
  Button,
  Heading,
  Icon,
  ITableColumnProps,
  Link,
  Popconfirm,
  Table,
  Tag,
  useAppFeedback,
} from "@bms/common-ui";
import { ROUTES } from "../../../../constants";
import { FormModal } from "../../../../../../components";
import { useState } from "react";
import { SportMatchLineupForm } from "../../SportMatchLineupForm";

type Props = ICommonMatchDetailsTabProps & {
  team: {
    id: string | number;
    name: string;
  };
};

const lineupService = new SportMatchTeamLineupService().promisify();

const defaultLineup: ISportMatchTeamLineupModel = {
  Id: -1,
  MatchId: 0,
  TeamId: 0,
  PlayerId: 0,
  PlayerFirstName: "",
  PlayerLastName: "",
  PositionDisplayName: "",
  IsReserve: false,
};

export const LineupTab = ({ match, team }: Props) => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const [showLineupModal, setShowLineupModal] = useState(false);
  const [editableLineup, setEditableLineup] = useState<
    ISportMatchTeamLineupModel
  >(defaultLineup);

  const lineupLoader = useDataLoader({
    loader: () =>
      match?.Id && team?.id
        ? lineupService.getMatchTeamLineups(Number(match?.Id), Number(team?.id))
        : Promise.resolve({
            ok: true,
            data: [],
            error: null,
          }),
    deps: [match?.Id, team?.id],
    onError: (error) => {
      notification.error({
        message: t("TEAM_LINEUP_LOAD_FAILURE"),
        description: error?.Message,
      });
    },
  });

  const getColumnsProps = (): Array<
    ITableColumnProps<ISportMatchTeamLineupModel>
  > => {
    return [
      {
        key: "PlayerFirstName",
        dataIndex: "PlayerFirstName",
        title: t("MODEL_FIRST_NAME"),
        render: (_, row: ISportMatchTeamLineupModel) => {
          return (
            <a
              title={row.PlayerFirstName}
              className="imageUrl"
              onClick={() => {
                setShowLineupModal(true);
                setEditableLineup(row);
              }}
            >
              {row.PlayerFirstName}
            </a>
          );
        },
      },
      {
        key: "PlayerLastName",
        dataIndex: "PlayerLastName",
        title: t("MODEL_LAST_NAME"),
        render: (_, row: ISportMatchTeamLineupModel) => (
          <Link to={`${ROUTES.PLAYER_DETAILS}/${row.PlayerId}`}>
            {row.PlayerLastName}
          </Link>
        ),
      },
      {
        key: "Positions",
        dataIndex: "Positions",
        title: t("MODEL_POSITION_NAME"),
        render: (_, row: ISportMatchTeamLineupModel) => row.PositionDisplayName,
      },
      {
        key: "IsReserve",
        dataIndex: "IsReserve",
        title: t("MODEL_RESERVE"),
        render: (isReserve: boolean) => (
          <Tag color={isReserve ? "blue" : "default"}>
            {isReserve ? t("MODEL_RESERVE") : t("MODEL_ACTIVE")}
          </Tag>
        ),
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_, player: ISportMatchTeamLineupModel) => (
          <div className="people-table__actions">
            <Popconfirm
              title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
              onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
                e?.preventDefault();
                const result = await lineupService.deleteMatchTeamLineup(
                  player!
                );
                if (result.ok) {
                  notification.success({
                    message: t("DELETE_ELEMENT_SUCCESS"),
                  });
                  await lineupLoader.refresh();
                } else {
                  notification.error({
                    message: t("DELETE_ELEMENT_FAILTURE"),
                    description: result.error?.Message,
                  });
                }
              }}
              okText={t("BUTTON_YES")}
              cancelText={t("BUTTON_NO")}
            >
              <Button
                danger
                icon={<Icon type="delete" />}
                title={t("DELETE_ELEMENT")}
              />
            </Popconfirm>
          </div>
        ),
      },
    ];
  };

  const closeModal = () => {
    setShowLineupModal(false);
    setEditableLineup(defaultLineup);
  };

  const [createLineup, createLineupState] = useServiceCaller(
    async (data: ISportMatchTeamLineupModel) => {
      const result = await lineupService.createMatchTeamLineup(data);
      if (result.ok) {
        setShowLineupModal(false);
        notification.success({
          message: t("TEAM_LINEUP_CREATE_SUCCESS"),
        });
        await lineupLoader.refresh();
      } else {
        notification.error({
          message: t("TEAM_LINEUP_CREATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const [updateLineup, updateLineupState] = useServiceCaller(
    async (data: ISportMatchTeamLineupModel) => {
      const result = await lineupService.updateMatchTeamLineup(data);
      if (result.ok) {
        setShowLineupModal(false);
        notification.success({
          message: t("TEAM_LINEUP_UPDATE_SUCCESS"),
        });
        await lineupLoader.refresh();
      } else {
        notification.error({
          message: t("TEAM_LINEUP_UPDATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  return (
    <>
      <FormModal
        isLoading={createLineupState.processing || updateLineupState.processing}
        isVisible={showLineupModal}
        isNewForm={editableLineup.Id < 0}
        isDeleteButtonEnabled={false}
        createFormTitle={t("TEAM_LINEUP_MODAL_NEW", "New lineup")}
        modalClassName="SportMatchLineupForm"
        submitFormName="SportMatchLineupForm"
        onCloseModal={closeModal}
        editFormTitle={t("TEAM_LINEUP_MODAL_EDIT", "Lineup edit")}
      >
        <SportMatchLineupForm
          lineup={editableLineup}
          matchConfig={{
            teamId: Number(team?.id),
            matchId: Number(match?.Id),
          }}
          isProcessing={lineupLoader.loading}
          insertLineup={createLineup}
          updateLineup={updateLineup}
        />
      </FormModal>
      <Heading
        actions={
          <>
            <Button
              key="reload"
              shape="circle"
              icon={<Icon type="reload" />}
              onClick={lineupLoader.refresh}
              title={t("BUTTON_REFRESH_TITLE")}
            />
            <Button
              key="add"
              shape="circle"
              type="primary"
              icon={<Icon type="plus" />}
              onClick={() => {
                setShowLineupModal(true);
                setEditableLineup(defaultLineup);
              }}
              title={t("BUTTON_ADD")}
            />
          </>
        }
      />
      <Table<ISportMatchTeamLineupModel>
        rowKey="Id"
        columns={getColumnsProps()}
        dataSource={lineupLoader.data}
        loading={lineupLoader.loading}
        pagination={false}
      />
    </>
  );
};
