import {
  Button,
  Heading,
  Icon,
  InputSearch,
  ITableFilter,
  ITableRowSelection,
  Modal,
  Pagination,
  Table,
} from "@bms/common-ui";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterCleanIcon } from "../../../../../resources/icons";
import { useTeamsBrowserTableColumns } from "./useTeamsBrowserTableColumns";
import { ISportTeamModel } from "@bms/common-services";

export interface ITeamsBrowserModalProps {
  visible: boolean;
  onCancel?: () => void;
  onSelect?: (row: ISportTeamModel) => void;
}

export const TeamsBrowserModal = memo(
  ({ onCancel, onSelect, visible }: ITeamsBrowserModalProps) => {
    const { t } = useTranslation();
    const [selectedRow, setSelectedRow] = useState<ISportTeamModel>();
    const {
      columns,
      fullTextSearch,
      filters,
      setFullTextSearch,
      teamsLoader,
      pagination,
    } = useTeamsBrowserTableColumns();

    const onTableChange = (_: any, incomingFilters: ITableFilter) =>
      filters.update((oldFilters) => ({
        ...oldFilters,
        ...incomingFilters,
      }));

    const onSearch = (value: string) =>
      filters.update((oldFilters) => ({
        ...oldFilters,
        FullTextSearch: value,
      }));

    const onSelectClick = () => {
      if (onSelect && selectedRow) {
        onSelect(selectedRow);
      }
    };

    const onTableRow = (row: ISportTeamModel, index?: number) => {
      return {
        onClick: () => {
          setSelectedRow(row);
        },
        onDoubleClick: () => {
          if (onSelect) {
            onSelect(row);
            return;
          }
          setSelectedRow(row);
        },
      };
    };

    const rowSelection: ITableRowSelection<ISportTeamModel> = {
      onChange: (_: React.Key[], selectedRows: ISportTeamModel[]) => {
        if (selectedRows.length > 0) {
          setSelectedRow(selectedRows[0]);
        }
      },
      type: "radio",
      selectedRowKeys: selectedRow?.Id ? [selectedRow.Id] : [],
    };

    const hasSelectedRow = selectedRow ? true : false;

    return (
      <>
        <Modal
          title={
            <Heading
              title={t("SPORT_TEAM_MODAL_SELECT_TEAM")}
              actions={[
                <InputSearch
                  key="search"
                  placeholder={t("SEARCH_PLACEHOLDER")}
                  defaultValue={fullTextSearch}
                  onSearch={onSearch}
                  onChange={({ target: { value } }) => setFullTextSearch(value)}
                  style={{ width: 250 }}
                  allowClear
                />,
                <Button
                  key="reload"
                  icon={<Icon type="reload" />}
                  onClick={teamsLoader.refresh}
                  title={t("BUTTON_REFRESH_TITLE")}
                />,
                <Button
                  key="clear"
                  icon={<Icon component={FilterCleanIcon} />}
                  onClick={filters.clear}
                  title={t("MENU_OPTION_CLEAR_FILTERS")}
                />,
              ]}
            />
          }
          onCancel={onCancel}
          visible={visible}
          className="TeamsBrowserModal"
          closable={false}
          centered
          width="70%"
          footer={
            <>
              <Button key="cancel" onClick={onCancel}>
                {t("COMMON_CANCEL")}
              </Button>
              <Button
                key="cancel"
                disabled={!hasSelectedRow}
                onClick={onSelectClick}
              >
                {t("BUTTON_SELECT")}
              </Button>
            </>
          }
        >
          <Table<ISportTeamModel>
            rowKey="Id"
            style={{ minHeight: "40vh" }}
            columns={columns}
            dataSource={teamsLoader.data?.Entities}
            loading={teamsLoader.loading}
            pagination={false}
            onChange={onTableChange}
            rowSelection={rowSelection}
            onRow={onTableRow}
          />
          <Pagination {...pagination.props} />
        </Modal>
      </>
    );
  }
);
