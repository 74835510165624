import { cloneDeep, findIndex } from "lodash";
import { SportAgeCategoryModel } from "../../models";
import * as Consts from "./consts";
import { ISportState, SportActionsTypes } from "./types";

export const initialState: ISportState = {
  ageCategories: {
    Data: [],
    Error: undefined,
    IsProcessing: false,
  },
  pendingDeleteCategory: undefined,
  ageCategory: {},
  isFetching: false,
  isProcessing: false,
  actionType: undefined,
  action: undefined,
  playerPositions: {
    Data: [],
    Error: undefined,
    IsProcessing: false,
  },
  staffRoles: {
    Data: [],
    Error: undefined,
    IsProcessing: false,
  },
  staffRole: {},
  playerPosition: {},
  statisticsUnits: {
    Data: [],
    Error: undefined,
    IsProcessing: false,
  },
  statisticsUnit: {},
};

export const sportReducer = (
  state = initialState,
  action: SportActionsTypes
): ISportState => {
  state.actionType = action.type;
  state.action = action;

  switch (action.type) {
    // #################### AGE CATEGORIES ####################
    case Consts.GET_AGE_CATEGORY: {
      return {
        ...state,
        isFetching: true,
        ageCategory: {
          Data: undefined,
          Error: undefined,
          IsProcessing: true,
        },
      };
    }
    case Consts.GET_AGE_CATEGORY_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        ageCategory: {
          Data: action.payload,
          Error: undefined,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_AGE_CATEGORY_FAILURE: {
      return {
        ...state,
        isFetching: false,
        isProcessing: false,
        ageCategory: {
          Data: undefined,
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_AGE_CATEGORIES: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.GET_AGE_CATEGORIES_SUCCESS: {
      return {
        ...state,
        ageCategories: {
          Data: action.payload,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_AGE_CATEGORIES_FAILURE: {
      return {
        ...state,
        ageCategories: {
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.DELETE_AGE_CATEGORY: {
      return {
        ...state,
        pendingDeleteCategory: action.data,
        isProcessing: true,
      };
    }
    case Consts.DELETE_AGE_CATEGORY_SUCCESS: {
      return {
        ...state,
        pendingDeleteCategory: undefined,
        ageCategories: {
          Data: state.ageCategories.Data?.filter(
            (category) => category.Id !== state.pendingDeleteCategory?.Id
          ),
          IsProcessing: false,
        },
      };
    }
    case Consts.DELETE_AGE_CATEGORY_FAILURE: {
      return {
        ...state,
        pendingDeleteCategory: undefined,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.CREATE_AGE_CATEGORY: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.CREATE_AGE_CATEGORY_SUCCESS: {
      return {
        ...state,
        ageCategories: {
          Data: [...(state.ageCategories.Data || []), action.payload.data],
          IsProcessing: false,
        },
      };
    }
    case Consts.CREATE_AGE_CATEGORY_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.UPDATE_AGE_CATEGORY: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.UPDATE_AGE_CATEGORY_SUCCESS: {
      const newState = cloneDeep(state);
      const entityIndex = findIndex(newState.ageCategories.Data, {
        Id: action.payload.data.Id,
      });

      if (newState.ageCategories.Data) {
        newState.ageCategories.Data[entityIndex] = action.payload
          .data as SportAgeCategoryModel;
      }

      newState.isProcessing = false;

      return newState;
    }
    case Consts.UPDATE_AGE_CATEGORY_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    // #################### PLAYER POSITIONS ####################
    case Consts.GET_PLAYER_POSITION: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case Consts.GET_PLAYER_POSITION_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        playerPosition: {
          Data: action.payload,
          Error: undefined,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_PLAYER_POSITION_FAILURE: {
      return {
        ...state,
        isFetching: false,
        playerPosition: {
          Data: undefined,
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_PLAYER_POSITIONS: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.GET_PLAYER_POSITIONS_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        playerPositions: {
          Error: undefined,
          Data: action.payload,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_PLAYER_POSITIONS_FAILURE: {
      return {
        ...state,
        isProcessing: false,
        playerPositions: {
          Data: undefined,
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.CREATE_PLAYER_POSITION: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.CREATE_PLAYER_POSITION_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
      };
    }
    case Consts.CREATE_PLAYER_POSITION_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.UPDATE_PLAYER_POSITION: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.UPDATE_PLAYER_POSITION_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
      };
    }
    case Consts.UPDATE_PLAYER_POSITION_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.DELETE_PLAYER_POSITION: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.DELETE_PLAYER_POSITION_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
      };
    }
    case Consts.DELETE_PLAYER_POSITION_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    // #################### STAFF ROLES ####################
    case Consts.GET_STAFF_ROLE: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case Consts.GET_STAFF_ROLE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        staffRole: {
          Data: action.payload,
          Error: undefined,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_STAFF_ROLE_FAILURE: {
      return {
        ...state,
        isFetching: false,
        staffRole: {
          Data: undefined,
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_STAFF_ROLES: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.GET_STAFF_ROLES_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        staffRoles: {
          Data: action.payload,
          Error: undefined,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_STAFF_ROLES_FAILURE: {
      return {
        ...state,
        isProcessing: false,
        staffRoles: {
          Data: undefined,
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.CREATE_STAFF_ROLE: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.CREATE_STAFF_ROLE_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
      };
    }
    case Consts.CREATE_STAFF_ROLE_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.UPDATE_STAFF_ROLE: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.UPDATE_STAFF_ROLE_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
      };
    }
    case Consts.UPDATE_STAFF_ROLE_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.DELETE_STAFF_ROLE: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.DELETE_STAFF_ROLE_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
      };
    }
    case Consts.DELETE_STAFF_ROLE_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    // #################### STATISTICS UNITS ####################
    case Consts.GET_STATISTICS_UNIT: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case Consts.GET_STATISTICS_UNIT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        statisticsUnit: {
          Data: action.payload,
          Error: undefined,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_STATISTICS_UNIT_FAILURE: {
      return {
        ...state,
        statisticsUnit: {
          Data: undefined,
          Error: action.error,
          IsProcessing: false,
        },
        isFetching: false,
      };
    }
    case Consts.GET_STATISTICS_UNITS: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.GET_STATISTICS_UNITS_SUCCESS: {
      return {
        ...state,
        statisticsUnits: {
          Data: action.payload,
          Error: undefined,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_STATISTICS_UNITS_FAILURE: {
      return {
        ...state,
        statisticsUnits: {
          Data: undefined,
          Error: action.error,
          IsProcessing: false,
        },
      };
    }
    case Consts.CREATE_STATISTICS_UNIT: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.CREATE_STATISTICS_UNIT_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
      };
    }
    case Consts.CREATE_STATISTICS_UNIT_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.UPDATE_STATISTICS_UNIT: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.UPDATE_STATISTICS_UNIT_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
      };
    }
    case Consts.UPDATE_STATISTICS_UNIT_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    case Consts.DELETE_STATISTICS_UNIT: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case Consts.DELETE_STATISTICS_UNIT_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
      };
    }
    case Consts.DELETE_STATISTICS_UNIT_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    }
    default:
      return state;
  }
};
