import React, { useMemo } from "react";
import {
  PageContent,
  Pagination,
  PageHeader,
  IBreadcrumbProps,
  useAppFeedback,
  InputSearch,
  Tooltip,
  Button,
  Icon,
  Table,
  ITableFilter,
  Link,
  ITableColumnProps,
  TTranslateToFilterSchemaValue,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import {
  ISearchFilterModelBase,
  IVoucherRedemptionModel,
  IUserSubscriptionsSearchFilterModel,
  TimeHelper,
  VoucherRedemptionsService,
} from "@bms/common-services";
import { useTableDataProvider } from "../../../../helpers";
import { FilterCleanIcon } from "./../../../../resources/icons";
import { ROUTES as USER_ROUTES } from "../../../User/constants";
import { ROUTES as PRODUCT_ROUTES } from "../../../Products/constants";
import { ROUTES as PAYMENT_ROUTES } from "../../../Payment/constants";
import { ROUTES } from "../../constants";

import "./VoucherRedemptionList.scss";

const voucherRedemptionsService = new VoucherRedemptionsService().promisify();

const filtersSchema: TSubscriptionListFilterSchema = {
  FullTextSearch: "string",
  UpToDate: "boolean",
  UserId: "number",
  AssetId: "number",
};

type IFilterModelWithoutPagination = Omit<
  IUserSubscriptionsSearchFilterModel,
  keyof ISearchFilterModelBase
> &
  Pick<ISearchFilterModelBase, "FullTextSearch">;

type TSubscriptionListFilterSchema = {
  [K in keyof Required<IFilterModelWithoutPagination>]: Required<
    TTranslateToFilterSchemaValue<IFilterModelWithoutPagination[K]>
  >;
};

interface IVoucherRedemptionListProps {
  title?: string;
  userId?: number;
  voucherId?: number;
  assetId?: number;
  getBreadcrumbProps?: () => IBreadcrumbProps;
}

export const VoucherRedemptionList = ({
  title,
  userId,
  assetId,
  voucherId,
  getBreadcrumbProps,
}: IVoucherRedemptionListProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { notification, modal } = useAppFeedback();

  const {
    dataLoader,
    filters,
    pagination,
    setFullTextSearch,
    fullTextSearch,
  } = useTableDataProvider({
    filtersSchema,
    debounce: 800,
    loader: (filters, pagination) =>
      voucherRedemptionsService.search({
        ...filters,
        ...pagination,
        RedeemedBy: userId,
        AssetId: assetId,
        VoucherId: voucherId,
      }),
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
    deps: [],
  });

  const extraItems = useMemo(() => {
    const onSearch = (text: string) => {
      filters.update((oldFilters) => ({
        ...oldFilters,
        FullTextSearch: text || undefined,
      }));
    };

    const onSearchInputChange = (el: React.ChangeEvent<HTMLInputElement>) => {
      const text = el.target.value;
      setFullTextSearch(text);
    };

    return [
      <InputSearch
        key="VoucherRedemptionListHeaderSearchInput"
        placeholder={t("SEARCH_PLACEHOLDER", "Search...")}
        value={fullTextSearch}
        onSearch={onSearch}
        onChange={onSearchInputChange}
        style={{ width: 250 }}
        allowClear
      />,
      <Tooltip
        key="clear-filter-tooltip"
        overlay={t("MENU_OPTION_CLEAR_FILTERS", "")}
      >
        <Button
          key="VoucherRedemptionListHeaderClearButton"
          shape="circle"
          icon={<FilterCleanIcon />}
          onClick={filters.clear}
          title={t("BUTTON_RESET")}
        />
      </Tooltip>,
      <Button
        key="VoucherRedemptionListHeaderRefreshButton"
        shape="circle"
        icon={<Icon type="reload" />}
        onClick={dataLoader.refresh}
        title={t("BUTTON_REFRESH_TITLE")}
      />,
    ];
  }, [
    dataLoader.refresh,
    fullTextSearch,
    setFullTextSearch,
    filters.clear,
    filters.update,
  ]);

  const columns = useMemo<
    Array<ITableColumnProps<IVoucherRedemptionModel>>
  >(() => {
    const columnProduct: ITableColumnProps<IVoucherRedemptionModel> = {
      key: "AssetTitle",
      dataIndex: "AssetTitle",
      title: t("PAYMENT_VOUCHER_PRODUCT_COLUMN"),
      ellipsis: true,
      render: (_: string, row) => (
        <Link to={`${PRODUCT_ROUTES.PRODUCTS_DETAILS}/${row.AssetId}`}>
          {row.AssetTitle}
        </Link>
      ),
    };

    const columnPayment: ITableColumnProps<IVoucherRedemptionModel> = {
      key: "PaymentKey",
      dataIndex: "PaymentKey",
      title: t("PAYMENT_VOUCHER_PAYMENT_COLUMN"),
      ellipsis: true,
      render: (_: string, row) => (
        <Link to={`${PAYMENT_ROUTES.PAYMENT_DETAILS}?paymentKey=${row.PaymentKey}`}>
          {row.PaymentKey}
        </Link>
      ),
    };

    const columnVoucherCode: ITableColumnProps<IVoucherRedemptionModel> = {
      key: "VoucherCode",
      dataIndex: "VoucherCode",
      title: t("PAYMENT_VOUCHER_VOUCHER_CODE_COLUMN"),
      render: (_: string, row) => (
        <Link to={`${ROUTES.VOUCHER_DETAILS}/${row.VoucherId}`}>
          {row.VoucherCode}
        </Link>
      ),
    };

    const columnUserName: ITableColumnProps<IVoucherRedemptionModel> = {
      key: "RedeemedByFullName",
      dataIndex: "RedeemedByFullName",
      title: t("PAYMENT_VOUCHER_REDEEM_BY_COLUMN"),
      render: (_: string, row) => (
        <Link to={`${USER_ROUTES.USER_DETAILS}/${row.RedeemedBy}`}>
          {row.RedeemedByFullName}
        </Link>
      ),
    };

    const columnRedeemedValue: ITableColumnProps<IVoucherRedemptionModel> = {
      key: "RedeemedValue",
      dataIndex: "RedeemedValue",
      title: t("PAYMENT_VOUCHER_REDEEMED_VALUE_COLUMN"),
      render: (_: string, row) => <>{row.RedeemedValue}</>,
    };

    const columnRedeemed: ITableColumnProps<IVoucherRedemptionModel> = {
      key: "Redeemed",
      dataIndex: "Redeemed",
      title: t("PAYMENT_VOUCHER_REDEEMED_COLUMN"),
      render: (_: string, row) =>
        row.Redeemed ? TimeHelper.format(row.Redeemed) : null,
    };

    if (assetId) {
      return [
        columnVoucherCode,
        columnUserName,
        columnRedeemed,
        columnRedeemedValue,
        columnPayment,
      ];
    }

    if (userId) {
      return [
        columnVoucherCode,
        columnRedeemed,
        columnRedeemedValue,
        columnProduct,
        columnPayment,
      ];
    }

    return [
      columnUserName,
      columnRedeemed,
      columnRedeemedValue,
      columnProduct,
      columnPayment,
    ];
  }, [filters, language, assetId, userId]);

  const handleTableChange = (_: unknown, tableFilters: ITableFilter) => {
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...{ UpToDate: tableFilters.UpToDate?.[0] },
    }));
  };

  return (
    <PageContent
      className="VoucherRedemptionList"
      footer={<Pagination {...pagination.props} />}
    >
      <PageHeader
        title={title}
        extra={extraItems}
        breadcrumb={getBreadcrumbProps?.()}
      />
      <Table<IVoucherRedemptionModel>
        rowKey={(row) => `${row.Id}`}
        columns={columns}
        dataSource={dataLoader.data?.Entities}
        loading={dataLoader.loading}
        pagination={false}
        tableLayout="auto"
        onChange={handleTableChange}
      />
    </PageContent>
  );
};
