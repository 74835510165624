import {
  ISportClubModel,
  ISportTeamModel,
  IUserModel,
} from "@bms/common-services";
import { Form, IFormValues, useSendable, useSyncedState } from "@bms/common-ui";
import { pick } from "lodash";
import { useEffect, useMemo } from "react";
import {
  FieldType,
  formLayouts,
  getSectionsFields,
  ISection,
} from "./TeamFormUtils";

export interface ITeamFormProps {
  isEditMode: boolean;
  team?: ISportTeamModel;
  section?: ISection;
  hiddenFields?: FieldType[];
  onSubmit?: (data: ISportTeamModel) => void;
}

export type SyncedPerson = {
  Id?: string | number;
  FirstName?: string;
  LastName?: string;
};

export type SyncedClub = {
  Id?: string | number;
  Name?: string;
};

export const useTeamFormController = ({
  isEditMode,
  team,
  section,
  onSubmit,
}: ITeamFormProps) => {
  const [form] = Form.useForm();
  const { onFieldsChange, resetSendable, setDirty, sendable } = useSendable();

  const [userCreator, setUserCreator] = useSyncedState<{
    Id?: string | number;
    FullName?: string | number;
  }>(
    () => ({
      Id: team?.CreatedBy,
      FullName: team?.CreatedByFullName,
    }),
    [team]
  );

  const [club, setClub] = useSyncedState<SyncedClub>(
    () => ({
      Id: team?.ClubId,
      Name: team?.ClubName,
    }),
    [team]
  );

  const formLayout = useMemo(
    () => (isEditMode ? formLayouts.formItem : formLayouts.modalFormItem),
    [isEditMode]
  );

  const formFieldNames = useMemo(() => {
    const everySectionFields: FieldType[] = [
      "Name",
      "Description",
      "City",
      "Stadium",
      "ClubId",
      "FoundationDate",
      "CountryCode",
      "CreatedBy",
    ];

    if (section) {
      return getSectionsFields()[section];
    }

    return everySectionFields;
  }, [section]);

  const onFinish = async (_values: IFormValues) => {
    const values = { ..._values };
    const picked: any = {};

    const parserMapper: IFormValues = {};

    formFieldNames
      .filter((it) => parserMapper[it] !== undefined)
      .forEach((it) => (picked[it] = parserMapper[it]));

    if (values.CountryCode) {
      delete team?.CountryCode;
      delete team?.CountryFlag;
      delete team?.CountryName;
    }

    const teamDetails: ISportTeamModel = {
      ...team,
      ...values,
      ...picked,
      ...(club.Id && { ClubId: club.Id }),
      ...(club.Name && { ClubName: club.Name }),
      ...(values.CountryCode && { CountryCode: values.CountryCode.value }),
      CreatedBy: userCreator.Id,
      CreatedByFullName: userCreator.FullName,
    };

    onSubmit?.(teamDetails);
  };

  const onUserSelect = (row?: IUserModel) => {
    setUserCreator({ Id: row?.Id, FullName: row?.FullName || row?.Id });
    setDirty();
  };

  const onUserClear = () => onUserSelect();

  const onClubSelect = (row?: ISportClubModel) => {
    setClub({ Id: row?.Id, Name: row?.Name });
    setDirty();
  };

  const onClubClear = () => onClubSelect();

  useEffect(() => {
    resetSendable();
    form.resetFields();
  }, [JSON.stringify(pick(team, formFieldNames))]);

  useEffect(() => {
    if (!club) {
      return;
    }
    form.setFieldValue("ClubId", club.Id);
  }, [club]);

  return {
    formLayout,
    formFieldNames,
    sendable,
    form,
    onFieldsChange,
    onFinish,
    userCreator,
    onUserSelect,
    onUserClear,
    club,
    onClubSelect,
    onClubClear,
  };
};
