import { IUserModel, RecordStatus } from "@bms/common-services";
import {
  CommonStore,
  CurrencyStore,
  dispatch,
  UserStore,
} from "@bms/common-services";
import { Button, Choose, ChooseOption, Col, Form, Row } from "@bms/common-ui";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
export interface IUserAccountSettingsFormOwnProps {
  user?: IUserModel;
  isActive: boolean;
  viewType: "customers" | "administrators" | "all";
}

export interface IUserAccountSettingsFormProps
  extends IUserAccountSettingsFormOwnProps {
  children?: React.ReactNode;
}

export const UserAccountSettingsForm = ({
  children,
  user,
}: IUserAccountSettingsFormProps) => {
  const { t } = useTranslation();
  const userSettings = user?.Settings
  const languages = useSelector(CommonStore.Selectors.languagesSelector);
  const currencies = useSelector(CurrencyStore.Selectors.currenciesSelector);
  const CurrencyOptions: React.ReactElement[] = [];
  const LanguageOptions: React.ReactElement[] = [];

  const [shouldFetchLanguages, setShouldFetchLanguages] = useState(
    !languages || languages.length === 0
  );
  const [shouldFetchCurencies, setShouldFetchCurencies] = useState(
    !currencies.data
  );

  useEffect(() => {
    if (shouldFetchLanguages) {
      setShouldFetchLanguages(false);
      dispatch(CommonStore.Actions.selectLanguages());
    }
  }, [languages]);

  useEffect(() => {
    if (shouldFetchCurencies) {
      setShouldFetchCurencies(false);
      dispatch(CurrencyStore.Actions.selectCurrencies());
    }
  }, [currencies]);

  const onFinish = ({
    language,
    currency,
  }: {
    language: {
      value?: number;
    };
    currency: {
      value?: number;
    };
  }) => {
    if (user) {
      dispatch(
        UserStore.Actions.updateUser({
          ...user,
          Settings: {
            UserId: user.Id,
            ...userSettings,
            RecordStatus: userSettings ? RecordStatus.Updated : RecordStatus.Inserted,
            LanguageId: language?.value,
            CurrencyId: currency?.value,
          }
        })
      );
    }
  };

  currencies?.data?.forEach((currency, index) => {
    CurrencyOptions.push(
      <ChooseOption key={index} value={currency.Id}>{currency.Name}</ChooseOption>
    );
  });

  languages?.forEach((language, index) => {
    LanguageOptions.push(
      <ChooseOption key={index} value={language.Id}>{language.Name}</ChooseOption>
    );
  });

  return user && currencies && languages ? (
    <Form
      name="UserAccountSettingsForm"
      labelCol={{
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 8 },
        lg: { span: 6 },
        xl: { span: 4 },
      }}
      wrapperCol={{
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 16 },
        lg: { span: 18 },
        xl: { span: 20 },
      }}
      onFinish={onFinish}
      initialValues={{
        language: userSettings?.LanguageId ? {value: userSettings.LanguageId} : undefined,
        currency: userSettings?.CurrencyId ? {value: userSettings.CurrencyId} : undefined,
      }}
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          <Form.Item
            name="currency"
            label={t("USER_ACCOUNT_FORM_CURRENCY_LABEL", "Currency")}
          >
            <Choose
              labelInValue={true}
              defaultActiveFirstOption={false}
              filterOption={false}
              placeholder={t("USER_ACCOUNT_FORM_CURRENCY", "Select a currency")}
              children={CurrencyOptions}
            />
          </Form.Item>
          <Form.Item
            name="language"
            label={t("USER_ACCOUNT_FORM_LANGUAGE_LABEL", "Language")}
          >
            <Choose
              labelInValue={true}
              defaultActiveFirstOption={false}
              filterOption={false}
              placeholder={t("USER_ACCOUNT_FORM_LANGUAGE", "Select a language")}
              children={LanguageOptions}
            />
          </Form.Item>
          {children}
          <Form.Item wrapperCol={{ style: { textAlign: "right" } }}>
            <Button type="primary" htmlType="submit">
              {t("BUTTON_SAVE", "Save")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ) : null;
};
