import {
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  PropertyType,
} from "@bms/common-services";
import { ActionPropertyModel } from "./ActionPropertyModel";
import { ComponentPropertyModel } from "./ComponentPropertyModel";

export class ApplicationSectionMenuItemPropertyModel extends ComponentPropertyModel {
  Title?: string;

  TitleTranslationKey?: string;

  Action?: ActionPropertyModel;

  IsVisible: boolean = true;

  constructor(component?: IApplicationComponentModel) {
    super();

    if (component) {
      this.init(component);
    }
  }

  initProperties(component: IApplicationComponentModel): void {
    if (!component.Properties) {
      component.Properties = [];
    }

    let titleProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Title"
    );

    if (!titleProperty) {
      this.onPropertyChange("Title", component);
    }

    let titleTranslationKeyProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "TitleTranslationKey"
    );

    if (!titleTranslationKeyProperty) {
      this.onPropertyChange("TitleTranslationKey", component);
    }
  }

  setProperties(component: IApplicationComponentModel): void {
    const isNewlyCreatedComponent =
      !Boolean(component.Properties?.length) && component.Id < 0;

    let titleProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Title"
    );

    if (titleProperty && titleProperty.Value) {
      this.Title = titleProperty.Value.StringValue;
    }

    let titleTranslationKeyProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "TitleTranslationKey"
    );

    if (titleTranslationKeyProperty && titleTranslationKeyProperty.Value) {
      this.TitleTranslationKey = titleTranslationKeyProperty.Value.StringValue;
    }

    const isVisibleProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "IsVisible"
    );

    if (
      isVisibleProperty &&
      isVisibleProperty.Value &&
      typeof isVisibleProperty.Value.BooleanValue === "boolean"
    ) {
      this.IsVisible = isVisibleProperty.Value.BooleanValue;
    } else if (isNewlyCreatedComponent) {
      // Attach default visibility property to new component.
      this.onPropertyChange("IsVisible", component);
    }
  }

  setProperty(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Title":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "TitleTranslationKey":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "IsVisible":
        property.PropertyType = PropertyType.Boolean;
        property.IsEnumerable = false;
        break;
    }
  }

  setPropertyValue(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Title":
        if (property.Value) {
          property.Value.StringValue = this.Title;
        }
        break;
      case "TitleTranslationKey":
        if (property.Value) {
          property.Value.StringValue = this.TitleTranslationKey;
        }
        break;
      case "IsVisible":
        if (property.Value) {
          property.Value.BooleanValue = this.IsVisible;
        }
        break;
    }
  }
}
