import { SectionGrid, SectionGridItem } from "@bms/common-ui";
import { ICommonClubDetailsTabProps } from "./ICommonClubDetailsTabProps";
import { useTranslation } from "react-i18next";
import { ClubForm } from "../../ClubForm";

export const DetailsTab = ({
  club,
  updateClubState,
  updateClub,
}: ICommonClubDetailsTabProps) => {
  const { t } = useTranslation();

  return (
    <SectionGrid>
      <SectionGridItem
        header={t("SPORT_CLUB_DETAILS_SECTION_GENERAL_INFORMATION")}
        processing={updateClubState?.processing}
      >
        <ClubForm
          key={`ClubForm-${club?.RowVersion}`}
          isEditMode
          club={club}
          onSubmit={updateClub}
        />
      </SectionGridItem>
    </SectionGrid>
  );
};
