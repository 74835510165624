import { AssetType, IAssetModel } from "@bms/common-services";
import {
  Button,
  Col,
  Form,
  Icon,
  Row,
  DatePicker,
  Input,
} from "@bms/common-ui";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AssetBrowserModal } from "../../../Asset/components";

interface IUserGrantProductModalFormProps {
  onSubmit: (values: IUserGrantProductForm) => void;
}

export interface IUserGrantProductForm {
  Asset: {
    id: IAssetModel["Id"];
    title: IAssetModel["Title"];
  };
  AvailableTo?: dayjs.Dayjs | null;
}

export const UserGrantProductModalForm: React.FC<IUserGrantProductModalFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IUserGrantProductForm>();
  const [isAssetModalVisible, setIsAssetModalVisible] = useState<boolean>(
    false
  );

  const assetFieldValue = Form.useWatch("Asset", form);

  return (
    <Form
      form={form}
      name="UserGrantProductModalForm"
      onFinish={onSubmit}
      style={{ textAlign: "left" }}
      labelCol={{
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
      }}
      wrapperCol={{
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
      }}
    >
      <Form.Item
        name="Asset"
        label={t("GRANT_PRODUCT_ASSET")}
        labelAlign="left"
        rules={[{ required: true }]}
        valuePropName="data-asset"
      >
        <Row gutter={8} align={"bottom"}>
          <Col span={18}>
            <Input
              placeholder={t("GRANT_PRODUCT_PICK_ASSET")}
              disabled={true}
              value={assetFieldValue?.title}
            />
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {assetFieldValue && (
              <Button
                icon={<Icon type="delete" />}
                onClick={() => form.resetFields(["Asset"])}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                setIsAssetModalVisible(true);
              }}
            />
          </Col>
        </Row>
      </Form.Item>
      <AssetBrowserModal
        visible={isAssetModalVisible}
        selectionMode={"single"}
        onSelect={(asset) => {
          form.setFieldValue("Asset", {
            id: asset.Id,
            title: asset.Title,
          });
        }}
        onCancel={() => setIsAssetModalVisible(false)}
        selectedType={[
          AssetType.Package,
          AssetType.Video,
          AssetType.Series,
          AssetType.Season,
          AssetType.Episode,
          AssetType.Live,
          AssetType.Article,
          AssetType.Podcast,
          AssetType.Album,
        ]}
      />
      <Row gutter={8}>
        <Col span={18}>
          <Form.Item
            name="AvailableTo"
            label={t("MODEL_AVAILABLE_TO")}
            labelAlign="left"
            rules={[
              {
                validator: (_, pickedData: unknown) => {
                  if (
                    dayjs.isDayjs(pickedData) &&
                    pickedData.isBefore(
                      dayjs()
                        .add(1, "day")
                        .set("hour", 0)
                        .set("minute", 0)
                        .set("second", 0)
                    )
                  ) {
                    return Promise.reject(
                      new Error(
                        t(
                          "GRANT_PRODUCT_FORM_AVAILABLE_TO_RANGE_VALIDATION_MESSAGE"
                        )
                      )
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <DatePicker
              placeholder={t("COMMON_SELECT_DATE")}
              style={{ width: `100%` }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
