import { UploadType } from "../enums";

export class UploadTypeHelper {
  static getDescription(value: UploadType): string {
    switch (value) {
      case UploadType.Direct:
        return "Direct";
      case UploadType.Transcoding:
        return "Transcoding";
      default:
        return "Undefined";
    }
  }

  static getValue(value: string): UploadType {
    switch (value) {
      case "DIRECT":
        return UploadType.Direct;
      default:
        return UploadType.Transcoding;
    }
  }

  static getOptions() {
    return [
      {
        text: UploadTypeHelper.getDescription(UploadType.Direct),
        value: UploadType.Direct,
      },
      {
        text: UploadTypeHelper.getDescription(UploadType.Transcoding),
        value: UploadType.Transcoding,
      },
    ];
  }
}
