import {
  ISportTeamModel,
  SportTeamService,
  TimeHelper,
} from "@bms/common-services";
import { ICommonClubDetailsTabProps } from "./ICommonClubDetailsTabProps";
import {
  Button,
  Heading,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  Link,
  Table,
  Tooltip,
  useAppFeedback,
} from "@bms/common-ui";
import { useTableDataProvider } from "../../../../../../helpers";
import { useTranslation } from "react-i18next";
import { FilterCleanIcon } from "../../../../../../resources/icons";
import { ROUTES } from "../../../../constants";
import { ROUTES as USER_ROUTES } from "../../../../../User/constants";

type Props = Pick<ICommonClubDetailsTabProps, "club">;

const teamsService = new SportTeamService().promisify();

export const TeamsTab = ({ club }: Props) => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const {
    dataLoader: teamsLoader,
    filters,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      Clubs: "numbers",
    },
    loader: (filters, pagination) => {
      if (!club?.Id) {
        return;
      }

      return teamsService.search({
        ...filters,
        ...pagination,
        Clubs: [club.Id],
      });
    },
    deps: [club?.Id],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
    }));

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value,
    }));

  const getColumnsProps = (): Array<ITableColumnProps<ISportTeamModel>> => {
    return [
      {
        key: "Name",
        dataIndex: "Name",
        title: t("MODEL_NAME"),
        render: (_, row: ISportTeamModel) => (
          <Link to={`${ROUTES.TEAM_DETAILS}/${row.Id}`}>{row.Name}</Link>
        ),
      },
      {
        key: "City",
        dataIndex: "City",
        title: t("MODEL_CITY"),
      },
      {
        key: "Stadium",
        dataIndex: "Stadium",
        title: t("MODEL_STADIUM"),
      },
      {
        key: "FoundationDate",
        dataIndex: "FoundationDate",
        title: t("MODEL_FOUNDATION_DATE"),
        render: (_, row: ISportTeamModel) =>
          row.FoundationDate
            ? TimeHelper.format(row.FoundationDate, "YYYY-MM-DD")
            : null,
      },
      {
        key: "Created",
        dataIndex: "Created",
        title: t("MODEL_CREATED"),
        render: (_, row: ISportTeamModel) =>
          row.Created
            ? TimeHelper.format(row.Created, "YYYY-MM-DD HH:mm")
            : null,
      },
      {
        key: "CreatedBy",
        dataIndex: "CreatedBy",
        title: t("MODEL_CREATED_BY"),
        render: (_, row: ISportTeamModel) => (
          <Link to={`${USER_ROUTES.USER_DETAILS}/${row.CreatedBy}`}>
            {row.CreatedByFullName}
          </Link>
        ),
      },
    ];
  };

  return (
    <>
      <Heading
        actions={
          <>
            <InputSearch
              key="search"
              placeholder={t("SEARCH_PLACEHOLDER")}
              value={fullTextSearch}
              onChange={({ target: { value } }) => setFullTextSearch(value)}
              onSearch={onSearch}
              style={{ width: 250 }}
              allowClear
            />
            <Tooltip overlay={t("MENU_OPTION_CLEAR_FILTERS")}>
              <Button
                key="clear-filters"
                shape="circle"
                icon={<FilterCleanIcon />}
                onClick={filters.clear}
                title={t("MENU_OPTION_CLEAR_FILTERS")}
              />
            </Tooltip>
            <Button
              key="reload"
              shape="circle"
              icon={<Icon type="reload" />}
              onClick={teamsLoader.refresh}
              title={t("BUTTON_REFRESH_TITLE")}
            />
          </>
        }
      />
      <Table<ISportTeamModel>
        rowKey="Id"
        columns={getColumnsProps()}
        dataSource={teamsLoader.data?.Entities}
        loading={teamsLoader.loading}
        pagination={false}
        onChange={onTableChange}
      />
    </>
  );
};
