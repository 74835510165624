import { DatePicker, Form } from "@bms/common-ui";
import { TimeHelper } from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { ITeamFormFieldCommonProps } from "./ITeamFormFieldCommonProps";

interface IFoundationDateFieldProps extends ITeamFormFieldCommonProps {}

export const FoundationDateField = ({
  isHidden,
  isEditMode,
  team,
}: IFoundationDateFieldProps) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="FoundationDate"
      label={t("MODEL_FOUNDATION_DATE")}
      key="FoundationDate"
      initialValue={
        isEditMode && team?.FoundationDate
          ? new Date(team?.FoundationDate)
          : undefined
      }
      rules={[
        {
          type: "object",
          message: t("REQUIRED_VALIDATION_MESSAGE"),
        },
        {
          validator: (_, value) => {
            if (!value) {
              return Promise.resolve();
            }
            return TimeHelper.isBeforeCurrent(value, "day")
              ? Promise.resolve()
              : Promise.reject(t("FOUNDATION_DATE_VALIDATION_MESSAGE"));
          },
        },
      ]}
    >
      <DatePicker
        placeholder={t("MODEL_FOUNDATION_DATE_PLACEHOLDER")}
        style={{ width: "100%" }}
      />
    </Form.Item>
  );
};
