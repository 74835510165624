import { Form, Input, required } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IPlayerFormFieldCommonProps } from "./IPlayerFormFieldCommonProps";
import { SyncedPerson } from "..";

interface ILastNameFieldProps extends IPlayerFormFieldCommonProps {
  person: SyncedPerson;
}

export const LastNameField = ({
  isHidden,
  isEditMode,
  player,
  person,
}: ILastNameFieldProps) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="LastName"
      label={t("MODEL_LAST_NAME")}
      key="lastName"
      initialValue={(isEditMode ? player?.LastName : "") || ""}
      rules={[required()]}
    >
      <Input
        placeholder={t("MODEL_LAST_NAME_PLACEHOLDER")}
        disabled={!!person.LastName}
      />
    </Form.Item>
  );
};
