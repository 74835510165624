import { intersection } from "lodash";

export type FieldType =
  | "Name"
  | "Description"
  | "City"
  | "Stadium"
  | "FoundationDate"
  | "CountryCode"
  | "CreatedBy"
  | "ClubId";

const generalFields: FieldType[] = [
  "Name",
  "Description",
  "City",
  "Stadium",
  "FoundationDate",
  "CreatedBy",
  "ClubId",
  "CountryCode",
];

interface IFieldsSections {
  general: FieldType[];
}

export type ISection = keyof IFieldsSections;

export const getSectionsFields = (): IFieldsSections => {
  const fieldNames: FieldType[] = [
    "Name",
    "Description",
    "City",
    "Stadium",
    "FoundationDate",
    "CreatedBy",
    "ClubId",
    "CountryCode",
  ];

  return {
    general: intersection(fieldNames, generalFields),
  };
};

export const formLayouts = {
  formItem: {
    labelCol: {
      xs: { span: 8 },
      lg: { span: 6 },
      xl: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 16 },
      lg: { span: 18 },
      xl: { span: 20 },
    },
  },
  modalFormItem: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
      md: { span: 6 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
      md: { span: 18 },
      lg: { span: 18 },
    },
  },
  tailFormItem: {
    wrapperCol: {
      style: {
        textAlign: "right",
        marginBottom: 0,
      } as React.CSSProperties,
    },
  },
};
