import React, { memo, useEffect, useMemo, useState } from "react";
import {
  Card,
  RadioGroup,
  Spin,
  StackedBarChart2,
  StackedBarChart2Control,
  Switch,
  Text,
  TStackedBarChart2Options,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import {
  AnalyticsAggregationPeriodType,
  IAnalyticsSalesRevenueAssetModel,
  IAnalyticsSalesRevenueFilterModel,
} from "@bms/common-services";
import { prepareChartData } from "./utils";
import "./SalesRevenueChart.scss";
import { IAnalyticsSalesRevenueAssetWithColorsModel, TGroupBy } from "../SalesRevenue";

interface ISalesRevenueChartProps {
  data?: IAnalyticsSalesRevenueAssetModel[];
  isLoading?: boolean;
  assetsListWithColors?:
    | IAnalyticsSalesRevenueAssetWithColorsModel[]
    | undefined;
  filter: IAnalyticsSalesRevenueFilterModel;
  aggregationPeriod?: AnalyticsAggregationPeriodType;
}

export const SalesRevenueChart = memo(
  ({
    data: salesRevenueData = [],
    assetsListWithColors,
    isLoading = false,
    filter,
    aggregationPeriod = AnalyticsAggregationPeriodType.YEAR,
  }: ISalesRevenueChartProps) => {
    const [groupBy, setGroupBy] = useState<TGroupBy>("asset");
    const [axisReversed, setAxisReversed] = useState(true);
    const { t } = useTranslation();
    const chartOptions: TStackedBarChart2Options = useMemo(
      () =>
        aggregationPeriod === AnalyticsAggregationPeriodType.YEAR
          ? {
              indexAxis: axisReversed ? "y" : "x",
              scales: {
                x: {
                  stacked: false,
                },
                y: {
                  stacked: false,
                  ticks: {
                    min: 0,
                    stepSize: 1,
                  },
                },
              },
            }
          : { indexAxis: axisReversed ? "y" : "x" },
      [aggregationPeriod, axisReversed]
    );
    useEffect(() => {
      if (Object.keys(filter).length === 0) {
        setGroupBy("asset");
        setAxisReversed(true);
      }
    }, [filter]);

    const data = useMemo(
      () =>
        prepareChartData(
          salesRevenueData,
          aggregationPeriod,
          groupBy,
          assetsListWithColors
        ),
      [salesRevenueData, aggregationPeriod, groupBy]
    );

    return (
      <Card className="SalesRevenueChart">
        {isLoading && <Spin size="large" className="SalesRevenueChart__spin" />}
        {!isLoading &&
          (!data ||
          data.datasets.length < 1 ||
          data.datasets[0].data.length < 1 ? (
            <Text className="SalesRevenueChart__no-data-text">
              {t("SALES_REVENUE_NO_CHART_DATA")}
            </Text>
          ) : (
            <StackedBarChart2
              title={t("SALES_REVENUE_CHART_TITLE")}
              data={data}
              options={chartOptions}
              showToolbar
              customToolbarControls={[
                <StackedBarChart2Control
                  key="sales-revenue-chart--reverse-axis-control"
                  label={t("SALES_REVENUE_CHART_REVERSE_AXIS")}
                >
                  <Switch
                    checked={axisReversed}
                    onChange={(val) => setAxisReversed(val)}
                  />
                </StackedBarChart2Control>,
                <StackedBarChart2Control
                  key="sales-revenue-chart--group-by-control"
                  label={t("SALES_REVENUE_CHART_GROUP_BY")}
                >
                  <RadioGroup
                    data={[
                      {
                        value: "asset",
                        name: t("SALES_REVENUE_CHART_GROUP_BY_ASSET"),
                      },
                      {
                        value: "creator",
                        name: t("SALES_REVENUE_CHART_GROUP_BY_CREATOR"),
                      },
                    ]}
                    value={groupBy}
                    onChange={(e) => {
                      e.preventDefault();
                      setGroupBy(e.target.value);
                    }}
                  />
                </StackedBarChart2Control>,
              ]}
            />
          ))}
      </Card>
    );
  }
);
