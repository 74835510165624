import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  EntityListModel,
  PaginationModel,
  ISportPlayerModel,
  ISportPlayerListModel,
  ISportPlayerSearchFilterModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class SportPlayersService extends SimpleServiceBase<
  ISportPlayerModel,
  ISportPlayerListModel,
  number,
  ISportPlayerSearchFilterModel
> {
  get url(): string {
    return "/SportPlayers";
  }

  public getPlayersList = (
    data: ISportPlayerModel,
    pagination: PaginationModel
  ): Observable<EntityListModel<ISportPlayerModel>> =>
    new Observable(
      (observer: Observer<EntityListModel<ISportPlayerModel>>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: pagination,
          },
          data,
          method: HTTP_METHOD.POST,
          url: `/SportPlayers/Search`,
        })
    );

  public getPlayer = (id: number): Observable<ISportPlayerModel> =>
    new Observable(
      (observer: Observer<ISportPlayerModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id },
          },
          method: HTTP_METHOD.GET,
          url: `/SportPlayers/Get`,
        })
    );

  public createPlayer = (
    data: ISportPlayerModel
  ): Observable<ISportPlayerModel> =>
    new Observable(
      (observer: Observer<ISportPlayerModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/SportPlayers/Insert`,
        })
    );

  public updatePlayer = (
    data: ISportPlayerModel
  ): Observable<ISportPlayerModel> =>
    new Observable(
      (observer: Observer<ISportPlayerModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `/SportPlayers/Update`,
        })
    );

  public deletePlayer = (
    data: ISportPlayerModel
  ): Observable<ISportPlayerModel> =>
    new Observable(
      (observer: Observer<ISportPlayerModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id: data.Id },
          },
          method: HTTP_METHOD.DELETE,
          url: `/SportPlayers/Delete`,
        })
    );
}
