import { Form } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IPlayerFormFieldCommonProps } from "./IPlayerFormFieldCommonProps";
import {
  IListModelBase,
  IModelBase,
  SportAgeCategoryModel,
  SportAgeCategoryService,
} from "@bms/common-services";
import { LazyChoose } from "../../../../../../components";

interface IAgeCategoryIdFieldProps extends IPlayerFormFieldCommonProps {}

const ageCategoriesService = new SportAgeCategoryService();

let ageCategories: SportAgeCategoryModel[];

export const AgeCategoryIdField = ({
  isHidden,
  isEditMode,
  player,
}: IAgeCategoryIdFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="AgeCategoryId"
      label={t("MODEL_AGE_CATEGORY_NAME")}
      initialValue={
        (isEditMode && {
          value: player?.AgeCategoryId,
          label: player?.AgeCategoryDisplayName || player?.AgeCategoryCode,
        }) ||
        {}
      }
      rules={[{ required: true }]}
    >
      <LazyChoose<
        SportAgeCategoryModel & IModelBase,
        SportAgeCategoryModel & IModelBase
      >
        loader={async () => {
          if (!ageCategories) {
            ageCategories = await ageCategoriesService
              .getCategories()
              .toPromise();
          }
          const data: IListModelBase<SportAgeCategoryModel & IModelBase, {}> = {
            TotalCount: ageCategories.length,
            Entities: ageCategories,
          };
          return {
            data,
            ok: true,
          };
        }}
        candidateToOption={(item) => ({
          label: item.DisplayName || item.Code,
          value: `${item.Id}`,
        })}
        multiple={false}
        selectedToOption={(item) => ({
          label: item.DisplayName || item.Code,
          value: `${item.Id}`,
          item,
        })}
      />
    </Form.Item>
  );
};
