import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  EntityListModel,
  PaginationModel,
  ISportMatchSearchFilterModel,
  ISportMatchModel,
  ISportMatchesListModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class SportMatchService extends SimpleServiceBase<
  ISportMatchModel,
  ISportMatchesListModel,
  number,
  ISportMatchSearchFilterModel
> {
  get url(): string {
    return "/SportMatches";
  }

  public getMatchesList = (
    data: ISportMatchModel,
    pagination: PaginationModel
  ): Observable<EntityListModel<ISportMatchModel>> =>
    new Observable(
      (observer: Observer<EntityListModel<ISportMatchModel>>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: pagination,
          },
          data,
          method: HTTP_METHOD.POST,
          url: `/SportMatches/Search`,
        })
    );

  public getMatch = (id: number): Observable<ISportMatchModel> =>
    new Observable(
      (observer: Observer<ISportMatchModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id },
          },
          method: HTTP_METHOD.GET,
          url: `/SportMatches/Get`,
        })
    );

  public createMatch = (data: ISportMatchModel): Observable<ISportMatchModel> =>
    new Observable(
      (observer: Observer<ISportMatchModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/SportMatches/Insert`,
        })
    );

  public updateMatch = (data: ISportMatchModel): Observable<ISportMatchModel> =>
    new Observable(
      (observer: Observer<ISportMatchModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `/SportMatches/Update`,
        })
    );

  public deleteMatch = (data: ISportMatchModel): Observable<ISportMatchModel> =>
    new Observable(
      (observer: Observer<ISportMatchModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id: data.Id },
          },
          method: HTTP_METHOD.DELETE,
          url: `/SportMatches/Delete`,
        })
    );
}
