import {
  ISportLeagueModel,
  SportLeaguesService,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { useAppFeedback, useSyncedState } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { useLeaguesDataPager } from "../../../hooks";
import { ROUTES } from "../../../constants";

const leaguesService = new SportLeaguesService().promisify();

export const useLeagueDetailsController = () => {
  const id = parseInt(useParams<{ id: string }>()["id"]);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [dataPager, dataPagerLoading] = useLeaguesDataPager(id);

  const { data, loading, refresh } = useDataLoader({
    loader: () => leaguesService.getLeague(id),
    deps: [id],
  });

  const [league, setLeague] = useSyncedState(() => data, [data]);

  const { modal, notification } = useAppFeedback();

  const [updateLeague, updateLeagueState] = useServiceCaller(
    async (data: ISportLeagueModel) => {
      const result = await leaguesService.updateLeague(data);
      if (result.ok) {
        setLeague(result.data);
        notification.success({
          message: t("SPORT_LEAGUE_DETAILS__LEAGUE_UPDATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("SPORT_LEAGUE_DETAILS__LEAGUE_UPDATE_FAILURE"),
          description: result.error.Message,
        });
      }
    },
    []
  );

  const [deleteLeague] = useServiceCaller(async () => {
    const result = await leaguesService.deleteLeague(league!);
    if (result.ok) {
      notification.success({
        message: t("SPORT_LEAGUE_DETAILS__LEAGUE_DELETE_SUCCESS"),
      });
      history.push(ROUTES.LEAGUES);
    } else {
      notification.error({
        message: t("SPORT_LEAGUE_DETAILS__LEAGUE_DELETE_FAILURE"),
        description: result.error?.Message,
      });
    }
  }, [league]);

  const onDeleteClick = () => {
    if (!league) {
      return;
    }

    modal.confirm({
      title: t("COMMON_DELETE"),
      content: t("SPORT_LEAGUE_DETAILS__LEAGUE_DELETE_MESSAGE", {
        leagueName: league?.Name,
      }),
      okText: t("BUTTON_DELETE"),
      cancelText: t("BUTTON_CANCEL"),
      onOk: deleteLeague,
    });
  };

  const onBackClick = () => {
    if (location.state && location.state.hasOwnProperty("from")) {
      // use location from router state
      const state = location.state as { from: Location };
      history.push(state.from);
    } else {
      // use leagues list location
      history.goBack();
    }
  };

  return {
    updateLeagueState,
    league,
    loading,
    dataPager,
    dataPagerLoading,
    refresh,
    updateLeague,
    onDeleteClick,
    onBackClick,
    t,
  };
};
