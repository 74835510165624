import { ITEM_NAMES } from "../constants";
import { VoucherRedeemType } from "@bms/common-services";
import {
  Choose,
  ChooseOption,
  ChooseValue,
  Form,
  IFormInstance,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";

interface IRedeemTypeItemProps {
  form: IFormInstance;
}

export const RedeemTypeItem = ({ form }: IRedeemTypeItemProps) => {
  const [t] = useTranslation();

  const onChange = (value: ChooseValue) => {
    const redeemType = value as VoucherRedeemType;

    if (redeemType === VoucherRedeemType.OneTime) {
      form.setFieldValue(ITEM_NAMES.REDEMPTION_LIMIT, null);
    }
  };

  return (
    <Form.Item
      name={ITEM_NAMES.REDEEM_TYPE}
      label={t("PAYMENT_VOUCHER_REDEEM_TYPE_COLUMN")}
      rules={[
        {
          required: true,
          message: t("REQUIRED_VALIDATION_MESSAGE"),
        },
      ]}
    >
      <Choose
        placeholder={t("PAYMENT_VOUCHER_FORM_SELECT_TYPE_PLACEHOLDER")}
        onChange={onChange}
      >
        {Object.values(VoucherRedeemType).map((redeemType) => (
          <ChooseOption key={redeemType} value={redeemType}>
            {redeemType === VoucherRedeemType.OneTime
              ? t("PAYMENT_VOUCHER_REDEEM_TYPE_COLUMN_ONE_TIME_FILTER")
              : t("PAYMENT_VOUCHER_REDEEM_TYPE_COLUMN_MULTIPLE_FILTER")}
          </ChooseOption>
        ))}
      </Choose>
    </Form.Item>
  );
};
