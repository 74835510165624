import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { ISportTeamStaffRoleModel } from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { PromisifiableBase } from "../Base";

export class SportTeamStaffRoleService extends PromisifiableBase {
  public getRoles = (): Observable<ISportTeamStaffRoleModel[]> =>
    new Observable(
      (observer: Observer<ISportTeamStaffRoleModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `/SportTeamStaffRoles/Select`,
        })
    );

  public getRole = (id: number): Observable<ISportTeamStaffRoleModel> =>
    new Observable(
      (observer: Observer<ISportTeamStaffRoleModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id },
          },
          method: HTTP_METHOD.GET,
          url: `/SportTeamStaffRoles/Get`,
        })
    );

  public createRole = (
    data: ISportTeamStaffRoleModel
  ): Observable<ISportTeamStaffRoleModel> =>
    new Observable(
      (observer: Observer<ISportTeamStaffRoleModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/SportTeamStaffRoles/Insert`,
        })
    );

  public updateRole = (
    data: ISportTeamStaffRoleModel
  ): Observable<ISportTeamStaffRoleModel> =>
    new Observable(
      (observer: Observer<ISportTeamStaffRoleModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `/SportTeamStaffRoles/Update`,
        })
    );

  public deleteRole = (
    data: ISportTeamStaffRoleModel
  ): Observable<ISportTeamStaffRoleModel> =>
    new Observable(
      (observer: Observer<ISportTeamStaffRoleModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id: data.Id },
          },
          method: HTTP_METHOD.DELETE,
          url: `/SportTeamStaffRoles/Delete`,
        })
    );
}
