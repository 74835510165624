import { Form, Input } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IMatchesFormFieldCommonProps } from "./IMatchesFormFieldCommonProps";

interface IHomeTeamScoreFieldProps extends IMatchesFormFieldCommonProps {}

// For now - Disabled field
export const HomeTeamScoreField = ({
  isHidden,
  isEditMode,
  match,
}: IHomeTeamScoreFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="HomeTeamScore"
      label={t("MODEL_HOME_TEAM_SCORE")}
      key="homeTeamScore"
      initialValue={isEditMode ? match?.HomeTeamScore || "" : ""}
    >
      <Input
        placeholder={t("MODEL_HOME_TEAM_SCORE_PLACEHOLDER")}
        type="number"
      />
    </Form.Item>
  );
};
