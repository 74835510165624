import {
  ISportTeamPlayerModel,
  SportAgeCategoryService,
  SportPlayerPositionsService,
  SportTeamPlayerService,
  TimeHelper,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { ICommonTeamDetailsTabProps } from "./ICommonTeamDetailsTabProps";
import {
  Button,
  Heading,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  Link,
  Popconfirm,
  Table,
  Tooltip,
  useAppFeedback,
} from "@bms/common-ui";
import { useTableDataProvider } from "../../../../../../helpers";
import { useTranslation } from "react-i18next";
import { FilterCleanIcon } from "../../../../../../resources/icons";
import { useState } from "react";
import { AddSportTeamPlayerModal } from "../../../TeamPlayers/AddSportTeamPlayerModal";
import { ROUTES } from "../../../../constants";
import { SportTeamPlayerForm } from "../../../TeamPlayers/SportTeamPlayerForm";

type Props = Pick<ICommonTeamDetailsTabProps, "team">;

const teamPlayersService = new SportTeamPlayerService().promisify();
const ageCategoriesService = new SportAgeCategoryService().promisify();
const positionsService = new SportPlayerPositionsService().promisify();

export const PlayersTab = ({ team }: Props) => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const [modalOpen, setModalOpen] = useState(false);
  const [editablePlayer, setEditablePlayer] = useState<ISportTeamPlayerModel>();

  const ageCategoriesLoader = useDataLoader({
    loader: () => ageCategoriesService.getCategories(),
    deps: [],
  });

  const positionsLoader = useDataLoader({
    loader: () => positionsService.getPlayerPositions(),
    deps: [],
  });

  const {
    dataLoader: teamPlayersLoader,
    filters,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      Positions: "strings",
      AgeCategories: "strings",
      Teams: "numbers",
    },
    loader: (filters, pagination) => {
      if (!team?.Id) {
        return;
      }

      return teamPlayersService.search({
        ...filters,
        ...pagination,
        Teams: [team.Id],
      });
    },
    deps: [team?.Id],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
    }));

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value,
    }));

  const getColumnsProps = (): Array<
    ITableColumnProps<ISportTeamPlayerModel>
  > => {
    return [
      {
        key: "FirstName",
        dataIndex: "FirstName",
        title: t("MODEL_FIRST_NAME"),
        render: (text: any, row: ISportTeamPlayerModel) => {
          return (
            <a
              title={row.FirstName}
              onClick={() => {
                setModalOpen(true);
                setEditablePlayer(row);
              }}
            >
              {row.FirstName}
            </a>
          );
        },
      },
      {
        key: "LastName",
        dataIndex: "LastName",
        title: t("MODEL_LAST_NAME"),
        render: (_, row: ISportTeamPlayerModel) => (
          <Link to={`${ROUTES.PLAYER_DETAILS}/${row.PlayerId}`}>
            {row.LastName}
          </Link>
        ),
      },
      {
        key: "AgeCategories",
        dataIndex: "AgeCategories",
        title: t("MODEL_AGE_CATEGORY_NAME"),
        filters: ageCategoriesLoader.data?.map((ageCategory) => ({
          text: ageCategory.DisplayName || ageCategory.Name,
          value: ageCategory.Code,
        })),
        filterSearch: true,
        filteredValue: filters.asTableArray.AgeCategories,
        render: (_, row: ISportTeamPlayerModel) => row.AgeCategoryDisplayName,
      },
      {
        key: "Positions",
        dataIndex: "Positions",
        title: t("MODEL_POSITION_NAME"),
        filters: positionsLoader.data?.map((position) => ({
          text: position.DisplayName || position.Name,
          value: position.Code,
        })),
        filterSearch: true,
        filteredValue: filters.asTableArray.Positions,
        render: (_, row: ISportTeamPlayerModel) =>
          row.PositionDisplayName || row.PositionCode,
      },
      {
        key: "ShirtNumber",
        dataIndex: "ShirtNumber",
        align: "center",
        title: t("MODEL_SHIRT_NUMBER"),
      },
      {
        key: "DateFrom",
        dataIndex: "DateFrom",
        width: "200px",
        title: t("MODEL_DATE_FROM"),
        render: (_, row: ISportTeamPlayerModel) =>
          row.DateFrom ? TimeHelper.format(row.DateFrom, "YYYY-MM-DD") : null,
      },
      {
        key: "DateTo",
        dataIndex: "DateTo",
        width: "200px",
        title: t("MODEL_DATE_TO"),
        render: (_, row: ISportTeamPlayerModel) =>
          row.DateTo ? TimeHelper.format(row.DateTo, "YYYY-MM-DD") : null,
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_, teamPlayer: ISportTeamPlayerModel) => (
          <>
            <Popconfirm
              title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
              onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
                e?.preventDefault();
                const result = await teamPlayersService.deleteTeamPlayer(
                  teamPlayer
                );
                if (result.ok) {
                  await teamPlayersLoader.refresh();
                } else {
                  notification.error({
                    message: t("DELETE_ELEMENT_FAILTURE"),
                    description: result.error?.Message,
                  });
                }
              }}
              okText={t("BUTTON_YES")}
              cancelText={t("BUTTON_NO")}
            >
              <Button
                danger
                icon={<Icon type="delete" />}
                title={t("DELETE_ELEMENT")}
              />
            </Popconfirm>
          </>
        ),
      },
    ];
  };

  const [createTeamPlayer, createTeamPlayerState] = useServiceCaller(
    async (data: ISportTeamPlayerModel) => {
      const result = await teamPlayersService.createTeamPlayer(data);
      if (result.ok) {
        setModalOpen(false);
        notification.success({
          message: t("SPORT_TEAM_PLAYER_CREATE_SUCCESS"),
        });
        await teamPlayersLoader.refresh();
      } else {
        notification.error({
          message: t("SPORT_TEAM_PLAYER_CREATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const [updateTeamPlayer, updateTeamPlayerState] = useServiceCaller(
    async (data: ISportTeamPlayerModel) => {
      const result = await teamPlayersService.updateTeamPlayer(data);
      if (result.ok) {
        setModalOpen(false);
        notification.success({
          message: t("SPORT_TEAM_PLAYER_UPDATE_SUCCESS"),
        });
        await teamPlayersLoader.refresh();
      } else {
        notification.error({
          message: t("SPORT_TEAM_PLAYER_UPDATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  return (
    <>
      <AddSportTeamPlayerModal
        visible={modalOpen}
        close={() => setModalOpen(false)}
        processing={
          createTeamPlayerState.processing || updateTeamPlayerState.processing
        }
      >
        <SportTeamPlayerForm
          isEditMode={!!editablePlayer}
          onSubmit={editablePlayer ? updateTeamPlayer : createTeamPlayer}
          team={team}
          player={editablePlayer}
        />
      </AddSportTeamPlayerModal>
      <Heading
        actions={
          <>
            <InputSearch
              key="search"
              placeholder={t("SEARCH_PLACEHOLDER")}
              value={fullTextSearch}
              onChange={({ target: { value } }) => setFullTextSearch(value)}
              onSearch={onSearch}
              style={{ width: 250 }}
              allowClear
            />
            <Tooltip overlay={t("MENU_OPTION_CLEAR_FILTERS")}>
              <Button
                key="clear-filters"
                shape="circle"
                icon={<FilterCleanIcon />}
                onClick={filters.clear}
                title={t("MENU_OPTION_CLEAR_FILTERS")}
              />
            </Tooltip>
            <Button
              key="reload"
              shape="circle"
              icon={<Icon type="reload" />}
              onClick={teamPlayersLoader.refresh}
              title={t("BUTTON_REFRESH_TITLE")}
            />
            <Button
              key="add"
              shape="circle"
              type="primary"
              icon={<Icon type="plus" />}
              onClick={() => {
                setModalOpen(true);
                setEditablePlayer(undefined);
              }}
              title={t("BUTTON_ADD")}
            />
          </>
        }
      />
      <Table<ISportTeamPlayerModel>
        rowKey="Id"
        columns={getColumnsProps()}
        dataSource={teamPlayersLoader.data?.Entities}
        loading={teamPlayersLoader.loading}
        pagination={false}
        onChange={onTableChange}
      />
    </>
  );
};
