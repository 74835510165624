import {
  CurrencyStore,
  IVoucherPriceListItemModel,
  TimeHelper,
} from "@bms/common-services";
import {
  Choose,
  ChooseOption,
  Col,
  DatePicker,
  Form,
  IFormValues,
  InputNumber,
  Row,
} from "@bms/common-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export interface IVoucherFormProps {
  voucher: IVoucherPriceListItemModel;
  onCreate?: (data: IVoucherPriceListItemModel) => void;
  onUpdate?: (data: IVoucherPriceListItemModel) => void;
}

export const VoucherPriceListForm: React.FC<IVoucherFormProps> = (props) => {
  const { voucher, onCreate, onUpdate } = props;

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { data: currenciesData } = useSelector(
    CurrencyStore.Selectors.currenciesSelector
  );

  const onFinish = async (values: IFormValues) => {
    let updatedAssetPrice: IVoucherPriceListItemModel = {
      ...voucher,
      Price: values.Price,
      CurrencyId: values.CurrencyId,
      AvailableFrom: values.AvailableFrom
        ? values.AvailableFrom.toISOString()
        : null,
      AvailableTo: values.AvailableTo ? values.AvailableTo.toISOString() : null,
    };

    if (voucher.Id !== -1) {
      onUpdate?.(updatedAssetPrice);
    } else {
      onCreate?.(updatedAssetPrice);
    }
  };

  return (
    <Form
      name="VoucherPricesListForm"
      className="VoucherPricesListForm"
      form={form}
      onFinish={onFinish}
      layout="vertical"
      disabled={!currenciesData?.[0]}
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          <Form.Item
            name={FIELD_NAMES.PRICE}
            label={t("MODEL_PRICE")}
            style={{ display: "inline-block", width: "calc(50%)" }}
            initialValue={voucher.Price}
            rules={[
              {
                required: true,
                message: t("REQUIRED_VALIDATION_MESSAGE"),
              },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!Number.isFinite(value)) {
                    return Promise.resolve();
                  }
                  const currencyFieldValue = getFieldValue(
                    FIELD_NAMES.CURRENCY
                  );
                  const selectedCurrency = currenciesData?.find(
                    (currency) => currency.Id === currencyFieldValue
                  );
                  if (
                    selectedCurrency?.MinPayment &&
                    value < selectedCurrency.MinPayment
                  ) {
                    return Promise.reject(
                      new Error(
                        t(
                          "VOUCHER_PRICE_LIST_FORM_VALUE_TO_LOW_VALIDATION_MESSAGE",
                          {
                            price: selectedCurrency.MinPayment,
                            currencyName: selectedCurrency.Code,
                          }
                        )
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            dependencies={[FIELD_NAMES.CURRENCY]}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder={t("MODEL_PRICE_PLACEHOLDER")}
              step={0.1}
            />
          </Form.Item>
          <Form.Item
            name={FIELD_NAMES.CURRENCY}
            label={t("MODEL_CURRENCY")}
            initialValue={voucher.CurrencyId}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 0 0 8px",
            }}
            rules={[
              {
                required: true,
                message: t("REQUIRED_VALIDATION_MESSAGE"),
              },
              {
                validator: (_, value) => {
                  const isValueValidCurrency = currenciesData?.find(
                    (currency) => currency.Id === value
                  );
                  return isValueValidCurrency
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(t("REQUIRED_VALIDATION_MESSAGE"))
                      );
                },
              },
            ]}
          >
            <Choose placeholder={t("MODEL_CURRENCY_PLACEHOLDER")}>
              {currenciesData?.map((currency) => (
                <ChooseOption key={currency.Id} value={currency.Id}>
                  {currency.Name}
                </ChooseOption>
              ))}
            </Choose>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              name={FIELD_NAMES.AVAILABLE_FROM}
              label={t("MODEL_AVAILABLE_FROM")}
              dependencies={[FIELD_NAMES.AVAILABLE_TO]}
              rules={[
                {
                  type: "object",
                  required: true,
                  message: t("REQUIRED_VALIDATION_MESSAGE"),
                },
                {
                  validator: (_, AvailableFrom) => {
                    const AvailableTo = form.getFieldValue(
                      FIELD_NAMES.AVAILABLE_TO
                    );
                    if (AvailableTo && AvailableFrom?.isAfter(AvailableTo)) {
                      return Promise.reject(
                        new Error(t("DATE_RANGE_VALIDATION_MESSAGE"))
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
              }}
              initialValue={
                voucher.AvailableFrom
                  ? TimeHelper.getDateTime(voucher.AvailableFrom)
                  : TimeHelper.getCurrentDateTime()
              }
            >
              <DatePicker showToday={true} showTime style={{ width: `100%` }} />
            </Form.Item>
            <Form.Item
              name={FIELD_NAMES.AVAILABLE_TO}
              label={t("MODEL_AVAILABLE_TO")}
              dependencies={[FIELD_NAMES.AVAILABLE_FROM]}
              initialValue={
                voucher.AvailableTo
                  ? TimeHelper.getDateTime(voucher.AvailableTo)
                  : undefined
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                marginLeft: 8,
              }}
              rules={[
                {
                  validator: (_, AvailableTo) => {
                    const AvailableFrom = form.getFieldValue("AvailableFrom");
                    if (AvailableFrom && AvailableTo?.isBefore(AvailableFrom)) {
                      return Promise.reject(
                        new Error(t("DATE_RANGE_VALIDATION_MESSAGE"))
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <DatePicker showToday={true} showTime style={{ width: `100%` }} />
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const FIELD_NAMES = {
  PRICE: "Price",
  CURRENCY: "CurrencyId",
  AVAILABLE_FROM: "AvailableFrom",
  AVAILABLE_TO: "AvailableTo",
};
