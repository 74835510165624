import {
  INotificationInsertByQueryModel,
  INotificationRecipientModel,
  INotificationRecipientSearchFilterModel,
} from "../../models";
import { SimpleServiceBase } from "../Base";
import { INotificationRecipientListModel } from "../../models/Notifications/INotificationRecipientListModel";
import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { HTTP_METHOD } from "../../constants";

export class NotificationRecipientsService extends SimpleServiceBase<
  INotificationRecipientModel,
  INotificationRecipientListModel,
  number,
  INotificationRecipientSearchFilterModel
> {
  get url(): string {
    return "/NotificationRecipients";
  }

  public insertByQuery = (
    data: INotificationInsertByQueryModel
  ): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/InsertByQuery`,
        })
    );
}
