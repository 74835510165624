import {
  IApplicationComponentLayoutModel,
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  PlatformType,
  PropertyType,
  RecordStatus,
} from "@bms/common-services";
import { ActionPropertyModel } from "./ActionPropertyModel";
import { ComponentPropertyModel } from "./ComponentPropertyModel";
import { LayoutOptionsModel } from "./LayoutOptionsModel";
import { ComponentLayoutPropertyModel } from "./ComponentLayoutPropertyModel";

export class SettingsMenuItemPropertyModel extends ComponentPropertyModel {
  Title?: string;

  TitleTranslationKey?: string;

  IconUrl?: string;

  IconAbsoluteUrl?: string;

  Action?: ActionPropertyModel;

  IsVisible: boolean = true;

  constructor(component?: IApplicationComponentModel) {
    super();

    if (component) {
      this.init(component);
    }
  }

  public getLayoutProperty(
    component: IApplicationComponentModel,
    layoutOptions: LayoutOptionsModel
  ): ComponentLayoutPropertyModel {
    if (!component.Layouts) {
      component.Layouts = [];
    }

    let componentLayout = component.Layouts.find(
      (row: IApplicationComponentLayoutModel) =>
        row.PlatformCode === layoutOptions.Platform
    );

    if (!componentLayout) {
      componentLayout = {
        RecordStatus: RecordStatus.Inserted,
        ApplicationComponentId: component.Id,
        PlatformCode: layoutOptions.Platform,
        PositionX: 0,
        PositionY: 0,
        Width: 5,
        Height: 2.5,
      };

      component.Layouts.push(componentLayout);

      if (
        componentLayout.PlatformCode === PlatformType.AndroidPhone ||
        componentLayout.PlatformCode === PlatformType.AndroidTablet ||
        componentLayout.PlatformCode === PlatformType.iOSPhone ||
        componentLayout.PlatformCode === PlatformType.iPad
      ) {
        componentLayout = {
          RecordStatus: RecordStatus.Inserted,
          ApplicationComponentId: component.Id,
          PlatformCode: layoutOptions.Platform,
          PositionX: componentLayout.PositionX,
          PositionY: componentLayout.PositionY,
          Width: 12,
          Height: 1.5,
        };

        component.Layouts.push(componentLayout);
      }

      if (
        componentLayout.PlatformCode === PlatformType.AndroidTV ||
        componentLayout.PlatformCode === PlatformType.Tizen ||
        componentLayout.PlatformCode === PlatformType.WebOS ||
        componentLayout.PlatformCode === PlatformType.AppleTV ||
        componentLayout.PlatformCode === PlatformType.Roku
      ) {
        componentLayout = {
          RecordStatus: RecordStatus.Inserted,
          ApplicationComponentId: component.Id,
          PlatformCode: layoutOptions.Platform,
          PositionX: componentLayout.PositionX,
          PositionY: componentLayout.PositionY,
          Width: 5,
          Height: 1.5,
        };

        component.Layouts.push(componentLayout);
      }
    }

    return {
      PositionX: componentLayout.PositionX,
      PositionY: componentLayout.PositionY,
      Width: componentLayout.Width,
      Height: componentLayout.Height,
      IsResizable: false,
      IsDraggable: false,
    };
  }

  initProperties(component: IApplicationComponentModel): void {
    if (!component.Properties) {
      component.Properties = [];
    }

    let titleProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Title"
    );

    if (!titleProperty) {
      this.onPropertyChange("Title", component);
    }

    let titleTranslationKeyProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "TitleTranslationKey"
    );

    if (!titleTranslationKeyProperty) {
      this.onPropertyChange("TitleTranslationKey", component);
    }

    let iconUrlProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "IconUrl"
    );

    if (!iconUrlProperty) {
      this.onPropertyChange("IconUrl", component);
    }
  }

  setProperties(component: IApplicationComponentModel): void {
    const isNewlyCreatedComponent =
      !Boolean(component.Properties?.length) && component.Id < 0;

    let titleProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Title"
    );

    if (titleProperty && titleProperty.Value) {
      this.Title = titleProperty.Value.StringValue;
    }

    let titleTranslationKeyProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "TitleTranslationKey"
    );

    if (titleTranslationKeyProperty && titleTranslationKeyProperty.Value) {
      this.TitleTranslationKey = titleTranslationKeyProperty.Value.StringValue;
    }

    let iconUrlProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "IconUrl"
    );

    if (iconUrlProperty && iconUrlProperty.Value) {
      this.IconUrl = iconUrlProperty.Value.StringValue;
      this.IconAbsoluteUrl = iconUrlProperty.Value.UrlValue;
    }

    const isVisibleProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "IsVisible"
    );

    if (
      isVisibleProperty &&
      isVisibleProperty.Value &&
      typeof isVisibleProperty.Value.BooleanValue === "boolean"
    ) {
      this.IsVisible = isVisibleProperty.Value.BooleanValue;
    } else if (isNewlyCreatedComponent) {
      // Attach default visibility property to new component.
      this.onPropertyChange("IsVisible", component);
    }
  }

  setProperty(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Title":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "TitleTranslationKey":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "IconUrl":
        property.PropertyType = PropertyType.Url;
        property.IsEnumerable = false;
        break;
      case "IsVisible":
        property.PropertyType = PropertyType.Boolean;
        property.IsEnumerable = false;
        break;
    }
  }

  setPropertyValue(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Title":
        if (property.Value) {
          property.Value.StringValue = this.Title;
        }
        break;
      case "TitleTranslationKey":
        if (property.Value) {
          property.Value.StringValue = this.TitleTranslationKey;
        }
        break;
      case "IconUrl":
        if (property.Value) {
          property.Value.StringValue = this.IconUrl;
          property.Value.UrlValue = this.IconAbsoluteUrl;
        }
        break;
      case "IsVisible":
        if (property.Value) {
          property.Value.BooleanValue = this.IsVisible;
        }
        break;
    }
  }
}
