/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  CellType,
  DataProvider,
  IResourceModel,
  PlayListSourceTypeHelper,
  Orientation,
  IPlaylistSourceModel,
  PlayListSourceType,
  PlatformType,
} from "@bms/common-services";
import {
  Button,
  Checkbox,
  ICheckboxChangeEvent,
  Choose,
  ChooseOption,
  Col,
  Form,
  IChooseValue,
  Icon,
  IFormInstance,
  IFormValues,
  Input,
  InputNumber,
  Link,
  Row,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ROUTES } from "../../../../../../constants";
import * as TranslationsModule from "../../../../../Translations";
import { CellTypeHelper, OrientationHelper } from "../../../../helpers";
import { ListComponentPropertyModel } from "../../models";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";
import "./ListComponentProperties.scss";
import { ListComponentSourceBrowserModal } from "../ListComponentSourceBrowserModal";

export interface IListComponentPropertiesStateProps {
  isProcessingData: boolean;
}

export interface IListComponentPropertiesDispatchProps {}

export interface IListComponentPropertiesOwnProps {
  platformType: PlatformType;
}

export interface IListComponentPropertiesProps
  extends IListComponentPropertiesStateProps,
    IListComponentPropertiesDispatchProps,
    IListComponentPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}

export interface IListComponentPropertiesState
  extends IComponentPropertiesBaseState {
  playListSourceBrowserVisible: boolean;
  translationsBrowserVisible: boolean;
  sourceTypeOptions?: PlayListSourceType[];
}

export class ListComponentProperties extends ComponentPropertiesBase<
  IListComponentPropertiesProps,
  IListComponentPropertiesState,
  ListComponentPropertyModel
> {
  public static defaultProps = {
    platformType: PlatformType.Any,
    isProcessingData: false,
  };

  formRef = React.createRef<IFormInstance>();

  public state: Readonly<IListComponentPropertiesState> = {
    playListSourceBrowserVisible: false,
    translationsBrowserVisible: false,
  };

  getPropertiesModel(): ListComponentPropertyModel {
    const { component, platformType } = this.props;
    const properties = new ListComponentPropertyModel();
    properties.init(component, platformType);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    if (!component) {
      return;
    }

    component.Name = values.Name;
  };

  public onTitleTranslationKeyCancel = () => {
    this.setState({ translationsBrowserVisible: false });
  };

  public onTitleTranslationKeySelect = (row: IResourceModel) => {
    this.properties.TitleTranslationKey = row.ResourceKey;
    this.onPropertyChange("TitleTranslationKey");

    this.setState({ translationsBrowserVisible: false });
  };

  public onTitleTranslationKeyClear = () => {
    const { component, onComponentChange } = this.props;

    this.properties.TitleTranslationKey = undefined;
    this.properties.deleteProperty("TitleTranslationKey", component);

    if (onComponentChange) {
      onComponentChange(component);
    }
  };

  public renderTitleTranslationKeyProperty = () => {
    const { component, t } = this.props;
    const { translationsBrowserVisible } = this.state;

    let titleTranslationKeyView: React.ReactElement = this.properties
      .TitleTranslationKey ? (
      <label>
        <Link
          to={`${TranslationsModule.ROUTES.CONFIGURATION_TRANSLATION_DETAILS}/${this.properties.TitleTranslationKey}`}
        >
          {this.properties.TitleTranslationKey}
        </Link>
      </label>
    ) : (
      <label>{t("MODEL_TRANSLATION_KEY_PLACEHOLDER")}</label>
    );

    return (
      <Form.Item name="TitleTranslationKey" label={t("MODEL_TITLE_KEY")}>
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item>{titleTranslationKeyView}</Form.Item>
            <TranslationsModule.Components.TranslationsBrowserModal
              key={`TranslationsBrowser-${component.Id}`}
              visible={translationsBrowserVisible}
              onCancel={this.onTitleTranslationKeyCancel}
              onSelect={this.onTitleTranslationKeySelect}
            />
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {this.properties.TitleTranslationKey && (
              <Button
                icon={<Icon type="delete" />}
                onClick={this.onTitleTranslationKeyClear}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                this.setState({ translationsBrowserVisible: true });
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  public renderTitleProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="Title"
        label={t("MODEL_TITLE")}
        initialValue={this.properties.Title}
      >
        <Input
          disabled={isProcessingData}
          placeholder={t("MODEL_TITLE_PLACEHOLDER")}
          onChange={(e) => {
            this.properties.Title = e.target.value;
            this.onPropertyChange("Title");
          }}
        />
      </Form.Item>
    );
  };

  public onVisibleItemsPropertyChange = (value: string | number | null) => {
    this.properties.VisibleItemsCount = value ? +value : 1;
    this.onPropertyChange("VisibleItemsCount");
  };

  public renderVisibleItemsProperty = () => {
    const { isProcessingData, t } = this.props;

    let disableInput = false;
    let minValue = 1;
    let maxValue = 10;

    switch (this.properties.CellType) {
      case CellType.HeroLandscape:
      case CellType.Highlights:
      case CellType.HighlightsWidescreen:
      case CellType.Promo:
        disableInput = true;
        minValue = 1;
        maxValue = 1;
        break;
      case CellType.Round:
      case CellType.Square:
      case CellType.Cover:
        minValue = 1;
        maxValue = 10;
        break;
      case CellType.Frame:
      case CellType.Default:
      default:
        minValue = 1;
        maxValue = 10;
        break;
    }

    return (
      <Form.Item
        name="VisibleItemsCount"
        label={t("COMPONENT_PROPERTIES__VISIBLE_ITEMS")}
        initialValue={this.properties.VisibleItemsCount}
      >
        <InputNumber
          disabled={isProcessingData || disableInput}
          placeholder={t("COMPONENT_PROPERTIES__VISIBLE_ITEMS_PLACEHOLDER")}
          onChange={this.onVisibleItemsPropertyChange}
          min={minValue}
          max={maxValue}
          defaultValue={3}
        />
      </Form.Item>
    );
  };

  public onCellTypePropertyChange = (value: IChooseValue) => {
    this.properties.CellType = CellTypeHelper.getValue(value as string);
    this.onPropertyChange("CellType");

    if (
      value === CellType.HeroLandscape ||
      value === CellType.Highlights ||
      value === CellType.HighlightsWidescreen ||
      value === CellType.Promo
    ) {
      this.properties.VisibleItemsCount = 1;
      this.onPropertyChange("VisibleItemsCount");
      this.formRef?.current?.setFieldsValue({
        VisibleItemsCount: 1,
      });
    }
  };

  public renderCellTypeProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="CellType"
        label={t("COMPONENT_PROPERTIES__CELL_TYPE")}
        initialValue={this.properties.CellType}
      >
        <Choose
          disabled={isProcessingData}
          placeholder={t("COMPONENT_PROPERTIES__CELL_TYPE_PLACEHOLDER")}
          defaultValue={CellType.Default}
          onChange={this.onCellTypePropertyChange}
        >
          {CellTypeHelper.getOptions()}
        </Choose>
      </Form.Item>
    );
  };

  public onOrientationPropertyChange = (value: IChooseValue) => {
    this.properties.Orientation = OrientationHelper.getValue(value as string);
    this.onPropertyChange("Orientation");
  };

  public renderOrientationProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="Orientation"
        label={t("COMPONENT_PROPERTIES__ORIENTATION")}
        initialValue={this.properties.Orientation}
      >
        <Choose
          disabled={isProcessingData}
          placeholder={t("COMPONENT_PROPERTIES__ORIENTATION_PLACEHOLDER")}
          defaultValue={Orientation.Default}
          onChange={this.onOrientationPropertyChange}
        >
          {OrientationHelper.getOptions()}
        </Choose>
      </Form.Item>
    );
  };

  public onSourcePropertyCancel = () => {
    this.setState({ playListSourceBrowserVisible: false });
  };

  public onSourcePropertySelect = (row: IPlaylistSourceModel) => {
    this.properties.SourceId = row.Id;
    this.properties.SourceName = row.Name;

    if (row.Type === PlayListSourceType.Custom) {
      if (row.TypeOptions && row.TypeOptions.length > 0) {
        this.properties.SourceType = row.TypeOptions[0];
      } else {
        this.properties.SourceType = PlayListSourceType.Standard;
      }
    } else {
      this.properties.SourceType = row.Type;
    }
    this.onPropertyChange("SourceId");
    this.onPropertyChange("SourceName");
    this.onPropertyChange("SourceType");
    this.setState({
      playListSourceBrowserVisible: false,
      sourceTypeOptions: row.TypeOptions,
    });
  };

  public onSourcePropertyClear = () => {
    const { component, onComponentChange } = this.props;

    this.properties.SourceId = undefined;
    this.properties.SourceName = undefined;
    this.properties.deleteProperty("SourceId", component);
    this.properties.deleteProperty("SourceName", component);
    this.properties.deleteProperty("SourceType", component);

    if (onComponentChange) {
      onComponentChange(component);
    }
  };

  renderSourcePropertyLabel = () => {
    const { t } = this.props;

    if (!this.properties.SourceId) {
      return <label>{t("COMPONENT_PROPERTIES__SOURCE_PLACEHOLDER")}</label>;
    }

    let link = DataProvider.ApplicationConfiguration?.linkToPlaylist?.({
      Id: this.properties.SourceId,
      Name: this.properties.SourceName || `${this.properties.SourceId}`,
    });

    if (!link) {
      link = {
        url: `${ROUTES.ASSET_COLLECTION_DETAILS}/${this.properties.SourceId}`,
        target: "_self",
      };
    }

    return (
      <label>
        <Link target={link.target} to={link.url}>
          {this.properties.SourceName || this.properties.SourceId}
        </Link>
      </label>
    );
  };

  public onSourceTypePropertyChange = (value: IChooseValue) => {
    this.properties.SourceType = PlayListSourceTypeHelper.getValue(
      value as string
    );
    this.onPropertyChange("SourceType");
  };

  renderSourceTypeProperty = () => {
    const { t, isProcessingData } = this.props;
    const { sourceTypeOptions } = this.state;

    if (!this.properties.SourceType && sourceTypeOptions) {
      return null;
    }

    if (sourceTypeOptions && sourceTypeOptions.length > 1) {
      return (
        <Form.Item
          name="SourceType"
          label={t("COMPONENT_PROPERTIES__SOURCE_TYPE")}
          initialValue={this.properties.Orientation}
        >
          <Choose
            disabled={isProcessingData}
            defaultValue={this.properties.SourceType}
            onChange={this.onSourceTypePropertyChange}
          >
            {sourceTypeOptions.map((option) => (
              <ChooseOption value={option}>
                {PlayListSourceTypeHelper.getDescription(option)}
              </ChooseOption>
            ))}
          </Choose>
        </Form.Item>
      );
    }

    return (
      <Form.Item
        name="SourceType"
        label={t("COMPONENT_PROPERTIES__SOURCE_TYPE")}
      >
        <label>
          {PlayListSourceTypeHelper.getDescription(this.properties.SourceType)}
        </label>
      </Form.Item>
    );
  };

  public renderSourceProperty = () => {
    const { t } = this.props;
    const { playListSourceBrowserVisible } = this.state;

    return (
      <>
        <Form.Item
          name="SourceId"
          label={t("COMPONENT_PROPERTIES__SOURCE")}
          initialValue={{
            key: `${this.properties.SourceId}`,
            label: this.properties.SourceName,
          }}
        >
          <Row gutter={8}>
            <Col span={18}>
              <Form.Item style={{ margin: 0 }}>
                {this.renderSourcePropertyLabel()}
              </Form.Item>
              <ListComponentSourceBrowserModal
                visible={playListSourceBrowserVisible}
                onCancel={this.onSourcePropertyCancel}
                onSelect={this.onSourcePropertySelect}
              />
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
              {this.properties.SourceId && (
                <Button
                  icon={<Icon type="delete" />}
                  onClick={this.onSourcePropertyClear}
                  style={{ marginRight: "8px" }}
                />
              )}
              <Button
                icon={<Icon type="edit" />}
                onClick={() => {
                  this.setState({ playListSourceBrowserVisible: true });
                }}
              />
            </Col>
          </Row>
        </Form.Item>
        {this.renderSourceTypeProperty()}
      </>
    );
  };

  public renderIsVisibleProperty = () => {
    const { isProcessingData, t } = this.props;

    const onVisibilityChange = (e: ICheckboxChangeEvent) => {
      const isVisible = e.target.checked;
      this.properties.IsVisible = Boolean(isVisible);
      this.onPropertyChange("IsVisible");
    };

    return (
      <>
        <Form.Item
          name="IsVisible"
          label={t("CONFIGURATION_COMPONENT__IS_VISIBLE")}
          valuePropName="checked"
          initialValue={this.properties.IsVisible}
        >
          <Checkbox onChange={onVisibilityChange} disabled={isProcessingData} />
        </Form.Item>
      </>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 18 },
      },
    };

    return (
      <Form
        name="ListComponentProperties"
        className="ListComponentProperties"
        {...formItemLayout}
        onFinish={this.onFinish}
        ref={this.formRef}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {this.renderTitleProperty()}
            {this.renderTitleTranslationKeyProperty()}
            {this.renderVisibleItemsProperty()}
            {this.renderCellTypeProperty()}
            {this.renderOrientationProperty()}
            {this.renderSourceProperty()}
            {this.renderIsVisibleProperty()}
          </Col>
        </Row>
      </Form>
    );
  }
}
