import { ITEM_NAMES } from "../constants";
import { Choose, ChooseOption, ChooseValue, Form } from "@bms/common-ui";
import { VoucherDiscountType } from "@bms/common-services";
import { useTranslation } from "react-i18next";

export const DiscountTypeItem = () => {
  const [t] = useTranslation();

  const onChange = (value: ChooseValue) => {
    const discountType = value as VoucherDiscountType;

    if(discountType === VoucherDiscountType.Percentage) {
      
    }
  };

  return (
    <Form.Item
      name={ITEM_NAMES.DISCOUNT_TYPE}
      label={t("PAYMENT_VOUCHER_DISCOUNT_TYPE_COLUMN")}
      rules={[
        {
          required: true,
          message: t("REQUIRED_VALIDATION_MESSAGE"),
        },
      ]}
    >
      <Choose
        placeholder={t("PAYMENT_VOUCHER_FORM_SELECT_TYPE_PLACEHOLDER")}
        onChange={onChange}
      >
        {Object.values(VoucherDiscountType).map((discountType) => (
          <ChooseOption key={discountType} value={discountType}>
            {discountType === VoucherDiscountType.Fixed
              ? t("PAYMENT_VOUCHER_DISCOUNT_TYPE_COLUMN_FIXED_FILTER")
              : t("PAYMENT_VOUCHER_DISCOUNT_TYPE_COLUMN_PERCENTAGE_FILTER")}
          </ChooseOption>
        ))}
      </Choose>
    </Form.Item>
  );
};
