import {
  IBreadcrumbProps,
  PageHeader,
  PageContent,
  Icon,
  Button,
  Tabs,
  TabPane,
  useSearchParamsTabs,
  Spin,
} from "@bms/common-ui";
import { generateBreadcrumb } from "../../../../../helpers";
import { ROUTES } from "../../../constants";

import { useParams } from "react-router";

import { useTranslation } from "react-i18next";
import { useLeagueDetailsController } from "./useLeagueDetailsController";
import { DetailsTab } from "./tabs";

export const LeagueDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [activeTabKey] = useSearchParamsTabs("DETAILS");

  const {
    refresh,
    updateLeague,
    onBackClick,
    onDeleteClick,
    loading,
    dataPager,
    dataPagerLoading,
    updateLeagueState,
    league,
  } = useLeagueDetailsController();

  const leagueName = league?.Name;

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.LEAGUES}`,
        breadcrumbName: t("MENU_CMS_LEAGUES"),
      },
      {
        path: `${ROUTES.LEAGUE_DETAILS}/${id}`,
        breadcrumbName: leagueName ?? t("MENU_CMS_LEAGUE_DETAILS"),
      },
    ]);
  };

  return (
    <Spin spinning={loading || dataPagerLoading}>
      <PageContent>
        <PageHeader
          title={leagueName ?? t("MENU_CMS_LEAGUE_DETAILS")}
          breadcrumb={getBreadcrumbProps()}
          onBack={onBackClick}
          extra={
            <>
              {dataPager.enabled && (
                <>
                  <Button
                    key="action-move-previous"
                    icon={<Icon type="arrow-left" />}
                    onClick={dataPager.movePrevious}
                    title={t("BUTTON_MOVE_PREVIOUS")}
                    shape="circle"
                    disabled={!dataPager.movePreviousEnabled}
                  />
                  <Button
                    key="action-move-next"
                    icon={<Icon type="arrow-right" />}
                    title={t("BUTTON_MOVE_NEXT")}
                    onClick={dataPager.moveNext}
                    shape="circle"
                    disabled={!dataPager.moveNextEnabled}
                  />
                  <Button
                    key="action-reload"
                    icon={<Icon type="reload" />}
                    onClick={refresh}
                    title={t("BUTTON_REFRESH_TITLE")}
                    shape="circle"
                  />
                  <Button
                    danger
                    key="action-delete"
                    icon={<Icon type="delete" />}
                    title={t("BUTTON_DELETE")}
                    onClick={onDeleteClick}
                    shape="circle"
                  />
                </>
              )}
            </>
          }
        />
        <Tabs activeKey={activeTabKey} destroyInactiveTabPane>
          <TabPane key="DETAILS" tab={t("SPORT_LEAGUE_DETAILS_TAB_DETAILS")}>
            <DetailsTab
              updateLeagueState={updateLeagueState}
              updateLeague={updateLeague}
              league={league}
            />
          </TabPane>
        </Tabs>
      </PageContent>
    </Spin>
  );
};
