import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICommonAppState, SportAgeCategoryModel } from "@bms/common-services";
import {
  Button,
  IBreadcrumbProps,
  Icon,
  ITableColumnProps,
  Link,
  PageContent,
  PageHeader,
  Popconfirm,
  Table,
  TagUpToDate,
  useAppFeedback,
} from "@bms/common-ui";
import { SportStore } from "@bms/common-services";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { generateBreadcrumb } from "../../../../../helpers";
import { ROUTES } from "../../../constants";
import { FormModal } from "../../../../../components";
import { ROUTES as TRANSLATIONS_ROUTES } from "../../../../Translations";
import { AgeCategoriesForm } from "../AgeCategoriesForm";

const defaultAgeCategory: SportAgeCategoryModel = {
  Id: -1,
  Name: "",
  Code: "",
  Sequence: 0,
  TranslationKey: "",
  UpToDate: false,
};

export const AgeCategoriesList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const ageCategories = useSelector(SportStore.Selectors.ageCategoriesSelector);
  const actionType = useSelector(
    (state: ICommonAppState) => state.common.actionType
  );

  const isFetching = useSelector(
    (state: ICommonAppState) => state.sport.ageCategories.IsProcessing
  );

  const { notification } = useAppFeedback();

  const [showAgeCategoryModal, setShowAgeCategoryModal] = useState(false);

  const [editableAgeCategory, setEditableAgeCategory] = useState<
    SportAgeCategoryModel
  >(defaultAgeCategory);

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.AGE_CATEGORIES}`,
        breadcrumbName: t("MENU_SPORT_AGE_CATEGORIES"),
      },
    ]);
  };

  const getColumnsProps = (): Array<
    ITableColumnProps<SportAgeCategoryModel>
  > => {
    return [
      {
        key: "Code",
        dataIndex: "Code",
        title: t("MODEL_CODE"),
        render: (text: any, row: SportAgeCategoryModel) => {
          return (
            <a
              title={row.Code}
              className="imageUrl"
              onClick={() => {
                setShowAgeCategoryModal(true);
                setEditableAgeCategory(row);
              }}
            >
              {row.Code}
            </a>
          );
        },
      },
      {
        key: "Name",
        dataIndex: "Name",
        title: t("MODEL_NAME"),
      },
      {
        key: "DisplayName",
        dataIndex: "DisplayName",
        title: t("MODEL_DISPLAY_NAME"),
      },
      {
        key: "NameTranslationKey",
        dataIndex: "NameTranslationKey",
        className: "drag-visible",
        title: t("MODEL_TRANSLATION_KEY"),
        render: (text: any, row: SportAgeCategoryModel) => {
          return row.TranslationKey ? (
            <label>
              <Link
                to={`${TRANSLATIONS_ROUTES.CONFIGURATION_TRANSLATION_DETAILS}/${row.TranslationKey}`}
              >
                {row.TranslationKey}
              </Link>
            </label>
          ) : (
            ""
          );
        },
      },
      {
        key: "Sequence",
        dataIndex: "Sequence",
        title: t("Sequence"),
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_: any, row: SportAgeCategoryModel) => (
          <Popconfirm
            title={t(
              "DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION",
              "Are you sure you want to delete element?"
            )}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              dispatch(SportStore.Actions.deleteAgeCategory(row));
            }}
            okText={t("BUTTON_YES", "Yes")}
            cancelText={t("BUTTON_NO", "No")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT", "Delete element")}
            />
          </Popconfirm>
        ),
      },
      {
        key: "UpToDate",
        dataIndex: "UpToDate",
        title: t("MODEL_UP_TO_DATE"),
        render: (upToDate: boolean) => <TagUpToDate value={upToDate} />,
      },
    ];
  };

  const closeModal = () => {
    setShowAgeCategoryModal(false);
    setEditableAgeCategory(defaultAgeCategory);
  };

  const onAddNewAgeCategoryClick = () => {
    setShowAgeCategoryModal(true);
  };

  const onRefreshClick = () => {
    dispatch(SportStore.Actions.getAgeCategories());
  };

  const insertAgeCategory = (ageCategory: SportAgeCategoryModel) => {
    dispatch(SportStore.Actions.createAgeCategory(ageCategory));
  };

  const updateAgeCategory = (ageCategory: SportAgeCategoryModel) => {
    dispatch(SportStore.Actions.updateAgeCategory(ageCategory));
  };

  const getExtraButtons = () => {
    return (
      <>
        <Button
          key="add"
          shape="circle"
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAddNewAgeCategoryClick}
          title={t("DICTIONARY_AGE_CATEGORY_ADD_NEW")}
        />
        <Button
          key="reload"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={onRefreshClick}
          title={t("BUTTON_REFRESH_TITLE")}
        />
      </>
    );
  };

  useEffect(() => {
    dispatch(SportStore.Actions.getAgeCategories());
  }, [dispatch]);

  useEffect(() => {
    switch (actionType) {
      case SportStore.Consts.CREATE_AGE_CATEGORY_SUCCESS:
        onRefreshClick();
        closeModal();
        notification.success({
          message: t("AGE_CATEGORY_INSERT_SUCCESS"),
        });
        break;

      case SportStore.Consts.CREATE_AGE_CATEGORY_FAILURE:
        notification.error({
          message: t("AGE_CATEGORY_INSERT_FAILURE"),
          description: ageCategories.Error?.Message,
        });
        break;

      case SportStore.Consts.UPDATE_AGE_CATEGORY_SUCCESS:
        onRefreshClick();
        closeModal();
        notification.success({
          message: t("AGE_CATEGORY_UPDATE_SUCCESS"),
        });
        break;

      case SportStore.Consts.UPDATE_AGE_CATEGORY_FAILURE:
        notification.error({
          message: t("AGE_CATEGORY_UPDATE_FAILURE"),
          description: ageCategories.Error?.Message,
        });
        break;

      case SportStore.Consts.DELETE_AGE_CATEGORY_SUCCESS:
        onRefreshClick();
        closeModal();
        notification.success({
          message: t("AGE_CATEGORY_DELETE_SUCCESS"),
        });
        break;

      case SportStore.Consts.DELETE_AGE_CATEGORY_FAILURE:
        notification.error({
          message: t("AGE_CATEGORY_DELETE_FAILURE"),
          description: ageCategories.Error?.Message,
        });
        break;
    }
  }, [ageCategories.Error, actionType, t]);

  return (
    <div>
      <PageContent>
        <PageHeader
          title={t("DICTIONARY_AGE_CATEGORY_TITLE", "Age Categories")}
          breadcrumb={getBreadcrumbProps()}
          extra={getExtraButtons()}
        />
        <Table<SportAgeCategoryModel>
          columns={getColumnsProps()}
          dataSource={ageCategories.Data}
          loading={isFetching}
          pagination={false}
        />
      </PageContent>
      <FormModal
        isLoading={false}
        isVisible={showAgeCategoryModal}
        isNewForm={editableAgeCategory.Id < 0}
        isDeleteButtonEnabled={false}
        createFormTitle={t(
          "DICTIONARY_AGE_CATEGORY_MODAL_NEW",
          "New age category"
        )}
        modalClassName="DictionaryAgeCategoryModal"
        submitFormName="DictionaryAgeCategoriesForm"
        onCloseModal={closeModal}
        editFormTitle={t("DICTIONARY_AGE_CATEGORY_MODAL_EDIT")}
      >
        <AgeCategoriesForm
          ageCategory={editableAgeCategory}
          insertAgeCategory={insertAgeCategory}
          updateAgeCategory={updateAgeCategory}
          isProcessing={!!isFetching}
        />
      </FormModal>
    </div>
  );
};
