import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { SportStatisticsUnitModel } from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { PromisifiableBase } from "../Base";

export class SportStatisticsUnitsService extends PromisifiableBase {
  public getUnits = (): Observable<SportStatisticsUnitModel[]> =>
    new Observable(
      (observer: Observer<SportStatisticsUnitModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `/SportStatisticsUnits/Select`,
        })
    );

  public getUnit = (code: string): Observable<SportStatisticsUnitModel> =>
    new Observable(
      (observer: Observer<SportStatisticsUnitModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { code },
          },
          method: HTTP_METHOD.GET,
          url: `/SportStatisticsUnits/Get`,
        })
    );

  public createUnit = (
    data: SportStatisticsUnitModel
  ): Observable<SportStatisticsUnitModel> =>
    new Observable(
      (observer: Observer<SportStatisticsUnitModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/SportStatisticsUnits/Insert`,
        })
    );

  public updateUnit = (
    data: SportStatisticsUnitModel
  ): Observable<SportStatisticsUnitModel> =>
    new Observable(
      (observer: Observer<SportStatisticsUnitModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `/SportStatisticsUnits/Update`,
        })
    );

  public deleteUnit = (
    data: SportStatisticsUnitModel
  ): Observable<SportStatisticsUnitModel> =>
    new Observable(
      (observer: Observer<SportStatisticsUnitModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { code: data.Code },
          },
          method: HTTP_METHOD.DELETE,
          url: `/SportStatisticsUnits/Delete`,
        })
    );
}
