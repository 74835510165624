import {
  Button,
  Col,
  Form,
  Icon,
  IFormInstance,
  IFormValues,
  Input,
  REGEX,
  Row,
} from "@bms/common-ui";
import { ApplicationResourceType } from "@bms/common-services";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ApplicationCustomPropertyModel } from "../../models";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";
import { ImagePreviewModal } from "../ImagePreviewModal";
import { ApplicationResourceUploadModal } from "../ApplicationResourceUploadModal";

import "./ApplicationCustomProperties.scss";
export interface ApplicationCustomPropertiesStateProps {
  isProcessingData: boolean;
}

export interface ApplicationCustomPropertiesDispatchProps {}

export interface ApplicationCustomPropertiesOwnProps {}
export interface ApplicationCustomPropertiesProps
  extends ApplicationCustomPropertiesStateProps,
    ApplicationCustomPropertiesDispatchProps,
    ApplicationCustomPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}
export interface ApplicationCustomPropertiesState
  extends IComponentPropertiesBaseState {
  applicationResourceUploadModalVisible: boolean;
  backgroundPreviewModalVisible: boolean;
}
export class ApplicationCustomProperties extends ComponentPropertiesBase<
  ApplicationCustomPropertiesProps,
  ApplicationCustomPropertiesState,
  ApplicationCustomPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  formRef = React.createRef<IFormInstance>();

  public state: Readonly<ApplicationCustomPropertiesState> = {
    applicationResourceUploadModalVisible: false,
    backgroundPreviewModalVisible: false,
  };

  getPropertiesModel(): ApplicationCustomPropertyModel {
    const { component } = this.props;
    const properties = new ApplicationCustomPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    if (!component) {
      return;
    }

    component.Name = values.Name;
  };

  public onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitized = e.target.value.replace(REGEX.ROUTE_NAME, "");
    this.formRef?.current?.setFieldsValue({
      RouteName: sanitized,
    });
    this.properties.RouteName = sanitized;
    this.onPropertyChange("RouteName");
  };

  public renderRouteNameProperty = () => {
    const { t } = this.props;

    return (
      <Form.Item
        name="RouteName"
        label={t("CONFIGURATION_PROPERTY__ROUTE_NAME")}
        initialValue={this.properties.RouteName}
      >
        <Input
          name="RouteName"
          placeholder={"greatest-hits"}
          onChange={this.onInputChange}
        />
      </Form.Item>
    );
  };

  public onApplicationResourceUploadCancel = () => {
    this.setState({ applicationResourceUploadModalVisible: false });
  };

  public onApplicationResourceUploadSuccess = (
    filePath?: string,
    fileUrl?: string
  ) => {
    this.properties.BackgroundUrl = filePath;
    this.properties.BackgroundAbsoluteUrl = fileUrl;
    this.onPropertyChange("BackgroundUrl");
    this.formRef?.current?.setFieldsValue({
      BackgroundUrl: filePath,
    });
    this.setState({ applicationResourceUploadModalVisible: false });
  };

  public onShowBackgroundPreviewModal = () => {
    const videoUrl =
      this.properties.BackgroundAbsoluteUrl ?? this.properties.BackgroundUrl;
    if (!videoUrl) {
      return;
    }

    this.setState({ backgroundPreviewModalVisible: true });
  };

  public onCloseBackgroundPreviewModal = () => {
    this.setState({ backgroundPreviewModalVisible: false });
  };

  public renderBackgroundUrlProperty = () => {
    const { component, isProcessingData, t } = this.props;
    const {
      applicationResourceUploadModalVisible,
      backgroundPreviewModalVisible,
    } = this.state;

    return (
        <Row gutter={8}>
          <Col flex="auto">
            <Form.Item
              name="BackgroundUrl"
              label={t("CONFIGURATION_PROPERTY__BACKGROUND_URL")}
              initialValue={this.properties.BackgroundUrl}
            >
              <Input
                disabled={isProcessingData}
                placeholder={"https://"}
                onChange={(e) => {
                  this.properties.BackgroundUrl = e.target.value;
                  this.onPropertyChange("BackgroundUrl");
                }}
              />
            </Form.Item>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <ApplicationResourceUploadModal
              key={`ApplicationResourceUploadModal-${component.Id}`}
              visible={applicationResourceUploadModalVisible}
              applicationConfigurationId={component.ApplicationConfigurationId}
              resourceType={ApplicationResourceType.Image}
              titleTransKey="CONFIGURATION_BUTTON__UPLOAD_IMAGE_TITLE"
              onCancel={this.onApplicationResourceUploadCancel}
              onSuccess={this.onApplicationResourceUploadSuccess}
            />
            {this.properties.BackgroundAbsoluteUrl && (
              <>
                <ImagePreviewModal
                  visible={backgroundPreviewModalVisible}
                  imageSrc={this.properties.BackgroundAbsoluteUrl}
                  onCancel={this.onCloseBackgroundPreviewModal}
                />
                <Button
                  title={t("CONFIGURATION_BUTTON__PREVIEW_IMAGE_TITLE")}
                  style={{ marginRight: "8px" }}
                  icon={<Icon type="Eye" style={{ fontSize: "20px" }} />}
                  onClick={() => this.onShowBackgroundPreviewModal()}
                />
              </>
            )}
            <Button
              icon={<Icon type="upload" />}
              title={t("CONFIGURATION_BUTTON__UPLOAD_IMAGE_TITLE")}
              onClick={() => {
                this.setState({ applicationResourceUploadModalVisible: true });
              }}
            />
          </Col>
        </Row>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 20 },
      },
    };

    return (
      <Form
        ref={this.formRef}
        
        name="ApplicationCustomProperties"
        {...formItemLayout}
        onFinish={this.onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {this.renderRouteNameProperty()}
            {this.renderBackgroundUrlProperty()}
          </Col>
        </Row>
      </Form>
    );
  }
}
