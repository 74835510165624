import {
  IPeopleModel,
  PeopleService,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { useAppFeedback, useSyncedState } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { usePeopleDataPager } from "../../hooks";
import { ROUTES } from "../../constants";

const peopleService = new PeopleService().promisify();

export const usePeopleDetailsController = () => {
  const id = parseInt(useParams<{ id: string }>()["id"]);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [dataPager, dataPagerLoading] = usePeopleDataPager(id);

  const { data, loading, refresh } = useDataLoader({
    loader: () => peopleService.getPeople(id),
    deps: [id],
  });

  const [people, setPeople] = useSyncedState(() => data, [data]);
  const { modal, notification } = useAppFeedback();

  const [updatePeople, updatePeopleState] = useServiceCaller(
    async (data: IPeopleModel) => {
      const result = await peopleService.updatePeople(data);
      if (result.ok) {
        setPeople(result.data);
        notification.success({
          message: t("PEOPLE_DETAILS__PEOPLE_UPDATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("PEOPLE_DETAILS__PEOPLE_UPDATE_FAILURE"),
          description: result.error.Message,
        });
      }
    },
    []
  );

  const [deletePeople] = useServiceCaller(async () => {
    const result = await peopleService.deletePeople(people!);
    if (result.ok) {
      notification.success({
        message: t("PEOPLE_DETAILS__PEOPLE_DELETE_SUCCESS"),
      });
      history.push(ROUTES.PEOPLE_LIST);
    } else {
      notification.error({
        message: t("PEOPLE_DETAILS__PEOPLE_DELETE_FAILURE"),
        description: result.error?.Message,
      });
    }
  }, [people]);

  const onDeleteClick = () => {
    if (!people) {
      return;
    }

    modal.confirm({
      title: t("COMMON_DELETE"),
      content: t("DELETE_PEOPLE_MESSAGE", { peopleName: people?.FirstName }),
      okText: t("BUTTON_DELETE"),
      cancelText: t("BUTTON_CANCEL"),
      onOk: deletePeople,
    });
  };

  const onBackClick = () => {
    if (location.state && location.state.hasOwnProperty("from")) {
      // use location from router state
      const state = location.state as { from: Location };
      history.push(state.from);
    } else {
      // use people list location
      history.goBack();
    }
  };

  return {
    updatePeopleState,
    people,
    loading,
    dataPager,
    dataPagerLoading,
    refresh,
    updatePeople,
    onDeleteClick,
    onBackClick,
    t,
  };
};
