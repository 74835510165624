import React, { useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AssetChildrenList, IAssetChildrenType } from "../../AssetChildrenList";
import { ICommonAssetDetailsTabProps } from "./ICommonAssetDetailsTabProps";

export const ProgramsTab = React.forwardRef<
  IAssetChildrenType,
  ICommonAssetDetailsTabProps
>(({ asset }: ICommonAssetDetailsTabProps, ref) => {
  const { t } = useTranslation();
  const assetChildrenListRef = useRef<IAssetChildrenType>(null);

  useImperativeHandle(ref, () => ({
    refresh() {
      assetChildrenListRef?.current?.refresh?.();
    },
  }));

  return (
    <AssetChildrenList
      key={`AssetContent-${asset?.Id}`}
      ref={assetChildrenListRef}
      title={t("ASSET_DETAILS_SECTION_PROGRAMS_LIST")}
      asset={asset}
    />
  );
});
