import { ApplicationResourceType, IResourceModel } from "@bms/common-services";
import {
  Button,
  Checkbox,
  Col,
  Form,
  ICheckboxChangeEvent,
  Icon,
  IFormInstance,
  IFormValues,
  Input,
  Link,
  Row,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import * as TranslationsModule from "../../../../../Translations";
import { ApplicationHeaderItemPropertyModel } from "../../models";
import { ActionProperties } from "../ActionProperties";
import { ApplicationResourceUploadModal } from "../ApplicationResourceUploadModal";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";
import { ImagePreview } from "../ImagePreview";
import "./ApplicationHeaderItemProperties.scss";

export interface IApplicationHeaderItemPropertiesStateProps {
  isProcessingData: boolean;
}

export interface IApplicationHeaderItemPropertiesDispatchProps {}

export interface IApplicationHeaderItemPropertiesOwnProps {}

export interface IApplicationHeaderItemPropertiesProps
  extends IApplicationHeaderItemPropertiesStateProps,
    IApplicationHeaderItemPropertiesDispatchProps,
    IApplicationHeaderItemPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}

export interface IApplicationHeaderItemPropertiesState
  extends IComponentPropertiesBaseState {
  translationsBrowserVisible: boolean;
  iconResourceUploadModalVisible: boolean;
}

export class ApplicationHeaderItemProperties extends ComponentPropertiesBase<
  IApplicationHeaderItemPropertiesProps,
  IApplicationHeaderItemPropertiesState,
  ApplicationHeaderItemPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  public state: Readonly<IApplicationHeaderItemPropertiesState> = {
    translationsBrowserVisible: false,
    iconResourceUploadModalVisible: false,
  };

  formRef = React.createRef<IFormInstance>();

  getPropertiesModel(): ApplicationHeaderItemPropertyModel {
    const { component } = this.props;
    const properties = new ApplicationHeaderItemPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    if (!component) {
      return;
    }

    component.Name = values.Name;
  };

  public onTitleTranslationKeyCancel = () => {
    this.setState({ translationsBrowserVisible: false });
  };

  public onTitleTranslationKeySelect = (row: IResourceModel) => {
    this.properties.TitleTranslationKey = row.ResourceKey;
    this.onPropertyChange("TitleTranslationKey");

    this.setState({ translationsBrowserVisible: false });
  };

  public onTitleTranslationKeyClear = () => {
    const { component, onComponentChange } = this.props;

    this.properties.TitleTranslationKey = undefined;
    this.properties.deleteProperty("TitleTranslationKey", component);

    if (onComponentChange) {
      onComponentChange(component);
    }
  };

  public renderTitleTranslationKeyProperty = () => {
    const { component, t } = this.props;
    const { translationsBrowserVisible } = this.state;

    let titleTranslationKeyView: React.ReactElement = this.properties
      .TitleTranslationKey ? (
      <label>
        <Link
          to={`${TranslationsModule.ROUTES.CONFIGURATION_TRANSLATION_DETAILS}/${this.properties.TitleTranslationKey}`}
        >
          {this.properties.TitleTranslationKey}
        </Link>
      </label>
    ) : (
      <label>{t("MODEL_TRANSLATION_KEY_PLACEHOLDER")}</label>
    );

    return (
      <Form.Item
        name="TitleTranslationKey"
        label={t("MODEL_TITLE_KEY")}
        style={{ marginBottom: 0 }}
      >
        <Row gutter={[8, 8]}>
          <Col span={18}>
            <Form.Item>{titleTranslationKeyView}</Form.Item>
            <TranslationsModule.Components.TranslationsBrowserModal
              key={`TranslationsBrowser-${component.Id}`}
              visible={translationsBrowserVisible}
              onCancel={this.onTitleTranslationKeyCancel}
              onSelect={this.onTitleTranslationKeySelect}
            />
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {this.properties.TitleTranslationKey && (
              <Button
                icon={<Icon type="delete" />}
                onClick={this.onTitleTranslationKeyClear}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                this.setState({ translationsBrowserVisible: true });
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  public renderTitleProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="Title"
        label={t("MODEL_TITLE")}
        initialValue={this.properties.Title}
      >
        <Input
          disabled={isProcessingData}
          placeholder={t("MODEL_TITLE_PLACEHOLDER")}
          onChange={(e) => {
            this.properties.Title = e.target.value;
            this.onPropertyChange("Title");
          }}
        />
      </Form.Item>
    );
  };

  public renderActionProperty = () => {
    const { component, onComponentChange } = this.props;

    return (
      <ActionProperties
        key={`Action-${component.Id}`}
        component={component}
        onComponentChange={onComponentChange}
      />
    );
  };

  public onIconResourceUploadCancel = () => {
    this.setState({ iconResourceUploadModalVisible: false });
  };

  public onIconResourceUploadSuccess = (
    filePath?: string,
    fileUrl?: string
  ) => {
    this.properties.IconUrl = filePath;
    this.properties.IconAbsoluteUrl = fileUrl;
    this.onPropertyChange("IconUrl");
    this.formRef?.current?.setFieldsValue({
      IconUrl: filePath,
    });
    this.setState({ iconResourceUploadModalVisible: false });
  };

  public renderIconUrlProperty = () => {
    const { isProcessingData, t, component } = this.props;
    const { iconResourceUploadModalVisible } = this.state;

    const iconPreviewUrl =
      this.properties.IconAbsoluteUrl || this.properties.IconUrl;

    return (
      <>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item
              name="IconUrl"
              label={t("MODEL_ICON_URL")}
              initialValue={this.properties.IconUrl}
            >
              <Input
                disabled={isProcessingData}
                placeholder={t("https://")}
                onChange={(e) => {
                  this.properties.IconUrl = e.target.value;
                  this.onPropertyChange("IconUrl");
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 16]}>
          <Col span={21} push={5}>
            <ImagePreview imageSrc={iconPreviewUrl} />
          </Col>
          <Col span={3} style={{ textAlign: "right" }}>
            <ApplicationResourceUploadModal
              key={`ApplicationResourceUploadModal-${component.Id}`}
              visible={iconResourceUploadModalVisible}
              applicationConfigurationId={component.ApplicationConfigurationId}
              resourceType={ApplicationResourceType.Image}
              onCancel={this.onIconResourceUploadCancel}
              onSuccess={this.onIconResourceUploadSuccess}
            />
            <Button
              icon={<Icon type="upload" />}
              title={t("CONFIGURATION_BUTTON__UPLOAD_VIDEO_TITLE")}
              onClick={() => {
                this.setState({ iconResourceUploadModalVisible: true });
              }}
            />
          </Col>
        </Row>
      </>
    );
  };

  public renderIsVisibleProperty = () => {
    const { isProcessingData, t } = this.props;

    const onVisibilityChange = (e: ICheckboxChangeEvent) => {
      const isVisible = e.target.checked;
      this.properties.IsVisible = Boolean(isVisible);
      this.onPropertyChange("IsVisible");
    };

    return (
      <>
        <Form.Item
          name="IsVisible"
          label={t("CONFIGURATION_COMPONENT__IS_VISIBLE")}
          valuePropName="checked"
          initialValue={this.properties.IsVisible}
        >
          <Checkbox onChange={onVisibilityChange} disabled={isProcessingData} />
        </Form.Item>
      </>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 19 },
      },
    };

    return (
      <Form
        name="ApplicationHeaderItemProperties"
        {...formItemLayout}
        onFinish={this.onFinish}
        ref={this.formRef}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {this.renderTitleProperty()}
            {this.renderTitleTranslationKeyProperty()}
            {this.renderIconUrlProperty()}
            {this.renderActionProperty()}
            {this.renderIsVisibleProperty()}
          </Col>
        </Row>
      </Form>
    );
  }
}
