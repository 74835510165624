import React, { useState } from "react";

import {
  AssetType,
  IAssetModel,
  IAssetTypeModel,
  ICommonAppState,
} from "@bms/common-services";
import {
  Choose,
  ChooseOption,
  ChooseValue,
  Form,
  required,
} from "@bms/common-ui";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const assetSelector = (state: ICommonAppState) => state.asset;

interface ITypeFieldProps extends IAssetFormFieldCommonProps {
  typeState?: AssetType;
  parent?: IAssetModel;
  onChange?: (val: AssetType) => void;
}

export const TypeField = ({
  isEditMode,
  typeState,
  parent,
  onChange,
  isHidden = false,
}: ITypeFieldProps) => {
  const { t } = useTranslation();
  const {
    assetTypes: {
      data: assetTypesData = [],
      isFetching: assetTypesDataLoading,
    } = {},
  } = useSelector(assetSelector);
  const [assetType, setAssetType] = useState(typeState);

  const handleChange = (value: ChooseValue) => {
    setAssetType(value as AssetType);
    onChange?.(value as AssetType);
  };

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="AssetTypeCode"
      label={t("MODEL_TYPE_CODE")}
      key="type"
      initialValue={typeState}
      rules={[required()]}
    >
      <Choose
        placeholder={t("MODEL_TYPE_PLACEHOLDER")}
        loading={assetTypesDataLoading}
        disabled={isEditMode || !!parent}
        onChange={handleChange}
        value={assetType}
      >
        {assetTypesData.map((assetType: IAssetTypeModel) => (
          <ChooseOption key={assetType.Code} value={assetType.Code}>
            {assetType.DisplayName}
          </ChooseOption>
        ))}
      </Choose>
    </Form.Item>
  );
};
