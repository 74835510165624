import React from "react";
import { Form, Input, required } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
import { AssetType } from "@bms/common-services";

interface ITitleFieldProps extends IAssetFormFieldCommonProps { }

export const TitleField = ({
  isHidden,
  isEditMode,
  asset,
}: ITitleFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="Title"
      label={t("MODEL_TITLE")}
      key="title"
      initialValue={isEditMode ? asset?.Title || "" : ""}
      rules={[required()]}
    >
      <Input placeholder={t("MODEL_TITLE_PLACEHOLDER")} disabled={asset?.AssetTypeCode === AssetType.Person} />
    </Form.Item>
  );
};
