export const ROUTES = {
  CUSTOMER_LIST: "/customer/list",
  CUSTOMER_DETAILS: "/customer/details",
  ADMINISTRATOR_LIST: "/administrator/list",
  ADMINISTRATOR_DETAILS: "/administrator/details",
  USER_BASE: "/user",
  USER_LIST: "/user/list",
  USER_DETAILS: "/user/details",
  USER_CREATE: "/user/create",
  SUBSCRIPTION_LIST: "/subscriptions/list",
};
