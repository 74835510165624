import { Button, Col, Form, Icon, Input, required, Row } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ISportTeamModel } from "@bms/common-services";
import { useState } from "react";
import { IMatchesFormFieldCommonProps } from "./IMatchesFormFieldCommonProps";
import { SyncedTeam } from "../useMatchesFormController";
import { TeamsBrowserModal } from "../../../Team/TeamsBrowserModal";

interface IHomeTeamFieldProps extends IMatchesFormFieldCommonProps {
  onSelect?: (row?: ISportTeamModel) => void;
  onClear?: () => void;
  homeTeam: SyncedTeam;
}

export const HomeTeamField = ({
  match,
  homeTeam,
  isHidden,
  onSelect,
  onClear,
}: IHomeTeamFieldProps) => {
  const { t } = useTranslation();

  const [teamBrowserVisible, setTeamBrowserVisible] = useState(false);

  const onTeamSelect = (row: ISportTeamModel) => {
    setTeamBrowserVisible(false);
    onSelect?.(row);
  };

  const defaultValue = homeTeam?.Name;

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="HomeTeamId"
      label={t("MODEL_HOME_TEAM")}
      key="homeTeamId"
      initialValue={defaultValue}
      rules={[required()]}
    >
      <Row gutter={8}>
        <Col flex="auto">
          <Input
            placeholder={t("MODEL_SPORT_TEAM_SELECT_PLACEHOLDER")}
            disabled
            value={defaultValue}
          />
          <TeamsBrowserModal
            visible={teamBrowserVisible}
            onCancel={() => setTeamBrowserVisible(false)}
            onSelect={onTeamSelect}
          />
        </Col>
        <Col style={{ textAlign: "right" }}>
          {homeTeam?.Id && (
            <Button
              icon={<Icon type="delete" />}
              onClick={onClear}
              style={{ marginRight: "8px" }}
            />
          )}
          <Button
            icon={<Icon type="edit" />}
            onClick={() => setTeamBrowserVisible(true)}
          />
        </Col>
      </Row>
    </Form.Item>
  );
};
