import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { empty, from, of } from "rxjs";
import {
  catchError,
  map,
  mergeMap,
  startWith,
  switchMap,
  take,
} from "rxjs/operators";
import { ICommonAppState } from "../";
import { RecordStatus } from "../../enums";
import { GuidHelper } from "../../helpers";
import {
  IAssetAgeRestrictionModel,
  IAssetCategoriesListModel,
  IAssetCategoryModel,
  IAssetCommentListModel,
  IAssetCommentModel,
  IAssetContentModel,
  IAssetContentTypeModel,
  IAssetEventsListModel,
  IAssetImageModel,
  IAssetImageTypeModel,
  IAssetInAssetModel,
  IAssetInCollectionListModel,
  IAssetInCollectionModel,
  IAssetListModel,
  IAssetModel,
  IAssetPeopleListModel,
  IAssetTypeModel,
  IErrorModel,
  OperationResult,
  UploadFileInfoModel,
} from "../../models";
import {
  IAssetContentStreamTypeModel,
  IAssetInAssetListModel,
} from "../../models/Asset";
import { DataProvider } from "../../providers";
import {
  AssetCategoryService,
  AssetCommentService,
  AssetEventsService,
  AssetInAssetService,
  AssetInCollectionService,
  AssetPaymentsService,
  AssetService,
  StorageService,
  UsersInAssetEventService,
} from "../../services";
import {
  addAssetCommentFailure,
  addAssetCommentSuccess,
  addAssetContentFailure,
  addAssetContentSuccess,
  addAssetImageFailure,
  addAssetImageSuccess,
  addAssetInCollectionFailure,
  addAssetInCollectionSuccess,
  browseAssetCollectionFailure,
  browseAssetCollectionSuccess,
  browseAssetsFailure,
  browseAssetsSuccess,
  buyAssetFailure,
  buyAssetSuccess,
  createAssetCollection,
  createAssetCollectionFailure,
  createAssetCollectionSuccess,
  createAssetFailure,
  createAssetsCollectionFailure,
  createAssetsCollectionSuccess,
  createAssetSuccess,
  deleteAssetCategoryFailure,
  deleteAssetCategorySuccess,
  deleteAssetContentFailure,
  deleteAssetContentSuccess,
  deleteAssetFailure,
  deleteAssetImageFailure,
  deleteAssetImageSuccess,
  deleteAssetInAssetFailure,
  deleteAssetInAssetSuccess,
  deleteAssetInCollectionFailure,
  deleteAssetInCollectionSuccess,
  deleteAssetsCollectionFailure,
  deleteAssetsCollectionSuccess,
  deleteAssetSuccess,
  getAssetAgeRestrictionsFailure,
  getAssetAgeRestrictionsSuccess,
  getAssetCommentFailure,
  getAssetCommentSuccess,
  getAssetContentStreamTypesFailure,
  getAssetContentStreamTypesSuccess,
  getAssetContentTypesFailure,
  getAssetContentTypesSuccess,
  getAssetContentUploadFileInfoFailure,
  getAssetContentUploadFileInfoSuccess,
  getAssetContentUploadUrlFailure,
  getAssetContentUploadUrlSuccess,
  getAssetFailure,
  getAssetImageTypesFailure,
  getAssetImageTypesSuccess,
  getAssetsCollectionFailure,
  getAssetsCollectionSuccess,
  getAssetSuccess,
  getAssetTypesFailure,
  getAssetTypesSuccess,
  getContentUploadUrlFailure,
  getContentUploadUrlSuccess,
  getUploadFileInfoFailure,
  getUploadFileInfoSuccess,
  insertAssetCategoryFailure,
  insertAssetCategorySuccess,
  insertAssetInAssetFailure,
  insertAssetInAssetSuccess,
  saveAssetsInCollection,
  saveAssetsInCollectionFailure,
  saveAssetsInCollectionSuccess,
  searchAssetCategoriesFailure,
  searchAssetCategoriesSuccess,
  searchAssetCollectionFailure,
  searchAssetCollectionSuccess,
  searchAssetCommentsFailure,
  searchAssetCommentsMoreFailure,
  searchAssetCommentsMoreSuccess,
  searchAssetCommentsSuccess,
  searchAssetEventsFailure,
  searchAssetEventsSuccess,
  searchAssetFailure,
  searchAssetMoreFailure,
  searchAssetMoreSuccess,
  searchAssetPeopleFailure,
  searchAssetPeopleSuccess,
  searchAssetsByAssetCollectionIdFailure,
  searchAssetsByAssetCollectionIdSuccess,
  searchAssetSuccess,
  selectAssetInCollectionFailure,
  selectAssetInCollectionSuccess,
  selectAssetParentFailure,
  selectAssetParentSuccess,
  selectAssetsInAssetFailure,
  selectAssetsInAssetSuccess,
  updateAssetCategoryFailure,
  updateAssetCategorySuccess,
  updateAssetContentFailure,
  updateAssetContentSuccess,
  updateAssetFailure,
  updateAssetImageFailure,
  updateAssetImageSuccess,
  updateAssetInAssetFailure,
  updateAssetInAssetSuccess,
  updateAssetInCollectionFailure,
  updateAssetInCollectionSuccess,
  updateAssetsCollectionFailure,
  updateAssetsCollectionSuccess,
  updateAssetSuccess,
  uploadAssetImageFailure,
  uploadAssetImageSuccess,
} from "./actions";
import * as Consts from "./consts";
import {
  IAddAssetCommentAction,
  IAddAssetContentAction,
  IAddAssetImageAction,
  IAddAssetInCollectionAction,
  IBrowseAssetAction,
  IBrowseAssetCollectionAction,
  IBuyAssetAction,
  ICreateAssetAction,
  ICreateAssetCollectionAction,
  ICreateAssetsCollectionAction,
  IDeleteAssetAction,
  IDeleteAssetCategoryAction,
  IDeleteAssetContentAction,
  IDeleteAssetImageAction,
  IDeleteAssetInAssetAction,
  IDeleteAssetInCollectionAction,
  IDeleteAssetsCollectionAction,
  IGetAssetAction,
  IGetAssetAgeRestrictionsAction,
  IGetAssetCommentAction,
  IGetAssetContentStreamTypesAction,
  IGetAssetContentTypesAction,
  IGetAssetContentUploadFileInfoAction,
  IGetAssetContentUploadUrlAction,
  IGetAssetImageTypesAction,
  IGetAssetsCollectionAction,
  IGetAssetTypesAction,
  IGetContentUploadUrlAction,
  IGetUploadFileInfoAction,
  IInsertAssetCategoryAction,
  IInsertAssetInAssetAction,
  ISaveAssetsInCollectionAction,
  ISearchAssetAction,
  ISearchAssetCategoriesAction,
  ISearchAssetCollectionAction,
  ISearchAssetCommentsAction,
  ISearchAssetCommentsMoreAction,
  ISearchAssetEventsAction,
  ISearchAssetMoreAction,
  ISearchAssetPeopleAction,
  ISearchAssetsByAssetCollectionIdAction,
  ISelectAssetInCollectionAction,
  ISelectAssetParentAction,
  ISelectAssetsInAssetAction,
  IUpdateAssetAction,
  IUpdateAssetCategoryAction,
  IUpdateAssetContentAction,
  IUpdateAssetImageAction,
  IUpdateAssetInAssetAction,
  IUpdateAssetInCollectionAction,
  IUpdateAssetsCollectionAction,
  IUploadAssetImageAction,
} from "./types";

const storageService: StorageService = StorageService.getInstance();
const assetService: AssetService = new AssetService();
const assetCategoryService: AssetCategoryService = new AssetCategoryService();
const assetEventsService: AssetEventsService = new AssetEventsService();
const assetCommentService: AssetCommentService = new AssetCommentService();
const assetInCollectionService: AssetInCollectionService = new AssetInCollectionService();
const assetPaymentsService: AssetPaymentsService = new AssetPaymentsService();
const usersInAssetEventService: UsersInAssetEventService = new UsersInAssetEventService();
const assetInAssetService: AssetInAssetService = new AssetInAssetService();

const browseAssetsEpic = (
  action$: ActionsObservable<IBrowseAssetAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.BROWSE_ASSET),
    switchMap((action: IBrowseAssetAction) =>
      assetService.search(action.filter, {}).pipe(
        map((data: IAssetListModel) => {
          data.Filter = action.filter;

          return browseAssetsSuccess(data);
        }),
        catchError((error: IErrorModel) => of(browseAssetsFailure(error)))
      )
    )
  );

const searchAssetsEpic = (
  action$: ActionsObservable<ISearchAssetAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_ASSET),
    switchMap((action: ISearchAssetAction) =>
      assetService.search(action.filter).pipe(
        map((data: IAssetListModel) => {
          if (action.callback) {
            action.callback(data.Entities);
          }

          data.Filter = action.filter;

          return searchAssetSuccess(data);
        }),
        catchError((error: IErrorModel) => of(searchAssetFailure(error)))
      )
    )
  );

const getAssetEpic = (
  action$: ActionsObservable<IGetAssetAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_ASSET),
    switchMap((action: IGetAssetAction) =>
      assetService.getAsset(action.id).pipe(
        map((data: IAssetModel) => {
          return getAssetSuccess(data);
        }),
        catchError((error: IErrorModel) => of(getAssetFailure(error)))
      )
    )
  );

const createAssetEpic = (
  action$: ActionsObservable<ICreateAssetAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.CREATE_ASSET),
    switchMap((action: ICreateAssetAction) =>
      assetService.createAsset(action.data).pipe(
        map((response: IAssetModel) => {
          action.callback && action.callback();
          return createAssetSuccess(response);
        }),
        catchError((error: IErrorModel) => of(createAssetFailure(error)))
      )
    )
  );

const updateAssetEpic = (
  action$: ActionsObservable<IUpdateAssetAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_ASSET),
    switchMap((action: IUpdateAssetAction) =>
      assetService.updateAsset(action.data).pipe(
        map((response: IAssetModel) => {
          action.callback && action.callback();
          return updateAssetSuccess(response);
        }),
        catchError((error: IErrorModel) => of(updateAssetFailure(error)))
      )
    )
  );

const deleteAssetEpic = (
  action$: ActionsObservable<IDeleteAssetAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_ASSET),
    switchMap((action: IDeleteAssetAction) =>
      assetService.deleteAsset(action.data).pipe(
        map(() => {
          return deleteAssetSuccess();
        }),
        catchError((error: IErrorModel) => of(deleteAssetFailure(error)))
      )
    )
  );

const selectAssetInCollectionEpic = (
  action$: ActionsObservable<ISelectAssetInCollectionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_ASSET_IN_COLLECTION),
    switchMap((action: ISelectAssetInCollectionAction) =>
      assetInCollectionService
        .select({}, { assetCollectionId: action.assetCollectionId })
        .pipe(
          map((data: IAssetInCollectionListModel) => {
            return selectAssetInCollectionSuccess(data);
          }),
          catchError((error: IErrorModel) =>
            of(selectAssetInCollectionFailure(error))
          )
        )
    )
  );

const deleteAssetInCollectionEpic = (
  action$: ActionsObservable<IDeleteAssetInCollectionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_ASSET_IN_COLLECTION),
    switchMap((action: IDeleteAssetInCollectionAction) =>
      assetInCollectionService.delete(action.payload).pipe(
        map(() => {
          return deleteAssetInCollectionSuccess();
        }),
        catchError((error: IErrorModel) =>
          of(deleteAssetInCollectionFailure(error))
        )
      )
    )
  );

const addAssetInCollectionEpic = (
  action$: ActionsObservable<IAddAssetInCollectionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.ADD_ASSET_IN_COLLECTION),
    switchMap((action: IAddAssetInCollectionAction) =>
      assetInCollectionService.insert(action.payload).pipe(
        map((data: IAssetInCollectionModel) => {
          return addAssetInCollectionSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(addAssetInCollectionFailure(error))
        )
      )
    )
  );

const updateAssetInCollectionEpic = (
  action$: ActionsObservable<IUpdateAssetInCollectionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_ASSET_IN_COLLECTION),
    switchMap((action: IUpdateAssetInCollectionAction) =>
      assetInCollectionService.update(action.payload).pipe(
        map((data: IAssetInCollectionModel) => {
          return updateAssetInCollectionSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(updateAssetInCollectionFailure(error))
        )
      )
    )
  );

const searchAssetMoreEpic = (
  action$: ActionsObservable<ISearchAssetMoreAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_ASSET_MORE),
    switchMap((action: ISearchAssetMoreAction) =>
      assetService.search(action.filter, { includeImages: true }).pipe(
        map((data: IAssetListModel) => searchAssetMoreSuccess(data)),
        catchError((error: IErrorModel) => of(searchAssetMoreFailure(error)))
      )
    )
  );

const getAssetContentUploadUrlEpic = (
  action$: ActionsObservable<IGetAssetContentUploadUrlAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_ASSET_CONTENT_UPLOAD_URL),
    switchMap((action: IGetAssetContentUploadUrlAction) =>
      assetService.getAssetContentUploadUrl(action.assetId).pipe(
        map((response: UploadFileInfoModel) => {
          return getAssetContentUploadUrlSuccess(action.assetId, response);
        }),
        catchError((error: IErrorModel) =>
          of(getAssetContentUploadUrlFailure(error))
        )
      )
    )
  );

const getUploadFileInfoEpic = (
  action$: ActionsObservable<IGetUploadFileInfoAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_UPLOAD_FILE_INFO),
    switchMap((action: IGetUploadFileInfoAction) =>
      assetService.getUploadFileInfo(action.assetId).pipe(
        map((response: UploadFileInfoModel) => {
          return getUploadFileInfoSuccess(response);
        }),
        catchError((error: IErrorModel) => of(getUploadFileInfoFailure(error)))
      )
    )
  );

const getContentUploadUrlEpic = (
  action$: ActionsObservable<IGetContentUploadUrlAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_CONTENT_UPLOAD_URL),
    switchMap((action: IGetContentUploadUrlAction) =>
      assetService.getContentUploadUrl(action.assetId).pipe(
        map((response: UploadFileInfoModel) => {
          return getContentUploadUrlSuccess(response);
        }),
        catchError((error: IErrorModel) =>
          of(getContentUploadUrlFailure(error))
        )
      )
    )
  );

const uploadAssetImageEpic = (
  action$: ActionsObservable<IUploadAssetImageAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPLOAD_ASSET_IMAGE),
    switchMap((action: IUploadAssetImageAction) =>
      from(storageService.uploadFile(action.file, action.fileUploadInfo)).pipe(
        map((response: OperationResult<UploadFileInfoModel>) => {
          return uploadAssetImageSuccess(response);
        }),
        catchError((error: IErrorModel) => of(uploadAssetImageFailure(error)))
      )
    )
  );

const updateAssetImageEpic = (
  action$: ActionsObservable<IUpdateAssetImageAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_ASSET_IMAGE),
    switchMap((action: IUpdateAssetImageAction) =>
      assetService.updateAssetImage(action.payload).pipe(
        map((response: IAssetImageModel) => {
          return updateAssetImageSuccess(response);
        }),
        catchError((error: IErrorModel) => of(updateAssetImageFailure(error)))
      )
    )
  );

const deleteAssetImageEpic = (
  action$: ActionsObservable<IDeleteAssetImageAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_ASSET_IMAGE),
    switchMap((action: IDeleteAssetImageAction) =>
      assetService.deleteAssetImage(action.payload).pipe(
        map((response: IAssetImageModel) => {
          return deleteAssetImageSuccess(response);
        }),
        catchError((error: IErrorModel) => of(deleteAssetImageFailure(error)))
      )
    )
  );

const addAssetImageEpic = (
  action$: ActionsObservable<IAddAssetImageAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.ADD_ASSET_IMAGE),
    switchMap((action: IAddAssetImageAction) =>
      assetService.addAssetImage(action.payload).pipe(
        map((response: IAssetImageModel) => {
          return addAssetImageSuccess(response);
        }),
        catchError((error: IErrorModel) => of(addAssetImageFailure(error)))
      )
    )
  );

const getAssetContentUploadFileInfoEpic = (
  action$: ActionsObservable<IGetAssetContentUploadFileInfoAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_ASSET_CONTENT_UPLOAD_FILE_INFO),
    switchMap((action: IGetAssetContentUploadFileInfoAction) =>
      assetService
        .getAssetContentUploadFileInfo({
          AssetId: action.assetId,
          AssetContentGuid: action.assetContentGuid,
          AssetStreamTypeCode: action.assetStreamTypeCode,
          ContentType: action.contentType,
          UploadType: action.uploadType,
        })
        .pipe(
          map((response: UploadFileInfoModel) => {
            return getAssetContentUploadFileInfoSuccess(
              action.assetId,
              response
            );
          }),
          catchError((error: IErrorModel) =>
            of(getAssetContentUploadFileInfoFailure(error))
          )
        )
    )
  );

const addAssetContentEpic = (
  action$: ActionsObservable<IAddAssetContentAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.ADD_ASSET_CONTENT),
    switchMap((action: IAddAssetContentAction) =>
      assetService.addAssetContent(action.payload).pipe(
        map((response: IAssetContentModel) => {
          return addAssetContentSuccess(response);
        }),
        catchError((error: IErrorModel) => of(addAssetContentFailure(error)))
      )
    )
  );

const updateAssetContentEpic = (
  action$: ActionsObservable<IUpdateAssetContentAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_ASSET_CONTENT),
    switchMap((action: IUpdateAssetContentAction) =>
      assetService.updateAssetContent(action.payload).pipe(
        map((response: IAssetContentModel) => {
          return updateAssetContentSuccess(response);
        }),
        catchError((error: IErrorModel) => of(updateAssetContentFailure(error)))
      )
    )
  );

const deleteAssetContentEpic = (
  action$: ActionsObservable<IDeleteAssetContentAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_ASSET_CONTENT),
    switchMap((action: IDeleteAssetContentAction) =>
      assetService.deleteAssetContent(action.payload).pipe(
        map((response: IAssetContentModel) => {
          return deleteAssetContentSuccess(response);
        }),
        catchError((error: IErrorModel) => of(deleteAssetContentFailure(error)))
      )
    )
  );

const createAssetCollectionEpic = (
  action$: ActionsObservable<ICreateAssetCollectionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.CREATE_ASSET_COLLECTION),
    switchMap((action: ICreateAssetCollectionAction) =>
      assetService
        .createAssetCollection({
          Name: GuidHelper.newGuid(),
          Description: action.data.Title,
        })
        .pipe(
          map(createAssetCollectionSuccess),
          catchError((error: IErrorModel) => {
            return of(createAssetCollectionFailure(error));
          })
        )
    )
  );

const saveAssetsInCollectionEpic = (
  action$: ActionsObservable<ISaveAssetsInCollectionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SAVE_ASSETS_IN_COLLECTION),
    switchMap((action: ISaveAssetsInCollectionAction) => {
      const assets = action.payload.assets.map((asset) => ({
        AssetCollectionId: action.payload.collection.Id,
        AssetId: asset.Id,
        AssetTitle: asset.Title,
        IsDefinition: false,
        RecordStatus: RecordStatus.Inserted,
      }));

      return assetService.saveAssetsInCollection(assets).pipe(
        map(saveAssetsInCollectionSuccess),
        catchError((error: IErrorModel) => {
          return of(saveAssetsInCollectionFailure(error));
        })
      );
    })
  );

const createAssetCollectionAndSaveAssetsEpic = (
  action$: ActionsObservable<any>
) =>
  action$.pipe(
    ofType(Consts.CREATE_ASSET_COLLECTION_AND_SAVE_ASSETS),
    mergeMap(({ payload: { assets, callback, collection } }) =>
      action$.pipe(
        ofType(Consts.CREATE_ASSET_COLLECTION_SUCCESS),
        take(1),
        mergeMap((createdCollection) =>
          action$.pipe(
            ofType(Consts.SAVE_ASSETS_IN_COLLECTION_SUCCESS),
            take(1),
            mergeMap(() => {
              callback(createdCollection.data, assets);
              return empty();
            }),
            startWith(saveAssetsInCollection(createdCollection.data, assets))
          )
        ),
        startWith(createAssetCollection(collection))
      )
    )
  );

const getAssetsCollectionEpic = (
  action$: ActionsObservable<IGetAssetsCollectionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_ASSETS_COLLECTION),
    switchMap(async (action: IGetAssetsCollectionAction) => {
      try {
        if (!DataProvider.AssetCollection?.get) {
          return getAssetsCollectionFailure({
            Message: "Not implemented method 'get'",
          });
        }
        const data = await DataProvider.AssetCollection.get(action.id);

        return getAssetsCollectionSuccess(data);
      } catch (error) {
        return getAssetsCollectionFailure(error as IErrorModel);
      }
    })
  );

const updateAssetsCollectionEpic = (
  action$: ActionsObservable<IUpdateAssetsCollectionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_ASSETS_COLLECTION),
    switchMap(async (action: IUpdateAssetsCollectionAction) => {
      try {
        if (!DataProvider.AssetCollection?.update) {
          return updateAssetsCollectionFailure({
            Message: "Not implemented method 'update'",
          });
        }
        const data = await DataProvider.AssetCollection.update(action.payload);

        return updateAssetsCollectionSuccess(data);
      } catch (error) {
        return updateAssetsCollectionFailure(error as IErrorModel);
      }
    })
  );

const createAssetsCollectionEpic = (
  action$: ActionsObservable<ICreateAssetsCollectionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.CREATE_ASSETS_COLLECTION),
    switchMap(async (action: ICreateAssetsCollectionAction) => {
      try {
        if (!DataProvider.AssetCollection?.insert) {
          return createAssetsCollectionFailure({
            Message: "Not implemented method 'insert'",
          });
        }
        const data = await DataProvider.AssetCollection.insert(action.payload);

        return createAssetsCollectionSuccess(data);
      } catch (error) {
        return createAssetsCollectionFailure(error as IErrorModel);
      }
    })
  );

const deleteAssetsCollectionEpic = (
  action$: ActionsObservable<IDeleteAssetsCollectionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_ASSETS_COLLECTION),
    switchMap(async (action: IDeleteAssetsCollectionAction) => {
      try {
        if (!DataProvider.AssetCollection?.delete) {
          return deleteAssetsCollectionFailure({
            Message: "Not implemented method 'delete'",
          });
        }
        await DataProvider.AssetCollection.delete(action.payload);

        return deleteAssetsCollectionSuccess();
      } catch (error) {
        return deleteAssetsCollectionFailure(error as IErrorModel);
      }
    })
  );

const searchAssetsByAssetCollectionIdEpic = (
  action$: ActionsObservable<ISearchAssetsByAssetCollectionIdAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_ASSETS_BY_ASSET_COLLECTION_ID),
    switchMap(async (action: ISearchAssetsByAssetCollectionIdAction) => {
      try {
        if (!DataProvider.AssetCollection?.searchAssetsByAssetCollectionId) {
          return searchAssetsByAssetCollectionIdFailure({
            Message: "Not implemented method 'searchAssetsByAssetCollectionId'",
          });
        }
        const data = await DataProvider.AssetCollection.searchAssetsByAssetCollectionId(
          action.filter
        );
        data.Filter = action.filter;

        return searchAssetsByAssetCollectionIdSuccess(data);
      } catch (error) {
        return searchAssetsByAssetCollectionIdFailure(error as IErrorModel);
      }
    })
  );

const searchAssetCollectionEpic = (
  action$: ActionsObservable<ISearchAssetCollectionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_ASSETS_COLLECTION),
    switchMap(async (action: ISearchAssetCollectionAction) => {
      try {
        if (!DataProvider.AssetCollection?.search) {
          return searchAssetCollectionFailure({
            Message: "Not implemented method 'search'",
          });
        }
        const data = await DataProvider.AssetCollection.search(action.filter);
        data.Filter = action.filter;

        return searchAssetCollectionSuccess(data);
      } catch (error) {
        return searchAssetCollectionFailure(error as IErrorModel);
      }
    })
  );

const browseAssetCollectionEpic = (
  action$: ActionsObservable<IBrowseAssetCollectionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.BROWSE_ASSETS_COLLECTION),
    switchMap(async (action: IBrowseAssetCollectionAction) => {
      try {
        if (!DataProvider.AssetCollection?.search) {
          return browseAssetCollectionFailure({
            Message: "Not implemented method 'search'",
          });
        }
        const data = await DataProvider.AssetCollection.search(action.filter);
        data.Filter = action.filter;

        return browseAssetCollectionSuccess(data);
      } catch (error) {
        return browseAssetCollectionFailure(error as IErrorModel);
      }
    })
  );

const getAssetTypesEpic = (
  action$: ActionsObservable<IGetAssetTypesAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_ASSET_TYPES),
    switchMap(() =>
      assetService.getAssetTypes().pipe(
        map((response: IAssetTypeModel[]) => {
          return getAssetTypesSuccess(response);
        }),
        catchError((error: IErrorModel) => of(getAssetTypesFailure(error)))
      )
    )
  );

const getAssetImageTypesEpic = (
  action$: ActionsObservable<IGetAssetImageTypesAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_ASSET_IMAGE_TYPES),
    switchMap(() =>
      assetService.getAssetImageTypes().pipe(
        map((response: IAssetImageTypeModel[]) => {
          return getAssetImageTypesSuccess(response);
        }),
        catchError((error: IErrorModel) => of(getAssetImageTypesFailure(error)))
      )
    )
  );

const getAssetContentTypesEpic = (
  action$: ActionsObservable<IGetAssetContentTypesAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_ASSET_CONTENT_TYPES),
    switchMap(() =>
      assetService.getAssetContentTypes().pipe(
        map((data: IAssetContentTypeModel[]) => {
          if (!Array.isArray(data)) {
            return getAssetContentTypesFailure({});
          }

          return getAssetContentTypesSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(getAssetContentTypesFailure(error))
        )
      )
    )
  );

const getAssetContentStreamTypesEpic = (
  action$: ActionsObservable<IGetAssetContentStreamTypesAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_ASSET_CONTENT_STREAM_TYPES),
    switchMap(() =>
      assetService.getAssetContentStreamTypes().pipe(
        map((data: IAssetContentStreamTypeModel[]) => {
          if (!Array.isArray(data)) {
            return getAssetContentStreamTypesFailure({});
          }

          return getAssetContentStreamTypesSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(getAssetContentStreamTypesFailure(error))
        )
      )
    )
  );

const getAssetAgeRestrictionEpic = (
  action$: ActionsObservable<IGetAssetAgeRestrictionsAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_ASSET_AGE_RESTRICTIONS),
    switchMap(() =>
      assetService.getAssetAgeRestriction().pipe(
        map((data: IAssetAgeRestrictionModel[]) => {
          return getAssetAgeRestrictionsSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(getAssetAgeRestrictionsFailure(error))
        )
      )
    )
  );

const searchAssetCategoriesEpic = (
  action$: ActionsObservable<ISearchAssetCategoriesAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_ASSET_CATEGORIES),
    switchMap((action: ISearchAssetCategoriesAction) =>
      assetService.searchAssetCategories(action.filter).pipe(
        map((data: IAssetCategoriesListModel) => {
          data.Filter = action.filter;
          return searchAssetCategoriesSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(searchAssetCategoriesFailure(error))
        )
      )
    )
  );

const searchAssetPeopleEpic = (
  action$: ActionsObservable<ISearchAssetPeopleAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_ASSET_PEOPLE),
    switchMap((action: ISearchAssetPeopleAction) =>
      assetService.searchAssetPeople(action.filter).pipe(
        map((data: IAssetPeopleListModel) => {
          return searchAssetPeopleSuccess(data);
        }),
        catchError((error: IErrorModel) => of(searchAssetPeopleFailure(error)))
      )
    )
  );

const getAssetCommentEpic = (
  action$: ActionsObservable<IGetAssetCommentAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_ASSET_COMMENT),
    switchMap((action: IGetAssetCommentAction) =>
      assetCommentService.get(action.id).pipe(
        map((response: IAssetCommentModel) => {
          return getAssetCommentSuccess(response);
        }),
        catchError((error: IErrorModel) => of(getAssetCommentFailure(error)))
      )
    )
  );

const searchAssetCommentEpic = (
  action$: ActionsObservable<ISearchAssetCommentsAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_ASSET_COMMENTS),
    switchMap((action: ISearchAssetCommentsAction) =>
      assetCommentService.search(action.filter).pipe(
        map((data: IAssetCommentListModel) => {
          data.Filter = action.filter;

          return searchAssetCommentsSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(searchAssetCommentsFailure(error))
        )
      )
    )
  );

const searchAssetCommentMoreEpic = (
  action$: ActionsObservable<ISearchAssetCommentsMoreAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_ASSET_COMMENTS_MORE),
    switchMap((action: ISearchAssetCommentsMoreAction) =>
      assetCommentService.search(action.filter).pipe(
        map((data: IAssetCommentListModel) => {
          data.Filter = action.filter;
          return searchAssetCommentsMoreSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(searchAssetCommentsMoreFailure(error))
        )
      )
    )
  );

const addAssetCommentEpic = (
  action$: ActionsObservable<IAddAssetCommentAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.ADD_ASSET_COMMENT),
    switchMap((action: IAddAssetCommentAction) =>
      assetCommentService.insert(action.payload).pipe(
        map((data: IAssetCommentModel) => {
          if (action.onSuccess) {
            action.onSuccess(data);
          }

          return addAssetCommentSuccess(data);
        }),
        catchError((error: IErrorModel) => {
          if (action.onError) {
            action.onError(error);
          }

          return of(addAssetCommentFailure(action.payload, error));
        })
      )
    )
  );

const searchAssetEventsEpic = (
  action$: ActionsObservable<ISearchAssetEventsAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_ASSET_EVENTS),
    switchMap((action: ISearchAssetEventsAction) =>
      assetEventsService.search(action.filter).pipe(
        map((response: IAssetEventsListModel) => {
          return searchAssetEventsSuccess(response);
        }),
        catchError((error: IErrorModel) => of(searchAssetEventsFailure(error)))
      )
    )
  );

const insertAssetCategoryEpic = (
  action$: ActionsObservable<IInsertAssetCategoryAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.INSERT_ASSET_CATEGORY),
    switchMap((action: IInsertAssetCategoryAction) =>
      assetCategoryService.insert(action.payload).pipe(
        map((data: IAssetCategoryModel) => {
          return insertAssetCategorySuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(insertAssetCategoryFailure(error))
        )
      )
    )
  );

const updateAssetCategoryEpic = (
  action$: ActionsObservable<IUpdateAssetCategoryAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_ASSET_CATEGORY),
    switchMap((action: IUpdateAssetCategoryAction) =>
      assetCategoryService.update(action.payload).pipe(
        map((data: IAssetCategoryModel) => {
          return updateAssetCategorySuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(updateAssetCategoryFailure(error))
        )
      )
    )
  );

const deleteAssetCategoryEpic = (
  action$: ActionsObservable<IDeleteAssetCategoryAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_ASSET_CATEGORY),
    switchMap((action: IDeleteAssetCategoryAction) =>
      assetCategoryService.delete(action.payload).pipe(
        map(() => {
          return deleteAssetCategorySuccess();
        }),
        catchError((error: IErrorModel) =>
          of(deleteAssetCategoryFailure(error))
        )
      )
    )
  );

const buyAssetEventEpic = (
  action$: ActionsObservable<IBuyAssetAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.BUY_ASSET),
    switchMap((action: IBuyAssetAction) =>
      assetPaymentsService.buy(action.assetId).pipe(
        map((response) => {
          window.open(response.RedirectUrl, "_self");
          return buyAssetSuccess(response);
        }),
        catchError((error: IErrorModel) => of(buyAssetFailure(error)))
      )
    )
  );

const selectAssetParentEpic = (
  action$: ActionsObservable<ISelectAssetParentAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_ASSET_PARENT),
    switchMap((action: ISelectAssetParentAction) =>
      assetService.selectAssetParent(action.id).pipe(
        map((data: IAssetModel[]) => {
          return selectAssetParentSuccess(data);
        }),
        catchError((error: IErrorModel) => of(selectAssetParentFailure(error)))
      )
    )
  );

const selectAssetsInAssetEpic = (
  action$: ActionsObservable<ISelectAssetsInAssetAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_ASSETS_IN_ASSET),
    switchMap((action: ISelectAssetsInAssetAction) =>
      assetInAssetService.select({ AssetParentId: action.AssetParentId }).pipe(
        map((data: IAssetInAssetListModel) => {
          return selectAssetsInAssetSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(selectAssetsInAssetFailure(error))
        )
      )
    )
  );

const insertAssetInAssetEpic = (
  action$: ActionsObservable<IInsertAssetInAssetAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.INSERT_ASSET_IN_ASSET),
    switchMap((action: IInsertAssetInAssetAction) =>
      assetInAssetService.insert(action.payload).pipe(
        map((data: IAssetInAssetModel) => {
          return insertAssetInAssetSuccess(data);
        }),
        catchError((error: IErrorModel) => of(insertAssetInAssetFailure(error)))
      )
    )
  );

const updateAssetInAssetEpic = (
  action$: ActionsObservable<IUpdateAssetInAssetAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_ASSET_IN_ASSET),
    switchMap((action: IUpdateAssetInAssetAction) =>
      assetInAssetService.update(action.payload).pipe(
        map((data: IAssetInAssetModel) => {
          return updateAssetInAssetSuccess(data);
        }),
        catchError((error: IErrorModel) => of(updateAssetInAssetFailure(error)))
      )
    )
  );

const deleteAssetInAssetEpic = (
  action$: ActionsObservable<IDeleteAssetInAssetAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_ASSET_IN_ASSET),
    switchMap((action: IDeleteAssetInAssetAction) =>
      assetInAssetService.delete(action.payload).pipe(
        map(() => {
          return deleteAssetInAssetSuccess();
        }),
        catchError((error: IErrorModel) => of(deleteAssetInAssetFailure(error)))
      )
    )
  );

export const assetEpics = [
  searchAssetsEpic,
  getAssetEpic,
  createAssetEpic,
  updateAssetEpic,
  deleteAssetEpic,
  browseAssetsEpic,
  searchAssetMoreEpic,
  getAssetContentUploadUrlEpic,
  createAssetCollectionEpic,
  saveAssetsInCollectionEpic,
  createAssetCollectionAndSaveAssetsEpic,
  getAssetsCollectionEpic,
  updateAssetsCollectionEpic,
  createAssetsCollectionEpic,
  deleteAssetsCollectionEpic,
  searchAssetCollectionEpic,
  searchAssetsByAssetCollectionIdEpic,
  browseAssetCollectionEpic,
  getAssetTypesEpic,
  getAssetAgeRestrictionEpic,
  searchAssetCategoriesEpic,
  searchAssetPeopleEpic,
  getUploadFileInfoEpic,
  getContentUploadUrlEpic,
  uploadAssetImageEpic,
  updateAssetImageEpic,
  addAssetImageEpic,
  deleteAssetImageEpic,
  getAssetImageTypesEpic,
  getAssetContentTypesEpic,
  getAssetContentUploadFileInfoEpic,
  addAssetContentEpic,
  deleteAssetContentEpic,
  deleteAssetInCollectionEpic,
  addAssetInCollectionEpic,
  updateAssetInCollectionEpic,
  browseAssetCollectionEpic,
  getAssetCommentEpic,
  searchAssetCommentEpic,
  searchAssetCommentMoreEpic,
  selectAssetInCollectionEpic,
  addAssetCommentEpic,
  searchAssetEventsEpic,
  insertAssetCategoryEpic,
  updateAssetCategoryEpic,
  deleteAssetCategoryEpic,
  buyAssetEventEpic,
  selectAssetParentEpic,
  updateAssetContentEpic,
  insertAssetInAssetEpic,
  selectAssetsInAssetEpic,
  deleteAssetInAssetEpic,
  updateAssetInAssetEpic,
  getAssetContentStreamTypesEpic,
];
