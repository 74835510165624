import { DatePicker, Form } from "@bms/common-ui";
import { TimeHelper } from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { IPeopleFormFieldCommonProps } from "./IPeopleFormFieldCommonProps";

interface IDateOfBirthFieldProps extends IPeopleFormFieldCommonProps {}

export const DateOfBirthField = ({
  isHidden,
  isEditMode,
  people,
}: IDateOfBirthFieldProps) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="DateOfBirth"
      label={t("MODEL_DATE_OF_BIRTH")}
      key="DateOfBirth"
      initialValue={
        isEditMode && people?.DateOfBirth
          ? new Date(people?.DateOfBirth)
          : undefined
      }
      rules={[
        {
          type: "object",
          message: t("REQUIRED_VALIDATION_MESSAGE"),
        },
        {
          validator: (_, value) => {
            if (!value) {
              return Promise.resolve();
            }
            return TimeHelper.isBeforeCurrent(value, "day")
              ? Promise.resolve()
              : Promise.reject(t("BIRTH_DATE_VALIDATION_MESSAGE"));
          },
        },
      ]}
    >
      <DatePicker
        placeholder={t("MODEL_DATE_OF_BIRTH_PLACEHOLDER")}
        style={{ width: "100%" }}
      />
    </Form.Item>
  );
};
