import {
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  IApplicationComponentPropertyValueModel,
  PropertyType,
} from "@bms/common-services";
import { ComponentPropertyModel } from "./ComponentPropertyModel";

export class ApplicationSectionMenuPropertyModel extends ComponentPropertyModel {
  BackgroundUrl?: string;

  BackgroundAbsoluteUrl?: string;

  Items?: IApplicationComponentPropertyValueModel[];

  IsVisible: boolean = true;

  constructor(component?: IApplicationComponentModel) {
    super();

    if (component) {
      this.init(component);
    }
  }

  initProperties(component: IApplicationComponentModel): void {
    if (!component.Properties) {
      component.Properties = [];
    }

    let itemsProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Items"
    );

    if (!itemsProperty) {
      this.onPropertyChange("Items", component);
    }

    let backgroundUrlProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "BackgroundUrl"
    );

    if (!backgroundUrlProperty) {
      this.onPropertyChange("BackgroundUrl", component);
    }
  }

  setProperties(component: IApplicationComponentModel): void {
    const isNewlyCreatedComponent =
      !Boolean(component.Properties?.length) && component.Id < 0;

    let itemsProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Items"
    );

    if (itemsProperty && itemsProperty.Values) {
      this.Items = itemsProperty.Values;
    }

    let backgroundUrlProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "BackgroundUrl"
    );

    if (backgroundUrlProperty && backgroundUrlProperty.Value) {
      this.BackgroundUrl = backgroundUrlProperty.Value.StringValue;
      this.BackgroundAbsoluteUrl = backgroundUrlProperty.Value.UrlValue;
    }

    const isVisibleProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "IsVisible"
    );

    if (
      isVisibleProperty &&
      isVisibleProperty.Value &&
      typeof isVisibleProperty.Value.BooleanValue === "boolean"
    ) {
      this.IsVisible = isVisibleProperty.Value.BooleanValue;
    } else if (isNewlyCreatedComponent) {
      // Attach default visibility property to new component.
      this.onPropertyChange("IsVisible", component);
    }
  }

  setProperty(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Items":
        property.PropertyType = PropertyType.Component;
        property.IsEnumerable = true;
        break;
      case "BackgroundUrl":
        property.PropertyType = PropertyType.Url;
        property.IsEnumerable = false;
        break;
      case "IsVisible":
        property.PropertyType = PropertyType.Boolean;
        property.IsEnumerable = false;
        break;
    }
  }

  setPropertyValue(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Items":
        if (property.Values) {
          property.Values = this.Items;
        }
        break;
      case "BackgroundUrl":
        if (property.Value) {
          property.Value.StringValue = this.BackgroundUrl;
          property.Value.UrlValue = this.BackgroundAbsoluteUrl;
        }
        break;
      case "IsVisible":
        if (property.Value) {
          property.Value.BooleanValue = this.IsVisible;
        }
        break;
    }
  }
}
