import { IResourceModel, SportPlayerPositionModel } from "@bms/common-services";
import {
  Button,
  Col,
  Form,
  Icon,
  IFormValues,
  Input,
  Link,
  required,
  Row,
  Spin,
  Switch,
} from "@bms/common-ui";
import * as TranslationsModule from "../../../../Translations";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type IPlayerPositionsFormProps = {
  playerPosition: SportPlayerPositionModel;
  isProcessing: boolean;
  insertPlayerPosition: (playerPosition: SportPlayerPositionModel) => void;
  updatePlayerPosition: (playerPosition: SportPlayerPositionModel) => void;
};

export const PlayerPositionsForm = ({
  isProcessing,
  insertPlayerPosition,
  updatePlayerPosition,
  playerPosition,
}: IPlayerPositionsFormProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [translationsBrowserVisible, setTranslationsBrowserVisible] = useState(
    false
  );
  const [nameTranslationKey, setNameTranslationKey] = useState<string>(
    playerPosition.TranslationKey || ""
  );

  const isNewPlayerPosition = playerPosition.Id < 0;

  const onFinish = async (values: IFormValues) => {
    const playerPositionContent = values as SportPlayerPositionModel;

    if (nameTranslationKey) {
      playerPositionContent.TranslationKey = nameTranslationKey;
    }

    if (isNewPlayerPosition) {
      delete playerPositionContent.DisplayName;
      insertPlayerPosition(playerPositionContent);
      return;
    }
    playerPositionContent.Sequence = parseInt(values.Sequence, 10);
    updatePlayerPosition({
      ...playerPosition,
      ...playerPositionContent,
      DisplayName: undefined,
    });
  };

  const onTitleTranslationKeyCancel = () =>
    setTranslationsBrowserVisible(false);

  const onTitleTranslationKeySelect = (row: IResourceModel) => {
    setNameTranslationKey(row.ResourceKey);
    setTranslationsBrowserVisible(false);
  };

  const renderSequenceField = () => {
    if (!isNewPlayerPosition) {
      return (
        <Form.Item
          name="Sequence"
          label={t("MODEL_SEQUENCE")}
          rules={[required()]}
        >
          <Input placeholder={t("MODEL_SEQUENCE")} />
        </Form.Item>
      );
    }
  };

  const renderNameTranslationKey = () => {
    let titleTranslationKeyView: React.ReactElement = nameTranslationKey ? (
      <label>
        <Link
          to={`${TranslationsModule.ROUTES.CONFIGURATION_TRANSLATION_DETAILS}/${nameTranslationKey}`}
        >
          {nameTranslationKey}
        </Link>
      </label>
    ) : (
      <label>{t("MODEL_TRANSLATION_KEY_PLACEHOLDER")}</label>
    );

    return (
      <Form.Item
        name="NameTranslationKey"
        label={t("DICTIONARY_CONSENT_FORM__NAME_TRANSLATION_KEY")}
        style={{ marginBottom: 0 }}
      >
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item>
              {titleTranslationKeyView}
              <Input
                hidden
                value={nameTranslationKey}
                placeholder={t(
                  "DICTIONARY_CONSENT_FORM__TRANSLATION_KEY_PLACEHOLDER"
                )}
              />
            </Form.Item>
            <TranslationsModule.Components.TranslationsBrowserModal
              key={`TranslationsBrowser-${playerPosition.Id}`}
              visible={translationsBrowserVisible}
              onCancel={onTitleTranslationKeyCancel}
              onSelect={onTitleTranslationKeySelect}
            />
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {nameTranslationKey && (
              <Button
                icon={<Icon type="delete" />}
                onClick={() => {
                  setNameTranslationKey("");
                }}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                setTranslationsBrowserVisible(true);
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <Spin spinning={isProcessing}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={
          !isNewPlayerPosition
            ? { ...playerPosition, Sequence: String(playerPosition.Sequence) }
            : {}
        }
        className="DictionaryPlayerPositionModal"
        name="DictionaryPlayerPositionsForm"
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            <Form.Item
              name="Code"
              label={t("MODEL_CODE", "Code")}
              rules={[required()]}
            >
              <Input placeholder={t("MODEL_CODE", "Code")} />
            </Form.Item>

            <Form.Item
              name="Name"
              label={t("MODEL_NAME", "Name")}
              rules={[required()]}
            >
              <Input placeholder={t("MODEL_NAME", "Name")} />
            </Form.Item>

            {renderNameTranslationKey()}
            {renderSequenceField()}
            <Form.Item>
              <Row gutter={8}>
                <Col>
                  <Form.Item name="UpToDate" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="UpToDate">
                    <label>{t("MODEL_UP_TO_DATE", "Up to date")}</label>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
