export const ITEM_NAMES = {
  DISCOUNT_TYPE: "DiscountType",
  VALUE: "Value",
  CURRENCY_ID: "CurrencyId",
  REDEEM_TYPE: "RedeemType",
  VALID_TO: "ValidTo",
  REDEMPTION_LIMIT: "RedemptionLimit",
  REDEEMED_COUNT: "RedeemedCount",
  CREATED_BY: "CreatedBy",
  USER: "UserInfo",
  CREATED: "Created",
  CURRENT_VALUE: "CurrentValue",
} as const;
