import { ContentStatus } from "../enums";

export class ContentStatusHelper {
  static getDescription(value: ContentStatus): string {
    switch (value) {
      case "UPLOADING":
        return "Uploading";
      case "READY":
        return "Ready";
      case "QUEUED":
        return "Queued";
      case "PROCESSING":
        return "Processing";
      case "ERROR":
        return "Error";
      default:
        return "Undefined";
    }
  }

  static getColor(value: ContentStatus): string {
    let color: string;

    switch (value) {
      case ContentStatus.Uploading:
        color = "#2962ff";
        break;
      case ContentStatus.Ready:
        color = "#418841";
        break;
      case ContentStatus.Queued:
        color = "#1890ff";
        break;
      case ContentStatus.Processing:
        color = "#2db7f5";
        break;
      case ContentStatus.Synchronizing:
        color = "#830491";
        break;
      case ContentStatus.Error:
        color = "#cc4444";
        break;
      case ContentStatus.Canceled:
        color = "#9e4113";
        break;
      default:
        color = "#555";
        break;
    }

    return color;
  }

  static getColorByValue(value: string | undefined): string {
    switch (value) {
      case "UPLOADING":
        return "#2962ff";
      case "READY":
        return "#418841";
      case "QUEUED":
        return "#1890ff";
      case "PROCESSING":
        return "#2db7f5";
      case "SYNCHRONIZING":
        return "#830491";
      case "ERROR":
        return "#cc4444";
      case "CANCELED":
        return "#9e4113";
      default:
        return "#555";
    }
  }

  static getValue(value: string): ContentStatus {
    switch (value) {
      case "UPLOADING":
        return ContentStatus.Uploading;
      case "READY":
        return ContentStatus.Ready;
      case "QUEUED":
        return ContentStatus.Queued;
      case "PROCESSING":
        return ContentStatus.Processing;
      case "SYNCHRONIZING":
        return ContentStatus.Synchronizing;
      case "ERROR":
        return ContentStatus.Error;
      case "CANCELED":
        return ContentStatus.Canceled;
      default:
        return ContentStatus.Ready;
    }
  }

  static getFilterOptions() {
    return [
      {
        text: ContentStatusHelper.getDescription(ContentStatus.Uploading),
        value: ContentStatus.Uploading,
      },
      {
        text: ContentStatusHelper.getDescription(ContentStatus.Ready),
        value: ContentStatus.Ready,
      },
      {
        text: ContentStatusHelper.getDescription(ContentStatus.Queued),
        value: ContentStatus.Queued,
      },
      {
        text: ContentStatusHelper.getDescription(ContentStatus.Processing),
        value: ContentStatus.Processing,
      },
      {
        text: ContentStatusHelper.getDescription(ContentStatus.Error),
        value: ContentStatus.Error,
      },
    ];
  }
}
