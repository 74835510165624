import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  EntityListModel,
  PaginationModel,
  ISportTeamListModel,
  ISportTeamSearchFilterModel,
  ISportTeamModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class SportTeamService extends SimpleServiceBase<
  ISportTeamModel,
  ISportTeamListModel,
  number,
  ISportTeamSearchFilterModel
> {
  get url(): string {
    return "/SportTeams";
  }

  public getTeamsList = (
    data: ISportTeamModel,
    pagination: PaginationModel
  ): Observable<EntityListModel<ISportTeamModel>> =>
    new Observable(
      (observer: Observer<EntityListModel<ISportTeamModel>>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: pagination,
          },
          data,
          method: HTTP_METHOD.POST,
          url: `/SportTeams/Search`,
        })
    );

  public getTeam = (id: number): Observable<ISportTeamModel> =>
    new Observable(
      (observer: Observer<ISportTeamModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id },
          },
          method: HTTP_METHOD.GET,
          url: `/SportTeams/Get`,
        })
    );

  public createTeam = (data: ISportTeamModel): Observable<ISportTeamModel> =>
    new Observable(
      (observer: Observer<ISportTeamModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/SportTeams/Insert`,
        })
    );

  public updateTeam = (data: ISportTeamModel): Observable<ISportTeamModel> =>
    new Observable(
      (observer: Observer<ISportTeamModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `/SportTeams/Update`,
        })
    );

  public deleteTeam = (data: ISportTeamModel): Observable<ISportTeamModel> =>
    new Observable(
      (observer: Observer<ISportTeamModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id: data.Id },
          },
          method: HTTP_METHOD.DELETE,
          url: `/SportTeams/Delete`,
        })
    );

  public selectTeams = (): Observable<ISportTeamModel[]> =>
    new Observable(
      (observer: Observer<ISportTeamModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `/SportTeams/Select`,
        })
    );
}
