import { useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Link, Button, Col, Form, Row } from "@bms/common-ui";
import { ROUTES as USER_ROUTES } from "../../../../User/constants";
import { IUserModel, IVoucherModel } from "@bms/common-services";
import { ITEM_NAMES } from "../constants";
import { UserBrowserModal } from "../../../../User/components/UserBrowserModal";

export const UserItem = () => {
  const [t] = useTranslation();

  return (
    <Form.Item
      name={ITEM_NAMES.USER}
      label={t("PAYMENT_VOUCHER_USER_FULL_NAME_COLUMN")}
    >
      <UserSelectorInput />
    </Form.Item>
  );
};

export type TUserSelectorInputValue = {
  userId: IVoucherModel["UserId"];
  userFullName: IVoucherModel["UserFullName"];
};

interface IUserSelectorInputProps {
  onChange?: (value: any) => void;
  value?: TUserSelectorInputValue;
}

const UserSelectorInput = ({ onChange, value }: IUserSelectorInputProps) => {
  const [t] = useTranslation();
  const [userBrowserVisible, setUserBrowserVisible] = useState<boolean>(false);

  const onUserSelect = (user: IUserModel) => {
    setUserBrowserVisible(false);
    onChange?.({ userId: user.Id, userFullName: user.FullName });
  };

  const onUserClear = () => {
    onChange?.(undefined);
  };

  return (
    <Row gutter={8}>
      <Col flex="auto">
        <Form.Item>
          {value?.userId ? (
            <Link to={`${USER_ROUTES.USER_DETAILS}/${value?.userId}`}>
              {value?.userFullName}
            </Link>
          ) : (
            t("PAYMENT_VOUCHER_DETAILS_USER_NAME_NO_USER")
          )}
        </Form.Item>
        <UserBrowserModal
          profiles={["USER"]}
          visible={userBrowserVisible}
          onCancel={() => setUserBrowserVisible(false)}
          onSelect={onUserSelect}
        />
      </Col>
      <Col style={{ textAlign: "right" }}>
        {value?.userId && (
          <Button
            icon={<DeleteOutlined />}
            onClick={onUserClear}
            style={{ marginRight: "8px" }}
          />
        )}
        <Button
          icon={<EditOutlined />}
          onClick={() => setUserBrowserVisible(true)}
        />
      </Col>
    </Row>
  );
};
