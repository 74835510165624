import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { SportAgeCategoryModel } from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { PromisifiableBase } from "../Base";

export class SportAgeCategoryService extends PromisifiableBase {
  public getCategories = (): Observable<SportAgeCategoryModel[]> =>
    new Observable(
      (observer: Observer<SportAgeCategoryModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `/AgeCategories/Select`,
        })
    );

  public getCategory = (id: number): Observable<SportAgeCategoryModel> =>
    new Observable(
      (observer: Observer<SportAgeCategoryModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id },
          },
          method: HTTP_METHOD.GET,
          url: `/AgeCategories/Get`,
        })
    );

  public createCategory = (
    data: SportAgeCategoryModel
  ): Observable<SportAgeCategoryModel> =>
    new Observable(
      (observer: Observer<SportAgeCategoryModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/AgeCategories/Insert`,
        })
    );

  public updateCategory = (
    data: SportAgeCategoryModel
  ): Observable<SportAgeCategoryModel> =>
    new Observable(
      (observer: Observer<SportAgeCategoryModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `/AgeCategories/Update`,
        })
    );

  public deleteCategory = (
    data: SportAgeCategoryModel
  ): Observable<SportAgeCategoryModel> =>
    new Observable(
      (observer: Observer<SportAgeCategoryModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id: data.Id },
          },
          method: HTTP_METHOD.DELETE,
          url: `/AgeCategories/Delete`,
        })
    );
}
