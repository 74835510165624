import { ApplicationResourceType } from "@bms/common-services";
import {
  Col,
  ColorPicker,
  Form,
  IColorResult,
  IFormValues,
  Input,
  Row,
  TextEditor,
  Switch,
  TextArea,
  Button,
  Icon,
  IFormInstance,
  IFormProps,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { TextWidgetComponentPropertyModel } from "../../models";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";
import "./TextWidgetComponentPoperties.scss";
import { ImagePreview } from "../ImagePreview";
import { ApplicationResourceUploadModal } from "../ApplicationResourceUploadModal";

export interface ITextWidgetComponentPopertiesProps
  extends IComponentPropertiesBaseProps,
    WithTranslation {
  isProcessingData: boolean;
}

export interface ITextWidgetComponentPopertiesState
  extends IComponentPropertiesBaseState {
  fileToBase64: string | null;
  isHTMLPreview: boolean;
  iconResourceUploadModalVisible: boolean;
}

const DEFAULT_FONT_COLOR = "#ffffff";
const DEFAULT_BACKGROUND_COLOR = "#000000";

export class TextWidgetComponentPoperties extends ComponentPropertiesBase<
  ITextWidgetComponentPopertiesProps,
  ITextWidgetComponentPopertiesState,
  TextWidgetComponentPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  public state: Readonly<ITextWidgetComponentPopertiesState> = {
    fileToBase64: null,
    isHTMLPreview: false,
    iconResourceUploadModalVisible: false,
  };

  private _isMounted: boolean;

  constructor(props: ITextWidgetComponentPopertiesProps) {
    super(props);
    this._isMounted = false;
  }

  formRef = React.createRef<IFormInstance>();

  getPropertiesModel(): TextWidgetComponentPropertyModel {
    const { component } = this.props;
    const properties = new TextWidgetComponentPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    if (!component) {
      return;
    }

    component.Name = values.Name;
  };

  public renderTitleProperty = () => {
    const { isProcessingData, t } = this.props;

    const formItemLayout: Pick<IFormProps, "labelCol" | "wrapperCol"> = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 4 },
        lg: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 20 },
        lg: { span: 22 },
      },
    };

    return (
      <Form.Item
        name="Title"
        label={t("MODEL_TITLE")}
        initialValue={this.properties.Title}
        {...formItemLayout}
      >
        <Input
          disabled={isProcessingData}
          placeholder={t("MODEL_TITLE_PLACEHOLDER")}
          onChange={({ target: { value } }) => {
            this.properties.Title = value;
            this.onPropertyChange("Title");
          }}
        />
      </Form.Item>
    );
  };

  public renderContentProperty = () => {
    const { t } = this.props;
    const { isHTMLPreview } = this.state;

    const formItemLayout: Pick<IFormProps, "labelCol" | "wrapperCol"> = {
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
      },
    };

    return (
      <>
        <Form.Item>
          <Switch
            checkedChildren={t("COMMON_ACTION_HTML_ON")}
            unCheckedChildren={t("COMMON_ACTION_HTML_OFF")}
            onChange={(isEnabled) =>
              this.setState({ isHTMLPreview: isEnabled })
            }
            defaultChecked={isHTMLPreview}
          />
        </Form.Item>
        <Form.Item
          name="Content"
          key="Content"
          initialValue={this.properties.Content}
          {...formItemLayout}
        >
          {isHTMLPreview ? (
            <TextArea
              placeholder={t("ENTER_CONTENT_PLACEHOLDER")}
              autoSize={{ minRows: 11 }}
              onChange={({ target: { value } }) => {
                this.properties.Content = value;
                this.onPropertyChange("Content");
              }}
            />
          ) : (
            <TextEditor
              onChange={(value) => {
                this.properties.Content = value;
                this.onPropertyChange("Content");
              }}
            />
          )}
        </Form.Item>
      </>
    );
  };

  public onIconResourceUploadCancel = () => {
    this.setState({ iconResourceUploadModalVisible: false });
  };

  public onIconResourceUploadSuccess = (
    filePath?: string,
    fileUrl?: string
  ) => {
    this.properties.IconUrl = filePath;
    this.properties.IconAbsoluteUrl = fileUrl;
    this.onPropertyChange("IconUrl");
    this.formRef?.current?.setFieldsValue({
      IconUrl: filePath,
    });
    this.setState({ iconResourceUploadModalVisible: false });
  };

  public renderIconUrlProperty = () => {
    const { isProcessingData, t, component } = this.props;
    const { iconResourceUploadModalVisible } = this.state;

    if (1 < 2) {
      return null;
    }

    const iconPreviewUrl =
      this.properties.IconAbsoluteUrl || this.properties.IconUrl;

    const formItemLayout: Pick<IFormProps, "labelCol" | "wrapperCol"> = {
      labelCol: {
        flex: "none",
      },
      wrapperCol: {
        flex: "auto",
      },
    };

    return (
      <>
        <Form.Item
          name="IconUrl"
          label={t("MODEL_ICON_URL")}
          initialValue={this.properties.IconUrl}
          {...formItemLayout}
        >
          <Input
            disabled={isProcessingData}
            placeholder={t("https://")}
            onChange={({ target: { value } }) => {
              this.properties.IconUrl = value;
              this.onPropertyChange("IconUrl");
            }}
          />
        </Form.Item>

        <Row gutter={[8, 16]} justify="space-between">
          <Col>
            <ImagePreview imageSrc={iconPreviewUrl} />
          </Col>
          <Col>
            <ApplicationResourceUploadModal
              key={`ApplicationResourceUploadModal-${component.Id}`}
              visible={iconResourceUploadModalVisible}
              applicationConfigurationId={component.ApplicationConfigurationId}
              resourceType={ApplicationResourceType.Image}
              onCancel={this.onIconResourceUploadCancel}
              onSuccess={this.onIconResourceUploadSuccess}
            />
            <Button
              icon={<Icon type="upload" />}
              title={t("CONFIGURATION_BUTTON__UPLOAD_IMAGE_TITLE")}
              onClick={() => {
                this.setState({ iconResourceUploadModalVisible: true });
              }}
            />
          </Col>
        </Row>
      </>
    );
  };

  public renderBackgroundColorProperty = () => {
    if (!this.properties.IsBrandingVisible) {
      return null;
    }

    if (1 < 2) {
      return null;
    }

    const { t } = this.props;
    const formItemLayout: Pick<IFormProps, "labelCol" | "wrapperCol"> = {
      labelCol: {
        flex: "none",
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 8 },
        lg: { span: 8 },
      },
    };

    return (
      <Form.Item
        name="BackgroundColor"
        label={t("COMPONENT_PROPERTIES__BACKGROUND_COLOR")}
        initialValue={this.properties.BackgroundColor}
        {...formItemLayout}
      >
        <ColorPicker
          color={this.properties.BackgroundColor || DEFAULT_BACKGROUND_COLOR}
          onChange={(color: IColorResult) => {
            this.properties.BackgroundColor = color.hex;
            this.onPropertyChange("BackgroundColor");
          }}
          pickerStyle={{ left: "-164px", bottom: "34px" }}
        />
      </Form.Item>
    );
  };

  public renderFontColorProperty = () => {
    if (!this.properties.IsBrandingVisible) {
      return null;
    }

    const { t } = this.props;
    const formItemLayout: Pick<IFormProps, "labelCol" | "wrapperCol"> = {
      labelCol: {
        flex: "none",
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 12 },
        lg: { span: 12 },
      },
    };

    return (
      <Form.Item
        name="FontColor"
        label={t("COMPONENT_PROPERTIES__FONT_COLOR")}
        initialValue={this.properties.FontColor}
        {...formItemLayout}
      >
        <ColorPicker
          color={this.properties.FontColor || DEFAULT_FONT_COLOR}
          onChange={(color: IColorResult) => {
            this.properties.FontColor = color.hex;
            this.onPropertyChange("FontColor");
          }}
          pickerStyle={{ bottom: "34px" }}
        />
      </Form.Item>
    );
  };

  public renderIsVisibleProperty = () => {
    const { t, isProcessingData } = this.props;

    const onIsVisibleChange = (checked: boolean) => {
      const isVisible = checked;
      this.properties.IsVisible = Boolean(isVisible);
      this.onPropertyChange("IsVisible");
    };

    const formItemLayout: Pick<IFormProps, "labelCol" | "wrapperCol"> = {
      labelCol: {
        flex: "none",
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
        md: { span: 20 },
        lg: { span: 20 },
      },
    };

    return (
      <Form.Item
        name="IsVisible"
        label={t("CONFIGURATION_COMPONENT__IS_VISIBLE")}
        valuePropName="checked"
        initialValue={this.properties.IsVisible}
        {...formItemLayout}
      >
        <Switch onChange={onIsVisibleChange} disabled={isProcessingData} />
      </Form.Item>
    );
  };

  public renderIsBrandingVisibleProperty = () => {
    const { t } = this.props;

    if (1 < 2) {
      return null;
    }
    
    const formItemLayout: Pick<IFormProps, "labelCol" | "wrapperCol"> = {
      labelCol: {
        flex: "none",
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
        md: { span: 20 },
        lg: { span: 20 },
      },
    };
    return (
      <Form.Item
        name="IsBrandingVisible"
        label={t("IS_BRANDING_VISIBLE")}
        {...formItemLayout}
      >
        <Switch
          defaultChecked={this.properties.IsBrandingVisible}
          onChange={(isEnabled) => {
            this.properties.IsBrandingVisible = isEnabled;
            this.onPropertyChange("IsBrandingVisible");
            if (!isEnabled) {
              this.deleteProperty("FontColor");
              this.deleteProperty("BackgroundColor");
              return;
            }
            this.properties.FontColor = DEFAULT_FONT_COLOR;
            this.properties.BackgroundColor = DEFAULT_BACKGROUND_COLOR;
            this.onPropertyChange("FontColor");
            this.onPropertyChange("BackgroundColor");
          }}
        />
      </Form.Item>
    );
  };

  public render() {
    const formItemLayout: Pick<IFormProps, "labelCol" | "wrapperCol"> = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 18 },
      },
    };

    return (
      <Form
        name="TextWidgetComponentPoperties"
        id="ApplicationComponentPropertiesForm"
        className="TextWidgetComponentPoperties"
        onFinish={this.onFinish}
        ref={this.formRef}
        {...formItemLayout}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {this.renderTitleProperty()}
            {this.renderContentProperty()}
            {this.renderIconUrlProperty()}
            {this.renderIsVisibleProperty()}
            {this.renderIsBrandingVisibleProperty()}
            <Row justify="space-between">
              <Col>{this.renderFontColorProperty()}</Col>
              <Col>{this.renderBackgroundColorProperty()}</Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
}
