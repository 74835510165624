// #################### AGE CATEGORIES ####################
export const GET_AGE_CATEGORY = "GET_AGE_CATEGORY";
export const GET_AGE_CATEGORY_SUCCESS = "GET_AGE_CATEGORY_SUCCESS";
export const GET_AGE_CATEGORY_FAILURE = "GET_AGE_CATEGORY_FAILURE";

export const GET_AGE_CATEGORIES = "GET_AGE_CATEGORIES";
export const GET_AGE_CATEGORIES_SUCCESS = "GET_AGE_CATEGORIES_SUCCESS";
export const GET_AGE_CATEGORIES_FAILURE = "GET_AGE_CATEGORIES_FAILURE";

export const CREATE_AGE_CATEGORY = "CREATE_AGE_CATEGORY";
export const CREATE_AGE_CATEGORY_SUCCESS = "CREATE_AGE_CATEGORY_SUCCESS";
export const CREATE_AGE_CATEGORY_FAILURE = "CREATE_AGE_CATEGORY_FAILURE";

export const UPDATE_AGE_CATEGORY = "UPDATE_AGE_CATEGORY";
export const UPDATE_AGE_CATEGORY_SUCCESS = "UPDATE_AGE_CATEGORY_SUCCESS";
export const UPDATE_AGE_CATEGORY_FAILURE = "UPDATE_AGE_CATEGORY_FAILURE";

export const DELETE_AGE_CATEGORY = "DELETE_AGE_CATEGORY";
export const DELETE_AGE_CATEGORY_SUCCESS = "DELETE_AGE_CATEGORY_SUCCESS";
export const DELETE_AGE_CATEGORY_FAILURE = "DELETE_AGE_CATEGORY_FAILURE";

// #################### PLAYER POSITIONS ####################
export const GET_PLAYER_POSITION = "GET_PLAYER_POSITION";
export const GET_PLAYER_POSITION_SUCCESS = "GET_PLAYER_POSITION_SUCCESS";
export const GET_PLAYER_POSITION_FAILURE = "GET_PLAYER_POSITION_FAILURE";

export const GET_PLAYER_POSITIONS = "GET_PLAYER_POSITIONS";
export const GET_PLAYER_POSITIONS_SUCCESS = "GET_PLAYER_POSITIONS_SUCCESS";
export const GET_PLAYER_POSITIONS_FAILURE = "GET_PLAYER_POSITIONS_FAILURE";

export const CREATE_PLAYER_POSITION = "CREATE_PLAYER_POSITION";
export const CREATE_PLAYER_POSITION_SUCCESS = "CREATE_PLAYER_POSITION_SUCCESS";
export const CREATE_PLAYER_POSITION_FAILURE = "CREATE_PLAYER_POSITION_FAILURE";

export const UPDATE_PLAYER_POSITION = "UPDATE_PLAYER_POSITION";
export const UPDATE_PLAYER_POSITION_SUCCESS = "UPDATE_PLAYER_POSITION_SUCCESS";
export const UPDATE_PLAYER_POSITION_FAILURE = "UPDATE_PLAYER_POSITION_FAILURE";

export const DELETE_PLAYER_POSITION = "DELETE_PLAYER_POSITION";
export const DELETE_PLAYER_POSITION_SUCCESS = "DELETE_PLAYER_POSITION_SUCCESS";
export const DELETE_PLAYER_POSITION_FAILURE = "DELETE_PLAYER_POSITION_FAILURE";

// #################### STAFF ROLES ####################
export const GET_STAFF_ROLE = "GET_STAFF_ROLE";
export const GET_STAFF_ROLE_SUCCESS = "GET_STAFF_ROLE_SUCCESS";
export const GET_STAFF_ROLE_FAILURE = "GET_STAFF_ROLE_FAILURE";

export const GET_STAFF_ROLES = "GET_STAFF_ROLES";
export const GET_STAFF_ROLES_SUCCESS = "GET_STAFF_ROLES_SUCCESS";
export const GET_STAFF_ROLES_FAILURE = "GET_STAFF_ROLES_FAILURE";

export const CREATE_STAFF_ROLE = "CREATE_STAFF_ROLE";
export const CREATE_STAFF_ROLE_SUCCESS = "CREATE_STAFF_ROLE_SUCCESS";
export const CREATE_STAFF_ROLE_FAILURE = "CREATE_STAFF_ROLE_FAILURE";

export const UPDATE_STAFF_ROLE = "UPDATE_STAFF_ROLE";
export const UPDATE_STAFF_ROLE_SUCCESS = "UPDATE_STAFF_ROLE_SUCCESS";
export const UPDATE_STAFF_ROLE_FAILURE = "UPDATE_STAFF_ROLE_FAILURE";

export const DELETE_STAFF_ROLE = "DELETE_STAFF_ROLE";
export const DELETE_STAFF_ROLE_SUCCESS = "DELETE_STAFF_ROLE_SUCCESS";
export const DELETE_STAFF_ROLE_FAILURE = "DELETE_STAFF_ROLE_FAILURE";

// #################### STATISTICS UNITS ####################
export const GET_STATISTICS_UNIT = "GET_STATISTICS_UNIT";
export const GET_STATISTICS_UNIT_SUCCESS = "GET_STATISTICS_UNIT_SUCCESS";
export const GET_STATISTICS_UNIT_FAILURE = "GET_STATISTICS_UNIT_FAILURE";

export const GET_STATISTICS_UNITS = "GET_STATISTICS_UNITS";
export const GET_STATISTICS_UNITS_SUCCESS = "GET_STATISTICS_UNITS_SUCCESS";
export const GET_STATISTICS_UNITS_FAILURE = "GET_STATISTICS_UNITS_FAILURE";

export const CREATE_STATISTICS_UNIT = "CREATE_STATISTICS_UNIT";
export const CREATE_STATISTICS_UNIT_SUCCESS = "CREATE_STATISTICS_UNIT_SUCCESS";
export const CREATE_STATISTICS_UNIT_FAILURE = "CREATE_STATISTICS_UNIT_FAILURE";

export const UPDATE_STATISTICS_UNIT = "UPDATE_STATISTICS_UNIT";
export const UPDATE_STATISTICS_UNIT_SUCCESS = "UPDATE_STATISTICS_UNIT_SUCCESS";
export const UPDATE_STATISTICS_UNIT_FAILURE = "UPDATE_STATISTICS_UNIT_FAILURE";

export const DELETE_STATISTICS_UNIT = "DELETE_STATISTICS_UNIT";
export const DELETE_STATISTICS_UNIT_SUCCESS = "DELETE_STATISTICS_UNIT_SUCCESS";
export const DELETE_STATISTICS_UNIT_FAILURE = "DELETE_STATISTICS_UNIT_FAILURE";
