import { SectionGrid, SectionGridItem } from "@bms/common-ui";
import { ICommonPeopleDetailsTabProps } from "./ICommonPeopleDetailsTabProps";
import { getSectionsFields, PeopleForm } from "../../PeopleForm";
import { useTranslation } from "react-i18next";

const sections = getSectionsFields();

export const DetailsTab = ({
  people,
  updatePeopleState,
  updatePeople,
}: ICommonPeopleDetailsTabProps) => {
  const { t } = useTranslation();

  return (
    <SectionGrid>
      <SectionGridItem
        header={t("PEOPLE_DETAILS_SECTION_GENERAL")}
        processing={updatePeopleState?.processing}
      >
        <PeopleForm
          key={`PeopleForm-${people?.RowVersion}`}
          isEditMode
          people={people}
          section="general"
          onSubmit={updatePeople}
          hiddenFields={[...sections.language, ...sections.country]}
        />
      </SectionGridItem>
    </SectionGrid>
  );
};
