import { DatePicker, Form } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ISportTeamStaffFormFieldCommonProps } from "./ISportTeamStaffFormFieldCommonProps";

interface IDateFromFieldProps extends ISportTeamStaffFormFieldCommonProps {}

export const DateToField = ({ isEditMode, staff }: IDateFromFieldProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name="DateTo"
      label={t("MODEL_DATE_TO")}
      key="DateTo"
      initialValue={
        isEditMode && staff?.DateTo ? new Date(staff?.DateTo) : undefined
      }
    >
      <DatePicker
        placeholder={t("MODEL_DATE_TO_PLACEHOLDER")}
        style={{ width: "100%" }}
      />
    </Form.Item>
  );
};
