import { useMemo } from "react";
import { ISportClubModel, SportClubsService } from "@bms/common-services";
import {
  ITableColumnProps,
  Link,
  setTableColumnSearchProps,
  useAppFeedback,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useTableDataProvider } from "../../../../../helpers";
import { ROUTES } from "../../..";

const clubsService = new SportClubsService().promisify();

export const useClubsBrowserTableColumns = () => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const {
    dataLoader: clubsLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      Name: "string",
      City: "string",
    },
    loader: (filters, pagination) => {
      return clubsService.search({
        ...filters,
        ...pagination,
      });
    },
    deps: [],
    onError: (error) =>
      notification.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const getColumnsProps = (): Array<ITableColumnProps<ISportClubModel>> => {
    return [
      {
        key: "Name",
        dataIndex: "Name",
        title: t("MODEL_NAME"),
        ellipsis: true,
        filteredValue: filters.asTableArray.Name,
        render: (_, row: ISportClubModel) => {
          const clubDetailLocation = {
            pathname: `${ROUTES.CLUB_DETAILS}/${row.Id}`,
          };
          return <Link to={clubDetailLocation}>{row.Name}</Link>;
        },
        ...setTableColumnSearchProps("Name", t("MODEL_NAME")),
      },
      {
        key: "Stadium",
        dataIndex: "Stadium",
        title: t("MODEL_STADIUM"),
        filteredValue: filters.asTableArray.Stadium,
        ...setTableColumnSearchProps("Stadium", t("MODEL_STADIUM")),
      },
      {
        key: "City",
        dataIndex: "City",
        title: t("MODEL_CITY"),
        filteredValue: filters.asTableArray.City,
        ...setTableColumnSearchProps("City", t("MODEL_CITY")),
      },
    ];
  };
  const columns = useMemo(() => getColumnsProps(), []);

  return {
    columns,
    fullTextSearch,
    filters,
    clubsLoader,
    pagination,
    setFullTextSearch,
  };
};
