import { Button, Col, Form, Row } from "@bms/common-ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FieldType, formLayouts } from "./PlayerFormUtils";
import {
  IPlayerFormProps,
  usePlayerFormController,
} from "./usePlayerFormController";
import {
  AgeCategoryIdField,
  CreatedByField,
  FirstNameField,
  LastNameField,
  PositionIdField,
  ShirtNumberField,
} from "./Fields";

export const PlayerForm = (props: IPlayerFormProps) => {
  const { t } = useTranslation();
  const { isEditMode, player, section, hiddenFields } = props;
  const {
    formLayout,
    formFieldNames,
    sendable,
    form,
    person,
    onPersonSelect,
    onPersonClear,
    onFieldsChange,
    onFinish,
    userCreator,
    onUserSelect,
    onUserClear,
  } = usePlayerFormController(props);

  const isHidden = (key: FieldType) =>
    !!hiddenFields && hiddenFields.includes(key);

  const formFieldsMapping: { [key: string]: () => JSX.Element } = {
    FirstName: () => (
      <FirstNameField
        key="PlayerForm--FirstNameField"
        isHidden={isHidden("FirstName")}
        isEditMode={isEditMode}
        player={player}
        person={person}
        onSelect={onPersonSelect}
        onClear={onPersonClear}
      />
    ),
    LastName: () => (
      <LastNameField
        key="PlayerForm--LastNameField"
        isHidden={isHidden("LastName")}
        isEditMode={isEditMode}
        player={player}
        person={person}
      />
    ),
    PositionId: () => (
      <PositionIdField
        key="PlayerForm--PositionIdField"
        isHidden={isHidden("PositionId")}
        isEditMode={isEditMode}
        player={player}
      />
    ),
    AgeCategoryId: () => (
      <AgeCategoryIdField
        key="PlayerForm--AgeCategoryIdField"
        isHidden={isHidden("AgeCategoryId")}
        isEditMode={isEditMode}
        player={player}
      />
    ),
    ShirtNumber: () => (
      <ShirtNumberField
        key="PlayerForm--ShirtNumberField"
        isHidden={isHidden("ShirtNumber")}
        isEditMode={isEditMode}
        player={player}
      />
    ),
    CreatedBy: () => (
      <CreatedByField
        key="PlayerForm--CreatedByField"
        isEditMode={isEditMode}
        isHidden={isHidden("CreatedBy")}
        onSelect={onUserSelect}
        onClear={onUserClear}
        userCreator={userCreator}
      />
    ),
  };

  const formFields = useMemo(
    () =>
      formFieldNames.map((field: string) =>
        formFieldsMapping[field] ? formFieldsMapping[field]() : null
      ),
    [formFieldNames, person, player, userCreator]
  );

  return (
    <Form
      {...formLayout}
      form={form}
      name={section ? `PlayerForm-${section}` : "PlayerForm"}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      className="PlayerForm"
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          {formFields}
          {isEditMode && (
            <Form.Item {...formLayouts.tailFormItem}>
              <Button type="primary" htmlType="submit" disabled={!sendable}>
                {t("BUTTON_SAVE")}
              </Button>
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};
