import {
  ISportTeamModel,
  SportTeamService,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { useAppFeedback, useSyncedState } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { useTeamsDataPager } from "../../../hooks";
import { ROUTES } from "../../../constants";

const teamsService = new SportTeamService().promisify();

export const useTeamDetailsController = () => {
  const id = parseInt(useParams<{ id: string }>()["id"]);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [dataPager, dataPagerLoading] = useTeamsDataPager(id);

  const { data, loading, refresh } = useDataLoader({
    loader: () => teamsService.getTeam(id),
    deps: [id],
  });

  const [team, setTeam] = useSyncedState(() => data, [data]);

  const { modal, notification } = useAppFeedback();

  const [updateTeam, updateTeamState] = useServiceCaller(
    async (data: ISportTeamModel) => {
      const result = await teamsService.updateTeam(data);
      if (result.ok) {
        setTeam(result.data);
        notification.success({
          message: t("SPORT_TEAM_DETAILS__TEAM_UPDATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("SPORT_TEAM_DETAILS__TEAM_UPDATE_FAILURE"),
          description: result.error.Message,
        });
      }
    },
    []
  );

  const [deleteTeam] = useServiceCaller(async () => {
    const result = await teamsService.deleteTeam(team!);
    if (result.ok) {
      notification.success({
        message: t("SPORT_TEAM_DETAILS__TEAM_DELETE_SUCCESS"),
      });
      history.push(ROUTES.TEAMS);
    } else {
      notification.error({
        message: t("SPORT_TEAM_DETAILS__TEAM_DELETE_FAILURE"),
        description: result.error?.Message,
      });
    }
  }, [team]);

  const onDeleteClick = () => {
    if (!team) {
      return;
    }

    modal.confirm({
      title: t("COMMON_DELETE"),
      content: t("SPORT_TEAM_DETAILS__TEAM_DELETE_MESSAGE", {
        teamName: team?.Name,
      }),
      okText: t("BUTTON_DELETE"),
      cancelText: t("BUTTON_CANCEL"),
      onOk: deleteTeam,
    });
  };

  const onBackClick = () => {
    if (location.state && location.state.hasOwnProperty("from")) {
      // use location from router state
      const state = location.state as { from: Location };
      history.push(state.from);
    } else {
      // use teams list location
      history.goBack();
    }
  };

  return {
    updateTeamState,
    team,
    loading,
    dataPager,
    dataPagerLoading,
    refresh,
    updateTeam,
    onDeleteClick,
    onBackClick,
    t,
  };
};
