import {
  IListModelBase,
  IModelBase,
  ISportMatchTeamLineupModel,
  ISportTeamPlayerModel,
  SportPlayerPositionModel,
  SportPlayerPositionsService,
} from "@bms/common-services";
import {
  Button,
  Col,
  Form,
  Icon,
  IFormValues,
  Input,
  Row,
  Spin,
  Switch,
} from "@bms/common-ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SportTeamPlayerBrowserModal } from "../../TeamPlayers/SportTeamPlayerBrowserModal";
import { LazyChoose } from "../../../../../components";

type ILineupFormProps = {
  lineup: ISportMatchTeamLineupModel;
  matchConfig: {
    teamId: number;
    matchId: number;
  };
  isProcessing: boolean;
  insertLineup: (lineup: ISportMatchTeamLineupModel) => void;
  updateLineup: (lineup: ISportMatchTeamLineupModel) => void;
};

const positionsService = new SportPlayerPositionsService();
let positions: SportPlayerPositionModel[];

export const SportMatchLineupForm = ({
  isProcessing,
  insertLineup,
  updateLineup,
  lineup,
  matchConfig,
}: ILineupFormProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [playerBrowserVisible, setPlayerBrowserVisible] = useState(false);
  const [player, setPlayer] = useState<ISportTeamPlayerModel>();

  const isNewLineup = lineup.Id < 0;

  const onPlayerSelect = (row: ISportTeamPlayerModel) => {
    setPlayer(row);
    setPlayerBrowserVisible(false);
  };

  const defaultPlayerValue = (isNewLineup || player
    ? `${player?.FirstName || ""} ${player?.LastName || ""}`
    : `${lineup.PlayerFirstName || ""} ${lineup.PlayerLastName || ""}`
  ).trim();

  const onFinish = async (values: IFormValues) => {
    const lineupContent = values as ISportMatchTeamLineupModel;

    lineupContent.TeamId = matchConfig.teamId;
    lineupContent.MatchId = matchConfig.matchId;
    lineupContent.PlayerId = player?.Id || lineup.PlayerId;
    lineupContent.PositionId =
      lineup.PositionId || Number(values.PositionDisplayName.value);

    delete values.PositionDisplayName;

    if (isNewLineup) {
      insertLineup(lineupContent);
      return;
    }

    updateLineup({
      ...lineup,
      ...lineupContent,
    });
  };

  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <Spin spinning={isProcessing}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={!isNewLineup ? lineup : {}}
        className="SportMatchLineupForm"
        name="SportMatchLineupForm"
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            <Row gutter={8}>
              <Col flex="auto">
                <Input
                  placeholder={t("MODEL_PLAYER_SELECT_PLACEHOLDER")}
                  disabled
                  value={defaultPlayerValue}
                />
                <SportTeamPlayerBrowserModal
                  visible={playerBrowserVisible}
                  onCancel={() => setPlayerBrowserVisible(false)}
                  onSelect={onPlayerSelect}
                  teamId={matchConfig.teamId}
                />
              </Col>
              <Col style={{ textAlign: "right" }}>
                {player?.Id && (
                  <Button
                    icon={<Icon type="delete" />}
                    onClick={() => setPlayer(undefined)}
                    style={{ marginRight: "8px" }}
                  />
                )}
                <Button
                  icon={<Icon type="edit" />}
                  onClick={() => setPlayerBrowserVisible(true)}
                />
              </Col>
            </Row>
            <Form.Item
              name="PositionDisplayName"
              label={t("MODEL_POSITION_NAME")}
              initialValue={{
                value: lineup?.PositionId,
                label: lineup?.PositionDisplayName,
              }}
              rules={[{ required: true }]}
            >
              <LazyChoose<
                SportPlayerPositionModel & IModelBase,
                SportPlayerPositionModel & IModelBase
              >
                loader={async () => {
                  if (!positions) {
                    positions = await positionsService
                      .getPlayerPositions()
                      .toPromise();
                  }
                  const data: IListModelBase<
                    SportPlayerPositionModel & IModelBase,
                    {}
                  > = {
                    TotalCount: positions.length,
                    Entities: positions,
                  };
                  return {
                    data,
                    ok: true,
                  };
                }}
                candidateToOption={(item) => ({
                  label: item.DisplayName || item.Code,
                  value: `${item.Id}`,
                })}
                multiple={false}
                selectedToOption={(item) => ({
                  label: item.DisplayName || item.Code,
                  value: `${item.Id}`,
                  item,
                })}
              />
            </Form.Item>
            <Form.Item>
              <Row gutter={8}>
                <Col>
                  <Form.Item name="IsReserve" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="IsReserve">
                    <label>{t("MODEL_RESERVE", "Reserve")}</label>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
