import {
  ISportClubModel,
  SportClubsService,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { useAppFeedback, useSyncedState } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { useClubsDataPager } from "../../../hooks";
import { ROUTES } from "../../../constants";

const clubsService = new SportClubsService().promisify();

export const useClubDetailsController = () => {
  const id = parseInt(useParams<{ id: string }>()["id"]);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [dataPager, dataPagerLoading] = useClubsDataPager(id);

  const { data, loading, refresh } = useDataLoader({
    loader: () => clubsService.getClub(id),
    deps: [id],
  });

  const [club, setClub] = useSyncedState(() => data, [data]);

  const { modal, notification } = useAppFeedback();

  const [updateClub, updateClubState] = useServiceCaller(
    async (data: ISportClubModel) => {
      const result = await clubsService.updateClub(data);
      if (result.ok) {
        setClub(result.data);
        notification.success({
          message: t("SPORT_CLUB_DETAILS__CLUB_UPDATE_SUCCESS"),
        });
      } else {
        notification.error({
          message: t("SPORT_CLUB_DETAILS__CLUB_UPDATE_FAILURE"),
          description: result.error.Message,
        });
      }
    },
    []
  );

  const [deleteClub] = useServiceCaller(async () => {
    const result = await clubsService.deleteClub(club!);
    if (result.ok) {
      notification.success({
        message: t("SPORT_CLUB_DETAILS__CLUB_DELETE_SUCCESS"),
      });
      history.push(ROUTES.CLUBS);
    } else {
      notification.error({
        message: t("SPORT_CLUB_DETAILS__CLUB_DELETE_FAILURE"),
        description: result.error?.Message,
      });
    }
  }, [club]);

  const onDeleteClick = () => {
    if (!club) {
      return;
    }

    modal.confirm({
      title: t("COMMON_DELETE"),
      content: t("SPORT_CLUB_DETAILS__CLUB_DELETE_MESSAGE", {
        clubName: club?.Name,
      }),
      okText: t("BUTTON_DELETE"),
      cancelText: t("BUTTON_CANCEL"),
      onOk: deleteClub,
    });
  };

  const onBackClick = () => {
    if (location.state && location.state.hasOwnProperty("from")) {
      // use location from router state
      const state = location.state as { from: Location };
      history.push(state.from);
    } else {
      // use clubs list location
      history.goBack();
    }
  };

  return {
    updateClubState,
    club,
    loading,
    dataPager,
    dataPagerLoading,
    refresh,
    updateClub,
    onDeleteClick,
    onBackClick,
    t,
  };
};
