/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useState } from "react";
import {
  Alert,
  Button,
  Form,
  IFormValues,
  Modal,
  Result,
  Spin,
} from "@bms/common-ui";
import {
  AssetService,
  IAssetModel,
  IErrorModel,
  OperationResult,
  OperationResultType,
} from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { AIPromptField } from "../AssetForm/fields/AIPromptField";
import { CUSTOM_METADATA_FILEDS } from "../AssetForm/AssetFormUtils";

const assetService = new AssetService();

export interface IAssetAiPromtModalProps {
  visible: boolean;
  asset?: IAssetModel;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export const AssetAiPromtModalModal: React.FC<IAssetAiPromtModalProps> = ({
  visible,
  asset,
  onCancel,
  onSuccess,
}: IAssetAiPromtModalProps) => {
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [result, setResult] = useState<OperationResult<string[]> | null>(null);
  const [error, setError] = useState<IErrorModel>();
  const isFinished = !isProcessing && (result || error) ? true : false;

  const [form] = Form.useForm();

  const reset = () => {
    setResult(null);
    setError(undefined);
  };

  const onFinish = async (values: IFormValues) => {
    const aiPrompt = values[CUSTOM_METADATA_FILEDS.AI_PROMPT];

    if (!aiPrompt || !asset?.Id) {
      return;
    }

    setIsProcessing(true);

    try {
      const response = await assetService
        .fillChildAssetsByTextQuery(asset?.Id, aiPrompt)
        .toPromise();
      console.error(response);
      setResult(response);
      setIsProcessing(false);
    } catch (error) {
      setError(error as IErrorModel);
      setIsProcessing(false);
    }
  };

  const renderAiPrompt = () => {
    if (
      (result && result?.ResultType !== OperationResultType.Error) ||
      isProcessing ||
      error
    ) {
      return null;
    }

    return (
      <AIPromptField
        key="AssetForm--AIPromptField"
        isHidden={false}
        isEditMode={true}
        asset={asset}
      />
    );
  };

  const renderInfo = () => {
    if (isFinished) {
      return null;
    }

    if (isProcessing) {
      return (
        <Form.Item
          style={{
            textAlign: "center",
            paddingTop: "24px",
            paddingBottom: "24px",
          }}
        >
          <Spin tip={t("ASSET_AI_PROMPT_MODAL_PROGRESS_MESSAGE")} size="large">
            <div
              style={{
                padding: 50,
                background: "rgba(0, 0, 0, 0.05)",
                borderRadius: 4,
              }}
            />
          </Spin>
        </Form.Item>
      );
    }

    return (
      <Form.Item>
        <Alert
          description={t("ASSET_AI_PROMPT_INFO_MESSAGE")}
          type="info"
          showIcon
        />
      </Form.Item>
    );
  };

  const renderResult = () => {
    if (error) {
      return (
        <Form.Item>
          <Result
            status="error"
            title={t("ASSET_AI_PROMPT_MODAL_ERROR__MESSAGE")}
            subTitle={error.Message}
            extra={[
              <Button
                key="try-again"
                onClick={() => {
                  setResult(null);
                  setError(undefined);
                }}
              >
                {t("BUTTON_TRY_AGAIN")}
              </Button>,
            ]}
          />
        </Form.Item>
      );
    }
    if (!result) {
      return null;
    }

    switch (result.ResultType) {
      case OperationResultType.Error:
        const errorMessage = result.Result?.[0];
        return (
          <Form.Item>
            <Result
              status="error"
              title={t("ASSET_AI_PROMPT_MODAL_ERROR__MESSAGE")}
              subTitle={errorMessage}
              extra={[
                <Button
                  key="try-again"
                  onClick={() => {
                    setResult(null);
                    setError(undefined);
                  }}
                >
                  {t("BUTTON_TRY_AGAIN")}
                </Button>,
              ]}
            />
          </Form.Item>
        );
      case OperationResultType.Warning:
        const warningMessage = result.Result?.[0];
        return (
          <Form.Item>
            <Result
              status="warning"
              title={t("ASSET_AI_PROMPT_MODAL_ERROR__MESSAGE")}
              subTitle={warningMessage}
              extra={[
                <Button
                  key="try-again"
                  onClick={() => {
                    setResult(null);
                    setError(undefined);
                  }}
                >
                  {t("BUTTON_TRY_AGAIN")}
                </Button>,
              ]}
            />
          </Form.Item>
        );
      case OperationResultType.Ok:
        return (
          <Form.Item>
            <Result
              status="success"
              title={t("ASSET_AI_PROMPT_MODAL_SUCCESS_MESSAGE")}
              extra={[
                <Button
                  key="close"
                  onClick={() => {
                    onSuccess?.();
                    reset();
                  }}
                >
                  {t("BUTTON_CLOSE")}
                </Button>,
              ]}
            />
          </Form.Item>
        );
    }

    return null;
  };

  const onCancelModal = () => {
    onCancel?.();
    reset();
  };

  const renderFooter = () => {
    if (isFinished) {
      return null;
    }

    return (
      <>
        <Button
          key="cancel"
          onClick={onCancelModal}
          type="link"
          disabled={isProcessing}
        >
          {t("BUTTON_CANCEL")}
        </Button>
        <Button
          form="AssetAiPromtModalForm"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={isProcessing}
          disabled={isProcessing}
        >
          {t("BUTTON_GENERATE")}
        </Button>
      </>
    );
  };

  return (
    <Modal
      title={t("ASSET_AI_PROMPT_MODAL_TITLE")}
      footer={renderFooter()}
      processing={isProcessing}
      width={600}
      open={visible}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={onCancelModal}
    >
      <Form
        form={form}
        name="AssetAiPromtModalForm"
        className="AssetAiPromtModalForm"
        onFinish={onFinish}
      >
        {renderInfo()}
        {renderAiPrompt()}
        {renderResult()}
      </Form>
    </Modal>
  );
};
