import {
  CellType,
  CellTypeHelper,
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  PropertyType,
} from "@bms/common-services";
import { ComponentPropertyModel } from "./ComponentPropertyModel";

export class ApplicationSearchPropertyModel extends ComponentPropertyModel {
  CellType: CellType = CellType.Default;

  BackgroundUrl?: string;

  BackgroundAbsoluteUrl?: string;

  constructor(component?: IApplicationComponentModel) {
    super();

    if (component) {
      this.init(component);
    }
  }

  initProperties(component: IApplicationComponentModel): void {
    if (!component.Properties) {
      component.Properties = [];
    }

    const cellTypeProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "CellType"
    );

    if (!cellTypeProperty) {
      this.onPropertyChange("CellType", component);
    }

    const BackgroundUrlProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "BackgroundUrl"
    );

    if (!BackgroundUrlProperty) {
      this.onPropertyChange("BackgroundUrl", component);
    }
  }

  setProperties(component: IApplicationComponentModel): void {
    const cellTypeProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "CellType"
    );

    if (cellTypeProperty && cellTypeProperty.Value) {
      this.CellType = CellTypeHelper.getValue(
        cellTypeProperty.Value.StringValue || "DEFAULT"
      );
    }

    const BackgroundUrlProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "BackgroundUrl"
    );

    if (BackgroundUrlProperty && BackgroundUrlProperty.Value) {
      this.BackgroundUrl = BackgroundUrlProperty.Value.StringValue;
      this.BackgroundAbsoluteUrl = BackgroundUrlProperty.Value.UrlValue;
    }
  }

  setProperty(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "CellType":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "BackgroundUrl":
        property.PropertyType = PropertyType.Url;
        property.IsEnumerable = false;
        break;
    }
  }

  setPropertyValue(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "CellType":
        if (property.Value) {
          property.Value.StringValue = CellTypeHelper.getStringValue(
            this.CellType
          );
        }
        break;
      case "BackgroundUrl":
        if (property.Value) {
          property.Value.StringValue = this.BackgroundUrl;
          property.Value.UrlValue = this.BackgroundAbsoluteUrl;
        }
        break;
    }
  }
}
