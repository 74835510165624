import {
  ActionType,
  AssetType,
  ComponentType,
  ComponentTypeHelper,
  GuidHelper,
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  IApplicationScreenModel,
  IApplicationsScreensSearchFilterModel,
  IAssetModel,
  PlatformType,
  PropertyType,
  RecordStatus,
  ScreenType,
} from "@bms/common-services";
import {
  Button,
  Choose,
  Col,
  Form,
  IChooseValue,
  Icon,
  IFormInstance,
  Input,
  Link,
  Row,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ROUTES } from "../../../../../../constants";
import { ApplicationScreensBrowserModal } from "../../../../components/ApplicationScreensBrowserModal";
import { ActionTypeHelper, ScreenTypeHelper } from "../../../../helpers";
import { ActionPropertyModel } from "../../models";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";

import * as AssetModule from "../../../../../Asset";

import "./ActionProperties.scss";

export interface IActionPropertiesStateProps {
  isProcessingData: boolean;
}

export interface IActionPropertiesDispatchProps { }

export interface IActionPropertiesOwnProps { }

export interface IActionPropertiesProps
  extends IActionPropertiesStateProps,
  IActionPropertiesDispatchProps,
  IActionPropertiesOwnProps,
  IComponentPropertiesBaseProps,
  WithTranslation { }

export interface IActionPropertiesState extends IComponentPropertiesBaseState {
  isBrowserVisible: boolean;
}

enum BrowserType {
  Screen = "SCREEN",
  Asset = "ASSET",
}

export class ActionProperties extends ComponentPropertiesBase<
  IActionPropertiesProps,
  IActionPropertiesState,
  ActionPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  public state: Readonly<IActionPropertiesState> = {
    isBrowserVisible: false,
  };

  formRef = React.createRef<IFormInstance>();

  constructor(props: IActionPropertiesProps, context?: any) {
    super(props, context);

    const { component, onComponentChange } = this.props;
    const { actionProperty, actionComponent } = this.getAction();

    this.properties.init(actionComponent);

    if (this.properties.ActionType) {
      if (!component.Properties) {
        component.Properties = [];
      }

      const actionPropertyIndex = component.Properties.findIndex(
        (row) => row.Name === "Action"
      );

      if (actionPropertyIndex < 0) {
        component.Properties.push(actionProperty);

        if (onComponentChange) {
          onComponentChange(component);
        }
      }
    }
  }

  getPropertiesModel(): ActionPropertyModel {
    const properties = new ActionPropertyModel();

    return properties;
  }

  getAction(): {
    actionProperty: IApplicationComponentPropertyModel;
    actionComponent: IApplicationComponentModel;
  } {
    const { component } = this.props;

    if (!component.Properties) {
      component.Properties = [];
    }

    const actionProperty = component.Properties.find(
      (row) => row.Name === "Action"
    ) || {
      Id: -1,
      Name: "Action",
      Guid: GuidHelper.newGuid(),
      RecordStatus: RecordStatus.Inserted,
      ApplicationComponentId: component.Id,
      IsEnumerable: false,
      PropertyType: PropertyType.Component,
      PlatformCode: this.properties.PlatformCode,
    };

    const actionComponent =
      actionProperty.Value && actionProperty.Value.Component
        ? actionProperty.Value.Component
        : {
          Id: -1,
          ApplicationConfigurationId: component.ApplicationConfigurationId,
          ComponentTypeCode: ComponentType.Action,
          PlatformCode: PlatformType.Any,
          Guid: GuidHelper.newGuid(),
          RecordStatus: RecordStatus.Inserted,
          IsVisible: true,
          Sequence: 0,
          Name: ComponentTypeHelper.getDescription(ComponentType.Action),
          Properties: [],
        };

    if (!actionProperty.Value) {
      actionProperty.Value = {
        Id: -1,
        Guid: GuidHelper.newGuid(),
        ApplicationComponentPropertyId: actionProperty.Id,
        Component: actionComponent,
        RecordStatus: RecordStatus.Inserted,
        ApplicationComponentPropertyValueSequence: 0,
      };
    }

    return {
      actionProperty,
      actionComponent,
    };
  }

  onPropertyChange(propertyName: string) {
    const { component, onComponentChange } = this.props;
    const { actionProperty, actionComponent } = this.getAction();

    if (!component.Properties) {
      component.Properties = [];
    }

    if (propertyName === "ActionType") {
      const propertyIndex = component.Properties.findIndex(
        (row) => row.Name === "Action"
      );

      if (this.properties.ActionType) {
        if (propertyIndex < 0) {
          if (actionProperty.RecordStatus === RecordStatus.Deleted) {
            actionProperty.RecordStatus = RecordStatus.Updated;
          }

          component.Properties.push(actionProperty);

          if (
            (component.RecordStatus || RecordStatus.NoChange) ===
            RecordStatus.NoChange
          ) {
            component.RecordStatus = RecordStatus.Updated;
          }
        }
      } else {
        if (propertyIndex >= 0) {
          if (actionProperty.RecordStatus === RecordStatus.Inserted) {
            component.Properties.splice(propertyIndex, 1);
          } else {
            actionProperty.RecordStatus = RecordStatus.Deleted;
          }
        }
      }
    }

    this.properties.onPropertyChange(propertyName, actionComponent);

    if (onComponentChange) {
      onComponentChange(component);
    }
  }

  deleteProperty(propertyName: string) {
    const { component, onComponentChange } = this.props;
    const { actionComponent } = this.getAction();

    this.properties.deleteProperty(propertyName, actionComponent);

    if (onComponentChange) {
      onComponentChange(component);
    }
  }

  public onActionTypePropertyChange = (value?: IChooseValue) => {
    const { actionComponent } = this.getAction();

    switch (this.properties.ActionType) {
      case ActionType.OpenScreen:
        this.properties.ScreenType = undefined;
        this.properties.ScreenId = undefined;
        this.properties.ScreenName = undefined;

        this.properties.deleteProperty("ScreenTypeCode", actionComponent);
        this.properties.deleteProperty("ScreenId", actionComponent);
        this.properties.deleteProperty("ScreenName", actionComponent);
        break;
      case ActionType.OpenUrl:
        this.properties.Url = undefined;

        this.properties.deleteProperty("Url", actionComponent);
        break;
      case ActionType.CallTo:
        this.properties.PhoneNo = undefined;

        this.properties.deleteProperty("PhoneNo", actionComponent);
        break;
      case ActionType.EmailTo:
        this.properties.Email = undefined;

        this.properties.deleteProperty("Email", actionComponent);
        break;
    }

    this.properties.ActionType = ActionTypeHelper.getValue(
      value ? (value as string) : undefined
    );
    this.onPropertyChange("ActionType");
  };

  public renderActionTypeProperty = () => {
    const { component, isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="ActionType"
        label={t("APPLICATION_CONFIGURATION__ACTION_TYPE_LABEL")}
        initialValue={this.properties.ActionType}
      >
        <Choose
          allowClear={true}
          disabled={isProcessingData}
          placeholder={t("APPLICATION_CONFIGURATION__ACTION_TYPE_PLACEHOLDER")}
          onChange={this.onActionTypePropertyChange}
        >
          {ActionTypeHelper.getOptions(component.ComponentTypeCode)}
        </Choose>
      </Form.Item>
    );
  };

  public renderUrlProperty = () => {
    const { isProcessingData, t } = this.props;

    if (this.properties.ActionType !== ActionType.OpenUrl) {
      return null;
    }

    return (
      <Form.Item
        name="Url"
        label={t("COMMON_URL")}
        initialValue={this.properties.Url}
      >
        <Input
          disabled={isProcessingData}
          placeholder={t("//")}
          onChange={(e) => {
            this.properties.Url = e.target.value;
            this.onPropertyChange("Url");
          }}
        />
      </Form.Item>
    );
  };

  public renderEmailProperty = () => {
    const { isProcessingData, t } = this.props;

    if (this.properties.ActionType !== ActionType.EmailTo) {
      return null;
    }

    return (
      <Form.Item
        name="Email"
        label={t("EMAIL_LABEL")}
        initialValue={this.properties.Email}
      >
        <Input
          disabled={isProcessingData}
          placeholder={t("")}
          onChange={(e) => {
            this.properties.Email = e.target.value;
            this.onPropertyChange("Email");
          }}
        />
      </Form.Item>
    );
  };

  public renderPhoneNoProperty = () => {
    const { isProcessingData, t } = this.props;

    if (this.properties.ActionType !== ActionType.CallTo) {
      return null;
    }

    return (
      <Form.Item
        name="PhoneNo"
        label={t("APPLICATION_CONFIGURATION__ACTION_PROPERTIES_PHONE")}
        initialValue={this.properties.PhoneNo}
      >
        <Input
          disabled={isProcessingData}
          placeholder={t("")}
          onChange={(e) => {
            this.properties.PhoneNo = e.target.value;
            this.onPropertyChange("PhoneNo");
          }}
        />
      </Form.Item>
    );
  };

  public onScreenTypeChange = (value?: IChooseValue) => {
    const { actionComponent } = this.getAction();

    const screenTypeCode = value ? (value as string) : undefined;

    if (screenTypeCode) {
      this.properties.ScreenType = ScreenTypeHelper.getValue(screenTypeCode);
      this.properties.ScreenId = undefined;
      this.properties.ScreenName = undefined;

      this.properties.deleteProperty("ScreenId", actionComponent);
      this.properties.deleteProperty("ScreenName", actionComponent);

      this.formRef?.current?.resetFields(["ScreenId"]);
    } else {
      this.properties.ScreenType = undefined;
      this.properties.deleteProperty("ScreenType", actionComponent);

      this.formRef?.current?.resetFields(["ScreenType"]);
    }

    this.onPropertyChange("ScreenTypeCode");
  };

  public renderScreenTypeProperty = () => {
    const { isProcessingData, t } = this.props;

    if (this.properties.ActionType !== ActionType.OpenScreen) {
      return null;
    }

    return (
      <Form.Item
        name="ScreenType"
        label={t("APPLICATION_CONFIGURATION__ACTION_PROPERTIES_SCREEN_TYPE")}
        initialValue={this.properties.ScreenType}
      >
        <Choose
          allowClear={true}
          disabled={isProcessingData}
          placeholder={t(
            "APPLICATION_CONFIGURATION__ACTION_PROPERTIES_SCREEN_PLACEHOOLDER"
          )}
          onChange={this.onScreenTypeChange}
        >
          {ScreenTypeHelper.getOptions()}
        </Choose>
      </Form.Item>
    );
  };

  public onScreenPropertyCancel = () => {
    this.setState({ isBrowserVisible: false });
  };

  public onScreenPropertySelect = (row: IApplicationScreenModel) => {
    this.properties.ScreenId = row.Id;
    this.properties.ScreenName = row.Name;
    this.onPropertyChange("ScreenId");
    this.onPropertyChange("ScreenName");

    this.setState({ isBrowserVisible: false });
  };

  public onScreenPropertyClear = () => {
    const { component, onComponentChange } = this.props;
    const { actionComponent } = this.getAction();

    this.properties.ScreenId = undefined;
    this.properties.ScreenName = undefined;
    this.properties.deleteProperty("ScreenId", actionComponent);
    this.properties.deleteProperty("ScreenName", actionComponent);

    this.formRef?.current?.resetFields(["ScreenId"]);

    if (onComponentChange) {
      onComponentChange(component);
    }
  };

  public onScreenAssetSelect = (row: IAssetModel) => {
    this.properties.ScreenId = row.Id;
    this.properties.ScreenName = row.Title;
    this.onPropertyChange("ScreenId");
    this.onPropertyChange("ScreenName");

    this.setState({ isBrowserVisible: false });
  };

  public renderScreenProperty = () => {
    const { component, t } = this.props;
    const { isBrowserVisible } = this.state;

    let browserType = BrowserType.Screen;

    if (this.properties.ActionType !== ActionType.OpenScreen) {
      return null;
    }

    var filter: IApplicationsScreensSearchFilterModel = {
      ApplicationConfigurationId: component.ApplicationConfigurationId,
      IncludeDefinitions: true,
    };

    var filterAssetType: AssetType[] = [];

    switch (this.properties.ScreenType) {
      case ScreenType.PackageDetails:
        filterAssetType = [AssetType.Package];
        browserType = BrowserType.Asset;
        break;
      case ScreenType.LiveDetails:
        filterAssetType = [AssetType.Live];
        browserType = BrowserType.Asset;
        break;
      case ScreenType.MovieDetails:
        filterAssetType = [AssetType.Video, AssetType.Episode, AssetType.Season];
        browserType = BrowserType.Asset;
        break;
      case ScreenType.SeriesDetails:
        filterAssetType = [AssetType.Series];
        browserType = BrowserType.Asset;
        break;
    }

    if (this.properties.ScreenType) {
      filter.ScreenTypeCode = this.properties.ScreenType;
    }

    let sourceValueView: React.ReactElement = this.properties.ScreenId ? (
      <label>
        <Link
          to={`${ROUTES.CONFIGURATION_SCREEN_DETAILS}/${this.properties.ScreenId}`}
        >
          {this.properties.ScreenName || this.properties.ScreenId}
        </Link>
      </label>
    ) : (
      <label>{t(browserType === BrowserType.Screen ? "ACTION_PROPERTIES_SCREEN_PLACEHOLDER" : "ACTION_PROPERTIES_ASSET_PLACEHOLDER")}</label>
    );

    return (
      <Form.Item
        name="ScreenId"
        label={t(browserType === BrowserType.Screen ? "ACTION_PROPERTIES_SCREEN_LABEL" : "ACTION_PROPERTIES_ASSET_LABEL")}
        initialValue={{
          key: `${this.properties.ScreenId}`,
          label: this.properties.ScreenName,
        }}
      >
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item>{sourceValueView}</Form.Item>
            {browserType === BrowserType.Asset && (<AssetModule.Components.AssetBrowserModal
              visible={isBrowserVisible}
              selectionMode={"single"}
              selectedType={filterAssetType}
              onSelect={this.onScreenAssetSelect}
              onCancel={() => this.setState({ isBrowserVisible: false })}
            />)}
            {browserType === BrowserType.Screen && (<ApplicationScreensBrowserModal
              visible={isBrowserVisible}
              filter={filter}
              onCancel={this.onScreenPropertyCancel}
              onSelect={this.onScreenPropertySelect}
            />)}
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {this.properties.ScreenId && (
              <Button
                icon={<Icon type="delete" />}
                onClick={this.onScreenPropertyClear}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                this.setState({ isBrowserVisible: true });
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  // form: ActionProperties

  public render() {
    return (
      <React.Fragment>
        {this.renderActionTypeProperty()}
        {this.renderScreenTypeProperty()}
        {this.renderScreenProperty()}
        {this.renderUrlProperty()}
        {this.renderEmailProperty()}
        {this.renderPhoneNoProperty()}
      </React.Fragment>
    );
  }
}
