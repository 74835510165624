import { ICommonAppState, IUserModel, UserStore } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IUserAccountInformationFormDispatchProps, IUserAccountInformationFormOwnProps, IUserAccountInformationFormStateProps, UserAccountInformationForm as UserAccountInformationFormDefinition } from "./UserAccountInformationForm";

const mapStateToProps = (state: ICommonAppState): IUserAccountInformationFormStateProps => {
  return {
    actionType: state.user.actionType,
    isProcessingData: state.user.isProcessingData,
    error: state.user.error,
    profiles: state.user.profiles,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IUserAccountInformationFormDispatchProps => ({
  updateUser: (data: IUserModel) => {
    return dispatch(UserStore.Actions.updateUser(data));
  },
  selectProfiles: () => {
    return dispatch(UserStore.Actions.selectProfiles());
  },
});

export const UserAccountInformationForm = connect<IUserAccountInformationFormStateProps, IUserAccountInformationFormDispatchProps, IUserAccountInformationFormOwnProps, ICommonAppState>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserAccountInformationFormDefinition));
