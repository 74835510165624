import { Form, InputNumber } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ILeagueFormFieldCommonProps } from "./ILeagueFormFieldCommonProps";

interface ILevelFieldProps extends ILeagueFormFieldCommonProps {}

export const LevelField = ({
  isHidden,
  isEditMode,
  league,
}: ILevelFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="Level"
      label={t("MODEL_LEVEL")}
      key="level"
      initialValue={isEditMode ? league?.Level || undefined : undefined}
      rules={[{ required: true }]}
    >
      <InputNumber min={0} precision={0} />
    </Form.Item>
  );
};
