import { ITEM_NAMES } from "../constants";
import { useTranslation } from "react-i18next";
import {
  IVoucherModel,
  VoucherDiscountType,
  VoucherRedeemType,
} from "@bms/common-services";
import { Form } from "@bms/common-ui";

interface IDiscountCurrentValueItemProps {
  voucher: IVoucherModel;
}

export const DiscountCurrentValueItem = ({
  voucher,
}: IDiscountCurrentValueItemProps) => {
  const [t] = useTranslation();

  if (
    !voucher ||
    voucher?.DiscountType !== VoucherDiscountType.Fixed ||
    voucher?.RedeemType !== VoucherRedeemType.OneTime
  ) {
    return null;
  }

  return (
    <Form.Item
      name={ITEM_NAMES.CURRENT_VALUE}
      label={t("PAYMENT_VOUCHER_DISCOUNT_CURRENT_VALUE_COLUMN")}
    >
      <label>{voucher.CurrentValue}</label>
    </Form.Item>
  );
};
