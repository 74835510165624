import {
  ISportMatchModel,
  SportMatchService,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { useAppFeedback, useSyncedState } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { useMatchesDataPager } from "../../../hooks";
import { ROUTES } from "../../../constants";

const matchesService = new SportMatchService().promisify();

export const useMatchDetailsController = () => {
  const id = parseInt(useParams<{ id: string }>()["id"]);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [dataPager, dataPagerLoading] = useMatchesDataPager(id);

  const { data, loading, refresh } = useDataLoader({
    loader: () => matchesService.getMatch(id),
    deps: [id],
  });

  const [match, setMatch] = useSyncedState(() => data, [data]);

  const { modal, notification } = useAppFeedback();

  const [updateMatch, updateMatchState] = useServiceCaller(
    async (data: ISportMatchModel) => {
      const result = await matchesService.updateMatch(data);
      if (result.ok) {
        setMatch(result.data);
        notification.success({
          message: t("SPORT_MATCH_DETAILS__MATCH_UPDATE_SUCCESS"),
        });
        refresh();
      } else {
        notification.error({
          message: t("SPORT_MATCH_DETAILS__MATCH_UPDATE_FAILURE"),
          description: result.error.Message,
        });
      }
    },
    []
  );

  const [deleteMatch] = useServiceCaller(async () => {
    const result = await matchesService.deleteMatch(match!);
    if (result.ok) {
      notification.success({
        message: t("SPORT_MATCH_DETAILS__MATCH_DELETE_SUCCESS"),
      });
      history.push(ROUTES.MATCHES);
    } else {
      notification.error({
        message: t("SPORT_MATCH_DETAILS__MATCH_DELETE_FAILURE"),
        description: result.error?.Message,
      });
    }
  }, [match]);

  const onDeleteClick = () => {
    if (!match) {
      return;
    }

    modal.confirm({
      title: t("COMMON_DELETE"),
      content: t("SPORT_MATCH_DETAILS__MATCH_DELETE_MESSAGE", {
        matchName: `${match?.HomeTeamName || ""} - ${
          match?.AwayTeamName || ""
        }`,
      }),
      okText: t("BUTTON_DELETE"),
      cancelText: t("BUTTON_CANCEL"),
      onOk: deleteMatch,
    });
  };

  const onBackClick = () => {
    if (location.state && location.state.hasOwnProperty("from")) {
      // use location from router state
      const state = location.state as { from: Location };
      history.push(state.from);
    } else {
      // use matches list location
      history.goBack();
    }
  };

  return {
    updateMatchState,
    match,
    loading,
    dataPager,
    dataPagerLoading,
    refresh,
    updateMatch,
    onDeleteClick,
    onBackClick,
    t,
  };
};
