import { Button, Col, Form, Icon, Input, Row } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { ISportClubModel } from "@bms/common-services";
import { useState } from "react";
import { ClubsBrowserModal } from "../../../Clubs";
import { ITeamFormFieldCommonProps } from "./ITeamFormFieldCommonProps";
import { SyncedClub } from "../useTeamFormController";

interface IClubIdFieldProps extends ITeamFormFieldCommonProps {
  onSelect?: (row?: ISportClubModel) => void;
  onClear?: () => void;
  club: SyncedClub;
}

export const ClubIdField = ({
  isHidden,
  isEditMode,
  team,
  onSelect,
  onClear,
  club,
}: IClubIdFieldProps) => {
  const { t } = useTranslation();

  const [clubBrowserVisible, setClubBrowserVisible] = useState(false);

  if (isHidden) {
    return null;
  }

  const onClubSelect = (row: ISportClubModel) => {
    setClubBrowserVisible(false);
    onSelect?.(row);
  };

  return (
    <Form.Item
      name="ClubId"
      label={t("MODEL_CLUB")}
      key="clubId"
      initialValue={(isEditMode ? team?.ClubName : "") || ""}
      rules={[{ required: true, message: t("REQUIRED_VALIDATION_MESSAGE") }]}
    >
      <Row gutter={8}>
        <Col flex="auto">
          <Input
            placeholder={t("MODEL_CLUB_PLACEHOLDER")}
            disabled={!!club.Id}
            value={club.Name}
          />
          <ClubsBrowserModal
            visible={clubBrowserVisible}
            onCancel={() => setClubBrowserVisible(false)}
            onSelect={onClubSelect}
          />
        </Col>
        <Col style={{ textAlign: "right" }}>
          {club.Id && (
            <Button
              icon={<Icon type="delete" />}
              onClick={onClear}
              style={{ marginRight: "8px" }}
            />
          )}
          <Button
            icon={<Icon type="edit" />}
            onClick={() => setClubBrowserVisible(true)}
          />
        </Col>
      </Row>
    </Form.Item>
  );
};
