import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ICommonAppState,
  SportStatisticsUnitModel,
} from "@bms/common-services";
import {
  Button,
  IBreadcrumbProps,
  Icon,
  ITableColumnProps,
  PageContent,
  PageHeader,
  Popconfirm,
  Table,
  TagUpToDate,
  useAppFeedback,
} from "@bms/common-ui";
import { SportStore } from "@bms/common-services";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { generateBreadcrumb } from "../../../../../helpers";
import { ROUTES } from "../../../constants";
import { FormModal } from "../../../../../components";
import { StatisticsUnitsForm } from "../StatisticsUnitsForm";

const defaultStatisticsUnit: SportStatisticsUnitModel = {
  Name: "",
  Code: "",
  UpToDate: false,
};

export const StatisticsUnitsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const statisticsUnits = useSelector(
    SportStore.Selectors.statisticsUnitsSelector
  );
  const actionType = useSelector(
    (state: ICommonAppState) => state.common.actionType
  );

  const isFetching = useSelector(
    (state: ICommonAppState) => state.sport.statisticsUnits.IsProcessing
  );

  const { notification } = useAppFeedback();

  const [showStatisticsUnitModal, setShowStatisticsUnitModal] = useState(false);

  const [editableStatisticsUnit, setEditableStatisticsUnit] = useState<
    SportStatisticsUnitModel
  >(defaultStatisticsUnit);

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.STATISTICS_UNITS}`,
        breadcrumbName: t("MENU_SPORT_STATISTICS_UNITS"),
      },
    ]);
  };

  const getColumnsProps = (): Array<
    ITableColumnProps<SportStatisticsUnitModel>
  > => {
    return [
      {
        key: "Code",
        dataIndex: "Code",
        title: t("MODEL_CODE"),
        render: (text: any, row: SportStatisticsUnitModel) => {
          return (
            <a
              title={row.Code}
              onClick={() => {
                setShowStatisticsUnitModal(true);
                setEditableStatisticsUnit(row);
              }}
            >
              {row.Code}
            </a>
          );
        },
      },
      {
        key: "Name",
        dataIndex: "Name",
        title: t("MODEL_NAME"),
      },
      {
        key: "UpToDate",
        dataIndex: "UpToDate",
        title: t("MODEL_UP_TO_DATE"),
        render: (upToDate: boolean) => <TagUpToDate value={upToDate} />,
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_: any, row: SportStatisticsUnitModel) => (
          <Popconfirm
            title={t(
              "DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION",
              "Are you sure you want to delete element?"
            )}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              dispatch(SportStore.Actions.deleteStatisticsUnit(row));
            }}
            okText={t("BUTTON_YES", "Yes")}
            cancelText={t("BUTTON_NO", "No")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT", "Delete element")}
            />
          </Popconfirm>
        ),
      },
    ];
  };

  const closeModal = () => {
    setShowStatisticsUnitModal(false);
    setEditableStatisticsUnit(defaultStatisticsUnit);
  };

  const onAddNewStatisticsUnitClick = () => {
    setShowStatisticsUnitModal(true);
  };

  const onRefreshClick = () => {
    dispatch(SportStore.Actions.getStatisticsUnits());
  };

  const insertStatisticsUnit = (statisticsUnit: SportStatisticsUnitModel) => {
    dispatch(SportStore.Actions.createStatisticsUnit(statisticsUnit));
  };

  const updateStatisticsUnit = (statisticsUnit: SportStatisticsUnitModel) => {
    dispatch(SportStore.Actions.updateStatisticsUnit(statisticsUnit));
  };

  const getExtraButtons = () => {
    return (
      <>
        <Button
          key="add"
          shape="circle"
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAddNewStatisticsUnitClick}
          title={t("DICTIONARY_STATISTICS_UNIT_ADD_NEW")}
        />
        <Button
          key="reload"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={onRefreshClick}
          title={t("BUTTON_REFRESH_TITLE")}
        />
      </>
    );
  };

  useEffect(() => {
    dispatch(SportStore.Actions.getStatisticsUnits());
  }, [dispatch]);

  useEffect(() => {
    switch (actionType) {
      case SportStore.Consts.CREATE_STATISTICS_UNIT_SUCCESS:
        onRefreshClick();
        closeModal();
        notification.success({
          message: t("STATISTICS_UNIT_INSERT_SUCCESS"),
        });
        break;

      case SportStore.Consts.CREATE_STATISTICS_UNIT_FAILURE:
        notification.error({
          message: t("STATISTICS_UNIT_INSERT_FAILURE"),
          description: statisticsUnits.Error?.Message,
        });
        break;

      case SportStore.Consts.UPDATE_STATISTICS_UNIT_SUCCESS:
        onRefreshClick();
        closeModal();
        notification.success({
          message: t("STATISTICS_UNIT_UPDATE_SUCCESS"),
        });
        break;

      case SportStore.Consts.UPDATE_STATISTICS_UNIT_FAILURE:
        notification.error({
          message: t("STATISTICS_UNIT_UPDATE_FAILURE"),
          description: statisticsUnits.Error?.Message,
        });
        break;

      case SportStore.Consts.DELETE_STATISTICS_UNIT_SUCCESS:
        onRefreshClick();
        closeModal();
        notification.success({
          message: t("STATISTICS_UNIT_DELETE_SUCCESS"),
        });
        break;

      case SportStore.Consts.DELETE_STATISTICS_UNIT_FAILURE:
        notification.error({
          message: t("STATISTICS_UNIT_DELETE_FAILURE"),
          description: statisticsUnits.Error?.Message,
        });
        break;
    }
  }, [statisticsUnits.Error, actionType, t]);

  return (
    <div>
      <PageContent>
        <PageHeader
          title={t("DICTIONARY_STATISTICS_UNIT_TITLE", "Statistics Units")}
          breadcrumb={getBreadcrumbProps()}
          extra={getExtraButtons()}
        />
        <Table<SportStatisticsUnitModel>
          columns={getColumnsProps()}
          dataSource={statisticsUnits.Data}
          loading={isFetching}
          pagination={false}
        />
      </PageContent>
      <FormModal
        isLoading={false}
        isVisible={showStatisticsUnitModal}
        isNewForm={!editableStatisticsUnit.Code?.length}
        isDeleteButtonEnabled={false}
        createFormTitle={t(
          "DICTIONARY_STATISTICS_UNIT_MODAL_NEW",
          "New statistics unit"
        )}
        modalClassName="DictionaryStatisticsUnitModal"
        submitFormName="DictionaryStatisticsUnitsForm"
        onCloseModal={closeModal}
        editFormTitle={t("DICTIONARY_STATISTICS_UNIT_MODAL_EDIT")}
      >
        <StatisticsUnitsForm
          statisticsUnit={editableStatisticsUnit}
          insertStatisticsUnit={insertStatisticsUnit}
          updateStatisticsUnit={updateStatisticsUnit}
          isProcessing={!!isFetching}
        />
      </FormModal>
    </div>
  );
};
