import {
  AnalyticsAggregationPeriodType,
  IAnalyticsSalesRevenueAssetModel,
} from "@bms/common-services";
import { IStackedBarChart2DataSet } from "@bms/common-ui";
import { IAnalyticsSalesRevenueAssetWithColorsModel, TGroupBy } from "../SalesRevenue";

export interface ISalesRevenueGrupedData {
  [key: string]: IAnalyticsSalesRevenueAssetModel[];
}

export const groupBy = (
  data: IAnalyticsSalesRevenueAssetModel[] = [],
  groupBy: keyof IAnalyticsSalesRevenueAssetModel
) => {
  const result: ISalesRevenueGrupedData = {};
  data.forEach((it) => {
    if (!result[it[groupBy]]) {
      result[it[groupBy]] = [];
    }
    result[it[groupBy]].push(it);
  });
  return result;
};

const padZero = (str: string, len: number) => {
  let result = str;
  while (result.length < len) {
    result = `0${result}`;
  }
  return result;
};

export const getLabels = (data: IAnalyticsSalesRevenueAssetModel[] = []) =>
  Array.from(new Set(data.map((it) => it.Period)));

export const convertLabelsByAggregationPeriodType = (
  labels: string[],
  type: AnalyticsAggregationPeriodType = AnalyticsAggregationPeriodType.YEAR
) =>
  labels.map((it) => {
    const [year, month, day] = it.split("T")[0].split("-");
    switch (type) {
      case AnalyticsAggregationPeriodType.DAY:
        return `${day}-${month}-${year}`;
      case AnalyticsAggregationPeriodType.MONTH:
        return `${month}-${year}`;
      case AnalyticsAggregationPeriodType.YEAR:
        return `${year}`;
      default:
        return "";
    }
  });

export const colorOfAsset = (
  found: IAnalyticsSalesRevenueAssetModel,
  assetsListWithColors:
    | IAnalyticsSalesRevenueAssetWithColorsModel[]
    | undefined,
  _groupBy: TGroupBy
) => {
  return assetsListWithColors?.find((obj) => {
    return _groupBy === "asset"
      ? obj.AssetId === found?.AssetId
      : obj.CreatorUserId === found?.CreatorUserId;
  });
};

export const prepareChartData = (
  data: IAnalyticsSalesRevenueAssetModel[] = [],
  aggregationPeriod: AnalyticsAggregationPeriodType = AnalyticsAggregationPeriodType.YEAR,
  _groupBy: TGroupBy = "asset",
  assetsListWithColors: IAnalyticsSalesRevenueAssetWithColorsModel[] | undefined
) => {
  const labels = getLabels(data);
  const groupedBy = groupBy(
    data,
    _groupBy === "asset" ? "AssetId" : "CreatorUserId"
  );
  const datasets: IStackedBarChart2DataSet<number>[] = [];

  Object.keys(groupedBy).forEach((key) => {
    const dataset: IStackedBarChart2DataSet<number> = {
      data: [],
      backgroundColor: undefined,
    };
    labels.forEach((lbl, index) => {
      const found = groupedBy[key].find((val) => val.Period === lbl);
      if (found) {
        const assetWithColor = colorOfAsset(
          found,
          assetsListWithColors,
          _groupBy
        );
        if (!dataset.label) {
          dataset.label =
            _groupBy === "asset"
              ? `${found.CreatorUserName} - ${found.AssetTitle}`
              : found.CreatorUserName;
        }
        dataset.data[index] = found.Count;
        dataset.backgroundColor = assetWithColor?.Color;
      } else {
        dataset.data[index] = null;
      }
    });
    datasets.push(dataset);
  });

  return {
    labels: convertLabelsByAggregationPeriodType(labels, aggregationPeriod),
    datasets,
  };
};
